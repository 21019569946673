import React from 'react';
import Dropdown from '~/presentation/components/drop-down';
import { IconArrowSelect } from '~/presentation/base/icons';

const TestDropdown: React.FC = () => {
  const icon = <IconArrowSelect />;
  return (
    <Dropdown icon={icon}>
      <h1>aqui vao os items</h1>
    </Dropdown>
  );
};

export default TestDropdown;
