import React from 'react';
import { AvatarBadger } from '../../presentation/components/avatar-roles';

// Como Utilizar o Componente ?
//  Vamos lá....
// O Componente precisa de 3 props para ser utilizado:
// name: Nome do Usuário
// src: Url da imagem
// size: Tamanho do Avatar
// Existe 4 tipos de size
// root: Menor tamanho
// small: um Pouco maior que o root
// medium: Tamanho Médio
// large: Maior tamanho

const data = {
  src:
    'https://avatars.githubusercontent.com/u/54696213?s=460&u=06f5eeb79a01f54bd7557019a643017051840dc2&v=4',
  name: 'Denilson',
};

const TestAvatarBadger = () => {
  return (
    <AvatarBadger name={data.name} src={data.src} size="root" status={false} />
  );
};

export default TestAvatarBadger;
