import React, { useState } from 'react';

import { Button } from '~/presentation/components/UI';

import { IconWaitingRoom, IconV4Hatendimento } from '~/presentation/base/icons';
import { Icon } from '~/presentation/components/icon';

import { translator } from '~/presentation/components/i18n';

import { Container, Header, Image } from './styles/StyledReady';

import { History } from '~/main/routes';

interface ownProps {
  appointmentId: number;
  waitingRoomUserId: number;
  professionalId: number;
  consultantId: number;
  left: () => any;
}

export default function Ready(props: ownProps): JSX.Element {
  const {
    appointmentId,
    waitingRoomUserId,
    left,
    consultantId,
    professionalId,
  } = props;

  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const start = () => {
    window.onpopstate = null;

    History.getHistory().push('/conf', {
      appointmentId,
      waitingRoomUserId,
      professionalId,
      consultantId,
    });
  };

  return (
    <Container>
      <Header>
        {logoUrl ? (
          <Image alt="Logo" src={logoUrl} width="200px" />
        ) : (
          <Icon src={IconV4Hatendimento} width="250px" height="80px" />
        )}
      </Header>
      <span className="title">{translator('O paciente está pronto')}</span>
      <IconWaitingRoom />
      <span className="message">
        {translator('Você já pode iniciar o atendimento')}
      </span>
      <span className="message">{translator('clicando em avançar')}</span>
      <Button onClick={start} rounded>
        {translator('Avançar')}
      </Button>
      <Button variant="secundary" onClick={left} rounded>
        {translator('Deixar sala')}
      </Button>
    </Container>
  );
}
