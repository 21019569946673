import React from 'react';
import { iListChatUsers } from './interface';
import { Container, Body } from './styles/StyledAvatarChat';
import ItemHistoryChat from './ItemHistoryChat';
import { ConnectComponent } from './mapper/MapperAvatarChat';

export interface ownProps {
  users: iListChatUsers[];
  openChat: Function;
}

const AvatarChat: React.FC<ownProps> = ({ users, openChat }) => {
  return (
    <Container>
      <Body>
        {users.map(user => (
          <ItemHistoryChat
            key={user.id}
            open={(id: number) => openChat(id)}
            user={user}
          />
        ))}
      </Body>
    </Container>
  );
};

export default ConnectComponent(AvatarChat);
