/*
example: const data = [{date:02/02/22},{date:02/03/23},{...}]
call: sortByParameter(data,"date")
important: type data is [{}] (array of object)
important: rename your date parameter to date
*/

export interface iData {
  cod: string;
  cpf: string;
  date: string;
  email: string;
  name: string;
  phone: string;
  status: boolean;
  surname: string;
  age: string;
  time: string;
  origin: string;
  clinicalDocument: string;
  statusSignature: boolean;
}

export interface iData2 {
  cod: string;
  cpf: string;
  date: string;
  email: string;
  name: string;
  phone: string;
  status: 'progress' | 'available' | 'finished' | 'canceled' | 'scheduled';
  surname: string;
  professional: string;
  specialty: string;
  participant: string;
  status2: string;
  timeStart: string;
  timeEnd: string;
  typeIs: string;
  register: string;
  age: string;
  time: string;
  origin: string;
  clinicalDocument: string;
  statusSignature: 'SIGNED' | 'SUBMITTED' | 'NOTREQUESTED';
}

export type iParameter =
  | 'cod'
  | 'cpf'
  | 'date'
  | 'email'
  | 'name'
  | 'phone'
  | 'status'
  | 'age'
  | 'origin'
  | 'clinicalDocument'
  | 'statusSignature'
  | 'time'
  | 'surname';

export type iParameter2 =
  | 'cod'
  | 'cpf'
  | 'date'
  | 'email'
  | 'name'
  | 'phone'
  | 'status'
  | 'surname'
  | 'professional'
  | 'specialty'
  | 'participant'
  | 'status2'
  | 'timeStart'
  | 'timeEnd'
  | 'typeIs'
  | 'age'
  | 'origin'
  | 'statusSignature'
  | 'time'
  | 'clinicalDocument'
  | 'register';

export const sortByParameter = (
  data: iData[],
  parameter: iParameter,
): iData[] => {
  if (parameter === 'date') {
    data.sort((a, b) => {
      if (new Date(a[parameter]) > new Date(b[parameter])) return 1;
      if (new Date(a[parameter]) < new Date(b[parameter])) return -1;
      return 0;
    });
  } else {
    data.sort((a: iData, b: iData) => {
      if (a[parameter] > b[parameter]) return 1;
      if (a[parameter] < b[parameter]) return -1;
      return 0;
    });
  }

  return data;
};

export const sortByParameter2 = (
  data: iData2[],
  parameter: iParameter2,
): iData2[] => {
  if (parameter === 'date') {
    data.sort((a, b) => {
      if (new Date(a[parameter]) > new Date(b[parameter])) return 1;
      if (new Date(a[parameter]) < new Date(b[parameter])) return -1;
      return 0;
    });
  } else {
    data.sort((a: iData2, b: iData2) => {
      if (a[parameter] > b[parameter]) return 1;
      if (a[parameter] < b[parameter]) return -1;
      return 0;
    });
  }

  return data;
};
