import React, { useState } from 'react';
import {
  ContainerChooseDocuments,
  ContentInputs,
  ButtonAdd,
  ContainerLeft,
  ContainerRight,
  ListPrescription,
  Dosage,
  Drug,
  Posology,
} from './styles/StyledChooseClinicalDocument';

import { Input, Button } from '~/presentation/components/UI';

import { ItemPrescription } from '~/presentation/components/prescription';
import { iPrescription } from './interface';

import { translator } from '~/presentation/components/i18n';

const ChooseClinicalDocument: React.FC = () => {
  const [prescription, setPrescription] = useState<iPrescription[]>([]);
  const [state, setState] = useState<iPrescription>({
    name: '',
    dosage: '',
    posology: '',
  });

  function handleAdd() {
    setPrescription([...prescription, state]);
    setState({
      name: '',
      dosage: '',
      posology: '',
    });
  }
  const content = [
    {
      drug: 'Rivotril',
      posology: '1x antes de dormir',
      dosage: '0,23mg',
    },
    {
      drug: 'Droga',
      posology: '2x antes de dormir',
      dosage: '0,65mg',
    },
  ];
  return (
    <ContainerChooseDocuments>
      <ContainerLeft>
        <ContentInputs>
          <Drug>
            <Input
              label="Droga"
              placeholder="Inserir Medicação"
              value={state.name}
              onChange={e => setState({ ...state, name: e.target.value })}
            />
          </Drug>
          <Dosage>
            <Input
              label="Dosagem"
              placeholder="Inserir Dosagem"
              value={state.dosage}
              onChange={e => setState({ ...state, dosage: e.target.value })}
            />
          </Dosage>
          <Posology>
            <Input
              label="Posologia"
              placeholder="Inserir Posologia"
              value={state.posology}
              onChange={e => setState({ ...state, posology: e.target.value })}
            />
          </Posology>
        </ContentInputs>
        <ButtonAdd>
          <p>
            Após preencher os campos, clique em “Adicionar” para incluir a
            medicação na prescrição da receita.
          </p>
          <Button
            id="btn_addDrug"
            size="medium"
            rounded
            variant="secundary"
            color="blue"
            onClick={handleAdd}
          >
            {'Adicionar >>'}
          </Button>
        </ButtonAdd>
      </ContainerLeft>
      <ContainerRight>
        <h2>Drogas Inseridas</h2>
        <ListPrescription>
          <ItemPrescription content={prescription} />
        </ListPrescription>
      </ContainerRight>
    </ContainerChooseDocuments>
  );
};

export default ChooseClinicalDocument;
