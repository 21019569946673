import React from 'react';

import { Input } from '~/presentation/components/UI';

import { Container } from './styles';

const InputsPage: React.FC = () => {
  return (
    <Container>
      <Input
        label="Input label"
        message="Helper text"
        placeholder="Placeholder"
        name="default"
        autoFocus
      />
      <br />
      <Input
        label="Input label"
        message="Helper text"
        placeholder="Placeholder"
        name="default"
        disabled
      />
      <br />
      <Input
        label="Input label"
        message="Error message"
        placeholder="Placeholder"
        name="default"
        error
      />
    </Container>
  );
};

export default InputsPage;
