import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iChat, iStore } from '~/domain/interfaces/models';

import { ownProps } from '../AvatarChat';
import { iListChatUsers } from '../interface';
import { makeReduxGetChat } from '~/main/factories/usecases/chat/GetChatFactory';
import { makeReduxGetAllChatMessage } from '~/main/factories/usecases/chat/GetAllChatMessageFactory';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const chat = useSelector((store: iStore) => store.chat);
    const userLogged = useSelector((store: iStore) => store.auth.info.user?.id);

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      users: MapChatUsers(chat, userLogged),
      openChat: (id: number) => {
        // makeReduxGetAllChatMessage().getAllMessage({
        //   pageSize: 99990,
        //   chatId: id,
        // });
        // makeReduxGetChat().get({ id });
      },
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param clinicalDocs data.
 */
const MapChatUsers = (
  clinicalDocs: iChat,
  userLogged: number | undefined,
): iListChatUsers[] => {
  const arr: iListChatUsers[] = [];
  // const { clinicalDocs }: iStore = store.getState();

  try {
    const filter = clinicalDocs.chats.filter(item => {
      return item.participants.some(i => i.id === userLogged);
    });

    filter.forEach(item => {
      const part = item.participants.find(el => el.id !== userLogged);
      arr.push({
        id: item.id,
        name: part?.firstName,
        role: part?.mainRole,
        src: '',
        newMessageCount: 10,
      });
    });
  } catch (e) {
    console.log('avatar chat mapper error: ', e);
  }

  return arr;
};
