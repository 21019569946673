import { CreateSocketChatMessage } from '~/domain/usecases/chat/redux';
import {
  ChatTypes,
  iActionSocketCreateMessage,
  iActionSocketCreateMessageSuccess,
  iActionSocketCreateMessageFailed,
} from '~/domain/interfaces/redux/chat/socketCreateMessage';

export const createSocketChatMessageRequest = (
  payload: CreateSocketChatMessage.Params,
): iActionSocketCreateMessage => ({
  type: ChatTypes.SOCKET_CREATE_MESSAGE,
  payload,
});

export const createSocketChatMessageSuccess = (
  params: CreateSocketChatMessage.Model,
): iActionSocketCreateMessageSuccess => ({
  type: ChatTypes.SOCKET_CREATE_MESSAGE_SUCCESS,
  payload: params,
});

export const createSocketChatMessageFailed = (): iActionSocketCreateMessageFailed => ({
  type: ChatTypes.SOCKET_CREATE_MESSAGE_FAILED,
});
