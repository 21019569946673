/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { iStore } from '~/domain/interfaces/models';
import { Navigator } from '../registerAppointment/Navigator';
import { IconAddProfessional } from '~/presentation/base/icons';

import { Button, Select } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';
import { ListParticipatingProfessionals } from '../interConsultaListParticipant';
import {
  Content,
  StyleButton,
  Container,
  ContentSelects,
  ContainerButtonsGrid,
  ContentButtonsGrid,
} from './styles/styledProfessional';
import { iProfessional, iListSpeciality } from './interface';
import { ConnectComponent } from './mapper/MapperProfessional';
import { AddAppointmentData } from '~/domain/usecases/appointment/redux/AddApointmentData';
import { makeReduxSetupProfession } from '~/main/factories/usecases/profession';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';

export interface ownProps {
  state?: iProfessional[];
  handlerState: AddAppointmentData;
  professional: iProfessional[];
  speciality: iListSpeciality[];
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Professional: React.FC<ownProps> = ({
  state,
  handlerState,
  professional,
  speciality,
}): JSX.Element => {
  const [checked, setChecked] = useState<boolean>(false);
  const [participant, setParticipant] = useState<iProfessional[]>([]);
  const [saveIds, setSaveIds] = useState<number[]>([]);
  const [stateModal, setStateModal] = useState<iProfessional>();
  const [update, setUpdate] = useState<iProfessional>({
    id: 0,
    name: '',
    specialty: '',
    type: '',
    phone: '',
    email: '',
  });

  const { results } = useSelector((store: iStore) => store.professions);

  const { results: specialties } = useSelector(
    (store: iStore) => store.specialty,
  );

  const role = useSelector((states: iStore) => states.auth.info.user?.mainRole);

  function handleAdd() {
    if (!update.name) return;

    if (update.id && !saveIds.includes(update.id)) {
      setSaveIds([...saveIds, update.id]);
    }

    setParticipant([...participant, update]);
    setUpdate({
      id: 0,
      name: '',
      specialty: '',
      type: '',
      phone: '',
      email: '',
    });
  }

  useEffect(() => {
    if (!stateModal?.name) return;
    setParticipant([...participant!, stateModal!]);
  }, [stateModal]);

  useEffect(() => {
    return () => {
      if (participant.length <= 0) return;
      const professionalMain = participant[0];
      const newUser: {
        name: string;
        specialty: string;
        phone: string;
        email: string;
        id: number;
      }[] = [];
      participant.forEach(user => {
        newUser.push({
          name: user?.name,
          specialty: user?.specialty,
          phone: user?.phone,
          email: user?.email,
          id: user?.id!,
        });
      });
      handlerState.add({
        data: {
          professional: professionalMain?.id,
          professionals: newUser,
        },
      });
    };
  }, [participant]);

  const classes = useStyles();

  return (
    <Container>
      <Content>
        <ContentSelects>
          <Select
            id="attendanceArea"
            width="500px"
            label="Área do atendimento"
            placeholder="Selecione a área"
            onChange={e => {
              makeReduxSetupProfession().setup({
                professionId: Number(e.target.value),
              });
            }}
            required
          >
            <option value={-1}>Selecione a área de atendimento</option>
            {results.map(item => (
              <option value={Number(item.profession.id)}>
                {item.profession.name}
              </option>
            ))}
          </Select>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Select
              disabled={checked}
              label={translator('Profissional')}
              value={JSON.stringify(update)}
              width="430px"
              onChange={e => setUpdate(JSON.parse(e.target.value))}
            >
              <option value="select">
                {translator('Selecione um profissional')}
              </option>
              {professional.map(item => {
                if (item.id && saveIds.includes(item.id)) return null;
                return (
                  <option
                    value={JSON.stringify({
                      id: Number(item.id),
                      name: item.name,
                      specialty: item.specialty,
                      type: item.type,
                    })}
                  >
                    {`${item.name}`}
                  </option>
                );
              })}
            </Select>
            <StyleButton type="button" onClick={() => handleAdd()}>
              +
            </StyleButton>
          </div>
        </ContentSelects>
        <div className="input">
          <ContainerButtonsGrid>
            <ContentButtonsGrid>
              <Select
                id="appointmentSpeciality"
                name="specialty"
                width="485px"
                label={translator('Especialidade')}
                onChange={e => Number(e.target.value)}
              >
                <option value={-1}>
                  {translator('Selecione uma especialidade')}
                </option>
                {specialties.map(item => (
                  <option value={Number(item.specialty?.id)}>
                    {item.specialty?.name}
                  </option>
                ))}
              </Select>
            </ContentButtonsGrid>
          </ContainerButtonsGrid>
          <div style={{ width: '100%', marginTop: '40px' }}>
            <Button
              variant="secundary"
              icon={IconAddProfessional}
              height="48px"
              size="bigger"
              onClick={() =>
                makeReduxActiveMessage().active({
                  active: 'inviteExternalProfessionals',
                  actionOk: () => closeModal(),
                  actionCancel: () => closeModal(),
                  handleUpdateValue: setStateModal,
                })
              }
            >
              Adicionar profissional externo
            </Button>
          </div>
        </div>
      </Content>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <ListParticipatingProfessionals
          className="list"
          label={translator('Lista de profissionais participantes')}
          professionals={participant}
          setProfessionals={setParticipant}
          setIds={setSaveIds}
          ids={saveIds}
        />
      </div>
      <Navigator back={() => console.log('oi')} />
    </Container>
  );
};

export default ConnectComponent(Professional);
