import { takeLatest } from 'redux-saga/effects';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { onDeleteRequest, onDeleteSuccess, onDeleteFailed } from './Delete';
import { onUpdateRequest, onUpdateSuccess, onUpdateFailed } from './Update';
import { onGetAllRequest, onGetAllSuccess, onGetAllFailed } from './GetAll';
import { onGetOneRequest, onGetOneSuccess, onGetOneFailed } from './GetOne';
import {
  onUpdateUserAvatarFailed,
  onUpdateUserAvatarRequest,
  onUpdateUserAvatarSuccess,
} from './UpdateUserAvatar';
import { UsersTypes } from '~/domain/interfaces/redux/users/types';

const tasks = [
  takeLatest(UsersTypes.CREATE, onCreate),
  takeLatest(UsersTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(UsersTypes.CREATE_FAILED, onCreateFailed),

  takeLatest(UsersTypes.DELETE, onDeleteRequest),
  takeLatest(UsersTypes.DELETE_SUCCESS, onDeleteSuccess),
  takeLatest(UsersTypes.DELETE_FAILED, onDeleteFailed),

  takeLatest(UsersTypes.UPDATE, onUpdateRequest),
  takeLatest(UsersTypes.UPDATE_SUCCESS, onUpdateSuccess),
  takeLatest(UsersTypes.UPDATE_FAILED, onUpdateFailed),

  takeLatest(UsersTypes.GETALL, onGetAllRequest),
  takeLatest(UsersTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(UsersTypes.GETALL_FAILED, onGetAllFailed),

  takeLatest(UsersTypes.GETONE, onGetOneRequest),
  takeLatest(UsersTypes.GETONE_SUCCESS, onGetOneSuccess),
  takeLatest(UsersTypes.GETONE_FAILED, onGetOneFailed),

  takeLatest(UsersTypes.UPDATE_AVATAR, onUpdateUserAvatarRequest),
  takeLatest(UsersTypes.GETONE_SUCCESS, onUpdateUserAvatarSuccess),
  takeLatest(UsersTypes.GETONE_FAILED, onUpdateUserAvatarFailed),
];

export default tasks;
