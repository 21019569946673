import React from 'react';

// import { Container } from './styles';
import { DocumentsList } from '~/presentation/components/documents-list';

const ClinicalDocuments: React.FC = () => {
  return <DocumentsList type="clinical-documents" />;
};

export default ClinicalDocuments;
