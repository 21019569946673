import { BorderBottom } from '@material-ui/icons';
import styled, { css } from 'styled-components';

interface ItemProps {
  active?: boolean;
}

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const Item = styled.div<ItemProps>`
  border: none;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  ${props =>
    props.active &&
    css`
      border-bottom: 3px solid ${props.theme.primary};
    `}

  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

export const Label = styled.div`
  color: ${props => props.theme.primary};
  font-size: 14px;
  font-weight: 700;
`;
