import React from 'react';
import { Container, Spin } from './styles/styled';
import { V4hSpinIcon } from '~/presentation/base/icons';

interface ownProps {
  fullScreen: boolean;
  background?: string;
  size?: string;
  className?: string;
}

export const V4hSpin: React.FC<ownProps> = ({
  fullScreen,
  background,
  size,
  className,
}): JSX.Element => {
  return fullScreen ? (
    <Container background={background} className={className}>
      <Spin src={V4hSpinIcon} size={size} />
    </Container>
  ) : (
    <Spin src={V4hSpinIcon} size={size} className={className} />
  );
};
