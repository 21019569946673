import { all } from 'redux-saga/effects';

import AppointmentTasks from './appointment';
import AppointmentTypeTasks from './appointmentType';
import AuthTasks from './auth';
import UsersTasks from './users';
import SpecialtyTasks from './specialty';
import ProfessionalTasks from './professional';
import ConsultantTasks from './consultant';
import OrganizerTasks from './organizer';
import AdministratorTasks from './administrator';
import SupportDoc from './supportDoc';
import Permissions from './permissions';
import ClinicalDocsTasks from './clinicalDocs';
import ProfessionTasks from './profession';
import WaitingRoomTasks from './waitingRoom';
import Chat from './chat';
import NotificationTasks from './Notifications';
import SelectedOrgTasks from './selectedOrg';
import DashboardTasks from './dashboard';
import OrgUnit from './orgUnit';
import BillingTasks from './billing';

export default function* rootSaga(): any {
  return yield all([
    ...AppointmentTasks,
    ...AppointmentTypeTasks,
    ...AuthTasks,
    ...UsersTasks,
    ...SpecialtyTasks,
    ...ProfessionalTasks,
    ...ConsultantTasks,
    ...AdministratorTasks,
    ...SupportDoc,
    ...Permissions,
    ...ClinicalDocsTasks,
    ...ProfessionTasks,
    ...WaitingRoomTasks,
    ...Chat,
    ...NotificationTasks,
    ...SelectedOrgTasks,
    ...OrganizerTasks,
    ...DashboardTasks,
    ...OrgUnit,
    ...BillingTasks,
  ]);
}
