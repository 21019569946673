import { number, object, array, string, enum as a, any, date } from 'zod';
import { validateDate } from '~/utils/validDate';
import { validateCpf } from '~/utils/validCpf';

export const schemaPersonalData = object({
  fullName: string()
    .max(128)
    .nonempty({ message: 'Insira seu nome completo.' }),
  cpf: string()
    .max(11)
    .min(11, { message: 'CPF inválido.' })
    .refine(value => validateCpf(value), {
      message: 'CPF inválido.',
    })
    .nonempty(),
  rg: string()
    .max(32, { message: 'RG inválido, número de digitos ultrapassou o limite' })
    .min(1, { message: 'RG inválido, existe(m) digitos faltando' })
    .nonempty({ message: 'RG inválido.' })
    .optional(),
  birthdate: string()
    .refine(value => validateDate(value), {
      message: 'Data inválida.',
    })
    .nonempty({ message: 'Data de nascimento é obrigatório.' }),
  motherName: string()
    .max(256)
    .nonempty({ message: 'Nome da mãe é obrigatório.' }),
  gender: a(['MALE', 'FEMALE', 'OTHER', '']),
});

export const schemaContact = object({
  phone: string().max(14).min(11, { message: 'Telefone inválido.' }).nonempty(),
  phone2: string().max(14).optional(),
  phone3: string().max(14).optional(),
  email: string().email({ message: 'Email inválido.' }).max(128),
});

export const schemaAddress = object({
  zipcode: string()
    .max(10, {
      message: 'CEP inválido, número de digitos ultrapassou o limite',
    })
    .min(8, { message: 'CEP inválido, existe(m) digitos faltando' })
    .optional(),
  uf: string()
    .max(2, { message: 'UF inválido, número de caracteres ultrapassados' })
    .min(2, { message: 'UF inválido, existe(m) caracteres faltando' })
    .optional(),
  city: string().max(128).nonempty({ message: 'Insira o nome da sua cidade.' }),
  neighborhood: string()
    .max(128)
    .nonempty({ message: 'Insira o nome do seu bairro.' }),
  street: string().max(256).nonempty({ message: 'Insira o seu endereço.' }),
  number: string()
    .max(20, { message: 'Número inválido, ultrapassou o limite' })
    .nonempty({ message: 'Insira o número de sua residência' }),
  complement: string().max(1024).optional(),
});

export const schemaSectors = object({
  sectors: array(
    object({
      id: number().nonnegative().int(),
      name: string().nonempty({ message: 'Selecione pelo menos um setor.' }),
      organizers: any().optional(),
      professionals: any().optional(),
    }),
  ).nonempty(),
});

export const schemaProfessionalData = object({
  org: number().nonnegative().int().optional(),
  orgUnit: number().nonnegative().int().optional(),
  profession: number()
    .nonnegative({ message: 'Selecione uma profissão.' })
    .int(),
  specialties: array(
    object({
      id: number().nonnegative().int(),
      docProf: string().nonempty(),
      docProfUF: string().nonempty(),
      docProfIssued: string().nonempty(),
    }),
  ).optional(),
});

export const schema = object({
  user: number().nonnegative().optional(),
  org: number().nonnegative().int(),
  orgUnit: number().nonnegative().int().optional(),
  profile: object({
    firstName: string().max(64),
    lastName: string().max(64),
    email: string().email().max(128),
    password: string().min(6).max(32).optional(),
    phone: string().max(14),
    phone2: string().max(14).optional(),
    phone3: string().max(14).optional(),
    avatar: string().max(1024).optional(),
    motherName: string().max(256).optional(),
    birthdate: string().optional(),
    gender: a(['MALE', 'FEMALE', 'OTHER']).optional(),
  }).optional(),
  documents: array(
    object({
      documentId: number().nonnegative().int().optional(),
      value: string().nonempty(),
      issued: string().or(date()).optional(),
      issuer: string().optional(),
      expires: string().or(date()).optional(),
    }),
  ).optional(),
  profession: number().nonnegative().int().optional(),
  specialties: array(
    object({
      id: number().nonnegative().int(),
      docProf: string().nonempty(),
      docProfUF: string().nonempty(),
      docProfIssued: string().nonempty(),
    }),
  ).optional(),
  address: object({
    zipcode: string().max(10),
    uf: string().max(2),
    city: string().max(128),
    country: string().max(3),
    neighborhood: string().max(128),
    street: string().max(256),
    number: string().max(20),
    complement: string().max(1024).optional(),
  }).optional(),
}).refine((data): boolean => {
  if (data.profession && !data.specialties) return false;
  return true;
});
