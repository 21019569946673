import React from 'react';
import { Notification } from '~/presentation/components/UI';
import { notification } from '~/presentation/components/UI/notification/interface';

const TestNotification: React.FC = () => {
  const notifications: notification[] = [
    {
      name: 'Vinicius dos Santos',
      action: 'APPOINTMENT_CREATED',
      date: '16/01/2021',
      role: 'Profissional de saúde',
    },
    {
      name: 'Vinicius dos Santos',
      action: 'APPOINTMENT_CANCELLED',
      date: '16/01/2021',
      role: 'Profissional de saúde',
    },
    {
      name: 'Vinicius dos Santos',
      action: 'APPOINTMENT_CANCELLED',
      date: '16/01/2021',
      role: 'Profissional de saúde',
    },
    {
      name: 'Vinicius dos Santos',
      action: 'APPOINTMENT_CANCELLED',
      date: '16/01/2021',
      role: 'Paciente',
    },
    {
      name: 'Vinicius dos Santos',
      action: 'APPOINTMENT_CREATED',
      date: '16/01/2021',
      role: 'Paciente',
    },
  ];
  return <Notification />;
};

export default TestNotification;
