import React, { useState } from 'react';

import { Button } from '~/presentation/components/UI';
import { IconWaitingRoom, IconV4Hatendimento } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { Icon } from '~/presentation/components/icon';
import { Container, Image, Header } from './styles/StyledReady';
import { History } from '~/main/routes';

interface ownProps {
  appointmentId: number;
  waitingRoomUserId: number;
  left: () => any;
}

export default function Ready(props: ownProps): JSX.Element {
  const { appointmentId, waitingRoomUserId, left } = props;

  const start = () => {
    window.onpopstate = null;

    History.getHistory().push('/conf', {
      appointmentId,
      waitingRoomUserId,
    });
  };

  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  return (
    <Container>
      <Header>
        {logoUrl ? (
          <Image alt="Logo" src={logoUrl} width="200px" />
        ) : (
          <Icon src={IconV4Hatendimento} width="250px" height="80px" />
        )}
      </Header>
      <span className="title">
        {translator('O médico está pronto')}
      </span>
      <IconWaitingRoom />
      <span className="message">
        {translator('O médico já está na sala de atendimento')}
      </span>
      <span className="message">
        <b>{translator('Você já pode iniciar seu atendimento')}</b>
      </span>
      <Button rounded onClick={start}>
        <span className="message">
          {translator(
            'Entrar na consulta',
          )}
        </span>
      </Button>
      <Button rounded variant="secundary" onClick={left}>
        <span className="message">
          {translator('Deixar a sala')}
        </span>
      </Button>
    </Container>
  );
}
