import React from 'react';

import { Icon } from '~/presentation/components/icon';
import { IconV4Hlogo } from '~/presentation/base/icons';

const IconPage = () => {
  return (
    <div>
      <Icon src={IconV4Hlogo} color="black" />
    </div>
  );
};

export default IconPage;
