import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateNotificationUser as UsecaseRemoteUpdateNotificationUser } from '~/domain/usecases/Notifications/remote';
import { UpdateNotificationUser as UsecaseReduxUpdateNotificationUser } from '~/domain/usecases/Notifications/redux';

import { RemoteUpdateNotificationUser } from '~/data/repository/Notifications';
import { ReduxUpdateNotificationUser } from '~/data/store/reducer/Notifications/usecases';

export const makeRemoteUpdateNotificationUser = (): UsecaseRemoteUpdateNotificationUser =>
  new RemoteUpdateNotificationUser(
    makeApiUrl('/notificationsUser'),
    makeHttpClient(),
  );

export const makeReduxUpdateNotificationUser = (): UsecaseReduxUpdateNotificationUser =>
  new ReduxUpdateNotificationUser();
