import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-style: solid;
  border-color: transparent;
`;

export const Access = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #004ba5;
  border-bottom: 1px solid #efefef;
  padding: 15px;
  gap: 45px;
  padding-left: 80px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #004ba5;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  gap: 10px;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: #004ba5;
    padding: 15px;
  }
  padding: 15px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #7a7a7a;
  padding: 15px;
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
  line-height: 20px;
  h2 {
    font-size: 22px;
    padding-left: 10px;
  }
  h3 {
    font-size: 18px;
    line-height: 25px;
  }
`;

export const CodeGen = styled.div`
  width: 310px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #004ba5;
  color: white;
  gap: 10px;
`;

export const QrGen = styled.div`
  width: 310px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #004ba5;
  border-radius: 5px;
  background: none;
  color: #004ba5;
  gap: 10px;
  cursor: pointer;
  transition: opacity 0.2s;
  &:hover {
    background-color: #004ba5;
    color: white;
    transition: all 0.3s;
  }
  &:active {
    background-color: #006ef2;
    transition: 0.2s;
  }
`;
