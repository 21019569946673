import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { UpdateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
  Forbidden,
  BadGateway,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export class RemoteUpdateClinicalDocs implements UpdateClinicalDocs {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateClinicalDocs.Model>;

  constructor(url: string, httClient: HttpClient<UpdateClinicalDocs.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  patch = async (
    params: UpdateClinicalDocs.Params,
  ): Promise<UpdateClinicalDocs.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.docId}`,
      method: 'patch',
      body: {
        certificate: params.certificate,
        content: params.content,
      },
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
