import React, { useState } from 'react';

import { Container } from './styles';

export default function TestComponents(): JSX.Element {
  const [current, setCurrent] = useState(1);

  function notifyMe() {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      const notification = new Notification('Hi there!');
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          const notification = new Notification('Hi there!');
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }

  return (
    <Container>
      {/* <UserListManager
        cod
        date
        name
        surname
        specialty
        register
        phone
        email
        status
        actions={{ cancel: true, details: true, delete: true, person: true }}
      /> */}
      <div>test</div>
    </Container>
  );
}
