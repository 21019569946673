import React from 'react';

// import { Container } from './styles';
const TestUserListManager: React.FC = () => {
  return (
    <div>
      <div>
        {/* <UserListManager
          list="list-1"
          participant
          professional
          timeStart
          timeEnd
          typeIs
          specialty
          status2
          actions={{ cancel: true, person: true }}
        /> */}
      </div>
      <div style={{ marginTop: 20 }}>
        {/* <UserListManager
          list="list-2"
          cod
          date
          name
          surname
          specialty
          register
          phone
          email
          actions={{ cancel: true, person: true }}
        /> */}
      </div>
    </div>
  );
};

export default TestUserListManager;
