import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { History } from '~/main/routes';
import { DocumentsList } from '~/presentation/components/documents-list';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import { iClinicalDocs } from '~/presentation/roles/professional/pages/appointment/interface';
import { DocsEmpty } from '../../componentEmpty';

interface ownProps {
  docs: iClinicalDocs[];
}

const ClinicalDocuments: React.FC<ownProps> = ({ docs }) => {
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  const messageEmpty = translator(
    'Você não tem nenhum documento associado ao atendimento.',
  );

  function permissions() {
    if (role === 'PRO') {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '20px',
          }}
        >
          <Button
            id="new_clinicalDocs"
            rounded
            size="large"
            onClick={() => {
              History.push('/clinicaldoc/new');
            }}
          >
            {`+ ${translator('Novo documento clínico')}`}
          </Button>
          <DocumentsList type="clinical-documents" docs={docs} />
        </div>
      );
    }
    if (role === 'CON') {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '20px',
          }}
        >
          {docs.length ? (
            <DocumentsList type="clinical-documents" docs={docs} />
          ) : (
            <DocsEmpty message={messageEmpty} />
          )}
        </div>
      );
    }

    return '';
  }

  return <>{permissions()}</>;
};

export default ClinicalDocuments;
