import React from 'react';
import { User } from './interface';
import AvatarChat from './AvatarChat';
import { Header } from './styles/StyledHistoryChat';
import { translator } from '../i18n';

const HistoryChat: React.FC = () => {
  return (
    <div style={{ width: '100%' }}>
      <Header>{`${translator('Últimas conversas')}`}</Header>
      <AvatarChat />
    </div>
  );
};

export default HistoryChat;
