import styled from 'styled-components';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
  height: 100%;
  height: fit-content;
  display: grid;
  grid-gap: 10px;

  #btnAction {
    max-width: 200px;
    height: fit-content;
    padding: 10px;
    width: 100%;
  }
`;

export const Scroll = styled.div`
  display: grid;
  grid-gap: 10px;
  max-height: 400px;
  overflow-y: scroll;
`;

export const Header = styled.div`
  width: 100%;
`;

export const Body = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .btnAction {
    width: 150px;
  }
`;

export const Card = styled.div`
  border-radius: 5px;
  display: grid;
  grid-gap: 10px;
  background: #f4f7fe;
  padding: 20px;
`;

export const Information = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 10% 25% 10%;
  grid-gap: 10px;
  align-items: center;
  Select {
    border-radius: 5px;
    background: #ffffff;
  }
  Input {
    border-radius: 5px;
    background: #ffffff;
  }
`;

export const Add = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 80px;
    margin-top: 20px;
    height: 50px;
    padding: 10px;
    border: 1px solid red;
    border-radius: 5px;
    color: red;
    font-weight: bold;
    background: transparent;
    cursor: pointer;
  }
`;
