/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { StepMagic, Step } from '~/presentation/components/steps';

import { translator } from '~/presentation/components/i18n';

import { PersonalData, Address, Contacts } from '../register';

import { Container, Header, Body } from './style/StyledRegisterUser';

import { CreateAdministrator as ReduxCreateAdministrator } from '~/domain/usecases/administrator/redux';
import { V4hSpin } from '~/presentation/components/spin';
import { iStore } from '~/domain/interfaces/models';

import { schema } from '~/validation/validators/user/CreateUserValidator';

import { iRegisterManager } from './interface';
import {
  makeReduxCreateAdministrator,
  makeRemoteCreateAdministrator,
} from '~/main/factories/usecases/administrator/CreateAdministratorFactory';
import { makeRemoteGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';

const RegisterAdm: React.FC = props => {
  const selectUser = useSelector((store: iStore) => store.auth.selectUser);

  const org = {
    org: selectUser?.orgId || 0,
    orgUnit: selectUser?.orgUnitId || 0,
  };

  const [crement, setCrement] = useState<number>(1);
  const [submit, setSubmit] = useState<boolean>(false);
  const [isFound, setIsFound] = useState<boolean>(false);
  const [user, setUser] = useState<iRegisterManager>({
    fullName: '',
    email: '',
    login: '',
    cpf: '',
    documents: [],
    phone: '',
    phone2: '',
    phone3: '',
    birthdate: '',
    avatar: '',
    motherName: '',
    address: {
      city: '',
      complement: '',
      country: 'BRA',
      neighborhood: '',
      number: '',
      street: '',
      uf: '',
      zipcode: '',
    },
    gender: 'MALE',
    mainRole: 'ORG',
    organizer: { org: org.org, orgUnit: org.orgUnit },
  });

  useEffect(() => {
    if (submit) createUser();
  }, [submit]);

  const FINAL_STEP = 3;

  function handleBar(number: number) {
    if (crement + number > FINAL_STEP) {
      setSubmit(true);
      return;
    }

    setSubmit(false);
    setCrement(crement + number);
  }

  const loading = useSelector((store: iStore) => store.users.loading);

  const createUser = async () => {
    const birthDateBack = user!.birthdate!.split('/');

    const splitName = user.fullName!.split(' ');
    const firstName = splitName?.splice(0, 1).join('');
    const lastName = splitName?.join(' ');

    const now = new Date();

    const dataToSend: ReduxCreateAdministrator.Params = user?.id
      ? {
          user: user?.id,
          org: org.org,
        }
      : {
          org: org.org!,
          profile: {
            firstName,
            lastName,
            email: user.email!,
            phone: user.phone!,
            phone2: user.phone2,
            phone3: user.phone3,
            avatar: user.avatar,
            motherName: user.motherName,
            gender: user.gender!,
            birthdate: `${birthDateBack[2]}-${birthDateBack[1]}-${birthDateBack[0]}`,
          },
          documents: [
            {
              documentId: 1,
              value: String(user.cpf),
              expires: new Date(
                now.getFullYear() + 10,
                now.getMonth(),
                now.getDate(),
              ),
              issued: now,
              issuer: `${firstName} ${lastName}`,
            },
          ],
          address: {
            zipcode: user.address!.zipcode!,
            uf: user.address!.uf!,
            city: user.address!.city!,
            country: user.address!.country!,
            neighborhood: user.address!.neighborhood!,
            street: user.address!.street!,
            number: user.address!.number!,
            complement: user.address!.complement!,
          },
        };

    try {
      if (!user?.id) schema.parse(dataToSend);

      makeReduxCreateAdministrator().create(dataToSend);
    } catch (e) {
      console.error(e);
    }
  };

  function next(data: iRegisterManager) {
    setUser({ ...user, ...data });
    handleBar(1);
  }

  function back(data: iRegisterManager) {
    setUser({ ...user, ...data });
    handleBar(-1);
  }

  return (
    <Container>
      <Header>
        <StepMagic current={crement}>
          <Step title={translator('Dados Pessoais')} />
          <Step title={translator('Contatos')} />
          <Step title={translator('Endereço')} />
        </StepMagic>
      </Header>

      <Body>
        {crement === 1 && (
          <PersonalData
            state={user}
            next={next}
            isFound={isFound}
            setIsFound={setIsFound}
            roleToRegister="ADM"
          />
        )}
        {crement === 2 && (
          <Contacts state={user} next={next} back={back} isFound={isFound} />
        )}
        {crement === 3 && (
          <Address
            state={user}
            next={next}
            back={back}
            isFound={isFound}
            isFinish
          />
        )}
        {loading && <V4hSpin fullScreen />}
      </Body>
    </Container>
  );
};

export default RegisterAdm;
