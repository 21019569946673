import { GetByDownloadSupportDocs } from '~/domain/usecases/supportDoc/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

export class RemoteGetByDownloadSupportDocs
  implements GetByDownloadSupportDocs {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetByDownloadSupportDocs.Model>;

  constructor(
    url: string,
    httClient: HttpClient<GetByDownloadSupportDocs.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: GetByDownloadSupportDocs.Params,
  ): Promise<GetByDownloadSupportDocs.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.docId}/download`,
      method: 'get',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
