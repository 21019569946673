import {
  iActionMakeClinicalDoc,
  iActionCancelMakeClinicalDoc,
  iActionSubmitMakeClinicalDoc,
  iActionSubmitSuccessMakeClinicalDoc,
  ClinicalDocsTypes,
} from '~/domain/interfaces/redux/clinicalDocs/makeClinicalDoc';

import { MakeClinicalDoc } from '~/domain/usecases/clinicalDocs/redux/MakeClinicalDoc';

export const makeClinicalDoc = (
  params: MakeClinicalDoc.Params,
): iActionMakeClinicalDoc => ({
  type: ClinicalDocsTypes.MAKE_CLINICAL_DOC,
  payload: params,
});

export const makeClinicalDocCancel = (): iActionCancelMakeClinicalDoc => ({
  type: ClinicalDocsTypes.MAKE_CLINICAL_DOC_CANCEL,
});

export const makeClinicalDocSubmit = (
  params: MakeClinicalDoc.Params,
): iActionSubmitMakeClinicalDoc => ({
  type: ClinicalDocsTypes.CREATE,
  payload: params,
});

export const makeClinicalDocSubmitSuccess = (): iActionSubmitSuccessMakeClinicalDoc => ({
  type: ClinicalDocsTypes.MAKE_CLINICAL_DOC_SUBMIT_SUCCESS,
});
