import { iNotifications } from '~/domain/interfaces/models/Notifications';
import { NotificationTypes } from '~/domain/interfaces/redux/Notifications/types';
import { NotificationActions } from './actions';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const initialState: iNotifications = {
  loading: false,
  notifications: {
    pageSize: 0,
    totalItems: 0,
    results: [],
  },
};

const reducer = (
  state = initialState,
  action: NotificationActions,
): iNotifications => {
  switch (action.type) {
    case NotificationTypes.GETALL:
      return { ...state, loading: true };
    case NotificationTypes.GETALL_SUCCESS:
      return { ...state, loading: false, notifications: { ...action.payload } };
    case NotificationTypes.UPDATE:
      return { ...state, loading: true };
    case NotificationTypes.UPDATE_SUCCESS:
      return { ...state, loading: false };
    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
