import React, { ReactComponentElement, useState } from 'react';

import { NavigationBar } from '~/presentation/components/navigationBar';

const TestNavigationBar: React.FC = () => {
  const labels: string[] = [
    'Documentos',
    'Chat',
    'Anotações',
    'Documentos Clinicos',
    'Status da sessão',
  ];

  const [select, setSelect] = useState<string>(labels[0]);

  function handleSelect(key: string) {
    setSelect(key);
  }
  return (
    <div style={{ width: '100%' }}>
      <NavigationBar
        active={select}
        clickLabel={handleSelect}
        labels={labels}
      />
      {labels.indexOf(select) === 0 ? 'igual a 0' : null}
      {labels.indexOf(select) === 1 ? 'igual a 1' : null}
      {labels.indexOf(select) === 2 ? 'igual a 2' : null}
      {labels.indexOf(select) === 3 ? 'igual a 3' : null}
      {labels.indexOf(select) === 4 ? 'igual a 4' : null}
    </div>
  );
};

export default TestNavigationBar;
