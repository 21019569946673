import { array, number, object, string } from 'zod';
import { validateDate } from '~/utils/validDate';

const schema = object({
  profession: number().int().nonnegative(),
  name: string().max(32),
  descr: string().max(256).optional(),
});

export const schemaSpecialtyCreate = object({
  id: number().int().nonnegative({ message: 'Selecione uma especialidade' }),
  docProf: string().nonempty({
    message: 'Preencha o Registro (conselho regulador).',
  }),
  docProfUF: string().nonempty({ message: 'Escolha a UF.' }),
  docProfIssued: string()
    .nonempty({
      message: 'Preencha a data de emissão corretamente.',
    })
    .refine(value => validateDate(value), {
      message: 'Preencha a data de emissão corretamente.',
    }),
});

export const schemaSpecialty = object({
  specialty: array(
    object({
      id: number()
        .nonnegative({ message: 'Selecione uma especialidade' })
        .int(),
      docProf: string().nonempty({
        message: 'Preencha o Registro (conselho regulador).',
      }),
      docProfUF: string().nonempty({ message: 'Escolha a UF.' }),
      docProfIssued: string()
        .nonempty({
          message: 'Preencha a data de emissão corretamente.',
        })
        .refine(value => validateDate(value), {
          message: 'Preencha a data de emissão corretamente.',
        }),
    }),
  ),
});

export default schema;
