/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import InputMask from 'react-input-mask';
import { translator } from '~/presentation/components/i18n';

import { iStore } from '~/domain/interfaces/models';
import { GetUfs as RemoteGetUfs } from '~/domain/usecases/externalServices/remote';
import { GetAllSpecialty as RemoteGetAllSpecialty } from '~/domain/usecases/specialty/remote';

import { makeRemoteGetUfs } from '~/main/factories/usecases/externalServices/GetUfs';
import { makeRemoteGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import { makeRemoteGetOneUser } from '~/main/factories/usecases/users/GetOneUserFactory';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import { schemaSpecialtyUpdate } from '~/validation/validators/specialty/UpdateSpecialtyValidator';
import { iProfessions, iSpecialty } from '../professionalData/interface';
import { Input, Select, Button } from '../UI';

import {
  Container,
  Add,
  Card,
  Information,
  Scroll,
} from './styles/StyledSpecialty';

interface ownProps {
  data: iProfessions;
  save?: boolean;
  handleChange: (value: iSpecialty[]) => void;
  handlePreviousData: (value: Array<number>) => void;
  handleDeleteData: (value: Array<number>) => void;
  resetSavingState?: () => void;
}

const Specialty: React.FC<ownProps> = ({
  data,
  handleChange,
  handlePreviousData,
  handleDeleteData,
  save,
  resetSavingState,
}) => {
  const [specialty, setSpecialty] = useState<iSpecialty[]>([]);
  const [ufs, setUfs] = useState<RemoteGetUfs.Model>([]);
  const [deleteIds, setDeleteIds] = useState<Array<number>>([]);
  const [previousIds, setPreviousIds] = useState<Array<number>>([]);
  const [specialties, setSpecialties] = useState<RemoteGetAllSpecialty.Model>(
    {} as RemoteGetAllSpecialty.Model,
  );
  const { info } = useSelector((store: iStore) => store.auth);
  const { orgUnitId } = useSelector((store: iStore) => store.auth.selectUser);
  const { specialty: specialtyRedux } = useSelector(
    (store: iStore) => store.showModal,
  );
  const selectedUser = useSelector(
    (store: iStore) => store.users.selected.user,
  );

  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaSpecialtyUpdate),
  });

  useEffect(() => {
    const loadData = async () => {
      const specialtiesData = await makeRemoteGetOneUser().getOne({
        userId: Number(selectedUser?.id || info.user?.id),
      });

      const previousIdsArray: Array<number> = [];

      const professional = specialtiesData?.professionals.find(
        item => item.orgUnit.id === orgUnitId,
      );

      console.assert(!!professional, 'Professional not found');

      const updatedSpecialty =
        professional?.professions?.specialties.map(item => {
          const splitDate = item?.docProfIssued?.substr(0, 10).split('-');
          const formattedDate =
            splitDate && splitDate.length > 1
              ? `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
              : '';

          previousIdsArray.push(Number(item.id));
          return { ...item, docProfIssued: formattedDate };
        }) ?? [];

      await Promise.all(updatedSpecialty);
      handlePreviousData(previousIdsArray);
      setPreviousIds(previousIdsArray);
      setSpecialty(updatedSpecialty);
    };

    loadData();
  }, []);

  useEffect(() => {
    makeRemoteGetUfs()
      .getUfs({})
      .then(setUfs)
      .catch(err => {
        console.log('Erro ao buscar UFs: ', err);
      });

    makeRemoteGetAllSpecialty()
      .getAll({
        pageSize: 9999,
        filter: {
          profession: data.professions.id,
        },
      })
      .then(setSpecialties)
      .catch(err => {
        console.log('Erro ao buscar Specialties: ', err);
      });

    makeReduxSetShowModal().set({ specialty: false });
  }, []);

  useEffect(() => {
    if (save) {
      const updatedSpecialty: iSpecialty[] = [];
      specialty.forEach(item => {
        const formattedDate = item?.docProfIssued?.split('/') || [];

        updatedSpecialty.push({
          ...item,
          docProfIssued:
            formattedDate?.length > 1
              ? `${formattedDate[2]}-${formattedDate[1]}-${formattedDate[0]}`
              : item.docProfIssued,
        });
      });

      handleChange(updatedSpecialty);
      makeReduxSetShowModal().set({ specialty: false });
      if (resetSavingState) resetSavingState();
    }
  }, [save]);

  const addSpecialty = () => {
    const now = new Date();

    if (!specialtyRedux) makeReduxSetShowModal().set({ specialty: true });
    setSpecialty([
      ...specialty,
      {
        id: -1,
        docProf: '',
        docProfIssued: `${`0${now.getUTCDate()}`.slice(-2)}/${`0${
          now.getUTCMonth() + 1
        }`.slice(-2)}/${now.getUTCFullYear()}`,
        docProfUF: 'AC',
      } as iSpecialty,
    ]);
  };

  const removeSpecialty = (id: number) => {
    if (!specialtyRedux) makeReduxSetShowModal().set({ specialty: true });
    if (deleteIds.findIndex(deleteId => deleteId === specialty[id].id) === -1) {
      handleDeleteData([...deleteIds, Number(specialty[id].id)]);
      setDeleteIds([...deleteIds, Number(specialty[id].id)]);
    }

    setSpecialty([...specialty.slice(0, id), ...specialty.slice(id + 1)]);
  };

  return (
    <Container>
      {specialty.length > 0 && (
        <Scroll>
          {specialty.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Card key={index}>
              <Information>
                <Select
                  name="profession"
                  label={`Especialidade ${index + 1}`}
                  placeholder="Placeholder"
                  value={item.id}
                  defaultValue={item.id}
                  onChange={e => {
                    if (!specialtyRedux)
                      makeReduxSetShowModal().set({ specialty: true });
                    if (
                      previousIds.findIndex(value => value === item.id) !==
                        -1 &&
                      deleteIds.findIndex(value => value === item.id) === -1
                    ) {
                      handleDeleteData([...deleteIds, Number(item.id)]);
                      setDeleteIds([...deleteIds, Number(item.id)]);
                    }

                    setSpecialty([
                      ...specialty.slice(0, index),
                      { ...item, id: Number(e.target.value) },
                      ...specialty.slice(index + 1),
                    ]);
                  }}
                >
                  <option key={-1} value={-1}>
                    Selecione uma especialidade
                  </option>
                  {specialties?.results?.length > 0 &&
                    specialties?.results?.map(specialtyData => (
                      <option
                        key={specialtyData?.specialty?.id}
                        value={specialtyData?.specialty?.id}
                      >
                        {specialtyData.specialty?.name}
                      </option>
                    ))}
                </Select>
                <Input
                  name="docProf"
                  label="Registro (conselho regulador)"
                  value={item.docProf}
                  onChange={e => {
                    if (!specialtyRedux)
                      makeReduxSetShowModal().set({ specialty: true });
                    setSpecialty([
                      ...specialty.slice(0, index),
                      { ...item, docProf: e.target.value },
                      ...specialty.slice(index + 1),
                    ]);
                  }}
                />
                <Select
                  name="docProfUF"
                  label="UF do registro"
                  labelStyle={{ fontSize: 12 }}
                  defaultValue={item.docProfUF ?? 'AC'}
                  value={item.docProfUF}
                  onChange={e => {
                    if (!specialtyRedux)
                      makeReduxSetShowModal().set({ specialty: true });
                    setSpecialty([
                      ...specialty.slice(0, index),
                      { ...item, docProfUF: e.target.value },
                      ...specialty.slice(index + 1),
                    ]);
                  }}
                >
                  {ufs?.length > 0 &&
                    ufs.map(uf => (
                      <option key={uf.id} value={uf.sigla}>
                        {uf.sigla}
                      </option>
                    ))}
                </Select>
                <InputMask
                  mask="99/99/9999"
                  name="docProfIssued"
                  value={item.docProfIssued}
                  onChange={e => {
                    if (!specialtyRedux)
                      makeReduxSetShowModal().set({ specialty: true });
                    setSpecialty([
                      ...specialty.slice(0, index),
                      { ...item, docProfIssued: e.target.value },
                      ...specialty.slice(index + 1),
                    ]);
                  }}
                >
                  <Input name="docProfIssued" label="Data de emissão" />
                </InputMask>
                <Add>
                  {specialty.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeSpecialty(index)}
                    >
                      Excluir
                    </button>
                  )}
                </Add>
              </Information>
            </Card>
          ))}
        </Scroll>
      )}
      <Button
        className="btnAction"
        variant="secundary"
        rounded
        onClick={addSpecialty}
        id="btnAction"
      >
        + Adicionar
      </Button>
    </Container>
  );
};

export default Specialty;
