import React from 'react';

import { Breadcrumb } from '~/presentation/components/breadcrumbs';

const BreadcrumbPage = () => {
  const history = [
    {
      name: 'Teste',
      href: '/test/01',
    },
    {
      name: 'Marcos',
      href: '/test/Marcos',
    },
  ];

  return (
    <div>
      <Breadcrumb history={history} />
    </div>
  );
};

export default BreadcrumbPage;
