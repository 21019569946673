import React from 'react';

import {
  Container,
  Title,
  Left,
  Right,
  Center,
} from './style/StyleHeaderSimples';
import { Icon } from '../icon';

interface ownProps {
  title: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  value?: number;
  valueDescription?: string;
}

type Props = ownProps;

const HeaderSimples: React.FC<Props> = ({
  title,
  icon,
  value,
  valueDescription,
}): JSX.Element => {
  const Icone = icon;
  const text = '';

  return (
    <Container>
      <Left />
      <Center>
        <Title>{title}</Title>
        {Icone && <Icon src={Icone} width="16px" height="16px" />}
      </Center>
      {valueDescription ? (
        <Right>{`${value} ${valueDescription}`}</Right>
      ) : (
        <Right />
      )}
    </Container>
  );
};

export default HeaderSimples;
