import { SocketLeftWaitingRoom } from '~/data/repository/socket/waitingRoom';
import { ReduxLeft } from '~/data/store/reducer/waitingroom/usecases';
import { makeSocket } from '~/main/factories/socket';
import { LeftWaitingRoom as UseCaseSocketLeftWaitingRoomFactory } from '~/domain/usecases/waitingRoom/socket';
import { LeftWaitingRoom as UseCaseReduxLeftWaitingRoomFactory } from '~/domain/usecases/waitingRoom/redux';

/**
 * Join at conference by SocketIO.
 */
export const makeSocketLeftWaitingRoom = (): UseCaseSocketLeftWaitingRoomFactory =>
  new SocketLeftWaitingRoom('leftWaitingRoom', makeSocket());

/**
 * Left at conference by Redux.
 */
export const makeReduxLeftWaitingRoom = (): UseCaseReduxLeftWaitingRoomFactory =>
  new ReduxLeft();
