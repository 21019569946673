import { SetDataActions } from './actions/index';
import { iDataPagination } from '../../../../domain/interfaces/models/DataPagination';
import { UsersTypes } from '~/domain/interfaces/redux/users/types';

export const initialState: iDataPagination = {
  dataPagination: [],
};

const reducer = (
  state = initialState,
  action: SetDataActions,
): iDataPagination => {
  switch (action.type) {
    case UsersTypes.SET_DATA:
      return { ...state, dataPagination: [...action.payload] };
    default:
      return state;
  }
};

export default reducer;
