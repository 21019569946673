import React from 'react';
import {
  Container,
  Description,
  Role,
  Actions,
  Table,
} from './styles/StyledItemListPermision';
import { ItemProps } from './interface';

const ItemListPermision: React.FC<ItemProps> = ({ professional }) => {
  return (
    <Container>
      <Table>
        <Description>{professional.description}</Description>
        <Role>{professional.role}</Role>
        <Actions>
          <professional.actions width="50px" height="20px" />
        </Actions>
      </Table>
    </Container>
  );
};

export default ItemListPermision;
