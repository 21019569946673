import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { PageHeader } from '~/presentation/components/header';
import { History } from '~/main/routes';
import { Button, Input, Select } from '~/presentation/components/UI';
import {
  Container,
  Body,
  Box,
  Content,
  Footer,
  Left,
  Right,
  Wrapper,
} from './StyledEditOrgUnit';
import { Menu } from '~/presentation/components/menu';
import LogoDropzone from '~/presentation/components/dropzone';
import { makeRemoteUpdateOrgUnit } from '~/main/factories/usecases/orgUnit/UpdateOrgUnitFactory';
import { ConnectComponent, editOrgUn } from './mapper/EditOrgUnit';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { translator } from '~/presentation/components/i18n';
import { makeReduxGetDashboardUnits } from '~/main/factories/usecases/dashboard/GetDashboardUnitsFactory';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface editOrgUnitProps {
  data: editOrgUn[];
}

interface iStateParams {
  orgUnitId: number;
  orgUnitName: string;
  orgName: string;
}

const EditOrgUnit: React.FC<editOrgUnitProps> = ({ data }): JSX.Element => {
  const [orgUnitInfo, setOrgUnitInfo] = useState<editOrgUn[]>(data);
  const [newUnitName, setNewUnitName] = useState('');
  const [newLogo, setNewLogo] = useState<any>(null);
  const [selectedOrg, setSelectedOrg] = useState<number>(-1);

  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const stateParams = useLocation<iStateParams>().state;

  useEffect(() => {
    if (stateParams.orgUnitId) setSelectedOrg(stateParams.orgUnitId);
    if (stateParams.orgUnitName) setNewUnitName(stateParams.orgUnitName);
  }, [stateParams]);

  const leavePage = () => {
    History.getHistory().goBack();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (stateParams.orgUnitName !== newUnitName) {
      makeRemoteUpdateOrgUnit()
        .patch({
          name: newUnitName.replace(/\s/g, ''),
          ouId: selectedOrg,
          fullname: newUnitName,
        })
        .then(res => {
          setNewUnitName(res.name);

          if (newLogo) {
            const formData = new FormData();
            formData.append('logo', newLogo);

            if (newLogo) {
              makeRemoteUpdateOrgUnit()
                .patch({ logo: formData, ouId: selectedOrg })
                .catch(err => {
                  AlertMessage({
                    type: 'danger',
                    message: intl.formatMessage({
                      id: 'Não foi possível atualizar imagem do setor.',
                    }),
                  });
                });
            }
          }

          makeReduxGetAllOrgUnitList().list({
            filter: { org: orgId, enabled: true },
          });

          makeReduxGetDashboardUnits().get({
            filter: {
              org: orgId,
            },
          });

          AlertMessage({
            type: 'success',
            message: intl.formatMessage({ id: 'Setor editado com sucesso!' }),
          });

          setTimeout(() => {
            History.back();
          }, 1500);
        })
        .catch(error => {
          if (error?.name === 'conflict') {
            AlertMessage({
              type: 'danger',
              message: intl.formatMessage({
                id: 'Já existe um setor com esse nome.',
              }),
            });
          } else {
            AlertMessage({
              type: 'danger',
              message: intl.formatMessage({
                id: 'Não foi possível atualizar setor.',
              }),
            });
          }
        });
    } else if (newLogo) {
      const formData = new FormData();
      formData.append('logo', newLogo);

      makeRemoteUpdateOrgUnit()
        .patch({ logo: formData, ouId: selectedOrg })
        .then(res => {
          makeReduxGetAllOrgUnitList().list({
            filter: { org: orgId, enabled: true },
          });

          AlertMessage({
            type: 'success',
            message: intl.formatMessage({ id: 'Setor editado com sucesso!' }),
          });

          setTimeout(() => {
            History.back();
          }, 1500);
        })
        .catch(err => {
          AlertMessage({
            type: 'danger',
            message: intl.formatMessage({
              id: 'Não foi possível atualizar imagem do setor.',
            }),
          });
        });
    }
  };

  return (
    <Container>
      <Menu />
      <PageHeader
        title={translator('Editar setor')}
        backDescription={translator('Voltar para a listagem de setores')}
      />
      <Wrapper>
        <Body>
          <Left>
            <p>Marca do setor</p>
            <Box>
              <LogoDropzone handleFile={setNewLogo} />
            </Box>
          </Left>
          <Right>
            <Content>
              <Select
                width="380px"
                name="orgs"
                value={selectedOrg}
                label="Organização"
                disabled
              >
                {orgUnitInfo.map(item => (
                  <option key={item.id} value={item.id}>
                    {`${item.shortname}`}
                  </option>
                ))}
              </Select>
              <Input
                autoFocus
                value={newUnitName}
                width="380px"
                name="sectorName"
                label="Nome do setor"
                placeholder="Digite o nome do setor"
                onChange={e => setNewUnitName(e.target.value)}
              />
            </Content>
          </Right>
        </Body>

        <Footer onSubmit={handleSubmit}>
          <Button
            id="btn_cancel"
            color="red"
            rounded
            variant="secundary"
            onClick={() => leavePage()}
            size="medium"
          >
            Cancelar
          </Button>
          <Button
            id="btn_save"
            rounded
            size="medium"
            type="submit"
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </Footer>
      </Wrapper>
    </Container>
  );
};

export default ConnectComponent(EditOrgUnit);
