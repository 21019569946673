/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from 'react-redux';
import { schemaPerfil } from '~/validation/validators/user/UpdateUserValidator';

import { IconUser } from '~/presentation/base/icons';
import { Container, Header, Body, Footer } from './style/StyledPerfil';

import { Button } from '../UI/buttons';
import { translator } from '../i18n';
import { Icon } from '../icon';
import Address from './Address';
import Contacts from './Contacts';
import GeneralData from './GeneralData';
import { iUserInformation } from './interface';
import { ConnectComponent } from './mapper/MapperPerfil';
import { UpdateUser } from '~/domain/usecases/users/redux';
import { UpdateUser as iUpdateUser } from '~/domain/usecases/users/remote';
import { iStore } from '~/domain/interfaces/models';
import { V4hSpin } from '../spin';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';

export interface ownProps {
  data: iUserInformation;
  actionUpdate: UpdateUser;
}
const Perfil: React.FC<ownProps> = ({ data, actionUpdate }) => {
  const [state, setState] = useState<iUserInformation>(data);
  const { loading } = useSelector((store: iStore) => store.users);

  const formData = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaPerfil),
    defaultValues: {
      ...data,
      city: data.address?.city,
      uf: data.address?.uf,
      street: data.address?.street,
      number: data.address?.number,
      complement: data.address?.complement,
      neighborhood: data.address?.neighborhood,
      zipcode: data.address?.zipcode,
    },
  });

  useEffect(() => {
    setState(data);
  }, [data]);

  const submit = formData.handleSubmit(datatosend => {
    const ubdate = datatosend.birthdate!.split('/');
    const userBirthdate = `${ubdate[2]}-${ubdate[1]}-${ubdate[0]}`;
    const splitFullName = datatosend.fullName!.split(' ');

    const obj: iUpdateUser.Params = {
      id: state.id,
      firstName: splitFullName[0],
      lastName: splitFullName.slice(1, splitFullName.length).join(' '),
      cpf: datatosend.cpf,
      motherName: datatosend.motherName,
      birthdate: userBirthdate,
      gender: datatosend.gender,
      documents: state.documents?.map(doc => ({
        ...doc,
        documentId: doc.id,
      })),
      address: {
        zipcode: datatosend.zipcode,
        city: datatosend.city,
        neighborhood: datatosend?.neighborhood?.trim(),
        uf: datatosend.uf,
        street: datatosend?.street?.trim(),
        number: datatosend.number,
        complement: datatosend?.complement?.trim(),
        country: datatosend?.country?.trim(),
      },
      phone: datatosend.phone,
      phone2: datatosend.phone2!,
    };
    if (datatosend.email !== data.email) {
      const { email } = datatosend;
      actionUpdate.update({ ...obj, email });
    } else {
      actionUpdate.update(obj);
    }

    makeReduxSetShowModal().set({ profile: false });
  });

  return (
    <Container>
      <Header>
        <Icon src={IconUser} color="#656A6E" height="24px" />
        {translator('Dados do perfil')}
      </Header>
      <Body onSubmit={submit}>
        <GeneralData form={formData} state={state} changeState={setState} />
        <Address form={formData} state={state} disableAutoFocus />
        <Contacts form={formData} state={state} disableAutoFocus />
        <Footer>
          <Button id="action" rounded type="submit" disabled={loading}>
            {loading ? translator('Salvando') : translator('Salvar alterações')}
          </Button>
        </Footer>
      </Body>
    </Container>
  );
};

export default ConnectComponent(Perfil);
