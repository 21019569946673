import React from 'react';
import {
  ContainerDocument,
  ContainerInformation,
  ContainerSigned,
  ContainerButtons,
  Content,
} from './styles/StyledDocumentPreview';

import { IconSignedDocuments } from '~/presentation/base/icons';

import { Button } from '~/presentation/components/UI';

import { MediaViewer } from '~/presentation/components/mediaViewer';

import { translator } from '~/presentation/components/i18n';

interface ownProps {
  isSigned: string;
  name: string;
}

const DocumentPreview: React.FC<ownProps> = ({ isSigned, name }) => {
  return (
    <ContainerDocument>
      <Content>
        <ContainerInformation>
          <h2>{`${translator('Paciente')}:`}</h2>
          <h1>{name}</h1>
        </ContainerInformation>
        <ContainerButtons>
          <Button size="medium" rounded variant="secundary" color="red">
            {`${translator('Excluir Receita')}`}
          </Button>
          <Button size="medium" rounded>
            {`${translator('Enviar ao Paciente')}`}
          </Button>
        </ContainerButtons>
      </Content>
      <ContainerSigned>
        <IconSignedDocuments />
        <h1>{isSigned}</h1>
      </ContainerSigned>
      <div>
        <MediaViewer link="https://homepages.dcc.ufmg.br/~fabricio/download/webmedia-short-course.pdf" />
      </div>
    </ContainerDocument>
  );
};

export default DocumentPreview;
