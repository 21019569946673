import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 450px;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
`;

interface IRole {
  selected?: boolean;
}

export const Itens = styled.div<IRole>`
  display: flex;
  flex-direction: column;

  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #f5f5f5;
    `}
`;

export const Text = styled.div`
  text-align: center;
  font-size: 14px;
  margin-bottom: 5px;
  color: #7a7a7a;

  strong {
    color: black;
  }
`;

export const Orgs = styled.div`
  margin-top: 15px;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  overflow: auto;
`;

interface iTitle {
  selected: boolean;
}

export const Title = styled.div<iTitle>`
  color: ${props => (props.selected ? '#004ba5' : '#7a7a7a')};
  font-weight: 500;
  margin-bottom: 10px;
`;
