/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';
import { makeReduxCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { translator } from '~/presentation/components/i18n';
import { Signature } from '~/presentation/components/signature';
import { ContentSignature } from '~/presentation/components/signature/styles/StyledSignature';
import { Button } from '~/presentation/components/UI';
import { schema } from '~/validation/validators/document/CreateClinicalDocValidator';
import { Request } from '.';
import GeneralData, { ownProps as GeneralDataProps } from './GeneralData';
import { iCreateExamRequest } from './interface';
import { ConnectComponent } from './mapper/MapperExamRequestSideBar';
import {
  Container,
  ContainerButton,
  Content,
  Header,
  Main,
  Phases,
} from './styles/StyledExamRequestSideBar';

export interface externalProps {
  examRequest?: ReduxCreateClinicalDocs | RemoteCreateClinicalDocs;
  goBackToListDocument: () => void;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

export type ownProps = GeneralDataProps;

const ExamRequestSideBar: React.FC<ownProps & externalProps> = props => {
  const [crement, setCrement] = useState(1);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const stateParams = useLocation<iStateParams>().state;
  const { auth } = useSelector((store: iStore) => store);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const specialty =
    role === 'PRO'
      ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
          ?.professions.specialties[0].id
      : -1;

  const [exam, setExam] = useState<iCreateExamRequest>({
    professional:
      stateParams && stateParams.professionalId
        ? Number(stateParams.professionalId)
        : -1,
    patient:
      stateParams && stateParams?.consultantId
        ? Number(stateParams?.consultantId)
        : -1,
    orgUnitName,
    org: orgId,
  });

  const { goBackToListDocument } = props;

  let dataToSend: RemoteCreateClinicalDocs.Params = {};

  const CreateExamRequest = () => {
    if (stateParams && stateParams.appointmentId !== undefined) {
      dataToSend = {
        appointment:
          stateParams && stateParams.appointmentId
            ? Number(stateParams.appointmentId)
            : undefined,

        type: 3,
        content: {
          solicitacao: [
            {
              exam: exam.exam,
            },
          ],
          indicacaoClinica: exam.indicacaoClinica,
        },
        certificate: exam.certificate,
        certificateId: exam.certificateId,
        sidebar: true,
      };
    } else {
      dataToSend = {
        type: 3,
        content: {
          solicitacao: [
            {
              exam: exam.exam,
            },
          ],
          indicacaoClinica: exam.indicacaoClinica,
        },
        org: orgId,
        orgUnit: orgUnitId,
        patient:
          stateParams && stateParams.consultantId
            ? Number(stateParams.consultantId)
            : exam.patient,
        professional:
          stateParams && stateParams.professionalId
            ? Number(stateParams.professionalId)
            : exam.professional,
        certificate: exam.certificate,
        certificateId: exam.certificateId,
        specialty,
        sidebar: true,
      };
    }
    try {
      const validate = schema.parse(dataToSend);
      makeReduxCreateClinicalDocs().create({
        ...dataToSend,
        goBackToListDocument,
      });
      console.log('validate: ', validate);
    } catch (e) {
      console.error('erro ao enviar para o back', e);
    }
  };

  const data = {
    isSigned: 'Assinado',
    name: 'Danilo',
  };

  function next(dados: iCreateExamRequest) {
    setExam({ ...exam, ...dados });
    handleBar(1);
  }

  function back(dados: iCreateExamRequest) {
    setExam({ ...exam, ...dados });
    handleBar(-1);
  }

  function handleBar(number: number) {
    if (crement + number > 3 || crement + number < 1) {
      setCrement(crement);
    } else {
      setCrement(crement + number);
    }
  }

  return (
    <Container>
      <Header>Requisição de exames</Header>
      <Content>
        <Main>
          {crement === 1 && (
            <Phases>Etapa {crement} de 3: Informações gerais</Phases>
          )}
          {crement === 2 && <Phases>Etapa {crement} de 3: Requisição</Phases>}
          {crement === 3 && (
            <Phases>Etapa {crement} de 3: Assinatura do documento</Phases>
          )}
          {crement === 1 && (
            <GeneralData
              next={next}
              state={exam}
              consultant={props.consultant}
              professional={props.professional}
            />
          )}
          {crement === 2 && <Request state={exam} next={next} back={back} />}
          {crement === 3 && (
            <ContentSignature>
              <Signature
                changeSelected={(e: {
                  certificateId: string;
                  certificate: string;
                }) =>
                  setExam({
                    ...exam,
                    certificate: e.certificate,
                    certificateId: e.certificateId,
                  })
                }
                changeDisabled={(stat: boolean) => setDisableButton(stat)}
                onSignSubmit={CreateExamRequest}
              />
            </ContentSignature>
          )}
        </Main>
        {crement === 3 ? (
          <ContainerButton>
            <Button
              id="btn_prev_step"
              rounded
              variant="secundary"
              onClick={() => handleBar(-1)}
              size="small"
            >
              {`${translator('Voltar')}`}
            </Button>
            <Button
              id="btn_next_step"
              rounded
              size="small"
              onClick={CreateExamRequest}
              disabled={loading || disableButton}
            >
              {`${translator('Concluir')}`}
            </Button>
          </ContainerButton>
        ) : null}
      </Content>
    </Container>
  );
};

export default ConnectComponent(ExamRequestSideBar);
