import React from 'react';

import {
  Container,
  CardHeader,
  HeaderImage,
  HeaderTitle,
  CardContent,
  BarSubtitleContainer,
  BarSubtitle,
  Bar,
  FilledBar,
  CardFooter,
  CardImage,
  FooterItem,
  FooterItemTextContainer,
  FooterItemText,
} from './styles/packagePlusStyle';

import { AvailableDotIcon, UsedDotIcon } from '~/presentation/base/icons';

interface Props {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  usedPercentage: string;
  usedAmount: string;
  availableAmount: string;
}

const ServicesCard: React.FC<Props> = ({
  Icon,
  title,
  usedPercentage,
  usedAmount,
  availableAmount,
}) => {
  const availableNumber = 100 - Number(usedPercentage.replace(/\D+/g, ''));

  return (
    <Container>
      <CardHeader>
        <HeaderImage>
          <Icon />
        </HeaderImage>
        <HeaderTitle>{title}</HeaderTitle>
      </CardHeader>
      <CardContent>
        <BarSubtitleContainer>
          <BarSubtitle width={usedPercentage}>{usedPercentage}</BarSubtitle>
          <BarSubtitle width={`${availableNumber}%`}>
            {`${availableNumber}%`}
          </BarSubtitle>
        </BarSubtitleContainer>
        <Bar>
          <FilledBar width={usedPercentage} />
        </Bar>
      </CardContent>
      <CardFooter>
        <FooterItem>
          <CardImage>
            <UsedDotIcon />
          </CardImage>
          <FooterItemTextContainer>
            <FooterItemText>{usedAmount}</FooterItemText>
            <FooterItemText subtitle>Utilizados</FooterItemText>
          </FooterItemTextContainer>
        </FooterItem>

        <FooterItem>
          <CardImage>
            <AvailableDotIcon />
          </CardImage>
          <FooterItemTextContainer>
            <FooterItemText color="#92AED1">{availableAmount}</FooterItemText>
            <FooterItemText subtitle color="#92AED1">
              Livre
            </FooterItemText>
          </FooterItemTextContainer>
        </FooterItem>
      </CardFooter>
    </Container>
  );
};

export default ServicesCard;
