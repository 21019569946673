import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { PatchPasswordRecovery as UsecaseRemotePatchPasswordRecovery } from '~/domain/usecases/password/remote';

import { RemotePatchPasswordRecovery } from '~/data/repository/password/RemotePatchPasswordRecovery';

/**
 * send request via API.
 */

export const makeRemotePatchPasswordRecovery = (): UsecaseRemotePatchPasswordRecovery =>
  new RemotePatchPasswordRecovery(
    makeApiUrl('/password/validate'),
    makeHttpClient(),
  );
