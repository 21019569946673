import React, { useEffect, useState, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';

import {
  Container,
  Schedule,
  ClinicalDocs,
  Users,
  Adm,
  LeftItem,
  RightItem,
  Drop,
  UserOptions,
  Image,
} from './styles/StyledMenu';

import { IconArrow, Bell, IconV4Hlogo } from '~/presentation/base/icons';
import { Icon } from '~/presentation/components/icon';

import { AvatarRoles } from '~/presentation/components/avatar-roles';
import { Popover } from '~/presentation/components/UI/popover';
import { Notification } from '~/presentation/components/UI';
import { HelpPopover } from '~/presentation/components/help';
import { translator } from '~/presentation/components/i18n';
import { Logout } from '~/domain/usecases/auth/redux/Logout';
import { ReduxLogout } from '~/data/store/reducer/auth/usecases/ReduxLogout';
import { History } from '~/main/routes';
import { makeReduxGetAllNotificationUser } from '~/main/factories/usecases/Notifications/GetAllNotificationUserFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxRefresh } from '~/main/factories/usecases/auth/RefreshFactory';
import { makeReduxSelectUser } from '~/main/factories/usecases/auth/SelectUserFactory';
import { closeModal } from '~/utils/closeModal';

interface iTranslations {
  adm: string;
  users: string;
  clinicalDocs: string;
}

const Menu: React.FC = (): JSX.Element => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [translations, setTranslations] = useState<iTranslations>({
    adm: translator('Administração') ?? 'Administração',
    clinicalDocs: translator('Documentos clínicos') ?? 'Documentos Clínicos',
    users: translator('Usuários') ?? 'Usuários',
  });

  const { auth, message } = useSelector((store: iStore) => store);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const refreshToken = useSelector((store: iStore) => store.auth.refresh.token);
  const results = useSelector((store: iStore) => store.selectedOrg.results);
  const { data } = message;
  const { state } = useLocation<{ from: string }>();

  useEffect(() => {
    if (results.length > 0 && results[0].org?.logo)
      setLogoUrl(results[0].org.logo);
  }, [results]);

  console.log('####data', data.orgId);

  useEffect(() => {
    makeReduxActiveMessage().active({
      actionOk: () => {
        makeReduxRefresh().refresh({
          user: auth.info.user?.id,
          org: data.orgId ?? auth.selectUser.orgId,
          refreshToken,
        });
        makeReduxSelectUser().update({
          orgUnitId: data?.orgUnitId ?? auth.selectUser.orgUnitId,
          orgId: data.orgId ?? auth.selectUser.orgId,
        });
        closeModal();
      },
    });
  }, [data]);

  const handleLogout = useCallback(() => {
    const logoutApp = new ReduxLogout();
    logoutApp.logout();
    History.push('/');
  }, []);

  const changeRole = () => {
    return makeReduxActiveMessage().active({
      active: 'userChangeRole',
      actionCancel: closeModal,
    });
  };

  const userOptions = (
    <UserOptions>
      <button
        type="button"
        onClick={() => {
          const url = window.location.pathname.replace(/\d+/g, '');

          if (url !== '/profile') {
            History.getHistory().push('/profile', {
              from: url.replaceAll('/', ''),
            });
          } else {
            History.getHistory().replace('/profile', {
              from: state.from,
            });
          }
        }}
      >
        <Drop id="btn_login">{`${translator('Perfil')}`}</Drop>
      </button>
      <button id="btn_org" type="button" onClick={() => changeRole()}>
        <Drop>{`${translator('Organizações')}`}</Drop>
      </button>
      <Drop id="btn_logout" onClick={() => handleLogout()}>
        {`${translator('Sair')}`}
      </Drop>
    </UserOptions>
  );

  const user = (
    <div>
      <Link to="/participant">
        <Drop resourcers={['PATIENT']} actions={['READ_ANY', 'READ_ORG']}>
          {translator('Pacientes')}
        </Drop>
      </Link>
      <Link to="/professional">
        <Drop
          resourcers={['ORGANIZER']}
          actions={[
            'READ_OWN',
            'READ_ORG',
            'READ_UNIT',
            'UPDATE_OWN',
            'DELETE_OWN',
          ]}
        >
          {translator('Profissionais de saúde')}
        </Drop>
      </Link>
      <Link to="/manager">
        <Drop
          resourcers={['ORGANIZER']}
          actions={[
            'CREATE',
            'CREATE_ORG',
            'UPDATE_ORG',
            'UPDATE_ANY',
            'READ_ORG',
            'READ_ANY',
            'DELETE_ORG',
            'DELETE_ANY',
          ]}
        >
          {translator('Organizador')}
        </Drop>
      </Link>
      <Link to="/administrator">
        <Drop
          resourcers={['ADMINISTRATOR']}
          actions={['READ_OWN', 'READ_ANY', 'READ_ORG']}
        >
          {translator('Administrador')}
        </Drop>
      </Link>
    </div>
  );
  const adm = (
    <div>
      {/* Billing (serviços e faturamento) */}
      <Link to="/administrator/billing">
        <Drop
          resourcers={['ADMINISTRATOR']}
          actions={['READ_OWN', 'READ_ANY', 'READ_ORG']}
        >
          {translator('Serviços e faturamentos')}
        </Drop>
      </Link>
      {/* <Drop resourcers={['ADMINISTRATOR']} actions={['READ_OWN', 'READ_ANY']}>
        {translator('Painéis')}
      </Drop>
      <Drop resourcers={['ADMINISTRATOR']} actions={['READ_OWN', 'READ_ANY']}>
        {translator('Log de atividade')}
      </Drop> */}
      <Link to="/administrator/sectors">
        <Drop
          resourcers={['ADMINISTRATOR']}
          actions={['READ_OWN', 'READ_ANY', 'READ_ORG']}
        >
          {translator('Setores')}
        </Drop>
      </Link>
    </div>
  );

  function callNotification() {
    makeReduxGetAllNotificationUser().getAll({
      filters: {
        channel: 'WEB',
        user: auth.info.user?.id,
      },
    });
  }

  return (
    <Container>
      <div id="logo">
        {logoUrl ? (
          <Image alt="Logo" src={logoUrl} width="80px" height="46px" />
        ) : (
          <Icon src={IconV4Hlogo} width="100%" height="100%" />
        )}
      </div>
      <LeftItem>
        {/* AGENDA */}
        <Link to="/appointment">
          <Schedule
            resourcers={['APPOINTMENT']}
            actions={[
              'CREATE_OWN',
              'CREATE_ORG',
              'UPDATE_OWN',
              'UPDATE_ORG',
              'READ_OWN',
              'READ_ORG',
              'DELETE_OWN',
              'DELETE_ORG',
            ]}
          >
            {translator('Agenda')}
          </Schedule>
        </Link>
        {role !== 'ORG' && ( // TODO: Verificar ACL para caso necessário, renderizar pagina
          // DOCUMENTOS CLINICOS
          <Link to="/clinicaldocs">
            <ClinicalDocs
              resourcers={['CLINICAL_DOC']}
              actions={[
                'CREATE_OWN',
                'DELETE_OWN',
                'DELETE_ORG',
                'UPDATE_OWN',
                'READ_OWN',
                'READ_ORG',
                'SHARE',
              ]}
            >
              {translations.clinicalDocs}
            </ClinicalDocs>
          </Link>
        )}
        {role === 'CON' ? (
          ''
        ) : (
          // USUÁRIOS
          <Popover
            content={user}
            originX="center"
            originY="bottom"
            resourcers={[
              'PROFESSIONAL',
              'ORGANIZER',
              'ADMINISTRATOR',
              'PATIENT',
            ]}
            actions={['READ_ORG', 'READ_ANY', 'READ_OWN']}
          >
            <Users id="menu_users" icon>
              {translations.users}
              <IconArrow />
            </Users>
          </Popover>
        )}
        {/* ADMINISTRADORES */}
        <Popover
          content={adm}
          originX="center"
          originY="bottom"
          resourcers={['ADMINISTRATOR']}
          actions={['READ_OWN', 'READ_ANY', 'READ_ORG']}
        >
          <Adm icon>
            {translations.adm}
            <IconArrow />
          </Adm>
        </Popover>
      </LeftItem>
      <RightItem>
        <Popover originX="right" originY="bottom" content={<Notification />}>
          <Bell />
        </Popover>
        {/* <HelpPopover /> */}
        <Popover content={userOptions} originX="right" originY="bottom">
          <AvatarRoles
            name={`${auth?.info?.user?.firstName || ''} ${
              auth?.info?.user?.lastName || ''
            }`}
            role="organizer"
            cargo={role || 'ADM'}
            size="mini"
            src={auth.info.user?.avatar}
          />
        </Popover>
      </RightItem>
    </Container>
  );
};

export default Menu;
