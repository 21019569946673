import React from 'react';
import { makeReduxSelectOne } from '~/main/factories/usecases/clinicalDocs/SelectOneFactory';
import {
  IconCrossBlue,
  IconCrossRed,
  IconFiles,
  IconMp4,
  IconNotSignedDocument,
  IconPng,
  IconSignedDocuments,
  Pdf,
  FileEarmarkIcon,
  FileEarmarkTextIcon,
  GroupIcon,
} from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { OptionsClinicalDoc } from '~/presentation/components/optionsMore';
import OptionsSupportDoc from '~/presentation/components/optionsMore/OptionsSupportDoc';
import { More } from '~/presentation/components/UI/popover';
import {
  Body,
  Container,
  Prefix,
  SubTitle,
  Suffix,
  Title,
  Wrapper,
} from './styles/StyledDocumentItem';

interface DocumentsItemProps {
  type?: string;
  documentSize?: number | string;
  title: string;
  subTitle: string;
  isSigned?: boolean;
  date: string;
  id?: any;
  open?: Function;
}

const DocumentsItem: React.FC<DocumentsItemProps> = ({
  type = 'clinical-documents',
  title,
  subTitle,
  isSigned,
  date,
  documentSize,
  id,
  open,
}) => {
  const extension = title.split('.').pop();

  function selectIcon() {
    if (extension === 'pdf') {
      return <Pdf />;
    }
    if (extension === 'mp4') {
      return <IconMp4 />;
    }
    if (
      extension === 'png' ||
      extension === 'jpeg' ||
      extension === 'jpg' ||
      extension === 'bmp'
    ) {
      return <IconPng />;
    }
    if (extension === 'odt' || extension === 'doc' || extension === 'docx') {
      return <FileEarmarkTextIcon />;
    }
    if (extension === 'ods' || extension === 'xls' || extension === 'xlsx') {
      return <FileEarmarkIcon />;
    }
    if (extension === 'odp' || extension === 'ppt' || extension === 'pptx') {
      return <GroupIcon />;
    }

    return <IconFiles />;
  }

  function prefixList() {
    console.log('date: ', date);
    switch (type) {
      case 'clinical-documents':
        return (
          <Wrapper isSigned={isSigned!} id={id}>
            {isSigned ? <IconCrossBlue /> : <IconCrossRed />}
          </Wrapper>
        );
      case 'supporting-documents':
        return (
          <Wrapper isSigned={isSigned!} id={id}>
            {selectIcon()}
          </Wrapper>
        );
      default:
        return <text>carregando...</text>;
    }
  }
  function suffixList() {
    switch (type) {
      case 'clinical-documents':
        return (
          <Wrapper isSigned={isSigned!} id={`status_${id}`}>
            {isSigned ? (
              <div>
                <IconSignedDocuments />
                <text>{translator('Assinado')}</text>
              </div>
            ) : (
              <div>
                <IconNotSignedDocument />
                <text>{translator('Pendente')}</text>
              </div>
            )}
          </Wrapper>
        );
      case 'supporting-documents':
        return (
          <Wrapper isSigned>
            <span>
              <text>{`${translator('Tamanho')}:`}</text>
              <text>
                <b>{`${documentSize}MB`}</b>
              </text>
            </span>
          </Wrapper>
        );
      default:
        return <text>carregando...</text>;
    }
  }
  function handleDoc(idDoc: number) {
    makeReduxSelectOne().select({
      id: idDoc,
    });
  }
  return (
    <Container id={id}>
      <Prefix>{prefixList()}</Prefix>
      <Body>
        {type === 'clinical-documents' ? (
          <Title id={id} isSigned={isSigned!} onClick={() => handleDoc(id)}>
            {title}
          </Title>
        ) : (
          <Title id={id} isSigned={isSigned!}>
            {title}
          </Title>
        )}
        <SubTitle>{subTitle}</SubTitle>
      </Body>
      <Suffix>
        <More
          content={
            type === 'clinical-documents' ? (
              <OptionsClinicalDoc id={id} status={isSigned} />
            ) : (
              <OptionsSupportDoc
                supportDocID={Number(id)}
                extension={extension}
              />
            )
          }
        />
      </Suffix>
    </Container>
  );
};
export default DocumentsItem;
