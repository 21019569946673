import React, { useEffect, useState } from 'react';

import { HeaderSimples } from '~/presentation/components/header';
import { SearchListProfessional } from '~/presentation/components/search-list';
import { Button } from '~/presentation/components/UI';

import { Menu } from '~/presentation/components/menu';
import {
  iListRegisteredProfessional,
  ListRegisteredProfessional,
} from '../../../../components/ListManager';

import {
  Container,
  Body,
  HeaderList,
  Wrapper,
  Attendiment,
  LockScroll,
} from './StyledSchedule';
import { History } from '~/main/routes';
import { ConnectProfessional } from '~/presentation/components/ListManager/mapper/MapperListProfessional';
import { TablePagination } from '~/presentation/components/tablePagination';
import { IconPlus } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { FilterProfessional } from '~/presentation/components/filter';

export interface pageProps {
  data: iListRegisteredProfessional[];
}
const ScheduleProfessional: React.FC<pageProps> = ({ data }): JSX.Element => {
  const url = window.location.pathname;

  function pushTo(path: string) {
    History.getHistory().push(path, {
      from: url.replaceAll('/', ''),
    });
  }

  const [state, setState] = useState(data);

  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <Container>
      <LockScroll>
        <Menu />
        <HeaderSimples
          title={translator('Profissionais cadastrados')}
          value={state.length}
          valueDescription={translator('Cadastrados')}
        />
        <HeaderList>
          {state.length > 0 && state !== undefined ? (
            <TablePagination data={state} itemPerPage={10} />
          ) : (
            <div />
          )}
          <Wrapper>
            <div>
              <SearchListProfessional>
                <FilterProfessional />
              </SearchListProfessional>
            </div>
            <Button
              id="button_create"
              onClick={() =>
                History.getHistory().push('/professional/register', {
                  from: url.replaceAll('/', ''),
                })
              }
              size="large"
              rounded
            >
              <Attendiment>
                <IconPlus />
              </Attendiment>
              <text>{translator('Novo profissional')}</text>
            </Button>
          </Wrapper>
        </HeaderList>
      </LockScroll>
      <Body>
        <div id="list-container">
          <ListRegisteredProfessional data={data} />
        </div>
      </Body>
    </Container>
  );
};

export default ConnectProfessional(ScheduleProfessional);
