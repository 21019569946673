import React, { useEffect, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { useSelector } from 'react-redux';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  Content,
  Container,
  SignedAfter,
  MessageCertificate,
  SignedNow,
  ContentSignature,
} from './styles/StyledSignature';

import { Button, Select, Input } from '~/presentation/components/UI';

import { translator } from '~/presentation/components/i18n';

import { MakeRemoteGetCertificates } from '~/main/factories/usecases/daemon/GetCertificates';
import { GetCertificates } from '~/domain/usecases/deamon/remote';

import { makeReduxSignClinicalDoc } from '~/main/factories/usecases/clinicalDocs/SignClinicalDocs';
import { V4hSpin } from '../spin';
import { iStore } from '~/domain/interfaces/models';
import { AlertMessage } from '../messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface ownProps {
  changeSelected?: (
    // {
    //   certificateId: number;
    //   certificate: string;
    // }
    data: any,
  ) => any;
  docId?: number;
  appointmentId?: number;
  changeDisabled?: (data: boolean) => any;
  onSignSubmit?: () => any;
  isSigned?: boolean;
}

const Signature: React.FC<ownProps> = ({
  changeSelected,
  docId,
  appointmentId,
  changeDisabled,
  onSignSubmit,
  isSigned,
}): JSX.Element => {
  const [certificates, setCertifaces] = useState<GetCertificates.Model>([]);
  const [selected, setSelected] = useState<number>(-1);
  const [loadCertificate, setLoading] = useState<boolean>(false);
  const assign = useSelector((store: iStore) => store.clinicalDocs.loading);

  console.log('docId: ', docId);
  const [check, setCheck] = useState<boolean>(!!docId);
  console.log('check: ', check);

  useEffect(() => {
    if (changeDisabled) changeDisabled(assign);
    setLoading(assign);
  }, [assign]);

  useEffect(() => {
    if (changeDisabled) {
      // * Se assinar agora estiver selecionado
      if (check) {
        // * Se um certificado estiver selecionado.
        if (selected !== -1) {
          changeDisabled(false);
        } else {
          changeDisabled(true);
        }
      } else {
        changeDisabled(false);
      }
    }
  }, [changeDisabled, check, selected]);

  useEffect(() => {
    if (!isSigned) {
      if (changeDisabled) changeDisabled(true);
      setLoading(true);
      MakeRemoteGetCertificates()
        .getCertificates()
        .then(response => {
          console.log('ledger: ', response);
          setCertifaces(response);
          setLoading(false);
          if (changeDisabled) changeDisabled(false);
        })
        .catch(e => {
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possivel encontrar nenhum certificado',
            }),
            type: 'info',
          });
          setLoading(false);
          if (changeDisabled) changeDisabled(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned]);

  const onSubmit = () => {
    makeReduxSignClinicalDoc().sign({
      certificate: certificates[selected].base64Certificate,
      certificateId: certificates[selected].id,
      docId,
      appointmentId,
    });
  };

  const textButton = translator('Assinar Documento');

  return (
    <Container>
      {!docId && (
        <>
          <SignedNow checked={check}>
            <Input
              type="radio"
              width="20px"
              height="20px"
              checked={check}
              onClick={() => setCheck(!check)}
              style={{ outline: 'none' }}
            />
            <text>{`${translator('Assinar agora')}`}</text>
          </SignedNow>
          <MessageCertificate checked={check}>
            <text>
              {`${translator(
                'Selecione seu certificado digital e finalize a criação do documento agora.',
              )}`}
            </text>
          </MessageCertificate>
        </>
      )}
      <ContentSignature>
        <Content>
          <Select
            label={`${translator('Certificado Digital')}`}
            value={selected}
            disabled={loadCertificate || !check}
            onChange={e => {
              setSelected(Number(e.target.value));
              if (changeSelected)
                changeSelected({
                  certificateId: certificates[Number(e.target.value)].id,
                  certificate:
                    certificates[Number(e.target.value)].base64Certificate,
                });
            }}
          >
            <option value="-1" disabled>
              {`${translator('Selecione um certificado')}`}
            </option>
            {certificates.map((e, index) => (
              <option value={index}>{e.name}</option>
            ))}
          </Select>
        </Content>
        {docId ? (
          <div>
            {!loadCertificate ? (
              <Button
                size="medium"
                rounded
                onClick={onSignSubmit}
                disabled={selected === -1}
              >
                {textButton}
              </Button>
            ) : (
              <V4hSpin fullScreen={false} size="40px" />
            )}
          </div>
        ) : (
          <div>
            {loadCertificate && check && (
              <V4hSpin fullScreen={false} size="40px" />
            )}
          </div>
        )}
      </ContentSignature>
      {!docId && (
        <>
          <SignedAfter checked={!check}>
            <Input
              type="radio"
              width="20px"
              height="20px"
              checked={!check}
              onClick={() => {
                setCheck(!check);
                // if (changeSelected)
                //   changeSelected({
                //     certificate: undefined,
                //     certificateId: undefined,
                //   });
              }}
              style={{ outline: 'none' }}
            />
            <text>{`${translator('Assinar depois')}`}</text>
          </SignedAfter>
          <MessageCertificate checked={!check}>
            <text>
              {`${translator(
                'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.',
              )}`}
            </text>
          </MessageCertificate>
        </>
      )}
    </Container>
  );
};

export default Signature;
