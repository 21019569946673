import styled from 'styled-components';

export const Header = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 13px;
  height: 14px;
  margin-bottom: 10px;
  color: #a7a7a7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
