import React from 'react';
import { TestUserListParticipant } from '.';

// import { Container } from './styles';

const TestUserListProfessional: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <div>
        {/* <UserListParticipant
          list="list-1"
          date
          participant
          timeStart
          timeEnd
          specialty
          register
          typeIs
          status2
          actions={{ cancel: true, person: true, delete: true }}
        /> */}
      </div>
      <div style={{ marginTop: 20 }}>
        {/* <UserListParticipant
          list="list-2"
          date
          participant
          statusSignature
          specialty
          time
          origin
          clinicalDocument
          actions={{ cancel: true, person: true }}
        /> */}
      </div>
    </div>
  );
};

export default TestUserListProfessional;
