import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.background};
  overflow: scroll;
  position: relative;
`;

export const Body = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const HeaderList = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 20px;
  margin-bottom: 3px;
  max-width: 100%;
  justify-content: space-between;
  background: linear-gradient(
    to bottom,
    ${props => props.theme.background} 0%,
    ${props => props.theme.background} 90%,
    transparent 100%
  );
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Attendiment = styled.div`
  width: 25px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
`;

export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;
