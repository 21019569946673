import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Ready from './Ready';
import WaitingParticipant from './WaitingParticipant';

import {
  LeftWaitingRoom,
  JoinWaitingRoom,
} from '~/domain/usecases/waitingRoom/socket';

import { makeReduxUserQueueWaitingRoom } from '~/main/factories/usecases/waitingRoom/UserQueueWaitingRoomFactory';
import { makeSocketJoinWaitingRoom } from '~/main/factories/usecases/waitingRoom/JoinWaitingRoomFactory';
import { ConnectComponent } from './mapper/MapperWaitingRoom';
import { iStore } from '~/domain/interfaces/models';
import { History } from '~/main/routes';

export interface ownProps {
  ActionRoom: LeftWaitingRoom;
  notification: {
    status: string;
    userId: number;
    appointmentId?: number;
  };
  waitingRoom?: {
    room: {
      firstName: string;
      lastName: string;
      userId: number;
    }[];
  };
}

interface iStateParams {
  appointmentId: string;
  consultantId: number;
  professionalId: number;
}

const WaitingRoom: React.FC<ownProps> = ({
  ActionRoom,
  notification,
  waitingRoom,
}): JSX.Element => {
  const [state, setState] = useState(0);
  const stateParams = useLocation<iStateParams>().state;

  const userLogged = useSelector((store: iStore) => store.auth.info.user);
  const [waitingRoomData, setWaitingRoomData] =
    useState<JoinWaitingRoom.Model>();

  useEffect(() => {
    const room = makeSocketJoinWaitingRoom();

    room.join(
      {
        appointmentId: Number(stateParams.appointmentId),
        data: {
          role: userLogged!.mainRole!,
          user: userLogged!.id,
          queue: 1,
        },
      },
      (data: JoinWaitingRoom.Model) => {
        data.waitingRoom.users.forEach(el => {
          if (el.id !== userLogged?.id) {
            makeReduxUserQueueWaitingRoom().add({
              data: {
                appointment: parseInt(stateParams.appointmentId, 10),
                status: 'JOINED',
              },
              from: {
                userId: el.id,
                firstName: el.name,
                lastName: '',
              },
            });
          }
        });
        setWaitingRoomData(data);
      },
    );
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      leaveWaitingRoom();
      window.onpopstate = null;
    };
  }, [waitingRoomData]);

  useEffect(() => {
    switch (notification?.status) {
      case 'JOINED':
        if (
          notification.userId !== userLogged?.id &&
          notification.appointmentId === Number(stateParams.appointmentId)
        )
          setState(1);
        break;
      case 'LEFT':
        setState(0);
        break;
      default:
        break;
    }
  }, [notification]);

  useEffect(() => {
    if (
      waitingRoom &&
      waitingRoom?.room?.length > 1 &&
      waitingRoom?.room?.filter(item => {
        return item.userId !== userLogged?.id;
      })
    ) {
      setState(1);
    }
  }, [waitingRoom, waitingRoomData]);

  const left = () => {
    window.onpopstate = null;

    ActionRoom.left({
      appointmentId: Number(stateParams.appointmentId),
      waitingRoomUserId: Number(waitingRoomData?.waitingRoomUserId),
    });
    History.push('/appointment');
  };

  const drawing = () => {
    switch (state) {
      case 0:
        return <WaitingParticipant left={left} />;
      case 1:
        return (
          <Ready
            appointmentId={Number(stateParams.appointmentId)}
            waitingRoomUserId={Number(waitingRoomData?.waitingRoomUserId)}
            consultantId={Number(stateParams.consultantId)}
            professionalId={Number(stateParams.professionalId)}
            left={left}
          />
        );
      default:
        return <></>;
    }
  };

  const leaveWaitingRoom = useCallback(() => {
    if (waitingRoomData !== undefined) {
      ActionRoom.left({
        appointmentId: Number(stateParams.appointmentId),
        waitingRoomUserId: Number(waitingRoomData?.waitingRoomUserId),
      });
    }
  }, [waitingRoomData]);

  return <>{drawing()}</>;
};

export default ConnectComponent(WaitingRoom);
