import React, { useState } from 'react';

import {
  ContainerNewConsult,
  Container,
  Arrow,
} from './styles/StyledNewConsult';

import { IconArrowSelect } from '~/presentation/base/icons';

import { Body } from '.';
import { translator } from '../i18n';

const NewConsult = () => {
  const [state, setState] = useState<boolean>(false);

  return (
    <ContainerNewConsult>
      <Container>
        <button type="button" onClick={() => setState(!state)}>
          {translator('Novo agendamento')}
          <Arrow active={state}>
            <IconArrowSelect className="icon" width="24px" height="24px" />
          </Arrow>
        </button>
      </Container>
      {state ? <Body /> : null}
    </ContainerNewConsult>
  );
};

export default NewConsult;
