import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { iListConsultant, iCreateExamRequest } from './interface';

import { schemaGeneralData } from '~/validation/validators/document/CreateClinicalDocValidator';

import { iProfessional } from '~/domain/interfaces/models/Professional';

import { Container, Form, Navigation } from './styles/StyledGeneralData';
import { Button, Input, Select } from '~/presentation/components/UI';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';

import { translator } from '~/presentation/components/i18n';

export interface ownProps {
  consultant: iListConsultant[];
  next: (data: iCreateExamRequest) => any;
  professional: iProfessional;
  state?: iCreateExamRequest;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

const GeneralData: React.FC<ownProps> = ({
  next,
  consultant,
  professional,
  state,
}): JSX.Element => {
  const stateParams = useLocation<iStateParams>().state;

  const [professionalId, setProfId] = useState<number | undefined>(
    stateParams.professionalId,
  );
  const [consultantId, setConId] = useState<number | undefined>(
    stateParams.consultantId,
  );

  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGeneralData),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  useEffect(() => {
    register('professional');
    register('patient');

    if (stateParams.professionalId) {
      setProfId(stateParams.professionalId);
      setValue('professional', stateParams.professionalId);
    }

    if (stateParams.consultantId) {
      setConId(stateParams.consultantId);
      setValue('patient', stateParams.consultantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateParams]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Input
          id="input_instituition"
          onChange={e => setValue('org', Number(e.target.value))}
          register={() => register('org')}
          error={Boolean(errors.org)}
          message={errors?.org?.message}
          placeholder={state?.orgUnitName}
          name="org"
          label={`${translator('Instituição')}`}
          autoFocus
          required
          disabled
        />
        <Select
          key={professionalId}
          id="select_professional"
          label={`${translator('Profissional')}`}
          onChange={e => {
            setProfId(Number(e.target.value));
            setValue('professional', Number(e.target.value));
          }}
          value={professionalId}
          name="professional"
          register={() => register('professional')}
          error={Boolean(errors.professional)}
          message={
            errors?.professional?.message
              ? translator(errors?.professional?.message)
              : ''
          }
          disabled={Boolean(professionalId && professionalId !== -1)}
        >
          <option value="selectPatient">
            {translator('Selecione um profissional')}
          </option>
          {professional?.results?.map(user => (
            <option
              key={user.professional.id}
              value={Number(user.professional.id)}
            >
              {`${user.user.firstName} ${user.user.lastName}`}
            </option>
          ))}
        </Select>

        <Select
          key={consultantId}
          id="select_patient"
          label={`${translator('Paciente')}`}
          onChange={e => {
            setConId(Number(e.target.value));
            setValue('patient', Number(e.target.value));
          }}
          value={consultantId}
          name="patient"
          register={() => register('patient')}
          error={Boolean(errors.patient)}
          message={
            errors?.patient?.message ? translator(errors?.patient?.message) : ''
          }
          disabled={Boolean(consultantId && consultantId !== -1)}
        >
          <option value="selectPatient">
            {translator('Selecione um paciente')}
          </option>
          {consultant.map(consult => (
            <option key={consult.id} value={consult.id}>
              {`${consult.firstName} ${consult.lastName}`}
            </option>
          ))}
        </Select>

        <InputKeyboardDate
          state={new Date()}
          setState={() => ''}
          label={`${translator('Data de emissão')}`}
          placeholder="DD/MM/AAAA"
          disabled
        />
      </Form>
      <Navigation>
        <Button variant="primary" size="medium" onClick={onSubmit} rounded>
          Próximo
        </Button>
      </Navigation>
    </Container>
  );
};

export default GeneralData;
