import styled from 'styled-components';

interface iError {
  error?: boolean;
  width?: string;
}

export const Container = styled.div<iError>`
  width: 100%;
  height: 48px;
  border: 1px solid;
  border-color: ${props => (props.error ? 'red' : '#bfc4ca')};
  border-radius: 4px;

  #date-picker-inline-helper-text {
    display: none;
  }
`;

export const ContainerRoot = styled.div<iError>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ?? '100%'};
  align-items: flex-start;
  justify-content: flex-end;

  h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: ${props => (props.error ? 'red' : '#444a51')};
    margin-bottom: 6px;
  }

  #error {
    color: red;
  }
`;
