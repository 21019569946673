import React, { useState, SelectHTMLAttributes } from 'react';
import { IconArrowSelect } from '~/presentation/base/icons';

import { Container, Label, SelectContainer, Message } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name?: string;
  label?: string;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  labelStyle?: React.CSSProperties;
  register?: () => any;
}

const Select: React.FC<SelectProps> = ({
  children,
  name,
  label,
  message,
  error = false,
  width = '100%',
  height = '48px',
  register,
  required = false,
  labelStyle = {},
  ...rest
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  const mTop = label ? '4px' : '0px';
  // const mBottom = message ? '2px' : '0px';
  const { disabled } = rest;

  //
  // if the component are use react-hook-form.
  if (register) register();

  if (required) label = `${label}*`;

  return (
    <Container width={width}>
      {label && <Label style={labelStyle}>{label}</Label>}
      <SelectContainer
        style={{ marginTop: mTop }}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
      >
        <select
          {...rest}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          name={name}
        >
          {children}
        </select>
        <button type="button">
          <IconArrowSelect color="black" />
        </button>
        <Message error={error}>{message}</Message>
      </SelectContainer>
    </Container>
  );
};

export default Select;
