import styled from 'styled-components';

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 28em;
  max-height: 28em;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 140px;
  margin-bottom: 25px;

  text-align: center;

  p {
    color: black;
    font-size: 18px;
    font-weight: bold;

    width: 230px;
    margin-left: 18px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 100%;
  gap: 32px;

  margin-bottom: 25px;
`;

export const LinkItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LinkHeader = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */

  width: 100%;
  gap: 6px;

  padding: 0 22px;
  margin-bottom: 8px;

  p {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }
`;

export const ConfirmCancel = styled.button`
  width: 240px;
  height: 38px;
  margin-top: 11px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  color: #eb5757;
  border: 2px solid #eb5757;
  border-radius: 15px;
  padding: 14px;
  font-size: 15px;
  font-weight: bolder;
  line-height: 25px;
  cursor: pointer;

  :hover {
    background-color: #eb5757;
    color: white;
    transition: 0.7s;
  }
`;

export const CloseButton = styled.div`
  padding: 10px 18px 5px 5px;
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #c9c9c9;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 19px;
`;
