import styled from 'styled-components';

export const ContainerClinicalDocument = styled.div`
  width: 347px;
  height: auto;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background: #fbfbfb;
  padding: 20px;
  div {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-align: center;
  }
`;
