import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { makeRemoteGetClinicalDocsByDownload } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsByDownloadFactory';
import MediaError from './MediaError';
import { Content } from './styles/StyledMediaViewer';

interface Media {
  link?: string;
  id?: string;
  metadata?: {
    patientName?: string;
  };
}

const MediaViewer: React.FC<Media> = ({ link, id, metadata }) => {
  const [error, setError] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>();

  const requestfile = useCallback(() => {
    if (link !== undefined) {
      return axios.get(`${link}`, {
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      });
    }

    return makeRemoteGetClinicalDocsByDownload().getByDownload({
      docId: parseInt(`${id}`, 10),
    });
  }, [id, link]);

  const loadfile = useCallback(async () => {
    try {
      const data = await requestfile();

      const fileURL = URL.createObjectURL(data);

      setFileUrl(fileURL);
      setError(false);
    } catch (e) {
      setFileUrl(undefined);
      setError(true);
    }
  }, [requestfile]);

  useEffect(() => {
    loadfile();
  }, [link, id, loadfile]);

  return <Content id="containerPDF">{error && <MediaError />}</Content>;
};

export default MediaViewer;
