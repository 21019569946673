import React from 'react';
import {
  ContainerGeneralData,
  ContainerColumn,
  ContainerRow,
} from './styles/StyledGeneralData';

import { Input, Select } from '~/presentation/components/UI';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { translator } from '~/presentation/components/i18n';

const GeneralData = () => {
  return (
    <ContainerGeneralData>
      <ContainerColumn>
        <Input
          label={`${translator('Instituição')}`}
        />
        <Input
          label={`${translator('Profissional')}`}
        />
        <Select label={`${translator('Paciente')}*`} />
      </ContainerColumn>
      <ContainerRow>
        <InputKeyboardDate
          state={new Date()}
          setState={() => ''}
          label={`${translator('Data de emissão')}`}
          placeholder="DD/MM/AAAA"
        />
      </ContainerRow>
    </ContainerGeneralData>
  );
};

export default GeneralData;
