import React, { useState } from 'react';
import {
  ContainerClinicalDocument,
  Container,
  StyleButton,
} from './styles/StyledChooseDoc';
import { Button } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';
import { History } from '~/main/routes';

interface ownProps {
  setDocType: Function;
}

const ChooseDoc: React.FC<ownProps> = ({ setDocType }) => {
  function goBack() {
    History.back();
  }

  function handleSelect(value: number) {
    setDocType(value);
  }

  return (
    <Container>
      <ContainerClinicalDocument>
        <h1>{`${translator('Selecione um tipo de documento clínico')}`}</h1>
        <div>
          <Button
            id="btn_medicalCertificate"
            // resourcers={['PROFESSIONAL', 'CLINICAL_DOC']}
            // actions={['CREATE_OWN', 'CREATE_ANY']}
            size="medium"
            rounded
            variant="secundary"
            onClick={() => handleSelect(1)}
          >
            {`${translator('Atestado Médico')}`}
          </Button>
          <Button
            id="btn_simpleRecipe"
            // resourcers={['PROFESSIONAL', 'CLINICAL_DOC']}
            // actions={['CREATE_OWN', 'CREATE_ANY']}
            size="medium"
            rounded
            variant="secundary"
            onClick={() => handleSelect(2)}
          >
            {`${translator('Receita Simples')}`}
          </Button>
          <Button
            id="btn_specialRecipe"
            // resourcers={['PROFESSIONAL', 'CLINICAL_DOC']}
            // actions={['CREATE_OWN', 'CREATE_ANY']}
            size="medium"
            rounded
            disabled
            variant="secundary"
            onClick={() => handleSelect(3)}
          >
            {`${translator('Receita Especial')}`}
          </Button>
          <Button
            id="btn_antiMicrobial"
            // resourcers={['PROFESSIONAL', 'CLINICAL_DOC']}
            // actions={['CREATE_OWN', 'CREATE_ANY']}
            size="medium"
            rounded
            disabled
            variant="secundary"
            onClick={() => handleSelect(4)}
          >
            {`${translator('Receita Antimicrobiano')}`}
          </Button>
          <Button
            id="btn_examRequest"
            // resourcers={['PROFESSIONAL', 'CLINICAL_DOC']}
            // actions={['CREATE_OWN', 'CREATE_ANY']}
            size="medium"
            rounded
            variant="secundary"
            onClick={() => handleSelect(5)}
          >
            {`${translator('Requisição de Exames')}`}
          </Button>
          <Button
            id="btn_medicalReport"
            // resourcers={['PROFESSIONAL', 'CLINICAL_DOC']}
            // actions={['CREATE_OWN', 'CREATE_ANY']}
            size="medium"
            rounded
            disabled
            variant="secundary"
            onClick={() => handleSelect(6)}
          >
            {`${translator('Relatório médico')}`}
          </Button>
        </div>
      </ContainerClinicalDocument>
      <StyleButton>
        <Button
          id="btn_back"
          rounded
          size="small"
          variant="secundary"
          onClick={goBack}
        >
          {`${translator('Voltar')}`}
        </Button>
      </StyleButton>
    </Container>
  );
};

export default ChooseDoc;
