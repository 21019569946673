import React, { useState } from 'react';

import {
  Container,
  Title,
  EndPoint,
  SchedulingDate,
} from './styles/StyledPoint';

// import { Status } from './Mapper';
import { translator } from '../i18n';
import { getLocale } from '~/utils/getLocale';

interface ownProps {
  status: 'available' | 'canceled' | 'finished' | 'scheduled';
  active: boolean;
  date: string;
  setActive: () => any;
}

type Props = ownProps;

const Point: React.FC<Props> = (props): JSX.Element => {
  const date = new Date(props.date);

  const Status = {
    available: translator('Disponível'),
    finished: translator('Finalizado'),
    canceled: translator('Cancelado'),
    scheduled: translator('Agendado'),
  };

  return (
    <Container active={props.active} status={props.status}>
      <Title
        active={props.active}
        status={props.status}
        onClick={props.setActive}
      >
        {Status[props.status]}
      </Title>

      <EndPoint
        active={props.active}
        status={props.status}
        onClick={props.setActive}
      />

      <SchedulingDate
        active={props.active}
        status={props.status}
        onClick={props.setActive}
      >
        {date.toLocaleDateString(getLocale())}
      </SchedulingDate>
    </Container>
  );
};

export default Point;
