import { takeLatest } from 'redux-saga/effects';
import { onCreateRequest, onCreateSuccess, onCreateFailed } from './Create';
import { onGetRequest, onGetSuccess, onGetFailed } from './Get';
import { onGetAllRequest, onGetAllSuccess, onGetAllFailed } from './GetAll';
import { SpecialtyTypes } from '~/domain/interfaces/redux/specialty/types';
import { onUpdateFailed, onUpdateSpecialty, onUpdateSuccess } from './Update';

const tasks = [
  takeLatest(SpecialtyTypes.CREATE, onCreateRequest),
  takeLatest(SpecialtyTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(SpecialtyTypes.CREATE_FAILED, onCreateFailed),

  takeLatest(SpecialtyTypes.GET, onGetRequest),
  takeLatest(SpecialtyTypes.GET_SUCCESS, onGetSuccess),
  takeLatest(SpecialtyTypes.GET_FAILED, onGetFailed),

  takeLatest(SpecialtyTypes.GETALL, onGetAllRequest),
  takeLatest(SpecialtyTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(SpecialtyTypes.GETALL_FAILED, onGetAllFailed),

  takeLatest(SpecialtyTypes.UPDATE, onUpdateSpecialty),
  takeLatest(SpecialtyTypes.UPDATE_SUCCESS, onUpdateSuccess),
  takeLatest(SpecialtyTypes.UPDATE_FAILED, onUpdateFailed),
];

export default tasks;
