import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import RegisteredAdmin from './Lists/RegisteredAdmin';

import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { ListEmpty } from '../componentEmpty';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';

const ListRegisteredAdmin: React.FC = () => {
  const { dataPagination } = useSelector(
    (store: iStore) => store.dataPagination,
  );
  useEffect(() => {
    makeReduxDataPagination().set([]);
    // return () => {};
  }, []);

  const { loading } = useSelector((store: iStore) => store.users);

  return dataPagination.length === 0 && !loading ? (
    <ListEmpty
      message={translator(
        'Os administradores cadastrados aparecerão aqui. No momento não há nenhum',
      )}
    />
  ) : (
    <RegisteredAdmin dataList={dataPagination} />
  );
};

export default ListRegisteredAdmin;
