import React from 'react';

import Help from '~/presentation/components/help/Help';

const dados = [
  {
    title: 'Câmera e Microfone não funcionam',
    link: 'notes',
  },
  {
    title: 'Compartilhamento de tela',
    link: 'notes',
  },
  {
    title: 'Convidar pessoas para videochamada',
    link: 'notes',
  },
];

export default function TestHelp() {
  return <Help data={dados} />;
}
