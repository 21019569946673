import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';
import { makeReduxCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { translator } from '~/presentation/components/i18n';
import { Signature, SignDoc } from '~/presentation/components/signature';
import { Button } from '~/presentation/components/UI';
import {
  schema,
  schemaMedicalCertificate,
} from '~/validation/validators/document/MedicalCertificateSideBarValidator';
import GeneralData, { ownProps as GeneralDataProps } from './GeneralData';
import { iCreateMedicalCertificate } from './interface';
import { ConnectComponent } from './mapper/MapperMedicalCertificateSideBar';
import {
  Body,
  Container,
  ContainerButton,
  Phases,
  TitleDoc,
} from './styles/StyledMedicalCertificateSideBar';

export interface externalProps {
  medicalCertificate?: ReduxCreateClinicalDocs | RemoteCreateClinicalDocs;
  goBackToListDocument: () => void;
}

interface iStateParams {
  appointmentId: string;
  professional: number;
  consultant: number;
}

export type ownProps = GeneralDataProps;

const MedicalCertificateSideBar: React.FC<ownProps & externalProps> = props => {
  const [phases, setPhases] = useState(1);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const stateParams = useLocation<iStateParams>().state;
  const { auth } = useSelector((store: iStore) => store);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const professional = auth.info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const [certificate, setCertificate] = useState<iCreateMedicalCertificate>({
    professional:
      stateParams && stateParams.professional
        ? Number(stateParams.professional)
        : -1,
    patient:
      stateParams && stateParams?.consultant
        ? Number(stateParams?.consultant)
        : -1,
    specialty:
      role === 'PRO' ? professional?.professions.specialties[0].id : -1,
    periodo: '',
    orgUnitName,
    org: orgId,
  });

  const { goBackToListDocument } = props;

  useEffect(() => {
    setCertificate({
      ...certificate,
      patient: stateParams.consultant ?? certificate.patient,
      professional: stateParams.professional ?? certificate.professional,
    });
  }, [stateParams]);

  const CreateMedicalCertificate = () => {
    if (stateParams && stateParams.appointmentId !== undefined) {
      const dataToSend: RemoteCreateClinicalDocs.Params = {
        appointment: stateParams.appointmentId
          ? Number(stateParams.appointmentId)
          : -1,
        type: 2,
        content: {
          observacoes: certificate.observacoes,
          periodo: certificate.periodo,
        },
        certificate: certificate.certificate,
        certificateId: certificate.certificateId,
        sidebar: true,
      };

      try {
        makeReduxCreateClinicalDocs().create({
          ...dataToSend,
          goBackToListDocument,
        });
      } catch (e) {
        console.log('create clinical docs error: ', e);
      }
    } else {
      const dataToSend: RemoteCreateClinicalDocs.Params = {
        type: 2,
        content: {
          observacoes: certificate.observacoes,
          periodo: certificate.periodo,
        },
        org: orgId,
        orgUnit: orgUnitId,
        patient: certificate.patient,
        professional: certificate.professional,
        certificate: certificate.certificate,
        certificateId: certificate.certificateId,
        specialty: certificate.specialty,
        sidebar: true,
      };

      try {
        const validate = schema.parse(dataToSend);

        makeReduxCreateClinicalDocs().create({
          ...dataToSend,
          goBackToListDocument,
        });
        storeDev.dispatch({ type: MessageOptions.clinicalDocsCreateSuccess });
      } catch (e) {
        console.log('create clinical docs error: ', e);
      }
    }
  };

  function next(dados: iCreateMedicalCertificate) {
    setCertificate({ ...certificate, ...dados });
    handlePhases(1);
  }

  function back(dados: iCreateMedicalCertificate) {
    setCertificate({ ...certificate, ...dados });
    handlePhases(-1);
  }

  function handlePhases(number: number) {
    if (phases + number > 3 || phases + number < 1) {
      setPhases(phases);
    } else {
      setPhases(phases + number);
    }
  }

  return (
    <Container>
      <TitleDoc>{`${translator('Atestado Médico')}`}</TitleDoc>
      {phases === 1 ? (
        <Phases>Etapa 1 de 2: Informações gerais</Phases>
      ) : (
        <Phases>Etapa 2 de 2: Assinatura do documento</Phases>
      )}
      <Body>
        {phases === 1 && (
          <GeneralData
            professional={props.professional}
            next={next}
            state={certificate}
            consultant={props.consultant}
          />
        )}
        {phases === 2 && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
            }}
          >
            <Signature
              changeSelected={(e: {
                certificateId: string;
                certificate: string;
              }) =>
                setCertificate({
                  ...certificate,
                  certificate: e.certificate,
                  certificateId: e.certificateId,
                })
              }
              onSignSubmit={CreateMedicalCertificate}
              changeDisabled={(state: boolean) => setDisableButton(state)}
            />
          </div>
        )}
      </Body>
      {phases === 2 && (
        <ContainerButton>
          <Button
            id="button_back"
            rounded
            variant="secundary"
            onClick={() => handlePhases(-1)}
            size="small"
          >
            {`${translator('Voltar')}`}
          </Button>
          <Button
            id="button_conclude"
            rounded
            size="small"
            onClick={CreateMedicalCertificate}
            disabled={loading || disableButton}
          >
            {`${translator('Concluir')}`}
          </Button>
        </ContainerButton>
      )}
    </Container>
  );
};

export default ConnectComponent(MedicalCertificateSideBar);
