import { iProfession } from '~/domain/interfaces/models/Profession';
import { ProfessionTypes } from '~/domain/interfaces/redux/profession/types';
import { ProfessionAction } from './actions';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const initialState: iProfession = {
  loading: false,
  selected: 0,
  results: [],
  pageCount: 0,
  pageOffSet: 0,
  pageSize: 0,
  totalItems: 0,
};

const reducer = (
  state = initialState,
  action: ProfessionAction,
): iProfession => {
  switch (action.type) {
    case ProfessionTypes.GETALL:
      return { ...state, loading: true };
    case ProfessionTypes.GETALL_SUCCESS:
      return { ...state, loading: false };
    case ProfessionTypes.SETUP_PROFESSION:
      return { ...state, selected: action.payload.professionId };
    case ProfessionTypes.PROFESSION_LIST:
      return { ...state, loading: true };
    case ProfessionTypes.PROFESSION_LIST_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case ProfessionTypes.PROFESSION_LIST_FAILED:
      return { ...state, loading: false };
    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
