import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, DatePicker } from '@material-ui/pickers';

import { Container, ContainerRoot } from './styles';

const GlobalCss = withStyles({
  '@global': {
    '.MuiPickersCalendarHeader-switchHeader': {
      justifyContent: 'flex-end',
    },
    '.MuiPickersCalendarHeader-transitionContainer': {
      position: 'absolute',
      /* textAlign: 'start !important',
      right: '76px', */
    },
    '.MuiPickersCalendarHeader-transitionContainer > p': {
      textAlign: 'start',
      paddingLeft: '23px',
    },
  },
})(() => null);

const CssKeyboardDatePicker = withStyles({
  root: {
    paddingLeft: '9px',
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiInput-underline:before': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      border: 'none',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#A7A7A7',
    },
  },
})(DatePicker);

interface ownProps {
  id?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  state: Date | null;
  defaultValue?: string;
  setState: Function;
  error?: boolean;
  message?: string;
  minDate?: Date;
  disabled?: boolean;
  autofocus?: boolean;
  width?: string;
}

const InputKeyboardDate: React.FC<ownProps> = ({
  id,
  label,
  placeholder,
  name,
  state,
  setState,
  error,
  defaultValue,
  message,
  minDate,
  disabled,
  autofocus,
  width,
}): JSX.Element => {
  const handlerDate = (date: Date | null) => {
    setState(date);
  };

  return (
    <>
      <ContainerRoot width={width} error={error}>
        <h5>{label}</h5>
        <GlobalCss />
        <Container id={id} error={error}>
          <CssKeyboardDatePicker
            fullWidth
            disableToolbar
            autoOk
            defaultValue={defaultValue}
            variant="inline"
            format="dd/MM/yyyy"
            margin="dense"
            id="date-picker-inline"
            placeholder={placeholder}
            value={state}
            onChange={handlerDate}
            name={name}
            error={error}
            disabled={disabled}
            minDate={minDate}
            autoFocus={autofocus}
          />
        </Container>
        {message && <p id="error">{message}</p>}
      </ContainerRoot>
    </>
  );
};

export default InputKeyboardDate;
