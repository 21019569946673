/* eslint-disable no-bitwise */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { IconNext, IconPrevious } from '~/presentation/base/icons';

import { Container, CountPage, Icon } from './styles/StyledTablePagination';

interface ownProps {
  data: any[];
  itemPerPage: number;
  onChangePage?: Function;
}

const TablePagination: React.FC<ownProps> = ({ itemPerPage, data }) => {
  const [currentPage, setPage] = useState<number>(0);
  const [oldCurrentPage, setOldCurrentPage] = useState<number>(0);
  const [state, setState] = useState<any[]>([]);
  const [oldState, setOldState] = useState<any>([]);
  const [minVisible, setMinVisible] = useState(0);
  const [maxVisible, setMaxVisible] = useState(minVisible + 5);
  const [totalPage, setTotalPage] = useState(
    Math.ceil(data.length / itemPerPage),
  );

  let count = data.length;

  let items: any[] = [];

  useEffect(() => {
    // Reset data when filter is actived
    setTotalPage(Math.ceil(data.length / itemPerPage));
    setPage(0);
    setOldCurrentPage(0);
    setMinVisible(0);
    setMaxVisible(5);
    configList();
  }, [data]);

  useEffect(() => {
    configList();
  }, [currentPage]);

  function configList() {
    rowsPerPage();
    setState(prevState => {
      if (prevState.length > 0) {
        makeReduxDataPagination().set(prevState[currentPage]);
      }
      return prevState;
    });
    setUpRightLeft();
  }

  function setUpRightLeft() {
    if (currentPage === totalPage - 1) return;

    let maxRight = maxVisible - 1;
    if (currentPage === maxRight) {
      setOldCurrentPage(currentPage);
      setMinVisible(minVisible + 1);
      setMinVisible(prevState => {
        setMaxVisible(prevState + 5);
        return prevState;
      });
    } else {
      if (currentPage === 0) return;
      if (oldCurrentPage >= 3 && currentPage <= maxVisible - 5) {
        setMaxVisible(maxVisible - 1);
        setMaxVisible(prevState => {
          setMinVisible(prevState - 5);
          return prevState;
        });
      }
    }
  }

  function nextPage() {
    if (currentPage + 1 < totalPage) setPage(currentPage + 1);
  }

  function prevPage() {
    if (currentPage !== 0) setPage(currentPage - 1);
  }

  function rowsPerPage() {
    let item: any[] = [];
    let list: any[][] = [];
    let countItemsPerPage = itemPerPage;

    data.forEach((element, index) => {
      if (index < countItemsPerPage - 1 && index < data.length - 1) {
        item.push(element);
      } else {
        let arr = [...item, element];

        list.push(arr);
        countItemsPerPage += itemPerPage;

        if (countItemsPerPage > count) {
          countItemsPerPage = count;
        }
        item = [];
      }
    });
    setState(list);
  }

  const RenderNumberPages: React.FC = () => {
    let arr = Array.from(Array(totalPage).keys());
    let component = [];
    if (totalPage > 6) {
      for (let index = minVisible; index < maxVisible; index++) {
        component.push(
          <CountPage
            id={`page-${index}`}
            key={index}
            active={index === currentPage}
            onClick={() => setPage(index)}
          >
            <text>{`${index + 1}`}</text>
          </CountPage>,
        );
      }
      return <>{component}</>;
    }
    return (
      <>
        {arr.map(item => {
          return (
            <CountPage
              id={`page-${item + 1}`}
              key={item}
              active={item === currentPage}
              onClick={() => setPage(item)}
            >
              <text>{`${item + 1}`}</text>
            </CountPage>
          );
        })}
      </>
    );
  };

  return data.length > 0 ? (
    <Container>
      <Icon id="table_pagination">
        <IconPrevious id="btn_previous" className="Icons" onClick={prevPage} />
        <RenderNumberPages />
        <IconNext id="btn_next" className="Icons" onClick={nextPage} />
      </Icon>
    </Container>
  ) : (
    <div />
  );
};
export default TablePagination;
