import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetClinicalDocsByDownload as UsecaseRemoteGetClinicalDocsByDownload } from '~/domain/usecases/clinicalDocs/remote';
import { GetClinicalDocsByDownload as UsecaseReduxGetClinicalDocsByDownload } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteGetClinicalDocsByDownload } from '~/data/repository/clinicalDocs';
import { ReduxGetClinicalDocsByDownload } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteGetClinicalDocsByDownload = (): UsecaseRemoteGetClinicalDocsByDownload =>
  new RemoteGetClinicalDocsByDownload(
    makeApiUrl('/clinicalDocs'),
    makeHttpClient(),
  );

export const makeReduxGetClinicalDocsByDownload = (): UsecaseReduxGetClinicalDocsByDownload =>
  new ReduxGetClinicalDocsByDownload();
