/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useHistory } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { Icon } from '~/presentation/components/icon';
import Input from '~/presentation/components/UI/input/index';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import {
  IconSmallLogoV4H,
  IconEmail,
  IconPadlock,
  IconArrowLink,
} from '~/presentation/base/icons';
import {
  Container,
  Left,
  Right,
  Form,
  ButtonsContainer,
  Body,
  Footer,
  Title,
  Forgotpass,
  RemindMe,
  FlexContainer,
  LinkContainer,
  Image,
  ImageContainer,
  FooterVersion,
} from './styles/LoginPageStyled';

import { V4hSpin } from '~/presentation/components/spin';
import { iStore } from '~/domain/interfaces/models';

import { Login as InterfaceLogin } from '~/domain/usecases/auth/redux';

import { schema } from '~/validation/validators/auth/LoginValidator';
import { makeReduxGetOneUser } from '~/main/factories/usecases/users/GetOneUserFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeRemoteValidateCaptcha } from '~/main/factories/usecases/externalServices/ValidateCaptcha';
import { makeReduxLogin } from '~/main/factories/usecases/auth/LoginFactory';

const Login: React.FC = (): JSX.Element => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { register, handleSubmit, errors, control, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const logIn = useSelector((store: iStore) => store.auth.loading);
  const auth = useSelector((store: iStore) => store.auth);
  const results = useSelector((store: iStore) => store.selectedOrg.results);

  const { push } = useHistory();

  const today = new Date();

  useEffect(() => {
    if (results.length > 0 && results[0].org?.logo)
      setLogoUrl(results[0].org.logo);
  }, [results]);

  useEffect(() => {
    return () => {
      if (auth.info.user?.id) {
        makeReduxGetOneUser().getOne({ userId: Number(auth.info.user.id) });
      }
    };
  }, [logIn]);

  const onSubmit = handleSubmit(
    async ({ password, email, login, org }): Promise<any> => {
      if (!recaptchaRef.current && auth.errorCount >= 3)
        return AlertMessage({
          type: 'danger',
          message: 'Erro ao validar o ReCAPTCHA, tente novamente mais tarde.',
        });

      if (!auth.errorCount || auth.errorCount < 3)
        return makeReduxLogin().login({
          email,
          login,
          password,
        });

      const captchaToken = recaptchaRef?.current?.getValue();

      if (captchaToken)
        makeRemoteValidateCaptcha()
          .validate({
            response: captchaToken,
          })
          .then(res => {
            if (res.success) {
              recaptchaRef.current?.reset();

              return makeReduxLogin().login({
                email,
                login,
                password,
              });
            }

            AlertMessage({
              type: 'danger',
              message:
                'Erro ao validar o ReCAPTCHA, tente novamente mais tarde.',
            });
          })
          .catch(err => {
            recaptchaRef.current?.reset();

            AlertMessage({
              type: 'danger',
              message:
                'Erro ao validar o ReCAPTCHA, tente novamente mais tarde.',
            });
          });
    },
  );

  const loading = useSelector((store: iStore) => store.auth.loading);

  return (
    <Container>
      <Body>
        <Left>
          {logoUrl ? (
            <Image id="logoV4H" alt="Logo" src={logoUrl} width="200px" />
          ) : (
            <ImageContainer>
              <Icon src={IconSmallLogoV4H} width="260px" height="130px" />
            </ImageContainer>
          )}
          <span className="text">
            {translator('Seu atendimento com qualidade e segurança.')}
          </span>
          <LinkContainer>
            <IconArrowLink />
            <a href="https://v4h.cloud" target="_blank" rel="noreferrer">
              {translator('Conheça mais sobre o serviço')}
            </a>
          </LinkContainer>
        </Left>
        <Right>
          <Form id="loginAuthentication" onSubmit={onSubmit}>
            <Title>Login</Title>
            <Input
              id="loginInput"
              name="email"
              type="text"
              icon={IconEmail}
              label={translator('E-mail')}
              placeholder={translator('Digite o email cadastrado')}
              register={() => register('login')}
              onChange={e =>
                setValue('login', e.target.value.toLowerCase().trim())
              }
              error={Boolean(errors.login)}
              message={
                errors?.login?.message ? translator('Campo obrigatório') : ''
              }
              autoFocus
            />

            <Input
              id="loginPassword"
              type="password"
              name="password"
              label={translator('Senha')}
              icon={IconPadlock}
              placeholder={translator('Digite sua senha')}
              register={() => register('password')}
              onChange={e => setValue('password', e.target.value)}
              error={Boolean(errors.password)}
              message={
                errors?.password?.message
                  ? translator(errors?.password?.message)
                  : ''
              }
            />
            <FlexContainer>
              <RemindMe>
                <input type="checkbox" />
                <span>{translator('Lembrar-me')}</span>
              </RemindMe>
              <Forgotpass id="loginForgotPass" href="/password/recovery">
                {translator('Esqueceu sua senha?')}
              </Forgotpass>
            </FlexContainer>

            {auth.errorCount >= 3 && (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lc4hHEmAAAAAL9e2reBU9fO_yRe9JdsHWfdGFLc"
              />
            )}

            <ButtonsContainer>
              <Button
                id="loginSubmit"
                type="submit"
                rounded
                height="48px"
                disabled={logIn}
              >
                {logIn
                  ? `${translator('Carregando')}...`
                  : translator('Entrar')}
              </Button>
              {window.config.hasFreemium && (
                <>
                  <span className="text">ou</span>
                  <Button
                    id="signIn"
                    rounded
                    variant="secundary"
                    height="48px"
                    onClick={() => push('/register')}
                    disabled={logIn}
                  >
                    {translator('Criar conta gratuita')}
                  </Button>
                </>
              )}
            </ButtonsContainer>
          </Form>
        </Right>
      </Body>
      <Footer>
        © {today.getFullYear()} Copyright |
        <a
          href="https://v4h.cloud/politicas-de-privacidade/"
          target="_blank"
          rel="noreferrer"
        >
          {translator('Políticas de privacidade')}
        </a>
        {translator('e')}
        <a
          href="https://v4h.cloud/termos-de-uso/"
          target="_blank"
          rel="noreferrer"
        >
          {translator('Termos de uso')}
        </a>
        <FooterVersion>
          <a
            href="https://docs.google.com/document/d/1jUQxrE8oyEg7PePCdT4FeRhyr7FI-p1FH5_VJj4BR4Y"
            target="_blank"
            rel="noreferrer"
          >
            v3.30.0R
          </a>
        </FooterVersion>
      </Footer>
      {loading && <V4hSpin fullScreen />}
    </Container>
  );
};

export default Login;
