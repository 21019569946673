import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Login } from '~/presentation/pages';
import { History } from '~/main/routes';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxSelectUser } from '~/main/factories/usecases/auth/SelectUserFactory';
import { makeReduxRefresh } from '~/main/factories/usecases/auth/RefreshFactory';
import { ReduxLogout } from '~/data/store/reducer/auth/usecases/ReduxLogout';
import { closeModal } from '~/utils/closeModal';

interface iParamsTypes {
  language: string;
}

export const LoginFactory: React.FC = () => {
  const { language } = useParams<iParamsTypes>();

  if (language && language.length === 5 && language.split('-').length === 2) {
    localStorage.setItem('i18nextLng', language);
    History.getHistory().push('/');
    window.location.reload();
  } else if (language) {
    History.getHistory().replace('/');
  }

  const auth = useSelector((store: iStore) => store.auth);
  const selectUser = useSelector((store: iStore) => store.auth.selectUser);
  const data = useSelector((store: iStore) => store.message.data);
  const refreshToken = auth.refresh.token;
  const { orgs } = auth;

  const countRoles = useCallback(() => {
    const roles: { role: 'CON' | 'ADM' | 'PRO' | 'ORG'; roleId: number }[] = [];
    const org = orgs[0];

    if (org?.consultant) {
      roles.push({ role: 'CON', roleId: org.consultant.id });
    }

    if (org?.administrator) {
      roles.push({ role: 'ADM', roleId: org.administrator.id });
    }

    if (org?.orgUnits?.length) {
      if (org?.orgUnits?.[0]?.professionals?.length > 0) {
        roles.push({
          role: 'PRO',
          roleId: org.orgUnits[0].professionals[0].id,
        });
      }

      if (org?.orgUnits?.[0]?.organizers?.length > 0) {
        roles.push({ role: 'ORG', roleId: org.orgUnits[0].organizers[0].id });
      }
    }

    return roles;
  }, [orgs]);

  useEffect(() => {
    // makeReduxGetAllSelectedOrg().getAll({
    //   filter: {
    //     url: window.location.origin,
    //   },
    // });

    if (auth.access.token) {
      if (auth.info.user?.mainRole && selectUser.roleId !== 0) {
        History.push('/appointment');
      } else if (orgs.length === 1 && orgs[0].orgUnits.length <= 1) {
        makeReduxRefresh().refresh({
          user: auth.info.user?.id,
          org: orgs[0].id,
          refreshToken,
        });
        makeReduxSelectUser().update({
          orgUnitId: orgs[0].orgUnits[0]?.id ?? undefined,
          orgId: orgs[0].id,
        });
      } else {
        makeReduxActiveMessage().active({
          active: 'userChangeRole',
          actionOk: () => {
            makeReduxRefresh().refresh({
              user: auth.info.user?.id,
              org: data.orgId,
              refreshToken,
            });
            makeReduxSelectUser().update({
              orgUnitId: data?.orgUnitId,
              orgId: data.orgId,
            });
            closeModal();
            History.push('/appointment');
          },
          actionCancel: () => {
            closeModal();
            new ReduxLogout().logout();
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.access.token,
    auth.info.user?.id,
    auth.info.user?.mainRole,
    countRoles,
    data.orgId,
    data?.orgUnitId,
    orgs,
    refreshToken,
  ]);

  return <Login />;
};
