import React, { useState } from 'react';
import { Health } from '~/presentation/base/icons';
import { StepMagic, Step } from '~/presentation/components/steps';
import { Button } from '~/presentation/components/UI/buttons';
import GeneralData from './GeneralData';
import ChooseClinicalDocument from './Prescription';
import { Signature } from '~/presentation/components/signature';
import {
  Body,
  Container,
  Content,
  Footer,
  Header,
} from './styles/StyledSpecialRecipe';
import SimplePrescriptionView from './SpecialPrescriptionView';

import { GetCertificates } from '~/domain/usecases/deamon/remote';
import { translator } from '../i18n';

const SpecialPrescription: React.FC = () => {
  const [crement, setCrement] = useState<number>(1);

  function handleBar(number: number) {
    if (crement + number > 4 || crement + number < 1) {
      setCrement(crement);
    } else {
      setCrement(crement + number);
    }
  }
  const data = {
    isSigned: 'Assinado',
    name: 'Danilo',
  };

  const certificatedSelected = (datatest: GetCertificates.Certificate) => {
    console.log('selected: ', datatest);
  };

  return (
    <Container>
      <Content>
        <Health />
        <h1>Receita Especial</h1>
      </Content>
      {crement !== 4 ? (
        <Header>
          <StepMagic current={crement}>
            <Step title="Informações gerais" />
            <Step title="Prescrição" />
            <Step title="Assinatura do documento" />
          </StepMagic>
        </Header>
      ) : null}
      <Body>
        {crement === 1 && <GeneralData />}
        {crement === 2 && <ChooseClinicalDocument />}
        {crement === 3 && <Signature />}
        {crement === 4 && <SimplePrescriptionView id="6" />}
      </Body>
      {crement !== 4 ? (
        <Footer>
          {crement > 1 ? (
            <Button
              rounded
              variant="secundary"
              onClick={() => handleBar(-1)}
              size="small"
            >
              {translator('Voltar')}
            </Button>
          ) : null}
          <Button rounded size="small" onClick={() => handleBar(1)}>
            {translator('Avançar')}
          </Button>
        </Footer>
      ) : null}
    </Container>
  );
};

export default SpecialPrescription;
