import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-style: solid;
  border-color: transparent;
`;

export const Access = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #004ba5;
  border-bottom: 1px solid #efefef;
  padding: 15px;
  gap: 40px;
  padding-left: 70px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #004ba5;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  gap: 10px;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
`;

export const Token = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 12px;
  padding: 20px;
`;

export const Password = styled.button`
  width: 280px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: 2px solid #004ba5;
  border-radius: 5px;
  background: none;
  color: #004ba5;
  gap: 10px;
  cursor: pointer;
  transition: opacity 0.2s;
  &:hover {
    background-color: #004ba5;
    color: white;
    transition: all 0.3s;
  }
  &:active {
    background-color: #006ef2;
    transition: 0.2s;
  }
`;
