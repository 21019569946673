import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 10vh;
  height: 100%;
  background: #e5e5e5;
  overflow: auto;
  padding-bottom: 20px;
`;

export const HeaderDiv = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 121px;
  background: ${props => props.theme.primary};
`;

export const Left = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding-left: 10px;
  h1 {
    color: #7a7a7a;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 15px;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 10vh;
  height: 100%;
  background: transparent;
  overflow: auto;
  padding-bottom: 20px;
`;

export const Signed = styled.div`
  gap: 10px;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 150%;

    color: #004ba5;
  }
`;

export const SendPatient = styled.div`
  padding-right: 10px;
  p {
    color: #7a7a7a;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }
`;

export const Body = styled.div`
  display: grid;
  grid-template-areas: 'session navi';
  grid-template-columns: 0.5fr 1fr;
  gap: 20px;
  grid-area: navi;
  padding: 0px 50px;
`;

export const Session = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
  grid-area: session;
`;

export const NavContent = styled.div`
  width: 100%;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 30px;
  border: 1px solid #efefef;

  #certificate-div {
    width: 50%;
    min-width: 30em;
    display: flex;
    justify-content: center;
    align-items: center;

    .option {
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const TextAreaDoc = styled.div`
  width: 100%;
  height: 50vh;
  padding: 10px;
  border-radius: 2px;

  .observacoes {
    margin-top: 25px;
    font-size: 16px;
    font-weight: bold;
  }
`;
