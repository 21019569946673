import React from 'react';
import { StickyHeadTable } from '~/presentation/components/Table';

const useTable: React.FC = () => {
  const data: any[] = [
    {
      drug: 'Rivotril',
      posology: '1x ao dia antes de dormir',
      dosage: '0,25mg',
    },
    {
      drug: 'Rivotril',
      posology: '1x ao dia antes de dormir',
      dosage: '0,25mg',
    },
    {
      drug: 'Rivotril',
      posology: '1x ao dia antes de dormir',
      dosage: '0,25mg',
    },
    {
      drug: 'Rivotril',
      posology: '1x ao dia antes de dormir',
      dosage: '0,25mg',
    },
    {
      drug: 'Rivotril',
      posology: '1x ao dia antes de dormir',
      dosage: '0,25mg',
    },
  ];

  const columns: string[] = ['Droga', 'Posologia', 'Dosagem'];
  const rows: any[] = data;

  // O StickyHeadTable recebe como parametro um array de string (columns) que vao identificar as colunas,
  // um array de objeto (rows) onde cada objeto representa uma linha da tabela e seus valores e
  // recebe um array de string (bold) que serve pra deixar em negrito as propiedades em questão.
  // No exemplo abaixo eu passo como parametro para o bold as props 'drug' e 'dosage'. Fazendo isso as colunas
  // vão ficar em negrito dando assim uma ênfase.

  return (
    <StickyHeadTable columns={columns} rows={rows} bold={['drug', 'dosage']} />
  );
};

export default useTable;
