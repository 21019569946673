import styled from 'styled-components';
import iconCancel from '~/presentation/base/icons/cancel.svg';
import iconCancelOutline from '~/presentation/base/icons/cancelOutline.svg';
import iconDetails from '~/presentation/base/icons/details.svg';
import iconDetailsOutline from '~/presentation/base/icons/detailsOutline.svg';
import iconPerson from '~/presentation/base/icons/person.svg';
import iconPersonOutline from '~/presentation/base/icons/personOutline.svg';
import iconDelete from '~/presentation/base/icons/delete.svg';
import iconDeleteOutline from '~/presentation/base/icons/deleteOutline.svg';
import iconShareEmailOutline from '~/presentation/base/icons/shareEmailOutline.svg';
import iconShareEmail from '~/presentation/base/icons/shareEmail.svg';
import { defaultTheme } from '~/presentation/base/themes';

interface StyledProps {
  list?: string;
}

interface WrapperProps {
  isOk: Boolean;
}

export const Container = styled.div<StyledProps>`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${({ list }) => {
    switch (list) {
      case 'list-1':
        return '2fr 2fr 1fr 1fr 1fr 1fr 1fr 2fr';
      case 'list-2':
        return '60px 1fr 2fr 1fr 1fr 2fr 2fr';
      case 'list-3':
        return '1fr 2fr 2fr 1fr 2fr 2fr 2fr 1fr';
      default:
        return 'auto';
    }
  }};
  /* grid-template-columns: repeat(auto,1fr); */
  grid-template-rows: auto;
  align-items: center;
  justify-items: start;
  /* grid-template-rows: auto;
  grid-template-columns: auto; */
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-column-gap: 10px;
  width: 100%;
`;

export const Avatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
  text {
    margin-left: 5px;
  }
`;
export const Status = styled.div``;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${({ isOk }) => (isOk ? defaultTheme.primary : defaultTheme.statusNo)};
  text {
    padding: 3px;
    font-weight: bold;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text {
      padding-left: 5px;
    }
  }
`;

export const Cancel = styled.div`
  background-image: url(${iconCancelOutline});
  margin: 0px 3px;
  width: 26px;
  height: 26px;
  :hover {
    background-image: url(${iconCancel});
  }
`;

export const Details = styled.div`
  margin: 0px 3px;
  background-image: url(${iconDetailsOutline});
  width: 26px;
  height: 26px;
  :hover {
    background-image: url(${iconDetails});
  }
`;

export const Delete = styled.div`
  margin: 0px 3px;
  background-image: url(${iconDeleteOutline});
  width: 26px;
  height: 26px;
  :hover {
    background-image: url(${iconDelete});
  }
`;

export const Person = styled.div`
  margin: 0px 3px;
  background-image: url(${iconPersonOutline});
  width: 26px;
  height: 26px;
  :hover {
    background-image: url(${iconPerson});
  }
`;
export const ShareEmail = styled.div`
  margin: 0px 3px;
  background-image: url(${iconShareEmailOutline});
  width: 26px;
  height: 26px;
  :hover {
    background-image: url(${iconShareEmail});
  }
`;

export const Img = styled.div`
  border-radius: 50px;
  min-width: 40px;
  background-color: #000;
  min-height: 40px;
`;
