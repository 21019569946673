import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Progress = styled.ul`
  width: 50%;
  animation: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  /* counter-reset: step; */
  li {
    list-style-type: none;
    float: left;
    width: calc(100% / 4);
    position: relative;
    text-align: center;
    transition: all 0.5s ease-in;
    /* color: #99a2a8;
    font-size: 18px; */
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      border: 1px solid #ddd;
      display: block;
      text-align: center;
      margin: 20px auto 0 auto;
      border: none;
      border-radius: 50%;
      background: #ddd;
    }
    &:before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 3px;
      background: #ddd;
      bottom: 10px;
      left: -50%;
      z-index: -1;
    }
    &:first-child:before {
      content: ' ';
    }
    &.active {
      color: darkblue;
    }
    &.active:after,
    :first-child:before {
      background: darkblue;
      border-color: darkblue;
    }
    &.active:before {
      background: darkblue;
    }
  }
`;
