import { TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxCancelAppointment } from '~/main/factories/usecases/appointment/CancelAppointmentFactory';
import { makeReduxSetupAppointment } from '~/main/factories/usecases/appointment/SetupAppointmentFactory';
import { makeReduxStopAppointment } from '~/main/factories/usecases/appointment/StopAppointmentFactory';
import { History } from '~/main/routes';
import { IconSort } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import orderData from '~/utils/orderData';
import ActionsList from '../../actionsList/ActionsList';
import { AvatarBadger } from '../../avatar-roles';
import ListSkeleton from '../../skeleton/ListSkeleton';
import { ScheduleStatus } from '../../statusList';
import { iListRegisteredAppointment } from '../interface';
import { ConnectComponent } from '../mapper/MapperListRegisteredAppointment';
import { Avatar } from '../styles/StyledUserItem';

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
});

interface ownProps {
  data: iListRegisteredAppointment[];
}

type iColumns =
  | 'participant'
  | 'professional'
  | 'specialty'
  | 'timeStart'
  | 'timeEnd'
  | 'typeIs'
  | 'status'
  | 'actions';

type iOrder =
  | 'participant'
  | 'professional'
  | 'specialty'
  | 'timeStart'
  | 'timeEnd'
  | 'typeIs'
  | 'status';

const ListAppointment: React.FC<ownProps> = props => {
  /**
   * @order pameter to sort 'asc' or 'desc'
   */
  const [order, setOrder] = useState<boolean>(true);
  const [data, setData] = useState<iListRegisteredAppointment[]>(props.data);
  const { loading } = useSelector((store: iStore) => store.appointment);
  const url = window.location.pathname;

  const classes = useStyles();
  const columns = [
    {
      id: 'title',
      label: translator('Título do atendimento'),
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'participant',
      label: translator('Paciente'),
      minWidth: 200,
      align: 'left',
    },
    {
      id: 'professional',
      label: translator('Profissional da saúde'),
      minWidth: 200,
      align: 'left',
    },
    {
      id: 'specialty',
      label: translator('Especialidade'),
      minWidth: 190,
      align: 'left',
    },
    {
      id: 'timeStart',
      label: translator('Início'),
      minWidth: 10,
      align: 'left',
    },
    {
      id: 'timeEnd',
      label: translator('Fim'),
      minWidth: 10,
      align: 'left',
    },
    {
      id: 'typeIs',
      label: translator('Tipo'),
      minWidth: 80,
      align: 'left',
    },
    {
      id: 'status',
      label: translator('Status'),
      minWidth: 100,
      maxWidth: 160,
      align: 'left',
    },
    {
      id: 'actions',
      label: translator('Ações'),
      minWidth: 20,
      align: 'left',
    },
  ];

  useEffect(() => {
    setOrder(false);
    setData([...orderData(props.data, true, 'timeStart')]);
  }, [props.data]);

  function StopAppointment(appointment: any) {
    if (appointment.status === 'AVAILABLE') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professionalId,
                consultant: appointment.consultantUserId,
                consultantId: appointment.consultantId,
                from: url.replaceAll('/', ''),
              }),
            stop: () => {
              makeReduxStopAppointment().stop({ id: appointment.id });
            },
            cancel: () => {
              makeReduxSetupAppointment().setup({
                appointmentId: appointment.id,
              });
              makeReduxCancelAppointment().cancel({
                appointmentId: appointment.id,
              });
            },
          }}
        />
      );
    }
    if (appointment.status === 'ENDED' || appointment.status === 'CANCELED') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professionalId,
                consultant: appointment.consultantUserId,
                consultantId: appointment.consultantId,
                from: url.replaceAll('/', ''),
              }),
          }}
        />
      );
    }
    if (appointment.status === 'SCHEDULED') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professionalId,
                consultant: appointment.consultantUserId,
                consultantId: appointment.consultantId,
                from: url.replaceAll('/', ''),
              }),
            cancel: () => {
              makeReduxSetupAppointment().setup({
                appointmentId: appointment.id,
              });
              makeReduxCancelAppointment().cancel({
                appointmentId: appointment.id,
              });
            },
          }}
        />
      );
    }

    if (appointment.status === 'CONFIRMED') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professionalId,
                consultant: appointment.consultantUserId,
                consultantId: appointment.consultantId,
                from: url.replaceAll('/', ''),
              }),
            cancel: () => {
              makeReduxSetupAppointment().setup({
                appointmentId: appointment.id,
              });
              makeReduxCancelAppointment().cancel({
                appointmentId: appointment.id,
              });
            },
          }}
        />
      );
    }

    return (
      <ActionsList
        actions={{
          stop: () => {
            makeReduxStopAppointment().stop({ id: appointment.id });
          },
          cancel: () => {
            makeReduxSetupAppointment().setup({
              appointmentId: appointment.id,
            });
            makeReduxCancelAppointment().cancel({
              appointmentId: appointment.id,
            });
          },
        }}
      />
    );
  }
  const RenderComponents: React.FC<{
    id: any;
    appointment: any;
  }> = ({ id, appointment }) => {
    switch (id) {
      case 'actions':
        return StopAppointment(appointment)!;
      case 'status':
        return <ScheduleStatus status={appointment.status} />;
      case 'professional':
        return (
          <Avatar>
            <AvatarBadger
              src={appointment.srcProfessional}
              name={appointment.professional}
              size="root"
              status={appointment.consultantStatus === 'ONLINE'}
            />
            <text>{`${appointment.professional}`}</text>
          </Avatar>
        );
      case 'participant':
        return (
          <Avatar>
            <AvatarBadger
              src={appointment.srcParticipant}
              name={appointment.participant}
              size="root"
              status={appointment.participantStatus === 'ONLINE'}
            />
            <text>{`${appointment.participant}`}</text>
          </Avatar>
        );
      case 'typeIs':
        return <div>{appointment[`${id}`]}</div>;
      default:
        return <div>{appointment[`${id}`]}</div>;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell align="left">
                  {item.id !== 'actions' ? (
                    <TableSortLabel
                      active
                      IconComponent={() => (
                        <div style={{ paddingLeft: 3 }}>
                          <IconSort id={`sort_${item.id}`} />
                        </div>
                      )}
                      style={{ color: '#8A8A8A', fontWeight: 'normal' }}
                      onClick={() => {
                        setOrder(prevState => !prevState);
                        setData([
                          ...orderData(props.data, order, item.id as iOrder),
                        ]);
                      }}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                      {item.label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              {columns.map(item => {
                return (
                  <TableRow>
                    <ListSkeleton columns={columns} />
                  </TableRow>
                );
              })}
            </>
          ) : (
            props.data.map((item: any) => (
              <TableRow key={item.id}>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      id={`${columnProps.id}-${item.id}`}
                      key={`${columnProps.id}-${item.id}`}
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <RenderComponents
                        id={columnProps.id}
                        appointment={item}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConnectComponent(ListAppointment);
