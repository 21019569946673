import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 20%;
`;

export const Table = styled.div`
  width: 40%;
  background: #fbfbfb;
  border-radius: 5px;
  border: 1px solid #efefef;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
`;

const widthVariant = css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: #000000;
`;

export const Description = styled.div`
  ${widthVariant}
`;

export const Role = styled.div`
  ${widthVariant}
  padding-left: 40%;
`;

export const Actions = styled.div`
  padding-left: 65%;
`;
