import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadGateway,
  Conflict,
  Forbidden,
  UnexpectedError,
  UnProcessableEntity,
} from '~/domain/errors';
import { PatchAppointmentConfirmation } from '~/domain/usecases/appointment/remote';

export class RemotePatchAppointmentConfirmation
  implements PatchAppointmentConfirmation {
  private readonly url: string;

  private readonly httpClient: HttpClient<PatchAppointmentConfirmation.Model>;

  constructor(
    url: string,
    httClient: HttpClient<PatchAppointmentConfirmation.Model>,
  ) {
    this.url = url;

    this.httpClient = httClient;
  }

  patch = async (
    params: PatchAppointmentConfirmation.Params,
  ): Promise<PatchAppointmentConfirmation.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'patch',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new Forbidden();
      case HttpStatusCode.unauthorized:
        throw new BadGateway();
      case HttpStatusCode.conflict:
        throw new Conflict();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
