import React, { useState, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { IconFilter, IconSearch } from '~/presentation/base/icons';
import { makeReduxGetAllOrganizer } from '~/main/factories/usecases/organizer/GetAllOrganizer';
import { Container } from './styles/StyledSearchList';

import Dropdown from '../drop-down';
import { translator } from '../i18n';
import { iStore } from '~/domain/interfaces/models';

interface SearchProps {
  name?: string;
  children: JSX.Element[] | JSX.Element;
  icon?: JSX.Element;
}

const SearchListOrganizer: React.FC<SearchProps> = ({ children }) => {
  const [organizer, setOrganizer] = useState('');

  const { orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const handleSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      makeReduxGetAllOrganizer().getAll({
        pageSize: 9999,
        filter: {
          org: orgId,
          orgUnit: orgUnitId ?? undefined,
          name: organizer,
          situation: ['ACTIVE'],
        },
      });
    }
  };

  return (
    <Container onSubmit={() => handleSubmit}>
      <IconSearch id="btn_searchFilter" />
      <input
        id="input_search"
        placeholder={`${translator('Buscar por...')}`}
        value={organizer}
        onChange={e => setOrganizer(e.target.value)}
        onKeyPressCapture={handleSubmit}
      />
      <Dropdown label="Filtros" icon={<IconFilter />}>
        {children}
      </Dropdown>
    </Container>
  );
};
export default SearchListOrganizer;
