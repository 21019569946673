import React from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Container, Content, Text } from './styles/StyledRequest';
import { Input } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';

import Navigator from './Navigator';

import { iCreateExamRequest } from './interface';

import { schemaObservation } from '~/validation/validators/document/ExamRequestContentValidator';

export interface ownProps {
  next: (data: iCreateExamRequest) => any;
  back: (data: iCreateExamRequest) => any;
  state?: iCreateExamRequest;
}

const Request: React.FC<ownProps> = ({ next, back, state }) => {
  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaObservation),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  const onBack = () => back({ ...getValues() });

  return (
    <Container onSubmit={onSubmit}>
      <Content>
        <Input
          id="input_indicacao_clinica"
          name="indicacaoClinica"
          label={`${translator('Indicação Clínica')}`}
          placeholder={`${translator('Insira uma indicação clínica.')}`}
          defaultValue={state?.indicacaoClinica}
          onChange={e => setValue('indicacaoClinica', e.target.value)}
          register={() => register('indicacaoClinica')}
          error={Boolean(errors.indicacaoClinica)}
          message={
            errors.indicacaoClinica?.message
              ? translator(errors.indicacaoClinica?.message)
              : ''
          }
          required
          autoFocus
        />
        <Text>
          <p>{`${translator('Exames requisitados')}`}</p>
          <textarea
            placeholder={`${translator('Requisito os seguintes exames')}`}
            id="text_area_exam"
            name="exam"
            ref={register}
            defaultValue={state?.exam}
            onChange={e => {
              setValue('exam', e.target.value);
            }}
          />
        </Text>
        <Navigator back={onBack} />
      </Content>
    </Container>
  );
};

export default Request;
