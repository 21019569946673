import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import { ClinicalDocumentViewer } from '~/presentation/components/clinicalDocumentViewer';
import { EditConsult } from '~/presentation/components/editConsult';
import { PageHeader } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';
import { Menu } from '~/presentation/components/menu';
// Import dos componentes a serem renderizados na nav
import {
  AppointmentDocs,
  MessageAvaliable,
  MessageCanceled,
  ServiceSchedule,
} from '~/presentation/components/roleParticipant/appointmentDetail';
import { Card } from '~/presentation/components/roleParticipant/clinicalDocumentView';
import { DropDownEvents } from '~/presentation/components/roleParticipant/dropDownEvents';
import { V4hSpin } from '~/presentation/components/spin';
import { AppointmentDetail, iClinicalDocs, iSupportDocs } from './interface';
import {
  MapAppointmentDetail,
  MapCLinicalDocuments,
  MapSupportingDocuments,
} from './mapper/map';
import {
  Body,
  Content,
  ContentComponents,
  Events,
  Header,
  Line,
  NavContent,
  Session,
} from './styles/StyledService';
import { LockScroll } from './StyledSchedules';

interface iStateParams {
  appointmentId: string;
}

const AppointmentDetails: React.FC = () => {
  const [infos, setInfos] = useState<AppointmentDetail>();
  const [clinicalDoc, setClinicalDoc] = useState<iClinicalDocs[]>([]);
  const [docsSupp, setDocsSupp] = useState<iSupportDocs[]>([]);
  const [openDoc, setOpenDoc] = useState<number>(-1);

  const select = useSelector((store: iStore) => store.clinicalDocs.selected);
  const clinicalDocs = useSelector((store: iStore) => store.clinicalDocs);

  async function handleGetInfo(id: number) {
    const res = await MapAppointmentDetail(id);
    const docsClincal = await MapCLinicalDocuments(id);
    const docsupp = await MapSupportingDocuments(id);

    setInfos(res);
    setClinicalDoc(docsClincal);
    setDocsSupp(docsupp);
  }
  const stateParams = useLocation<iStateParams>().state;

  useEffect(() => {
    handleGetInfo(Number(stateParams.appointmentId));
  }, [clinicalDocs]);

  return (
    <Content>
      <LockScroll>
        <Header>
          <Menu />
          <PageHeader title={translator('Atendimento')} />
        </Header>
      </LockScroll>
      <Body>
        <Session>
          <Card user={infos && infos.user} session={infos && infos.session} />
          {/* <DropDownEvents /> */}
        </Session>
        {/* <Events>
        </Events> */}
        {!infos && (
          <NavContent>
            <V4hSpin fullScreen={false} background="none" className="spin" />
          </NavContent>
        )}
        {infos && infos.session.status === 'AVAILABLE' && (
          <NavContent>
            <ContentComponents>
              <MessageAvaliable />
            </ContentComponents>
          </NavContent>
        )}
        {infos && infos.session.status === 'CANCELED' && (
          <NavContent>
            <ContentComponents>
              <MessageCanceled />
            </ContentComponents>
          </NavContent>
        )}
        {infos && infos.session.status === 'ENDED' && (
          <NavContent>
            <ContentComponents>
              {openDoc !== -1 ? (
                <ClinicalDocumentViewer id={select} />
              ) : (
                <AppointmentDocs
                  clinicalDocs={clinicalDoc}
                  supportingDocs={docsSupp}
                />
              )}
            </ContentComponents>
          </NavContent>
        )}
        {infos &&
          (infos.session.status === 'SCHEDULED' ||
            infos.session.status === 'CONFIRMED') && (
            <NavContent>
              <ContentComponents>
                {/* <ServiceSchedule /> */}
                <EditConsult />
              </ContentComponents>
            </NavContent>
          )}
        {infos && infos.session.status === 'INPROGRESS' && (
          <NavContent>
            <ContentComponents>
              {/* <ServiceSchedule /> */}
              <EditConsult />
            </ContentComponents>
          </NavContent>
        )}
      </Body>
    </Content>
  );
};

export default AppointmentDetails;
