import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { SpecialPrescriptionView } from '~/presentation/components/specialPrescription';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { iStore } from '~/domain/interfaces/models';

interface iStateParams {
  docId: string;
  from: string;
}
const DocumentDetailPage: React.FC = () => {
  const { docId } = useLocation<iStateParams>().state;
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  useEffect(() => {
    makeReduxGetAllClinicalDocs().getAll({
      filter: {
        org: orgId,
        enabled: true,
      },
    });
  });

  return <SpecialPrescriptionView id={docId} />;
};
export default DocumentDetailPage;
