import React from 'react';
import { Link } from 'react-router-dom';
import Seta from '~/presentation/base/icons/seta.png';
import Lupa from '~/presentation/base/icons/lupa.svg';
import {
  ContainerHelp,
  FunctionsHelp,
  MicrofoneHelp,
  InputBusca,
} from './styles/StyledHelp';

interface helpProps {
  data?: Array<indexHelp>;
}

interface indexHelp {
  title: string;
  link: string;
}

const Help: React.FC<helpProps> = ({ data }) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function handleLink(link: string) {}
  return (
    <ContainerHelp>
      <FunctionsHelp>
        {data?.map(item => (
          <MicrofoneHelp onClick={() => handleLink(item.link)}>
            <h1>{item.title}</h1>
            <Link to={`/teste/${item.link}`}>
              <img src={Seta} alt="seta" id="seta" />
            </Link>
          </MicrofoneHelp>
        ))}
        <InputBusca>
          <form action="" method="">
            <input
              type="text"
              id="input-search"
              placeholder="Como podemos ajudar?"
            />
            <button type="submit" id="button-search">
              <img src={Lupa} alt="Lupa" />
            </button>
          </form>
        </InputBusca>
      </FunctionsHelp>
    </ContainerHelp>
  );
};

export default Help;
