import { object, number, string, boolean } from 'zod';

export const schemaGeneralData = object({
  professional: number().nonnegative({
    message: 'Profissional inválido ou não encontrado.',
  }),
  appointment: number().nonnegative().optional(),
  patient: number().nonnegative({
    message: 'Consultante inválido ou não encontrado.',
  }),
  org: number().nonnegative({ message: 'Instituição Inválida' }),
  orgUnit: number().nonnegative().optional(),
});

export const schema = object({
  appointment: number().nonnegative().optional(),
  org: number().nonnegative().optional(),
  orgUnit: number().nonnegative().optional(),
  professional: number().nonnegative().optional(),
  specialty: number().nonnegative().optional(),
  patient: number().nonnegative().optional(),
  type: number().nonnegative(),
  certificate: string().optional(),
  certificateId: string().optional(),
  sidebar: boolean().optional(),
  content: object({}).nonstrict().optional(),
}).refine(data => {
  // if ((!data.appointment && (!data.professional || !data.specialty || !data.org || !data.orgUnit))) return false;
  if (!data.appointment && (!data.professional || !data.org || !data.orgUnit))
    return false; // avulso
  if (
    data.appointment &&
    (data.professional || data.specialty || data.org || data.orgUnit)
  )
    return false; // vinculado
  return true;
});
