import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

import { ownProps } from '../Chat';
import { makeReduxSetupChat } from '~/main/factories/usecases/chat/SetupChatFactory';
import {
  makeSocketCreateChatMessage,
  makeReduxSocketCreateChatMessage,
} from '~/main/factories/usecases/chat/CreateChatMessageFactory';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const chats = useSelector((store: iStore) => store.chat);
    const userLogged = useSelector((store: iStore) => store.auth.info.user?.id);

    const activeChat = chats.chats.find(el => el.id === chats.chatSelect);

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      actionClose: () => makeReduxSetupChat().setup({ visible: false }),
      sendMessage: makeReduxSocketCreateChatMessage,
      activeChat,
      userRoot: activeChat?.participants.find(el => el.id !== userLogged),
      userLogged,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}
