import React, { useState } from 'react';
import { IconCloseButtonModal, IconCrossBlue } from '~/presentation/base/icons';
import {
  Container,
  Access,
  Header,
  Body,
  Password,
  Token,
} from './styles/StyledQrCode';
import { translator } from '../i18n';

const QrCode: React.FC = () => {
  return (
    <Container>
      <Access>
        {translator('Acesso para farmácia')}
        <IconCloseButtonModal />
      </Access>
      <Header>
        <IconCrossBlue />
        {translator('Receita simples')}
      </Header>
      <Body>
        {translator('Acesse o documento')}
      </Body>
      <Body>{translator('via o QR code do documento')}</Body>
      <Token>Token: HT24GN671</Token>
      <Password>
        {translator('Código e Senha')}
      </Password>
    </Container>
  );
};

export default QrCode;
