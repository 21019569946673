import React from 'react';

import { EditConsult } from '~/presentation/components/editConsult';
import { Container } from './StyledEditconsult';

const Editconsult = () => {
  return (
    <Container>
      <EditConsult />
    </Container>
  );
};

export default Editconsult;
