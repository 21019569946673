import React, { useState } from 'react';

import {
  IconLeft,
  IconLeftEnd,
  IconRight,
  IconRightEnd,
} from '~/presentation/base/icons';
import Point from './Point';

import { Icon } from '../icon';

import { Container, Bar, MiniBar } from './styles/StyledTimeline';

import { iTimelineData } from './interface';

interface ownProps {
  data: iTimelineData[];
  maxPoints: number;
}

const Timeline: React.FC<ownProps> = ({ data, maxPoints }): JSX.Element => {
  const isMaxPoints = data.length > maxPoints;

  const [active, setActive] = useState(
    isMaxPoints
      ? Math.round(maxPoints / 2) - 1
      : Math.round(data.length / 2) - 1,
  );
  const [start, setStart] = useState(0);

  const packageData = [...data].splice(start, maxPoints);
  const points: any[] = [];

  function renderPoints() {
    points.push(<Bar active={false} />);
    packageData.map((elem, index) => {
      if (index !== 0) {
        points.push(<Bar active={false} />);
      }

      points.push(
        <Point
          status={elem.status}
          date={elem.date}
          active={active === index}
          setActive={() => setActive(index)}
        />,
      );

      return elem;
    });
    points.push(<Bar active={false} />);

    return points;
  }

  function startPoint() {
    setActive(0);
    setStart(0);
  }

  function next() {
    if (!isMaxPoints) {
      if (active < data.length - 1) setActive(active + 1);
      return;
    }

    if (start === data.length - maxPoints) {
      if (active < maxPoints - 1) {
        setActive(active + 1);
      }

      return;
    }
    setStart(start + 1);
  }

  function previous() {
    if (start === 0) {
      if (active > 0) {
        setActive(active - 1);
      }

      return;
    }

    setStart(start - 1);
  }

  function endPoint() {
    if (!isMaxPoints) {
      setActive(data.length - 1);
      return;
    }

    setActive(maxPoints - 1);
    setStart(data.length - maxPoints);
  }

  const canPrevious: boolean = active !== 0 || start !== 0;
  let canNext: boolean =
    active !== maxPoints - 1 || start !== data.length - maxPoints;

  if (!isMaxPoints) {
    canNext = active !== data.length - 1;
  }

  return (
    <Container>
      <Icon
        src={IconLeftEnd}
        onClick={startPoint}
        color={canPrevious ? '#004BA5' : ''}
      />
      <Icon
        src={IconLeft}
        onClick={previous}
        color={canPrevious ? '#004BA5' : ''}
      />
      {renderPoints()}
      <Icon src={IconRight} onClick={next} color={canNext ? '#004BA5' : ''} />
      <Icon
        src={IconRightEnd}
        onClick={endPoint}
        color={canNext ? '#004BA5' : ''}
      />
    </Container>
  );
};

export default Timeline;
