import React, { useState } from 'react';

import { Content, Item, Label } from './styles/StyledNavigationBar';

interface BarProps {
  labels: string[];
  active: string;
  clickLabel: Function;
}

const NavigationBar: React.FC<BarProps> = ({ labels, active, clickLabel }) => {
  const [select, setSelect] = useState<string>(labels[0]);

  function handleSelect(key: string) {
    setSelect(key);
  }
  return (
    <Content>
      {Object.entries(labels).map(([key, value]) => (
        <Item
          id={`${value.toLowerCase().replaceAll(' ', '_')}`}
          onClick={() => clickLabel(value)}
          active={active === value}
        >
          <Label>{value}</Label>
        </Item>
      ))}
    </Content>
  );
};

export default NavigationBar;
