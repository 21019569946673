import styled from 'styled-components';

export const Divs = styled.div`
  width: 100%;
  height: 100%;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
`;

export const GridTick = styled.div`
  padding: 20px;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  width: 90%;
`;
