import { boolean, object, string, number } from 'zod';

const schema = object({
  name: string().max(32).optional(),
  descr: string().max(256).optional(),
  enabled: boolean().optional(),
});

export const schemaSpecialtyUpdate = object({
  id: number(),
  docProf: string(),
  docProfUF: string(),
  docProfIssued: string(),
  name: string(),
});

export default schema;
