import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { PostUsersConfirmation as UsecaseRemotePostUsersConfirmation } from '~/domain/usecases/createUserConfirmation/remote';

import { RemotePostUsersConfirmation } from '~/data/repository/createUsersConfirmation/RemotePostUsersConfirmation';

/**
 * send request via API.
 */

export const makeRemotePostUserConfirmation = (): UsecaseRemotePostUsersConfirmation =>
  new RemotePostUsersConfirmation(
    makeApiUrl('/users/confirmation'),
    makeHttpClient(),
  );
