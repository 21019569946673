import React, { useState } from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

interface iBreadcrumb {
  name: string;
  href: string;
}

interface ownProps {
  history: iBreadcrumb[];
}

const Breadcrumb: React.FC<ownProps> = ({ history }): JSX.Element => {
  const [item, setItem] = useState<iBreadcrumb[]>(history);

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {item.map((elem, index) => {
        if (index === item.length - 1) {
          return <Typography color="textPrimary">{elem.name}</Typography>;
        }

        return (
          <Link color="inherit" href={elem.href}>
            {elem.name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
