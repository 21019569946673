import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iUserInformation } from '../interface';
import { iStore } from '~/domain/interfaces/models';

import { ownProps } from '../Perfil';
import { makeReduxUpdateUser } from '~/main/factories/usecases/users/UpdateUserFactory';
import { GetOneUser } from '~/domain/usecases/users/remote';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const user = useSelector((store: iStore) => store.users.selected);
    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      data: MapPerfilData(user),
      actionUpdate: makeReduxUpdateUser(),
      ...(rest as P),
    };
    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param appointment data.
 */
export const MapPerfilData = (users: GetOneUser.Model): iUserInformation => {
  let data: iUserInformation = Object();
  const { user, address } = users;
  const ubdate = user.birthdate?.split('-');
  const userBirthdate = ubdate?.length
    ? `${ubdate?.[2]}/${ubdate?.[1]}/${ubdate?.[0]}`
    : '';

  data = {
    id: user.id,
    login: user.login,
    fullName: `${user.firstName} ${user.lastName}`,
    avatar: user.avatar,
    birthdate: userBirthdate,
    cpf: String(user.documents?.[0]?.value),
    documents: user.documents ?? [],
    rg: String(user.documents?.[1]?.value),
    gender: user.gender,
    mainRole: user.mainRole,
    motherName: user.motherName,
    address: {
      street: address?.street || '',
      city: address?.city || '',
      complement: address?.complement || '',
      neighborhood: address?.neighborhood || '',
      number: address?.number || '',
      uf: address?.uf || '',
      zipcode: address?.zipcode || '',
      country: address?.country || '',
    },
    email: user.email,
    phone: user.phone,
    phone2: user.phone2,
    phone3: user.phone3,
  };

  return data;
};
