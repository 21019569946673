import React, { useState } from 'react';
import { ContainerClinicalDocument } from './styles/StyledClinicalDocument';
import { Button } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';

const ClinicalDocument: React.FC = () => {
  return (
    <ContainerClinicalDocument>
      <h1>{`${translator('Selecione um tipo de documento clínico')}`}</h1>
      <div>
        <Button
          id="btn_medicalCertificate"
          size="medium"
          rounded
          variant="secundary"
        >
          {`${translator('Atestado Médico')}`}
        </Button>
        <Button id="btn_simpleRecipe" size="medium" rounded variant="secundary">
          {`${translator('Receita Simples')}`}
        </Button>
        <Button
          id="btn_specialRecipe"
          size="medium"
          rounded
          variant="secundary"
        >
          {`${translator('Receita Especial')}`}
        </Button>
        <Button
          id="btn_antiMicrobial"
          size="medium"
          rounded
          variant="secundary"
          color="gray"
        >
          {`${translator('Receita Antimicrobiano')}`}
        </Button>
        <Button id="btn_examRequest" size="medium" rounded variant="secundary">
          {`${translator('Requisição de Exames')}`}
        </Button>
      </div>
    </ContainerClinicalDocument>
  );
};

export default ClinicalDocument;
