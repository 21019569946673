import styled from 'styled-components';

interface iGroup {
  quant?: string[];
  gap?: string;
}

export const Container = styled.div`
  width: 100%;
  min-width: 324px;
  border: 1px solid #bfc4ca;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 15px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #efefef;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  .icon {
    position: absolute;
    right: 0;
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const ContainerForm = styled.form`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  justify-content: center;
  gap: 10px;
  p {
    font-weight: normal;
    font-size: 16px;
    color: #000000;
  }
  justify-content: space-between;
  .hover-input {
    :hover {
      background: #f4f7fe;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const FormGroup = styled.div<iGroup>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props =>
    props.quant ? props.quant.join(' ') : '1fr 1fr'};
  grid-gap: ${props => props.gap || '10px'};
`;

export const Options = styled.option`
  width: 100%;
  min-width: 211px;
  background-color: #ffffff;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  font-size: 16px;
`;
