import React, { useState } from 'react';
import InputMask from 'react-input-mask';

import { Input } from '~/presentation/components/UI';

export const Inputmask: React.FC = (): JSX.Element => {
  const [store, setStore] = useState('');

  return (
    <div>
      <InputMask
        mask="999.999.999-99"
        onChange={e => {
          const { value } = e.target;
          setStore(value.replace(/[^\d]+/g, ''));
        }}
        value={store}
      >
        <Input />
      </InputMask>

      <button type="button">print!</button>
    </div>
  );
};
