import styled from 'styled-components';

interface iBar {
  active: boolean;
  status: string;
}

export const Container = styled.div<iBar>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: ${props => (props.active ? '3px solid' : 'none')};
  padding: ${props => (props.active ? '4px' : '0px')};
  border-radius: 100%;
  border-color: ${props => {
    switch (props.status) {
      case 'scheduled':
        return 'blue';
      case 'available':
        return 'green';
      case 'canceled':
        return 'red';
      case 'finalized':
        return 'gray';
      default:
        return 'gray';
    }
  }};
`;

export const Title = styled.div<iBar>`
  position: absolute;
  white-space: nowrap;
  top: -30px;
  color: ${props => {
    switch (props.status) {
      case 'scheduled':
        return 'blue';
      case 'available':
        return 'green';
      case 'canceled':
        return 'red';
      case 'finalized':
        return 'gray';
      default:
        return 'gray';
    }
  }};
`;

export const SchedulingDate = styled(Title)`
  top: 30px;
`;

export const EndPoint = styled.div<iBar>`
  border-radius: 100%;
  width: 15px;
  height: 15px;
  background: ${props => {
    switch (props.status) {
      case 'scheduled':
        return 'blue';
      case 'available':
        return 'green';
      case 'canceled':
        return 'red';
      case 'finalized':
        return 'gray';
      default:
        return 'gray';
    }
  }};
`;
