import React, { useEffect, useState } from 'react';
import {
  Container,
  Body,
  HeaderList,
  Wrapper,
  Attendiment,
} from './styles/StyledSectors';
import { Menu } from '~/presentation/components/menu';
import { HeaderSimples } from '~/presentation/components/header';
import { History } from '~/main/routes';
import { TablePagination } from '~/presentation/components/tablePagination';
import { IconPlus, IconSectors } from '~/presentation/base/icons';
import { Button } from '~/presentation/components/UI';
import SearchListSectors from '~/presentation/components/search-list/SearchListSectors';
import { translator } from '~/presentation/components/i18n';
import { ConnectComponent } from '~/presentation/components/ListSectors/mapper/MapperListSector';
import { iListSectors } from '~/presentation/components/ListSectors/interface';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import ListRegisteredSectors from '~/presentation/components/ListAdmin/ListRegisteredSectors';

export interface ownProps {
  data: iListSectors[];
  selectUnit: Function;
}

const Sectors: React.FC<ownProps> = ({ data, selectUnit }) => {
  const [state, setState] = useState(data);

  useEffect(() => {
    if (data.length === 0) {
      makeReduxDataPagination().set([]);
    }
    // setState(data);
  }, [data]);

  return (
    <Container>
      <Menu />

      <HeaderSimples
        title={`${translator('Setores')}`}
        value={state?.length}
        valueDescription={translator('Cadastrados')}
        icon={IconSectors}
      />

      <Body>
        <HeaderList>
          {state?.length > 0 && state !== undefined ? (
            <TablePagination data={state} itemPerPage={10} />
          ) : (
            <div />
          )}
          <Wrapper>
            <div>
              <SearchListSectors />
            </div>
            <Button
              id="button_create"
              size="large"
              rounded
              onClick={() =>
                History.getHistory().push('/administrator/newsector')
              }
            >
              <Attendiment>
                <IconPlus />
              </Attendiment>
              <text>Novo setor</text>
            </Button>
          </Wrapper>
        </HeaderList>

        <div className="list-container">
          <ListRegisteredSectors selectUnit={selectUnit} />
        </div>
      </Body>
    </Container>
  );
};

export default ConnectComponent(Sectors);
