import React from 'react';
import { DocumentsList } from '~/presentation/components/documents-list';

// import { Container } from './styles';

const SupportingDocuments: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return <DocumentsList type="supporting-documents" openFunction={() => {}} />;
};

export default SupportingDocuments;
