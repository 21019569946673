import React from 'react';

export const MapUsers = (appointment: any): any[] => {
  const arr: any[] = [
    {
      id: appointment.professional.id,
      firstName: appointment.professional.firstName,
      lastName: appointment.professional.lastName,
      avatar: appointment.professional.avatar || '',
      role: 'PRO',
    },
    {
      id: appointment.consultant.id,
      firstName: appointment.consultant.firstName,
      lastName: appointment.consultant.lastName,
      avatar: appointment.consultant.avatar || '',
      role: 'CON',
    },
  ];

  return arr;
};
