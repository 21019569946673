import styled from 'styled-components';

export const ChooseCertificate = styled.div`
  width: 100%;
  background: white;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  #certificate-div {
    justify-content: center;
    align-items: center;
    max-width: 600px;
    width: 100%;
  }
`;

export const NavContent = styled.div`
  width: 100%;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 30px;
  /* border: 1px solid #efefef; */

  #certificate-div {
    width: 50%;
    min-width: 30em;
    display: flex;
    justify-content: center;
    align-items: center;

    .option {
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const TextAreaDoc = styled.div`
  width: 100%;
  height: 50vh;
  padding: 10px;
  border-radius: 2px;

  .observacoes {
    margin-top: 25px;
    font-size: 16px;
    font-weight: bold;
  }
`;
