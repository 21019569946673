import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Signature } from '../signature';
import { Button } from '~/presentation/components/UI/buttons';
import { Step, StepMagic } from '~/presentation/components/steps';
import {
  Container,
  Content,
  Header,
  Body,
  ContainerButton,
} from './styles/StyledExamRequest';
import { Health } from '~/presentation/base/icons';
import GeneralData, { ownProps as GeneralDataProps } from './GeneralData';
import Observation from './Observation';
import ExamRequestView from './ExamRequestView';

import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';

import { iCreateExamRequest } from './interface';

import {
  makeRemoteCreateClinicalDocs,
  makeReduxCreateClinicalDocs,
} from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';

import { schema } from '~/validation/validators/document/CreateClinicalDocValidator';

import { ConnectComponent } from './mapper/MapperExamRequest';

import { GetCertificates } from '~/domain/usecases/deamon/remote';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';

export interface Props {
  examRequest: ReduxCreateClinicalDocs | RemoteCreateClinicalDocs;
}

interface iStateLocation {
  appointmentId: string;
  professional: string;
  consultant: string;
}

export type ownProps = Props & GeneralDataProps;

const ExamRequest: React.FC<ownProps> = props => {
  const [crement, setCrement] = useState<number>(1);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const { state } = useLocation<iStateLocation>();

  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { auth } = useSelector((store: iStore) => store);

  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const specialty =
    role === 'PRO'
      ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
          ?.professions.specialties[0].id
      : -1;

  const [exam, setExam] = useState<iCreateExamRequest>({
    professional: state && state.professional ? Number(state.professional) : -1,
    patient: state && state.consultant ? Number(state.consultant) : -1,
    orgUnitName,
  });

  let dataToSend: RemoteCreateClinicalDocs.Params = {};

  const CreateExamRequest = () => {
    if (state && state.appointmentId !== undefined) {
      dataToSend = {
        appointment:
          state && state.appointmentId
            ? Number(state.appointmentId)
            : undefined,

        type: 3,
        content: {
          solicitacao: [
            {
              exam: exam.exam,
            },
          ],
          indicacaoClinica: exam.indicacaoClinica,
        },
        certificate: exam.certificate,
        certificateId: exam.certificateId,
      };
    } else {
      dataToSend = {
        type: 3,
        content: {
          solicitacao: [
            {
              exam: exam.exam,
            },
          ],
          indicacaoClinica: exam.indicacaoClinica,
        },
        org: orgId,
        orgUnit: orgUnitId,
        patient:
          state && state.consultant ? Number(state.consultant) : exam.patient,
        professional:
          state && state.professional
            ? Number(state.professional)
            : exam.professional,
        certificate: exam.certificate,
        certificateId: exam.certificateId,
        specialty,
      };
    }
    try {
      const validate = schema.parse(dataToSend);
      makeReduxCreateClinicalDocs().create(dataToSend);
      console.log('validate: ', validate);
    } catch (e) {
      console.error('erro ao enviar para o back', e);
    }
  };

  const data = {
    isSigned: 'Assinado',
    name: 'Danilo',
  };

  function next(dados: iCreateExamRequest) {
    setExam({ ...exam, ...dados });
    handleBar(1);
  }

  function back(dados: iCreateExamRequest) {
    setExam({ ...exam, ...dados });
    handleBar(-1);
  }

  function handleBar(number: number) {
    if (crement + number > 3 || crement + number < 1) {
      setCrement(crement);
    } else {
      setCrement(crement + number);
    }
  }
  return (
    <Container>
      <Content>
        <Health />
        <h1>{`${translator('Requisição de exame')}`}</h1>
      </Content>
      {crement !== 4 ? (
        <Header>
          <StepMagic current={crement}>
            <Step title={`${translator('Informações gerais')}`} />
            <Step title={`${translator('Prescrição')}`} />
            <Step title={`${translator('Assinatura do documento')}`} />
          </StepMagic>
        </Header>
      ) : null}
      <Body>
        {crement === 1 && (
          <GeneralData
            next={next}
            state={exam}
            consultant={props.consultant}
            professional={props.professional}
          />
        )}
        {crement === 2 && <Observation state={exam} next={next} back={back} />}
        {crement === 3 && (
          <Signature
            changeSelected={(e: {
              certificateId: string;
              certificate: string;
            }) =>
              setExam({
                ...exam,
                certificate: e.certificate,
                certificateId: e.certificateId,
              })
            }
            changeDisabled={(stat: boolean) => setDisableButton(stat)}
            onSignSubmit={CreateExamRequest}
          />
        )}
        {crement === 4 && (
          <ExamRequestView isSigned={data.isSigned} name={data.name} />
        )}
      </Body>
      {crement === 3 ? (
        <ContainerButton>
          <Button
            id="btn_prev_step"
            rounded
            variant="secundary"
            onClick={() => handleBar(-1)}
            size="small"
          >
            {`${translator('Voltar')}`}
          </Button>
          <Button
            id="btn_next_step"
            rounded
            size="small"
            onClick={CreateExamRequest}
            disabled={loading || disableButton}
          >
            {`${translator('Concluir')}`}
          </Button>
        </ContainerButton>
      ) : null}
    </Container>
  );
};

export default ConnectComponent(ExamRequest);
