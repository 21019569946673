import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { iProfessional } from '~/domain/interfaces/models';
import { translator } from '~/presentation/components/i18n';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { Button, Input, Select } from '~/presentation/components/UI';
import { schema } from '~/validation/validators/document/MedicalCertificateSideBarValidator';
import { iCreateMedicalCertificate, iListConsultant } from './interface';
import {
  Container,
  Content,
  Form,
  Navigation,
} from './styles/StyledGeneralData';

export interface ownProps {
  consultant: iListConsultant[];
  next: (data: iCreateMedicalCertificate) => any;
  professional: iProfessional;
  state?: iCreateMedicalCertificate;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

const GeneralData: React.FC<ownProps> = ({
  next,
  consultant,
  professional,
  state,
}): JSX.Element => {
  const stateParams = useLocation<iStateParams>().state;

  const [professionalId, setProfId] = useState<number | undefined>(
    stateParams.professionalId,
  );
  const [consultantId, setConId] = useState<number | undefined>(
    stateParams.consultantId,
  );

  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  useEffect(() => {
    register('professional');
    register('patient');

    if (stateParams.professionalId) {
      setProfId(stateParams.professionalId);
      setValue('professional', stateParams.professionalId);
    }

    if (stateParams.consultantId) {
      setConId(stateParams.consultantId);
      setValue('patient', stateParams.consultantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateParams]);

  return (
    <Container>
      <Content>
        <Form onSubmit={onSubmit}>
          <Input
            id="input_instituition"
            label={`${translator('Instituição')}`}
            onChange={e => setValue('org', Number(e.target.value))}
            name="org"
            register={() => register('org')}
            error={Boolean(errors.org)}
            message={errors?.org?.message}
            placeholder={state?.orgUnitName}
            disabled={state && state.orgUnitName !== ''}
            autoFocus
          />
          <Select
            key={professionalId}
            id="select_professional"
            label={`${translator('Profissional')}`}
            onChange={e => {
              setProfId(Number(e.target.value));
              setValue('professional', Number(e.target.value));
            }}
            value={professionalId}
            name="professional"
            register={() => register('professional')}
            error={Boolean(errors.professional)}
            message={
              errors?.professional?.message
                ? translator(errors?.professional?.message)
                : ''
            }
            disabled={Boolean(professionalId && professionalId !== -1)}
          >
            <option value="selectPatient">
              {translator('Selecione um profissional')}
            </option>
            {professional?.results?.map(user => (
              <option
                key={user.professional.id}
                value={Number(user.professional.id)}
              >
                {`${user.user.firstName} ${user.user.lastName}`}
              </option>
            ))}
          </Select>

          <Select
            key={consultantId}
            id="select_patient"
            label={`${translator('Paciente')}`}
            onChange={e => {
              setConId(Number(e.target.value));
              setValue('patient', Number(e.target.value));
            }}
            value={consultantId}
            name="patient"
            register={() => register('patient')}
            error={Boolean(errors.patient)}
            message={
              errors?.patient?.message
                ? translator(errors?.patient?.message)
                : ''
            }
            disabled={Boolean(consultantId && consultantId !== -1)}
          >
            <option value="selectPatient">
              {translator('Selecione um paciente')}
            </option>
            {consultant.map(consult => (
              <option key={consult.id} value={consult.id}>
                {`${consult.firstName} ${consult.lastName}`}
              </option>
            ))}
          </Select>

          <InputKeyboardDate
            state={new Date()}
            setState={() => ''}
            label={`${translator('Data de emissão')}`}
            placeholder="DD/MM/AAAA"
            disabled
          />

          <Input
            id="input_periodo"
            name="periodo"
            defaultValue={state?.periodo}
            placeholder="Selecione o turno ou dias de afastamento"
            label={`${translator('Tempo de afastamento')}`}
            onChange={e => {
              setValue('periodo', e.target.value);
            }}
            register={() => register('periodo')}
            error={Boolean(errors.periodo)}
            message={
              errors?.periodo?.message
                ? translator(errors?.periodo?.message)
                : ''
            }
            autoFocus
          />

          <p>{`${translator('Observações')}`}</p>
          <textarea
            id="text_area_observacoes"
            name="observacoes"
            ref={register}
            placeholder={`${translator('Motivo do afastamento')}`}
            defaultValue={state?.observacoes}
            onChange={e => {
              setValue('observacoes', e.target.value);
            }}
          />
        </Form>
        <Navigation>
          <Button variant="primary" onClick={onSubmit} size="medium" rounded>
            Próximo
          </Button>
        </Navigation>
      </Content>
    </Container>
  );
};

export default GeneralData;
