/* eslint-disable consistent-return */
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Popover, Option, Text } from './styles/optionsMore';
import {
  IconPdf,
  IconCloud,
  IconEmail,
  IconHome,
} from '~/presentation/base/icons';
import { makeRemoteGetByDownloadSupportDocs } from '~/main/factories/usecases/supportDoc/GetByDownloadSupportDocsFactory';
import { SendMail } from '~/presentation/components/messages/email/SendMail';
import { History } from '~/main/routes';

interface ownProps {
  supportDocID?: number;
  extension?: string;
}

const OptionsSupportDoc: React.FC<ownProps> = ({ supportDocID, extension }) => {
  const id = supportDocID || 1;
  const [urlPdf, setUrl] = useState<string>();

  async function downloadFile() {
    if (id === 0) {
      alert('Selecione um arquivo');
      return;
    }

    if (Number.isNaN(id)) {
      alert('Selecione um arquivo');
      return;
    }

    const { url } = await makeRemoteGetByDownloadSupportDocs().get({
      docId: Number(id),
    });

    setUrl(url);

    axios
      .get(url, {
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      })
      .then(response => {
        const link = document.createElement('a');
        const urlToDownload = window.URL.createObjectURL(response.data);
        const name = 'FILE';
        link.href = urlToDownload;
        link.download = name;
        link.click();
      })
      .catch(err => {
        console.error('Falha ao realizar download do arquivo.', err);
      });
  }

  // const deleteFile = async () => {
  //   makeRemoteDeleteSupportDocs().delete({ docId: id });
  // };

  const shareFile = ({ message }: any) => {
    // return <SendMail message={message} />;
  };

  return (
    <Container>
      <Popover>
        {/* {extension === 'pdf' && (
          <Option
            onClick={() =>
              History.getHistory().push('/supportdoc/detail', {
                docId: id,
                from: window.location.pathname.replaceAll('/', ''),
              })
            }
          >
            <IconPdf />
            <Text>Visualizar PDF</Text>
          </Option>
        )} */}
        <Option id="btn_download" onClick={() => downloadFile()}>
          <IconCloud />
          <Text>Baixar</Text>
        </Option>
        {/* <Option>
          <IconHome />
          <Text>Acesso para farmácia</Text>
        </Option> */}
        {/* <Option>
          <IconEmail />
          <Text>Reenviar por e-mail</Text>
        </Option> */}
      </Popover>
    </Container>
  );
};

export default OptionsSupportDoc;
