import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllAdministrator as UseCaseGetAllAdministrator } from '~/domain/usecases/administrator/remote';
import { GetAllAdministrator as UsecaseReduxGetAllAdministrator } from '~/domain/usecases/administrator/redux';

import { RemoteGetAllAdministrator } from '~/data/repository/administrator';
import { ReduxGetAllAdministrator } from '~/data/store/reducer/administrator/usecases/ReduxGetAllAdministrator';

export const makeRemoteGetAllAdministrator = (): UseCaseGetAllAdministrator =>
  new RemoteGetAllAdministrator(
    makeApiUrl('/administrators/list'),
    makeHttpClient(),
  );

export const makeReduxGetAllAdministrator = (): UsecaseReduxGetAllAdministrator =>
  new ReduxGetAllAdministrator();
