import styled from 'styled-components';

export const Container = styled.div`
  background: #fcfcfc;
  width: 100%;
  padding: 10px 40px 10px 40px;
  color: #656a6e;
  border-bottom: 1px solid ${props => props.theme.typographyDarkGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: 700;
`;
export const ButtonBack = styled.div``;

export const Left = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Right = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;
