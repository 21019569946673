import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateAppointmentCompanion as UseCaseRemoteUpdateAppointmentCompanion } from '~/domain/usecases/appointmentCompanion/remote';

import { RemoteUpdateAppointmentCompanion } from '~/data/repository/appointmentCompanion';

export const makeRemoteUpdateAppointmentCompanion = (): UseCaseRemoteUpdateAppointmentCompanion =>
  new RemoteUpdateAppointmentCompanion(
    makeApiUrl('/appointments'),
    makeHttpClient(),
  );
