import { GetAppointmentIdRecording } from '~/domain/usecases/appointment/remote';
import {
  BadRequestError,
  InvalidCredentialsError,
  Forbidden,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

export class RemoteGetAppointmentIdRecording
  implements GetAppointmentIdRecording {
  private readonly url: string;

  private readonly httpCliente: HttpClient<GetAppointmentIdRecording.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<GetAppointmentIdRecording.Model>,
  ) {
    this.url = url;
    this.httpCliente = httpClient;
  }

  getRecordings = async (
    params: GetAppointmentIdRecording.Params,
  ): Promise<GetAppointmentIdRecording.Model> => {
    const httpResponse = await this.httpCliente.request({
      url: `${this.url}/${params.appointmentId}/recordings`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new Forbidden();
    }
  };
}
