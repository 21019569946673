import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Container, Form } from './styles/StyledGeneralData';

import { Input, Select } from '~/presentation/components/UI';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { translator } from '~/presentation/components/i18n';

import { iListConsultant, iValidation } from './interface';

import { iClinicalDocs } from '~/domain/interfaces/models/ClinicalDocs';
import { iProfessional } from '~/domain/interfaces/models/Professional';
import { MakeClinicalDoc } from '~/domain/usecases/clinicalDocs/redux/MakeClinicalDoc';
import { Navigator } from '../register/Navigator';
import { schemaGeneralData } from '~/validation/validators/document/CreateClinicalDocValidator';

export interface ownProps {
  consultant: iListConsultant[];
  professional: iProfessional;
  next: (data: iValidation) => any;
  state?: iValidation;
}

interface iStateParams {
  professional: string;
  consultant: string;
}

const GeneralData: React.FC<ownProps> = ({
  consultant,
  professional,
  next,
  state,
}): JSX.Element => {
  const stateParams = useLocation<iStateParams>().state;
  const [consultantId, setConId] = useState<number | undefined>(state?.patient);

  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGeneralData),
    defaultValues: {
      ...state,
      orgUnit: 1,
      org: 1,
      patient: state?.patient,
    },
  });

  register('orgUnit');

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Input
          name="org"
          label={`${translator('Instituição')}`}
          onChange={e => setValue('org', Number(e.target.value))}
          register={() => register('org')}
          error={Boolean(errors.org)}
          message={errors.org?.message}
          placeholder={state?.orgUnitName}
          disabled={state && state.orgUnitName !== ''}
          autoFocus
          required
        />

        <Select
          name="professional"
          defaultValue={state?.professional}
          label={`${translator('Profissional')}`}
          onChange={e => setValue('professional', Number(e.target.value))}
          register={() => register('professional')}
          error={Boolean(errors.professional)}
          message={
            errors.professional?.message
              ? translator(errors.professional?.message)
              : ''
          }
          disabled={stateParams && Number(stateParams.professional) !== -1}
          required
        >
          <option value={-1}>{translator('Selecione um profissional')}</option>
          {professional.results.map(user => (
            <option value={Number(user.professional.id)}>
              {`${user.user.firstName} ${user.user.lastName}`}
            </option>
          ))}
        </Select>

        <Select
          key={consultantId}
          id="select_patient"
          label={`${translator('Paciente')}*`}
          onChange={e => {
            setValue('patient', Number(e.target.value));
          }}
          defaultValue={state?.patient}
          name="patient"
          register={() => register('patient')}
          error={Boolean(errors.patient)}
          message={
            errors?.patient?.message ? translator(errors?.patient?.message) : ''
          }
          disabled={
            stateParams &&
            stateParams?.consultant !== undefined &&
            stateParams?.consultant !== ''
          }
        >
          <option value={-1}>{translator('Selecione um paciente')}</option>
          {consultant.map(consult => (
            <option key={consult.id} value={consult.id}>
              {`${consult.firstName} ${consult.lastName}`}
            </option>
          ))}
        </Select>
        <InputKeyboardDate
          state={new Date()}
          setState={() => ''}
          label={`${translator('Data de emissão')}`}
          placeholder="DD/MM/AAAA"
          disabled
        />
        <Navigator />
      </Form>
    </Container>
  );
};

export default GeneralData;
