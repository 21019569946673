/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Button } from '~/presentation/components/UI';

import { translator } from '~/presentation/components/i18n';
import { Icon } from '~/presentation/components/icon';
import { IconV4Hatendimento } from '~/presentation/base/icons';
import {
  Container,
  Header,
  TermContainer,
  CheckTerm,
  CheckRecorder,
  Image,
  TermsLink,
} from './styles/StyledAcceptTerm';
import { History } from '~/main/routes';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';

interface ownProps {
  updateState: Function;
}

export default function AcceptTerm(props: ownProps): JSX.Element {
  const { updateState } = props;

  const [checkOne, setCheckOne] = useState<boolean>(false);
  const [checkTwo, setCheckTwo] = useState<boolean>(false);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const displayModal = () => {
    makeReduxActiveMessage().active({
      active: 'termsOfUse',
      actionOk: closeModal,
      actionCancel: closeModal,
    });
  };

  return (
    <Container>
      <Header>
        {logoUrl ? (
          <Image alt="Logo" src={logoUrl} width="200px" />
        ) : (
          <Icon src={IconV4Hatendimento} width="250px" height="80px" />
        )}
      </Header>
      <TermContainer>
        <span className="title">
          {translator('Antes de entrar na sala de espera')}
        </span>
        <span className="message">
          {translator('Você precisa concordar com os termos de uso da')}
        </span>
        <span className="message">
          {translator('plataforma e também concordar que o atendimento poderá')}
        </span>
        <span className="message">
          {translator('ser gravado mediante escolha do médico.')}
        </span>
        <CheckTerm>
          <input
            type="checkbox"
            checked={checkOne}
            onClick={() => setCheckOne(!checkOne)}
          />
          <span>
            {translator('Aceito os')}
            &nbsp;
          </span>
          <TermsLink onClick={() => displayModal()}>
            <span>{translator('termos de uso.')}</span>
          </TermsLink>
        </CheckTerm>
        <CheckRecorder>
          <input
            type="checkbox"
            checked={checkTwo}
            onClick={() => setCheckTwo(!checkTwo)}
          />
          {translator('Aceito que o atendimento poderá ser gravado.')}
        </CheckRecorder>

        <Button
          rounded
          onClick={() => updateState(1)}
          disabled={!(checkOne && checkTwo)}
        >
          {translator('Ir para sala de espera')}
        </Button>
      </TermContainer>
      <Button
        rounded
        variant="secundary"
        onClick={() => History.getHistory().goBack()}
      >
        {translator('Voltar')}
      </Button>
    </Container>
  );
}
