import React from 'react';
import { UserInfo } from './interface';
import { Container, Name, Role, Content } from './styles/StyledItemHistoryChat';
import { AvatarImage } from '../avatar-roles';

import { translator } from '../i18n';

const ItemHistoryChat: React.FC<UserInfo> = ({ user, open }) => {
  const userType = (r: string | undefined) => {
    switch (r) {
      case 'ORG':
        return translator('Organizador');
      case 'PRO':
        return translator('Profissional');
      case 'CON':
        return translator('Paciente');
      case 'ADM':
        return translator('Administrador');
      default:
        return '';
    }
  };
  return (
    <Container onClick={() => open(user.id)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AvatarImage size="mini" name={user.name} src={user.src} />
        <Content>
          <Name>{user.name}</Name>
          <Role>{userType(user.role)}</Role>
        </Content>
      </div>
      {user.newMessageCount !== 0 && (
        <div className="bolinha">{user.newMessageCount}</div>
      )}
    </Container>
  );
};

export default ItemHistoryChat;
