import React from 'react';
import { IconCloseButtonModal, IconCrossBlue } from '~/presentation/base/icons';
import {
  Container,
  Access,
  Header,
  Body,
  Footer,
  Buttons,
  CodeGen,
  QrGen,
} from './styles/StyledCodePassword';
import { translator } from '../i18n';

const CodePassword: React.FC = () => {
  return (
    <Container>
      <Access>
        {translator('Acesso para farmácia')}
        <IconCloseButtonModal />
      </Access>
      <Header>
        <IconCrossBlue />
        {translator('Receita simples')}
      </Header>
      <Body>
        {translator('Solicite a farmácia que acesse o endereço')}
        <h2>www.receita.v4h.cloud</h2>
        {translator('para baixar sua receita digital.')}
      </Body>
      <Footer>{translator('Utilize o código e senha para acessar')}</Footer>
      <Buttons>
        <CodeGen>
          {translator('Código:')}
          <h2>4yh35b</h2>
        </CodeGen>
        <CodeGen>
          {translator('Senha:')}
          <h2>150378</h2>
        </CodeGen>
        <QrGen>
          <h3>{translator('Ver QR Code')}</h3>
        </QrGen>
      </Buttons>
    </Container>
  );
};

export default CodePassword;
