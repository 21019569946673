import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import * as datefns from 'date-fns';
import { compareAsc } from 'date-fns';
import { iListAppointment } from '../interface';
import {
  iAppointment,
  iAppointmentTypeState,
  iStore,
} from '~/domain/interfaces/models';

import { ownProps } from '../ListAppointment';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const appointment = useSelector((store: iStore) => store.appointment);
    const { data: appointmentTypes } = useSelector(
      (store: iStore) => store.appointmentType,
    );

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      data: MapAppointmentData(appointment, appointmentTypes),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param appointment data.
 */
function makeTime(item: string, duration: number) {
  const time = new Date(item);
  const timeStart = new Date(time.getTime()).toLocaleTimeString('pt-BR', {
    hour12: false,
  });
  const timeEnd = new Date(
    time.getTime() + duration * 60000,
  ).toLocaleTimeString('pt-BR', { hour12: false });

  const startFormat = `${timeStart.split(':')[0]}:${timeStart.split(':')[1]}`;
  const endFormat = `${timeEnd.split(':')[0]}:${timeEnd.split(':')[1]}`;
  return { startFormat, endFormat };
}
const MapAppointmentData = (
  appointment: iAppointment,
  appointmentTypes: iAppointmentTypeState['data'],
): iListAppointment[] => {
  const arr: iListAppointment[] = [];
  const selectedDate = datefns.format(
    appointment.date instanceof Date
      ? appointment.date
      : new Date(appointment.date),
    'yyyy-MM-dd',
  );
  const appointments = appointment.resultsMap
    ? appointment.resultsMap[selectedDate]
    : [];

  if (appointments !== undefined)
    appointments?.forEach(item => {
      const date = item.appointment.scheduled;
      const { duration } = item.appointment;

      const typeIs = appointmentTypes.find(
        type => type.id === item.appointment.type,
      );

      arr.push({
        fullName: `${item.consultant?.firstName} ${item.consultant?.lastName}`,
        specialty: item.specialty.name,
        timeEnd: makeTime(date, duration).endFormat,
        timeStart: makeTime(date, duration).startFormat,
        typeIs: typeIs?.msgKey ?? '',
        status: item.appointment.status,
        id: item.appointment.id,
        patient: {
          name: `${item.consultant?.firstName} ${item.consultant?.lastName}`,
          avatar: item.consultant!.avatar,
          id: item.consultant!.id!,
          userId: item.consultant!.user!,
        },
        professional: item.professional!.id,
        consultantStatus: item.consultant?.status,
        title: item.appointment?.title ?? '',
      });
    });

  return arr;
};

/**
 * Compare the two dates and return 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
 * @param date1 first Date.
 * @param date2 second Date.
 * @return boolean
 */
const validDate = (date1: Date, date2: Date): boolean => {
  return (
    compareAsc(
      new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()),
      new Date(date2.getFullYear(), date2.getMonth(), date2.getDate()),
    ) === 0
  );
};
