import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { PaginationRenderItemParams } from '@material-ui/lab';
import { iStore, iUsers } from '~/domain/interfaces/models';

import { ownProps } from '../ProfessionalData';
import { iProfessions } from '../interface';
import { makeReduxUpdateSpecialty } from '~/main/factories/usecases/specialty/UpdateSpecialtyFactory';
import { makeRemoteDeleteProfessionalSpecialtys } from '~/main/factories/usecases/professional/DeleteProfessionalSpecialty';
import { makeRemoteCreateProfessionalSpecialtys } from '~/main/factories/usecases/professional/CreateProfessionalSpecialty';
import { makeRemoteGetOneUser } from '~/main/factories/usecases/users/GetOneUserFactory';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const users = useSelector((store: iStore) => store.users);

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      data: MapPerfilData(users),
      actionUpdate: (e: any) => makeReduxUpdateSpecialty().update(e),
      actionDelete: (e: any) =>
        makeRemoteDeleteProfessionalSpecialtys().delete(e),
      actionCreate: (e: any) =>
        makeRemoteCreateProfessionalSpecialtys().create(e),
      getOneUser: (e: any) => makeRemoteGetOneUser().getOne(e),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param appointment data.
 */
const MapPerfilData = (users: iUsers): iProfessions[] => {
  const arr: iProfessions[] = [];
  const { selected } = users;

  // const { appointment }: iStore = store.getState();
  selected.professionals.forEach(item => {
    arr.push({
      userId: selected.user.id,
      id: item.id,
      professions: {
        id: item.professions.id,
        name: item.professions.name,
        specialties: item.professions.specialties,
      },
    });
  });

  return arr;
};
