import React from 'react';
import { Select, Button } from '../UI';
import { Container } from './styles/StyledAddPermission';
import { translator } from '../i18n';

const AddPermision = () => {
  return (
    <Container>
      <Select name="role" label={translator('Papel')}>
        <option value="select">
          {translator('Selecione um papel')}
        </option>
        <option value="organizador">
          {translator('Organizador')}
        </option>
        <option value="profissionaldesaude">
          {translator('Profissional de saúde')}
        </option>
      </Select>
      <Button size="small" height="48px" className="btnAction" rounded>
        {translator('+Adicionar')}
      </Button>
    </Container>
  );
};

export default AddPermision;
