import React from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '~/main/routes/PrivateRoute';

import {
  MakeListManagePage,
  MakeListProfessionalPage,
  MakeListParticipantPage,
  MakeListAdministratorPage,
  MakeRegisterManagerPage,
  MakeRegisterParticipantPage,
  MakeRegisterProfessionalPage,
} from '~/main/factories/pages/user';

import SectorsFactory from '~/main/factories/pages/sectors/SectorsFactory';

import { BillingFactory } from '~/main/factories/pages/billing';

import { OrgUnitDetailFactory } from '~/main/factories/pages/orgUnitDetail';
import { CreateOrgUnitFactory } from '~/main/factories/pages/createOrgUnit';
import { RegisterFactory } from '~/main/factories/pages/register';

import { InviteProfessionalFactory } from '~/main/factories/pages/inviteProfessional';

import {
  MakePerfilFactories,
  MakeProfileFactories,
} from '../factories/pages/profile';
import { EditOrgUnitFactory } from '../factories/pages/editOrgUnit';
import { MakeRegisterAdmPage } from '../factories/pages/user/RegisterAdministrator';

const RoutesUser = [
  <PrivateRoute path="/profile" exact component={MakeProfileFactories} />,
  <PrivateRoute path="/profile/:userId" component={MakePerfilFactories} />,

  <PrivateRoute
    path="/administrator"
    exact
    component={MakeListAdministratorPage}
  />,
  <PrivateRoute path="/administrator/register" component={RegisterFactory} />,
  <PrivateRoute path="/manager" exact component={MakeListManagePage} />,
  <PrivateRoute path="/manager/register" component={MakeRegisterManagerPage} />,
  <PrivateRoute path="/administrator/billing" component={BillingFactory} />,
  <PrivateRoute
    path="/administrator/newsector"
    component={CreateOrgUnitFactory}
  />,
  <PrivateRoute
    path="/administrator/editsector"
    component={EditOrgUnitFactory}
  />,
  <PrivateRoute path="/administrator/sectors" component={SectorsFactory} />,
  <PrivateRoute
    path="/administrator/orgdetail"
    component={OrgUnitDetailFactory}
  />,
  <PrivateRoute
    path="/participant"
    exact
    component={MakeListParticipantPage}
  />,
  <PrivateRoute path="/participant/register" component={RegisterFactory} />,

  <PrivateRoute
    path="/professional"
    exact
    component={MakeListProfessionalPage}
  />,

  <Route path="/invite" exact component={InviteProfessionalFactory} />,

  <PrivateRoute
    path="/professional/register"
    component={MakeRegisterProfessionalPage}
  />,

  <Route path="/user/register" component={RegisterFactory} />,
];

export default RoutesUser;
