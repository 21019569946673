import React from 'react';

import { Menu } from '~/presentation/components/menu';

import { RegisterParticipant as RegisterUserForm } from '~/presentation/components/registerParticipant';

import { Container, Body, LockScroll } from './StyledRegisterParticipant';

import { PageHeader } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';

const RegisterParticipant: React.FC = (): JSX.Element => {
  return (
    <Container>
      <LockScroll>
        <Menu />
        <PageHeader
          title={translator('Cadastrar novo paciente')}
          confirmChanges
        />
      </LockScroll>
      <Body>
        <RegisterUserForm />
      </Body>
    </Container>
  );
};

export default RegisterParticipant;
