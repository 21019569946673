import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllClinicalDocs as UseCaseRemoteGetAllClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { GetAllClinicalDocs as UseCaseReduxGetAllClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteGetAllClinicalDocs } from '~/data/repository/clinicalDocs';
import { ReduxGetAllClinicalDocs } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteGetAllClinicalDocs = (): UseCaseRemoteGetAllClinicalDocs =>
  new RemoteGetAllClinicalDocs(
    makeApiUrl('/clinicalDocs/list'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxGetAllClinicalDocs = (): UseCaseReduxGetAllClinicalDocs =>
  new ReduxGetAllClinicalDocs();
