import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ListProfessional } from '~/presentation/roles/manager/pages';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { iStore } from '~/domain/interfaces/models';

import { PageNotFound, Unauthorized } from '~/presentation/pages/errors';
import { V4hSpin } from '~/presentation/components/spin';

interface iStateParams {
  unit?: number;
}

export const MakeListProfessionalPage: React.FC = () => {
  const loading = useSelector((store: iStore) => store.users.loading);
  const { orgId, orgUnitId, role } = useSelector(
    (state: iStore) => state.auth.selectUser,
  );

  const unit = useLocation<iStateParams>().state?.unit;

  useEffect(() => {
    if (unit) {
      makeReduxGetAllProfessionals().getAll({
        pageSize: 9999,
        filter: {
          unit,
          situation: ['ACTIVE'],
        },
      });
    } else {
      makeReduxGetAllProfessionals().getAll({
        pageSize: 9999,
        filter: {
          org: orgId,
          unit: orgUnitId ?? undefined,
          situation: ['ACTIVE'],
        },
      });
    }
    // makeReduxGetAllUsers().getAll({ page: 0, limit: 0 });
  }, [unit, orgId, orgUnitId]);

  switch (role) {
    case 'ADM':
      return <ListProfessional />;
    case 'ORG':
      return <ListProfessional />;
    case 'CON':
      return <Unauthorized />;
    case 'PRO':
      return <Unauthorized />;
    default:
      return <PageNotFound />;
  }
};
