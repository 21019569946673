import styled, { css } from 'styled-components';

interface ClickProps {
  active: boolean;
}

export const ContainerNewConsult = styled.div``;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0px 0px;
  gap: 15px;
  background: #004ba5;
  color: white;
  padding: 15px;
  button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: transparent;
    border: 0;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const Arrow = styled.div<ClickProps>`
  cursor: pointer;
  .icon {
    ${props =>
      props.active
        ? css`
            transition: all 0.5s ease-in-out;
            transform: rotate(180deg);
          `
        : css`
            transition: all 0.5s ease-in-out;
          `};
  }
`;
