import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  height: 100%;

  background: #fbfbfb;
`;

export const Left = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;

  .text {
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
    color: #7a7a7a;
  }
`;

export const Right = styled.div`
  display: flex;
  width: 40%;
`;

export const LinkContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;

  cursor: pointer;

  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.primary};
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 30%;
  height: 100%;

  @media (max-width: 1580px) {
    width: 50%;
  }

  @media (max-width: 1300px) {
    width: 70%;
    min-width: 430px;
  }

  @media (max-width: 700px) {
    width: 100%;
    min-width: 300px;

    #logoV4H {
      width: 100%;
    }
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: start;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  min-width: 412px;
  height: fit-content;
  flex-direction: column;
  padding: 36px;
  background: white;
  border: 1px solid #efefef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  gap: 16px;
  padding-bottom: 48px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  .text {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #7a7a7a;
  }
`;

export const VersionContentText = styled.a`
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a6a6a6;
  text-align: right;
  align-self: flex-end;

  @media (min-width: 1580px) {
    display: none;
  }
`;

export const ForgotPasswordText = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a6a6a6;

  cursor: pointer;

  &:hover {
    color: #000000;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   height: auto; */
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 50px);
  gap: 136px;
  margin: 0 auto;
  max-width: 1080px;
`;

export const Footer = styled.footer`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #64696f;
  background-color: white;
  position: relative;

  gap: 4px;

  a {
    color: ${props => props.theme.primary};
    text-decoration: underline;
  }
`;

export const FooterVersion = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;

  a {
    font-size: 10px;
    color: ${props => props.theme.primary};
    text-decoration: none;
  }
`;

export const FooterImgs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Image = styled.img`
  margin-right: 2px;
`;

const SizeVariant = css`
  text-decoration: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  :hover {
    color: ${shade(0.2, '#1756E8')};
  }
`;

export const SignUp = styled.a`
  ${SizeVariant};
  font-weight: bold;
  font-size: 18px;
`;

export const Forgotpass = styled.a`
  ${SizeVariant};
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2489f3;
`;

export const RemindMe = styled.div`
  display: flex;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7a7a7a;
`;
