/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import * as datefns from 'date-fns';
import { KeyboardTimePicker } from '@material-ui/pickers';

import { useForm, Controller, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useSelector } from 'react-redux';
import { add, format, isAfter, isBefore, isEqual } from 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Container, ContainerMain } from './styles/StyledDateHour';

import InputKeyboardDate from '../inputKeyboardDate';
import { translator } from '../i18n';
import { iUpdateAppointment } from './interface';
import { makeReduxSetData as makeReduxSetDate } from '~/main/factories/usecases/appointment/SetDataFactory';

import { schemaDateHours } from '~/validation/validators/appointment/UpdateAppointmentValidator';

import { ButtonSave } from './ButtonSave';
import { iStore } from '~/domain/interfaces/models';
import { makeDuration } from '~/utils/makeDurationAndTime';
import { Select } from '../UI';
import { makeDateAndDuration } from '~/utils/makeDateAndDuration';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import { getLocale } from '~/utils/getLocale';

interface ownProps {
  handleChangeEditConsult: Function;
  setValue: Function;
  handleSubmit: Function;
  register: Function;
  errors: any;
  control: any;
  state: iUpdateAppointment;
  appointmentId: string;
}
interface typeArrayTime {
  time: string;
  timeFormat: string;
  disabled: boolean;
}

const DateHour: React.FC<ownProps> = ({
  state,
  control,
  // errors,
  // register,
  setValue,
  handleChangeEditConsult,
  appointmentId,
}): JSX.Element => {
  const [times, setTimes] = useState<typeArrayTime[]>([]);
  const [timesEnd, setTimesEnd] = useState<typeArrayTime[]>([]);
  const [defaultStart, setStart] = useState(state.hourStart);
  const [defaultEnd, setEnd] = useState(state.hourEnd);
  const [dateIsoString, setDate] = useState<string>(state?.date || '');
  const [initialDateIsoString, setInitialDate] = useState<string>(
    state?.date || '',
  );
  const labelTimeEnd = translator('Hora fim');
  const labelTimeStart = translator('Hora início');
  const selectDate = useSelector((store: iStore) =>
    datefns.format(
      store.appointment.date instanceof Date
        ? store.appointment.date
        : new Date(store.appointment.date),
      'yyyy-MM-dd',
    ),
  );
  const appointments = useSelector(
    (store: iStore) => store.appointment.resultsMap[selectDate],
  );
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const { appointment } = useSelector((store: iStore) => store.showModal);

  const formatIsoDateWithoutTimezone = (datetime: string | Date) => {
    const dateWithoutTimezone = new Date(datetime)
      .toLocaleDateString(getLocale(), { hour12: false })
      .split('/');
    const timeWithoutTimezone = new Date(datetime)
      .toLocaleTimeString(getLocale(), { hour12: false })
      .split(':');

    return `${dateWithoutTimezone[2]}-${dateWithoutTimezone[1]}-${dateWithoutTimezone[0]}T${timeWithoutTimezone[0]}:${timeWithoutTimezone[1]}:00.000Z`;
  };

  const handleChange = (event: MaterialUiPickersDate) => {
    if (!appointment) makeReduxSetShowModal().set({ appointment: true });

    setDate(event!.toISOString());
    setUpAvaiableTime();
    handleChangeEditConsult({
      key: 'date',
      value: event!.toISOString(),
    });
    // console.log(event);
    // makeReduxSetDate().set({ date: event!.toISOString() });
  };

  useEffect(() => {
    if (state.hourStart === '') {
      setStart('');
      setEnd('');
    }
  }, [state]);

  useEffect(() => {
    setUpDefaultValues();
  }, []);

  useEffect(() => {
    const tempTimes = setUpArrayTimes();
    setUpAvaiableTime();
    setupArrayTimesEnd(tempTimes);
  }, [dateIsoString]);

  // useEffect(() => {
  //   const found = times.find((item: typeArrayTime) => {
  //     return item.time === defaultStart;
  //   });

  //   const foundEnd = timesEnd.find((item: typeArrayTime) => {
  //     return item.time === defaultEnd;
  //   });

  //   if (!found) {
  //     handleChangeEditConsult({
  //       key: 'hourStart',
  //       value: '',
  //     });
  //   }

  //   if (!foundEnd) {
  //     handleChangeEditConsult({
  //       key: 'hourEnd',
  //       value: '',
  //     });
  //   }
  // }, [defaultEnd, defaultStart]);

  const setUpDefaultValues = () => {
    const timeStart = makeDuration(
      dateIsoString,
      state.duration!,
    ).dateStartIsoString;
    const timeEnd = makeDuration(
      dateIsoString,
      state.duration!,
    ).dateEndIsoString;

    setStart(formatIsoDateWithoutTimezone(timeStart));
    setEnd(formatIsoDateWithoutTimezone(timeEnd));
  };

  const setUpAvaiableTime = () => {
    console.log('appointments', appointments);
    appointments?.forEach(item => {
      // if the user is an organizer then an additional filter to
      // check if the professional or consultant has time blocking

      if (role === 'ORG') {
        const isProfessional = item.professional?.id === state.professional;
        const isConsultant = item.consultant?.id === state.consultant;

        if (!isProfessional && !isConsultant) {
          return;
        }
      }

      const dateFromAppointment = new Date(item.appointment.scheduled);
      const dateFromInput = new Date(dateIsoString);
      // filter dateInput and dateAppointment
      if (
        isEqual(
          new Date(
            dateFromAppointment.getUTCFullYear(),
            dateFromAppointment.getUTCMonth(),
            dateFromAppointment.getUTCDate(),
          ),
          new Date(
            dateFromInput.getUTCFullYear(),
            dateFromInput.getUTCMonth(),
            dateFromInput.getUTCDate(),
          ),
        )
      ) {
        const { dateStartIsoString, dateEndIsoString } = makeDuration(
          item.appointment.scheduled,
          item.appointment.duration,
        );

        setTimes(prevState =>
          prevState.map(el => {
            let dateAppointment = new Date(item.appointment.scheduled);
            const timeFromArray = new Date(el.time);

            dateAppointment = new Date(
              dateAppointment.getUTCFullYear(),
              dateAppointment.getUTCMonth(),
              dateAppointment.getUTCDate(),
              timeFromArray.getHours(),
              timeFromArray.getMinutes(),
            );
            dateAppointment.setHours(dateAppointment.getHours() + 3);
            dateAppointment.setMinutes(dateAppointment.getMinutes() + 15);

            // disable = false in current appointment and
            // status canceled || ended
            const isCurrentAppointment =
              item.appointment.id === Number(appointmentId);
            const isAvaibleAppointment =
              item.appointment.status === 'CANCELED' ||
              item.appointment.status === 'ENDED';
            if (isCurrentAppointment || isAvaibleAppointment) {
              return el;
            }

            const elDate = new Date(el.time);
            elDate.setHours(elDate.getHours() + 3);
            elDate.setMinutes(elDate.getMinutes() + 15);

            if (isBefore(elDate, new Date())) {
              return { ...el, disabled: true };
            }

            // Filter time from interval
            if (
              (isAfter(dateAppointment, new Date(dateStartIsoString)) ||
                isEqual(dateAppointment, new Date(dateStartIsoString))) &&
              isBefore(dateAppointment, new Date(dateEndIsoString)) &&
              !el.disabled
            ) {
              return { ...el, disabled: true };
            }
            return el;
          }),
        );
      }
    });
  };

  const setupArrayTimesEnd = (timesProps: typeArrayTime[]) => {
    const timeStart = makeDateAndDuration(
      state.hourStart!,
      state.hourEnd!,
      state.date!,
      state.duration,
    ).start;
    let breakMap = false;
    const timeEnd: typeArrayTime[] = [];

    const tempTimeStart = timeStart;

    const isInitialDate = isEqual(
      new Date(dateIsoString),
      new Date(initialDateIsoString),
    );

    if (tempTimeStart?.toISOString().includes('180Z') || !isInitialDate) {
      tempTimeStart?.setHours(timeStart.getHours() - 3);
    }

    const formattedDate = new Date(
      tempTimeStart?.toISOString().replace('180Z', '000Z'),
    );

    for (let i = 0; i < timesProps.length; i++) {
      if (isEqual(new Date(timesProps[i].time), formattedDate)) {
        breakMap = true;
      }

      if (
        breakMap &&
        isAfter(new Date(timesProps[i].time), formattedDate) &&
        !timesProps[i].disabled
      ) {
        timeEnd.push(timesProps[i]);
      }

      if (timesProps[i].disabled && breakMap) {
        // if exist appointment then add start time appointment in array timesEnd [SM]
        let lastTime = { ...timesProps[i] };
        lastTime = { ...lastTime, disabled: false };
        timeEnd.push(lastTime);
        break;
      }
    }

    setTimesEnd([...timeEnd]);
  };

  const setUpArrayTimes = (): typeArrayTime[] => {
    const time: typeArrayTime[] = [];
    let start = new Date(2020, 1, 1);
    let i = 0;
    let disabled = false;

    while (i <= 95) {
      const timeFormat = format(start, 'HH:mm');

      const date = new Date(dateIsoString.replace('180Z', '000Z'));
      date.setHours(Number(timeFormat.split(':')[0]));
      date.setMinutes(Number(timeFormat.split(':')[1]));

      if (isBefore(date, new Date())) {
        disabled = true;
      } else {
        disabled = false;
      }
      const obj = {
        disabled,
        time: formatIsoDateWithoutTimezone(date),
        timeFormat: format(start, 'HH:mm'),
      };
      time.push(obj);
      start = add(start, { minutes: 15 });
      i += 1;
    }
    setTimes([...time]);
    return [...time];
  };

  const onChangeTimesStart = (event: string) => {
    const timeStart = new Date(event);
    let breakFor = false;
    const timeEnd: typeArrayTime[] = [];

    for (let i = 0; i < times.length; i++) {
      if (isEqual(new Date(times[i].time), timeStart)) {
        breakFor = true;
      } else if (
        isAfter(new Date(times[i].time), timeStart) &&
        !times[i].disabled &&
        breakFor
      ) {
        timeEnd.push(times[i]);
      } else if (times[i].disabled && breakFor) {
        let lastTime = { ...times[i] };
        lastTime = { ...lastTime, disabled: false };
        timeEnd.push(lastTime);
        break;
      }
    }

    setTimesEnd([...timeEnd]);
  };

  const {
    register,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    console.debug('times: ', times);
    console.debug('timesEnd: ', timesEnd);
  }, [times, timesEnd]);

  return (
    <ContainerMain>
      <Container>
        <Controller
          render={({ value, onChange }) => (
            <InputKeyboardDate
              state={value}
              setState={(date: Date) => {
                if (dateIsoString !== date.toISOString()) {
                  handleChange(date);
                  onChange(date?.toISOString());
                }
              }}
              name="date"
              label={translator('Data do atendimento')}
              // error={Boolean(errors.date)}
              defaultValue={state.date}
              message={errors.date?.message}
              minDate={new Date()}
              disabled={role === 'CON'}
            />
          )}
          name="date"
          control={control}
          // ref={() => register('date')}
          // defaultValue={state.date}
          // minDate={new Date()}
        />

        <Select
          id="input_hourStart"
          width="140px"
          value={defaultStart}
          onChange={e => {
            if (!appointment)
              makeReduxSetShowModal().set({ appointment: true });

            onChangeTimesStart(e.target.value);
            setStart(e.target.value);
            setValue('hourEnd', '');
            setEnd('');
            handleChangeEditConsult({
              key: 'hourEnd',
              value: '',
            });
            handleChangeEditConsult({
              key: e.target.name,
              value: e.target.value,
            });
          }}
          register={() => register('hourStart')}
          error={Boolean(errors.hourStart)}
          message={
            errors?.hourStart?.message
              ? translator(errors?.hourStart?.message)
              : ''
          }
          label={labelTimeStart}
          required
          disabled={role === 'CON'}
          name="hourStart"
        >
          <option id="option_0" value="selectHour">
            {labelTimeStart}
          </option>
          {times.map((item: typeArrayTime, index) => (
            <option
              id={`option_${index}`}
              disabled={item.disabled}
              style={{
                backgroundColor: item.disabled ? '#C9C9C9' : '#fff',
              }}
              value={item.time}
            >
              {item.timeFormat}
            </option>
          ))}
        </Select>

        <Select
          label={labelTimeEnd}
          value={defaultEnd}
          width="140px"
          id="input_hourFinish"
          onChange={e => {
            if (!appointment)
              makeReduxSetShowModal().set({ appointment: true });

            setEnd(e.target.value);
            handleChangeEditConsult({
              key: e.target.name,
              value: e.target.value,
            });
          }}
          register={() => register('hourEnd')}
          name="hourEnd"
          error={Boolean(errors.hourEnd)}
          disabled={role === 'CON'}
          message={
            errors?.hourEnd?.message ? translator(errors?.hourEnd?.message) : ''
          }
          required
        >
          <option id="option_0" value="selectHour">
            {labelTimeEnd}
          </option>
          {timesEnd.map((item: typeArrayTime, index) => (
            <option id={`option_${index}`} value={item.time}>
              {item.timeFormat}
            </option>
          ))}
        </Select>
      </Container>
      {/* <ButtonSave
        action={() => console.log('teste')}
        disable={role === 'CON'}
      /> */}
    </ContainerMain>
  );
};

export default DateHour;
