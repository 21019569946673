import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAppointmentRecordingDownload as UsecaseRemoteGetAppointmentRecordingDownload } from '~/domain/usecases/appointment/remote';
import { RemoteGetAppointmentRecordingDownload } from '~/data/repository/appointment';

/**
 * send request via API.
 */

export const makeRemoteGetAppointmentRecordingDownload = (): UsecaseRemoteGetAppointmentRecordingDownload =>
  new RemoteGetAppointmentRecordingDownload(
    makeApiUrl('/appointments'),
    makeHttpClient(),
  );
