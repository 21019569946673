export enum SupportTypes {
  CREATE = '@supportDoc/CREATE',
  CREATE_SUCCESS = '@supportDoc/CREATE_SUCCESS',
  CREATE_FAILED = '@supportDoc/CREATE_FAILED',
  DELETE = '@supportDoc/DELETE',
  DELETE_SUCCESS = '@supportDoc/DELETE_SUCCESS',
  DELETE_FAILED = '@supportDoc/DELETE_FAILED',
  GET = '@supportDoc/GETALL',
  GET_SUCCESS = '@supportDoc/GETALL_SUCCESS',
  GET_FAILED = '@supportDoc/GETALL_FAILED',
  GETALL = '@supportDoc/GETALL',
  GETALL_SUCCESS = '@supportDoc/GETALL_SUCCESS',
  GETALL_FAILED = '@supportDoc/GETALL_FAILED',
  GET_BY_DOWNLOAD = '@suuportDoc/GET_BY_DOWNLOAD',
  GET_BY_DOWNLOAD_SUCESS = '@suuportDoc/GET_BY_DOWNLOAD_SUCESS',
  GET_BY_DOWNLOAD_FAILED = '@suuportDoc/GET_BY_DOWNLOAD_FAILED',
}
