import * as datefns from 'date-fns';
import { iAppointmentTypeState } from '~/domain/interfaces/models/AppointmentType';
import { AppointmentTypeTypes } from '~/domain/interfaces/redux/appointmentType/types';
import { AppointmentTypeActions } from './actions';

export const initialState: iAppointmentTypeState = {
  loading: false,
  data: [],
};

const reducer = (
  state = initialState,
  action: AppointmentTypeActions,
): iAppointmentTypeState => {
  switch (action.type) {
    case AppointmentTypeTypes.GET_ALL:
      return { ...state, loading: true };
    case AppointmentTypeTypes.GET_ALL_FAILED:
      return { ...state, loading: false };
    case AppointmentTypeTypes.GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
