import React from 'react';

import { Link as Clink } from '~/presentation/components/link';

export default function TestPage(): JSX.Element {
  return (
    <>
      {/* <Clink linkAppointment="http://www.google.com" /> */}
      <h1>hello</h1>
    </>
  );
}
