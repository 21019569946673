import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { PatchAppointmentConfirmation as UseCaseRemotePatchAppointmentConfirmation } from '~/domain/usecases/appointment/remote';
import { AppointmentConfirmation as UseCaseReduxPatchAppointmentConfirmation } from '~/domain/usecases/appointment/redux';

import { RemotePatchAppointmentConfirmation } from '~/data/repository/appointment/RemotePatchAppointmentConfirmation';
import { ReduxAppointmentConfirmation } from '~/data/store/reducer/appointment/usecases/ReduxPatchAppointmentConfirm';
/**
 * send request via API.
 */
export const makeRemotePatchAppointmentConfirmation = (): UseCaseRemotePatchAppointmentConfirmation =>
  new RemotePatchAppointmentConfirmation(
    makeApiUrl('/appointments/confirmation'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxPatchAppointmentConfirmation = (): UseCaseReduxPatchAppointmentConfirmation =>
  new ReduxAppointmentConfirmation();
