import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Content, NavContent, Nav } from './styles/StyledService';

import { EditConsult } from '~/presentation/components/editConsult';
import { NavigationBar } from '~/presentation/components/navigationBar';
import { Link } from '~/presentation/components/link';
import { translator } from '~/presentation/components/i18n';

interface iParams {
  appointmentId: string;
}

const EditingConsultant: React.FC = () => {
  const labels = [
    translator('Editar consulta'),
    translator('Links e Convidados'),
  ];
  const [select, setSelect] = useState<string>(labels[0]);
  const { appointmentId } = useLocation<iParams>().state;

  return (
    <Content>
      <NavContent>
        <Nav>
          <NavigationBar
            active={select}
            labels={labels}
            clickLabel={setSelect}
          />
        </Nav>
        {labels.indexOf(select) === 0 ? (
          <EditConsult />
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link />
        )}
      </NavContent>
    </Content>
  );
};

export default EditingConsultant;
