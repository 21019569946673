import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { iConsultant, iStore } from '~/domain/interfaces/models';
import { iListConsultant } from '../interface';

import { makeReduxMakeClinicalDoc } from '~/main/factories/usecases/clinicalDocs/MakeClinicalDocsFactory';

import { ownProps } from '../ExamRequestSideBar';

export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { consultant, clinicalDocs, professional } = useSelector(
      (state: iStore) => state,
    );

    Component.defaultProps = {
      consultant: _.orderBy(
        MapConsultant(consultant),
        item => item.firstName,
        'asc',
      ),
      setState: makeReduxMakeClinicalDoc(),
      clinicalDoc: clinicalDocs,
      professional,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * Map list o consultant to component.
 * @param consultant list of consultant.
 */
const MapConsultant = (consultant: iConsultant): iListConsultant[] => {
  const arr: iListConsultant[] = [];

  consultant.results.forEach(item => {
    arr.push({
      id: Number(item.consultant.id),
      userId: item.user.id,
      firstName: item.user.firstName,
      lastName: item.user.lastName,
      email: item.user.email,
      phone: item.user.phone,
    });
  });

  return arr;
};
