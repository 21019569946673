import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { makeSocket } from '~/main/factories/socket';
import { CreateSocketChatMessage as UsecaseSocketCreateChatMessage } from '~/domain/usecases/chat/socket';
import { CreateChatMessage as UsecaseRemoteCreateChatMessage } from '~/domain/usecases/chat/remote';
import {
  CreateChatMessage as UsecaseReduxCreateChatMessage,
  CreateSocketChatMessage as UsecaseReduxSocketCreateChatMessage,
} from '~/domain/usecases/chat/redux';

import { SocketCreateChatMessage } from '~/data/repository/socket/chat';
import { RemoteCreateChatMessage } from '~/data/repository/chat';
import {
  ReduxCreateChatMessage,
  ReduxSocketCreateChatMessage,
} from '~/data/store/reducer/chat/usecases';

export const makeSocketCreateChatMessage = (): UsecaseSocketCreateChatMessage =>
  new SocketCreateChatMessage('chatMessage', makeSocket());

export const makeRemoteCreateChatMessage = (): UsecaseRemoteCreateChatMessage =>
  new RemoteCreateChatMessage(
    makeApiUrl('/chats/{chatId}/messages'),
    makeHttpClient(),
  );

export const makeReduxCreateChatMessage = (): UsecaseReduxCreateChatMessage =>
  new ReduxCreateChatMessage();

export const makeReduxSocketCreateChatMessage = (): UsecaseReduxSocketCreateChatMessage =>
  new ReduxSocketCreateChatMessage();
