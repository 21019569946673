export enum ClinicalDocsTypes {
  CREATE = '@clinicalDocs/CREATE',
  CREATE_SUCCESS = '@clinicalDocs/CREATE_SUCCESS',
  CREATE_FAILED = '@clinicalDocs/CREATE_FAILED',

  CREATE_BY_EMAIL_SUCESS = '@clinicalDocs/CREATE_BY_EMAIL_SUCESS',
  CREATE_BY_EMAIL_FAILED = '@clinicalDocs/CREATE_BY_EMAIL_FAILED',
  CREATE_BY_EMAIL = '@clinicalDocs/CREATE_BY_EMAIL',

  MAKE_CLINICAL_DOC = '@clinicalDocs/MAKE_CLINICAL_DOC',
  MAKE_CLINICAL_DOC_CANCEL = '@clinicalDocs/MAKE_CLINICAL_DOC_CANCEL',
  MAKE_CLINICAL_DOC_SUBMIT = '@clinicalDocs/MAKE_CLINICAL_DOC_SUBMIT',
  MAKE_CLINICAL_DOC_SUBMIT_SUCCESS = '@clinicalDocs/MAKE_CLINICAL_DOC_SUBMIT_SUCCESS',

  DELETE = '@clinicalDocs/DELETE',
  DELETE_SUCCESS = '@clinicalDocs/DELETE_SUCCESS',
  DELETE_FAILED = '@clinicalDocs/DELETE_FAILED',

  GETALL = '@clinicalDocs/GETALL',
  GETALL_SUCCESS = '@clinicalDocs/GETALL_SUCCESS',
  GETALL_FAILED = '@clinicalDocs/GETALL_FAILED',

  GET_BY_DOWNLOAD = '@clinicalDocs/GET_BY_DOWNLOAD',
  GET_BY_DOWNLOAD_SUCESS = '@clinicalDocs/GET_BY_DOWNLOAD_SUCESS',
  GET_BY_DOWNLOAD_FAILED = '@clinicalDocs/GET_BY_DOWNLOAD_FAILED',

  GET_BY_ID = '@clinicalDocs/GET_BY_ID',
  GET_BY_ID_SUCCESS = '@clinicalDocs/GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILED = '@clinicalDocs/GET_BY_ID_FAILED',

  UPDATE = '@clinicalDocs/UPDATE',
  UPDATE_SUCCESS = '@clinicalDocs/UPDATE_SUCCESS',
  UPDATE_FAILED = '@clinicalDocs/UPDATE_FAILED',

  SIGN = '@clinicalDocs/SIGN',
  SIGN_SUCCESS = '@clinicalDocs/SIGN_SUCCESS',
  SIGN_FAILED = '@clinicalDocs/SIGN_FAILED',

  UPDATE_BY_SIGN = '@clinicalDocs/UPDATE_BY_SIGN',
  UPDATE_BY_SIGN_SUCESS = '@clinicalDocs/UPDATE_BY_SIGN_SUCESS',
  UPDATE_BY_SIGN_FAILED = '@clinicalDocs/UPDATE_BY_SIGN_FAILED',

  SELECT_ONE = '@clinicalDocs/SELECT_ONE',
  SELECT_ONE_SUCCESS = '@clinicalDocs/SELECT_ONE_SUCCESS',

  INSERT_CLINICAL_DOC = '@clinicalDocs/INSERT_CLINICAL_DOC',
}
