import { array, literal, object, string } from 'zod';

export const schemaContent = object({
  drugs: array(
    object({
      name: string().nonempty(),
      dosage: string().nonempty(),
      posology: string().nonempty(),
    }),
  ),
});

export const schemaPrescription = object({
  name: string().nonempty({ message: 'Insira o nome da droga' }),
  dosage: string().nonempty({ message: 'Insira a dosagem' }),
  posology: string().nonempty({ message: 'Insira a posologia da medicação' }),
});

const schema = object({
  drugs: array(
    object({
      name: string().nonempty(),
      dosage: string().nonempty(),
      posology: string().nonempty(),
    }),
  ),
});

export default schema;
