import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

export const Table = styled.div`
  width: 40%;
  background: #fbfbfb;
  border-radius: 5px;
  border: 1px solid #efefef;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
`;

export const Content = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 13px;
  height: 14px;
  margin-bottom: 10px;
  color: #a7a7a7;
`;

const widthVariant = css`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 14px;
  color: #7a7a7a;
`;

export const Description = styled.h3`
  ${widthVariant}
`;

export const Role = styled.h3`
  ${widthVariant}
  padding-left: 40%;
`;

export const Actions = styled.h3`
  ${widthVariant}
  padding-left: 65%;
`;

export const ButtonStyle = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
`;
