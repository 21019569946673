import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 440px;
  display: flex;
  overflow-y: scroll;
  cursor: pointer;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
