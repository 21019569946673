import { Tooltip } from '@material-ui/core';
import React from 'react';
import {
  IconCancelOutline,
  IconSignedDocuments,
  IconNotSignedDocument,
} from '~/presentation/base/icons';
import {
  DocumentStatus,
  ScheduleStatus,
} from '~/presentation/components/statusList';
import { Name } from '~/presentation/components/interConsultaListParticipant/styles/StyledItemParticipatingProfessionals';

import {
  Container,
  Avatar,
  Status,
  Img,
  Actions,
  Cancel,
  Delete,
  Person,
  Wrapper,
  Details,
  ShareEmail,
} from './styles/StyledUserItem';

interface UserListProps {
  list?: string;
  cod?: string;
  professional?: string;
  specialty?: string;
  participant?: string;
  avatar?: string;
  cpf?: string;
  time?: string;
  date?: string;
  email?: string;
  name?: string;
  phone?: string;
  status?: 'AVAILABLE' | 'ENDED' | 'CANCELED' | 'INPROGRESS' | 'SCHEDULED';
  status2?: string;
  timeStart?: string;
  timeEnd?: string;
  typeIs?: string;
  register?: string;
  origin?: string;
  statusSignature?: 'SIGNED' | 'SUBMITTED' | 'NOTREQUESTED';
  isOk?: boolean;
  clinicalDocument?: string;
  age?: string;
  actions?: {
    details?: Boolean;
    cancel?: Boolean;
    person?: Boolean;
    delete?: Boolean;
    shareEmail?: Boolean;
  };
}

const UserItemAdm: React.FC<UserListProps> = ({
  cod,
  date,
  list = 'list-1',
  email,
  phone,
  status,
  timeStart,
  timeEnd,
  typeIs,
  time,
  age,
  register,
  name,
  cpf,
  participant,
  specialty,
  origin,
  professional,
  clinicalDocument,
  statusSignature,
  actions,
}) => (
  <Container list={list}>
    {cod !== undefined && (
      <div>
        <text>{cod}</text>
      </div>
    )}

    {date !== undefined && (
      <div>
        <text>{date}</text>
      </div>
    )}

    {participant !== undefined && (
      <Avatar>
        <Img />
        <text>{`${participant}`}</text>
      </Avatar>
    )}
    {professional !== undefined && (
      <Avatar>
        <Img />
        <text>{professional}</text>
      </Avatar>
    )}
    {name !== undefined && (
      <Avatar>
        <Img />
        <text>{name}</text>
      </Avatar>
    )}

    {cpf !== undefined && (
      <div>
        <text>{cpf}</text>
      </div>
    )}

    {statusSignature !== undefined && (
      <DocumentStatus status={statusSignature} />
    )}

    {age !== undefined && (
      <div>
        <text>{age}</text>
      </div>
    )}

    {timeStart !== undefined && (
      <div>
        <text>{timeStart}</text>
      </div>
    )}

    {timeEnd !== undefined && (
      <div>
        <text>{timeEnd}</text>
      </div>
    )}
    {specialty !== undefined && (
      <div>
        <text>{specialty}</text>
      </div>
    )}

    {time !== undefined && (
      <div>
        <text>{time}</text>
      </div>
    )}

    {origin !== undefined && (
      <div>
        <text>{origin}</text>
      </div>
    )}

    {clinicalDocument !== undefined && (
      <div>
        <text>{clinicalDocument}</text>
      </div>
    )}

    {typeIs !== undefined && (
      <div>
        <text>{typeIs}</text>
      </div>
    )}

    {/* {status !== undefined && (
      <Status>
        <text>{status}</text>
      </Status>
    )} */}

    {status !== undefined && (
      <div>
        <ScheduleStatus status={status} />
      </div>
    )}

    {register !== undefined && (
      <div>
        <text>{register}</text>
      </div>
    )}

    {phone !== undefined && (
      <div>
        <text>{phone}</text>
      </div>
    )}

    {email !== undefined && (
      <div>
        <text>{email}</text>
      </div>
    )}

    <div>
      <Actions>
        <Tooltip title="Cancelar atendimento" placement="top" arrow>
          <div>{actions?.cancel !== undefined && <Cancel />}</div>
        </Tooltip>
        <Tooltip title="Ver perfil" placement="top" arrow>
          <div>{actions?.person !== undefined && <Person />}</div>
        </Tooltip>
        <Tooltip title="Excluir usuário" placement="top" arrow>
          <div>{actions?.delete !== undefined && <Delete />}</div>
        </Tooltip>
        <Tooltip title="Detalhes" placement="top" arrow>
          <div>{actions?.details !== undefined && <Details />}</div>
        </Tooltip>
        <Tooltip title="Compartilhar por email" placement="top" arrow>
          <div>{actions?.shareEmail !== undefined && <ShareEmail />}</div>
        </Tooltip>
      </Actions>
    </div>
  </Container>
);

export default UserItemAdm;
