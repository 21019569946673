import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iGuestData, User } from '~/domain/interfaces/models/GuestData';

import WaitingGuest from '~/presentation/roles/guest/pages/WaitingRoomGuest/WaitingGuest';
import ReadyGuest from '~/presentation/roles/guest/pages/WaitingRoomGuest/ReadyGuest';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { History } from '~/main/routes';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface locationParams {
  guestData?: iGuestData;
}

const WaitingRoom: React.FC = () => {
  const params = useLocation<locationParams>().state;

  if (!params) History.getHistory().push('/guest');

  const [users, setUsers] = useState<User[]>([]);
  const [state, setState] = useState(1);
  const [waitingRoomUserId, setRoomUserId] = useState(undefined);

  const appointmentId = params?.guestData?.appointment.id;
  const name = params?.guestData?.guest?.identification?.fullName;
  const socketGuest = socketIOClient(window.config.connection.backendUrl);

  useEffect(() => {
    console.log('>>> Conectando... ', appointmentId);

    socketGuest.on('notification', (data: any) => {
      console.log('>>> data', data);

      switch (data.data.status) {
        case 'JOINED':
          // TODO: Criar Remote para requisição de buscar usuários na Waiting Room
          axios
            .get(
              `${window.config.connection.backendUrl}/api/appointments/${appointmentId}/waitingRooms`,
            )
            .then(res => {
              if (res.data) setUsers(res.data);
            })
            .catch(err => {
              AlertMessage({
                message: intl.formatMessage({
                  id: 'Não foi possível obter informações da sala de espera, tente novamente',
                }),
                type: 'danger',
              });
            });
          break;
        case 'LEFT':
          setUsers([]);
          setState(1);
          break;
        default:
          break;
      }
    });

    socketGuest.emit(
      'joinWaitingRoom',
      {
        appointmentId,
        data: {
          name,
          role: 'CON',
        },
      },
      (err: any, data: any) => {
        setRoomUserId(data.waitingRoomUserId);
      },
    );

    return () => {
      socketGuest.off('notification');
    };
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      leaveWaitingRoom();
      window.onpopstate = null;
    };
  }, [waitingRoomUserId]);

  const leaveWaitingRoom = useCallback(() => {
    if (waitingRoomUserId !== undefined) {
      socketGuest.emit(
        'leftWaitingRoom',
        {
          appointmentId,
          waitingRoomUserId,
        },
        (err: any, data: any) => {
          console.log(err);
          console.log(data);
        },
      );
    }
  }, [waitingRoomUserId]);

  useEffect(() => {
    if (users.length >= 2) {
      users.map(user => user.role === 'PRO' && setState(2));
    } else {
      setState(1);
    }
  }, [users]);

  if (!params) return null;

  return state === 1 ? <WaitingGuest /> : <ReadyGuest />;
};

export default WaitingRoom;
