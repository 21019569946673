import React from 'react';
import { AnyAction, combineReducers } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import omit from 'lodash/omit';
import storage from 'redux-persist/lib/storage';
// import { initialState } from '~/data/store/reducer/auth';
import supportDoc from './supportDoc';
import appointment from './appointment';
import appointmentType from './appointmentType';
import menu from './menu';
import auth from './auth';
import users from './users';
import dataPagination from './dataPagination';
import message from './message';
import specialty from './specialty';
import professional from './Professional';
import consultant from './Consultant';
import organizer from './organizer';
import dashboard from './dashboard';
import billing from './billing';
import administrator from './administrator';
import permissions from './permissions';
import clinicalDocs from './clinicalDocs';
import professions from './profession';
import waitingroom from './waitingroom';
import chat from './chat';
import notifications from './Notifications';
import selectedOrg from './selectedOrg';
import showModal from './showModal';
import orgUnit from './orgUnit';
import { iActionLogout } from '~/domain/interfaces/redux/auth/Logout';

const reducer = combineReducers({
  appointment,
  appointmentType,
  menu,
  auth,
  users,
  message,
  specialty,
  professional,
  consultant,
  supportDoc,
  permissions,
  clinicalDocs,
  professions,
  waitingroom,
  chat,
  notifications,
  selectedOrg,
  dataPagination,
  showModal,
  organizer,
  administrator,
  dashboard,
  orgUnit,
  billing,
});

// export type RootState = ReturnType<typeof rootReducer>;

// const store = createStore(reducer);

interface iLogout {
  logoutApplication: iActionLogout;
  state: any;
}

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === '@auth/LOGOUT') {
    storage.removeItem('persist:reducers');
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

// Blacklist specific property to not persist on LocalStorage
const blacklistProperty = (blacklistPaths: Array<string>) => {
  return createTransform((inboundState: any, key) => {
    const blacklistPathsForKey = blacklistPaths
      .filter(path => path.startsWith(`${String(key)}.`))
      .map(path => path.substr(String(key).length + 1));

    return omit(inboundState, ...blacklistPathsForKey);
  }, null);
};

const authPersistConfig = {
  key: 'reducers',
  storage,
  blacklist: ['message', 'showModal'],
  transforms: [
    blacklistProperty([
      'auth.loading',
      'auth.errorCount',
      // 'appointment.date',
      'appointment.loading',
      'chat.loading',
      'clinicalDocs.loading',
      'notifications.loading',
      'permissions.loading',
      'professions.loading',
      'specialty.loading',
      'supportDoc.loading',
      'users.loading',
    ]),
  ],
};

export default persistReducer(authPersistConfig, rootReducer);
