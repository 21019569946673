import { takeLatest } from 'redux-saga/effects';
import * as Login from './Login';
import * as Refresh from './Refresh';
import * as Acl from './GetACL';
import { AuthTypes } from '~/domain/interfaces/redux/auth';

const tasks = [
  takeLatest(AuthTypes.LOGIN, Login.onLogin),
  takeLatest(AuthTypes.LOGIN_SUCCESS, Login.onLoginSuccess),
  takeLatest(AuthTypes.LOGIN_FAILED, Login.onLoginFailed),

  takeLatest(AuthTypes.GET_ACL, Acl.onAuthGetACL),
  takeLatest(AuthTypes.GET_ACL_SUCCESS, Acl.onAuthGetACLSuccess),
  takeLatest(AuthTypes.GET_ACL_FAILED, Acl.onAuthGetACLFailed),

  takeLatest(AuthTypes.REFRESH, Refresh.onRefresh),
  takeLatest(AuthTypes.REFRESH_SUCCESS, Refresh.onRefreshSuccess),
  takeLatest(AuthTypes.REFRESH_FAILED, Refresh.onRefreshFailed),
];

export default tasks;
