import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* @media (max-height: 586px) {
    overflow-y: auto;
  } */

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }

  .message {
    margin-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }

  .spin {
    margin-bottom: 16px;
  }

  button {
    margin-top: 16px;
    max-width: 282px;
  }
`;

export const Header = styled.div`
  margin-bottom: 32px;
  margin-top: 10px;
`;

export const Image = styled.img`
  margin-right: 2px;
`;
