import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
`;

export const NewAppointment = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #7a7a7a;
    text-align: center;
  }
  :hover {
    background: #efefef;
  }
`;

export const Interconsult = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  a {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #7a7a7a;
    text-align: center;
  }
  :hover {
    background: #efefef;
  }
`;
