import React from 'react';

import { WritingArea } from '.';
import { ContainerNotes } from './styles/StyledInfoNotes';

interface NotesProps {
  sidebar?: boolean;
  appointmentId?: number;
  handleSavingLoading?: (loading: boolean) => void;
}

const Notes: React.FC<NotesProps> = ({
  sidebar,
  appointmentId,
  handleSavingLoading,
}) => {
  return (
    <ContainerNotes>
      <WritingArea
        sidebar={sidebar}
        appointmentId={appointmentId}
        handleSavingLoading={handleSavingLoading}
      />
    </ContainerNotes>
  );
};

export default Notes;
