import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { iStore } from '~/domain/interfaces/models';
import {
  iListConsultant,
  iListHealthPlan,
  iListProfessional,
  iListSpeciality,
} from '../interface';

import { ownProps, externalProps } from '../GeneralData';

import { iConsultant } from '~/domain/interfaces/models/Consultant';
import { makeRemoteGetAllProfession } from '~/main/factories/usecases/profession';
import { makeRemoteGetHealthPlan } from '~/main/factories/usecases/healthPlan/GetHealthPlan';
import { makeReduxAddAppointmentData } from '~/main/factories/usecases/appointment/AddAppointmentDataFactory';

/**
 * Connect component.
 * @param Component componente to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P & externalProps> {
  const Render: React.FC<P & externalProps> = ({ ...rest }) => {
    const { consultant, professional, specialty, professions } = useSelector(
      (state: iStore) => state,
    );

    Component.defaultProps = {
      consultant: _.orderBy(
        MapConsultant(consultant),
        item => item.firstName,
        'asc',
      ),
      specialty: specialty.results,
      handlerState: makeReduxAddAppointmentData(),
      professionals: professions,
      filterProfessional: (id: number | undefined) =>
        _.orderBy(
          filterProfessional(id, professional.results),
          item => item.user.firstName,
          'asc',
        ),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps & externalProps)} />;
  };

  return Render;
}

/**
 * Map list o consultant to component.
 * @param consultant list of consultant.
 */
export const MapConsultant = (consultant: iConsultant): iListConsultant[] => {
  const arr: iListConsultant[] = [];

  consultant.results.forEach(item => {
    arr.push({
      id: item.consultant.id,
      userId: item.user.id,
      firstName: item.user.firstName,
      lastName: item.user.lastName,
      email: item.user.email,
      phone: item.user.phone,
    });
  });

  return arr;
};

/**
 * filter professional based specialty ID.
 * @param id specialty ID.
 * @param professionals list of professional.
 */
const filterProfessional = (
  id: number | undefined,
  professionals: iListProfessional[],
): iListProfessional[] => {
  const arr: iListProfessional[] = professionals.filter(prof => {
    let hasSpecialty = false;

    prof.specialties.forEach(specialty => {
      if (Number(specialty.id) === Number(id)) hasSpecialty = true;
    });

    return hasSpecialty;
  });

  return arr;
};
