import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

import { SessionInformation, InfoUser, Actions, InfoUserVariant } from '.';
import { Container } from './styles/StyledCard';
import { SessionData, UserData, iParticipants, iUser } from './interface';

interface ownProps {
  user?: iParticipants;
  session?: SessionData;
}

const Card: React.FC<ownProps> = ({ user, session }) => {
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  return (
    <Container>
      <SessionInformation session={session} />
      {role === 'ORG' ? (
        <InfoUserVariant participants={user} />
      ) : (
        <InfoUser user={user} />
      )}
      {session?.status === 'ENDED' ? null : (
        <Actions status={session?.status} cancelDate={session?.cancelDate} />
      )}
    </Container>
  );
};

export default Card;
