import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ownProps } from '../index';

import { makeReduxLeftWaitingRoom } from '~/main/factories/usecases/waitingRoom/LeftWaitingRoomFactory';
import { iStore } from '~/domain/interfaces/models/Store';

interface iStateParams {
  appointmentId: number;
}

export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const user = useSelector((store: iStore) => store.auth);
    const { appointmentId } = useLocation<iStateParams>().state;
    const { rooms, data } = useSelector((store: iStore) => store.waitingroom);

    Component.defaultProps = {
      ActionRoom: makeReduxLeftWaitingRoom(),
      notification: data,
      waitingRoom: { room: rooms[appointmentId] },
      user,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}
