import styled from 'styled-components';

export const Header = styled.div`
  width: 65%;
  margin-top: 50px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 20px;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Body = styled.div`
  padding: 15px;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
`;

export const Footer = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
`;

export const TitlePrescription = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.160976px;

    color: #004ba5;
  }
`;
