import React from 'react';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { translator } from '../../i18n';
import { Button } from '../../UI';
import { ownProps } from '../interface';
import {
  Body,
  ButtonsDiv,
  CloseButton,
  Container,
  Header,
  Text,
} from './styles/StyledExcludeMessage';

const DeleteMessage: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.delete;

  const { active, actionCancel, actionOk, title, content } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body content={Boolean(content)}>
            <Header>
              <span>{title}</span>
              <CloseButton onClick={actionCancel}>X</CloseButton>
            </Header>
            {content && <Text>{content}</Text>}
            <ButtonsDiv content={Boolean(content)}>
              <Button
                id="btn_cancel"
                rounded
                variant="primary"
                size="large"
                onClick={actionCancel}
                autoFocus
              >
                {translator('Não excluir')}
              </Button>
              <Button
                id="btn_confirm"
                rounded
                variant="secundary"
                size="large"
                onClick={actionOk}
                color="red"
              >
                {translator('Sim, excluir')}
              </Button>
            </ButtonsDiv>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default DeleteMessage;
