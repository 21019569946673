import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  textarea {
    max-width: 950px;
    min-height: 184px;
    width: 100%;
    resize: none;
    padding: 10px;
    border: 1px solid #c7cbd1;
    box-sizing: border-box;
    border-radius: 3px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    color: #444a51;
  }
`;

export const TitleDoc = styled.div`
  width: 100%;
  display: flex;
  height: 46px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background: #f4f7fe;
  color: #004ba5;
`;

export const Phases = styled.div`
  width: 100%;
  padding: 15px;
`;

export const Form = styled.form`
  width: 100%;
  overflow-y: auto;
  max-height: 700px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px 0px 15px;
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0px 15px 0px 15px;
`;
