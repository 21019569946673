import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MeetDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  #meet {
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
`;
