import React, { useState } from 'react';
import {
  ContainerChooseDocuments,
  ContentInputs,
  ButtonAdd,
  ContainerLeft,
  ContainerRight,
  ListPrescription,
  Dosage,
  Drug,
  Posology,
} from './styles/StyledPrescription';

import { Input, Button } from '~/presentation/components/UI';

import { ItemPrescription } from '~/presentation/components/specialPrescription';
import { iPrescription } from './interface';

import { translator } from '~/presentation/components/i18n';

const Prescription: React.FC = () => {
  const [prescription, setPrescription] = useState<iPrescription[]>([]);
  const [state, setState] = useState<iPrescription>({
    drug: '',
    dosage: '',
    posology: '',
  });

  function handleAdd() {
    setPrescription([...prescription, state]);
    setState({
      drug: '',
      dosage: '',
      posology: '',
    });
  }

  return (
    <ContainerChooseDocuments>
      <ContainerLeft>
        <ContentInputs>
          <Drug>
            <Input
              label="Droga"
              placeholder="Inserir Medicação"
              value={state.drug}
              onChange={e => setState({ ...state, drug: e.target.value })}
            />
          </Drug>
          <Dosage>
            <Input
              label="Dosagem"
              placeholder="Inserir Dosagem"
              value={state.dosage}
              onChange={e => setState({ ...state, dosage: e.target.value })}
            />
          </Dosage>
          <Posology>
            <Input
              label="Posologia"
              placeholder="Inserir Posologia"
              value={state.posology}
              onChange={e => setState({ ...state, posology: e.target.value })}
            />
          </Posology>
        </ContentInputs>
        <ButtonAdd>
          <p>
            Após preencher os campos, clique em “Adicionar” para incluir a
            medicação na prescrição da receita.
          </p>
          <Button
            id="btn_addDrug"
            size="medium"
            rounded
            variant="secundary"
            color="blue"
            onClick={handleAdd}
          >
            {'Adicionar >>'}
          </Button>
        </ButtonAdd>
      </ContainerLeft>
      <ContainerRight>
        <h2>Drogas Inseridas</h2>
        <ListPrescription>
          <ItemPrescription content={prescription} />
        </ListPrescription>
      </ContainerRight>
    </ContainerChooseDocuments>
  );
};

export default Prescription;
