import React from 'react';

/* import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Container } from './styles/StyledCalendar'; */

const CalendarPage = () => {
  // const selectDate = (date: any) => {};

  return (
    <div>
      {/* <MuiPickersUtilsProvider utils={DayjsUtils}>
        <Calendar date={dayjs()} onChange={selectDate} />
      </MuiPickersUtilsProvider> */}
    </div>
  );
};

export default CalendarPage;
