import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
// import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import { Button } from '~/presentation/components/UI/buttons';
import { defaultTheme } from '~/presentation/base/themes';
import { IconCloseButtonModal } from '~/presentation/base/icons';
import { Body, Container } from './styles/StyledDropdown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    root: {
      width: '100%',
      maxWidth: 550,
      backgroundColor: defaultTheme.background,
    },
  }),
);
interface PopOver {
  children: JSX.Element[] | JSX.Element;
  icon: JSX.Element;
  label?: string;
}
const Dropdown: React.FC<PopOver> = ({ children, icon, label }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container open={open}>
      <Button height="20px" size="small" id="btn_filter" onClick={handleClick}>
        {/* <Img  src = {notificationIcon}/> */}
        {icon}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'center',
        }}
      >
        <Body>
          {label && (
            <button type="button" onClick={handleClose}>
              {label}
            </button>
          )}
          <button id="close_filter" type="button" onClick={handleClose}>
            <IconCloseButtonModal />
          </button>
        </Body>
        <List className={`${classes.root}`}>{children}</List>
      </Popover>
    </Container>
  );
};

export default Dropdown;
