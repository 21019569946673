import React from 'react';

import { Action, InfoUser } from '.';
import { Container } from './styles/StyledCard';
import { UserData, SessionData } from './interface';
import { SessionInformation } from '~/presentation/components/sessionInformation';

interface ownProps {
  user?: UserData;
  session?: SessionData;
}

const Card: React.FC<ownProps> = ({ user, session }) => {
  return (
    <Container>
      <SessionInformation session={session} />
      <InfoUser user={user} />
      {session?.status === 'ENDED' ? null : (
        <Action status={session?.status} cancelDate={session?.cancelDate} />
      )}
    </Container>
  );
};

export default Card;
