import * as datefns from 'date-fns';
import { makeReduxGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';
import { iUser } from '~/domain/interfaces/models/Auth';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';

interface iFilter {
  professional?: number;
  professionalName?: string;
  consultant?: number;
  consultantName?: string;
  type?: string;
  hasDocClin?: boolean;
  hasDocSup?: boolean;
  status?: string;
  scheduleFrom?: string;
  scheduleTo?: string;
  org?: number;
}

export const getAppointmentsByDate = (
  date: string,
  userLogged: iUser,
  filter?: iFilter,
) => {
  const store: iStore = storeDev.getState();
  const { orgId, role, orgUnitId } = store.auth.selectUser;

  const dateInit = new Date(date);
  const scheduleFrom = datefns.format(dateInit, 'yyyy-MM-dd');
  const scheduleTo = datefns.format(datefns.addDays(dateInit, 2), 'yyyy-MM-dd');

  const professionalId = userLogged.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  const consultantId = userLogged.consultants?.find(
    item => item.org?.id === orgId,
  )?.id;

  filter = {
    scheduleFrom,
    scheduleTo,
    org: orgId,
    ...filter,
  };

  switch (role) {
    case 'CON':
      makeReduxGetAllAppointment().getAll({
        filter: {
          consultant: consultantId,
          ...filter,
        },
      });
      break;

    case 'PRO':
      makeReduxGetAllAppointment().getAll({
        filter: {
          professional: professionalId,
          ...filter,
        },
      });
      break;

    case 'ORG':
    case 'ADM':
      makeReduxGetAllAppointment().getAll({
        filter,
      });
      break;

    default:
      break;
  }
};
