import React, { useEffect, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { useSelector } from 'react-redux';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  Content,
  Container,
  SignedAfter,
  MessageCertificate,
  SignedNow,
  ContentSignDoc,
} from './styles/StyledSignature';

import { Button, Select, Input } from '~/presentation/components/UI';

import { translator } from '~/presentation/components/i18n';

import { MakeRemoteGetCertificates } from '~/main/factories/usecases/daemon/GetCertificates';
import { GetCertificates } from '~/domain/usecases/deamon/remote';

import { makeReduxSignClinicalDoc } from '~/main/factories/usecases/clinicalDocs/SignClinicalDocs';
import { V4hSpin } from '../spin';
import { iStore } from '~/domain/interfaces/models';
import { AlertMessage } from '../messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface ownProps {
  changeSelected?: (
    // {
    //   certificateId: number;
    //   certificate: string;
    // }
    data: any,
  ) => any;
  docId?: number;
  appointmentId?: number;
  changeDisabled?: (data: boolean) => any;
  onSignSubmit?: () => any;
  isSigned?: boolean;
}

const SignDoc: React.FC<ownProps> = ({
  changeSelected,
  docId,
  appointmentId,
  changeDisabled,
  onSignSubmit,
  isSigned,
}): JSX.Element => {
  const [certificates, setCertifaces] = useState<GetCertificates.Model>([]);
  const [selected, setSelected] = useState<number>(-1);
  const [loadCertificate, setLoading] = useState<boolean>(false);
  const assign = useSelector((store: iStore) => store.clinicalDocs.loading);
  const [check, setCheck] = useState<boolean>(!!docId);

  useEffect(() => {
    setLoading(assign);
  }, [assign]);

  useEffect(() => {
    if (changeDisabled) {
      // * Se assinar agora estiver selecionado
      if (check) {
        // * Se um certificado estiver selecionado.
        if (selected !== -1) {
          changeDisabled(false);
        } else {
          changeDisabled(true);
        }
      } else {
        changeDisabled(false);
      }
    }
  }, [check, selected]);

  useEffect(() => {
    if (!isSigned) {
      setLoading(true);
      MakeRemoteGetCertificates()
        .getCertificates()
        .then(response => {
          console.log('ledger: ', response);
          setCertifaces(response);
          setLoading(false);
        })
        .catch(e => {
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possivel encontrar nenhum certificado',
            }),
            type: 'info',
          });
          setLoading(false);
        });
    }
  }, [isSigned]);

  const onSubmit = () => {
    makeReduxSignClinicalDoc().sign({
      certificate: certificates[selected].base64Certificate,
      certificateId: certificates[selected].id,
      docId,
      appointmentId,
    });
  };

  const textButton = translator('Assinar Documento');

  return (
    <Container>
      <ContentSignDoc>
        <Content>
          <p>{`${translator('Certificado Digital')}`}</p>
          <Select
            label={`${translator('Certificado Digital')}`}
            value={selected}
            disabled={loadCertificate}
            onChange={e => {
              setSelected(Number(e.target.value));
              if (changeSelected)
                changeSelected({
                  certificateId: certificates[Number(e.target.value)].id,
                  certificate:
                    certificates[Number(e.target.value)].base64Certificate,
                });
            }}
          >
            <option value="-1" disabled>
              {`${translator('Selecione um certificado')}`}
            </option>
            {certificates.map((e, index) => (
              <option value={index}>{e.name}</option>
            ))}
          </Select>
        </Content>
        {!loadCertificate ? (
          <Button
            size="medium"
            rounded
            onClick={onSignSubmit}
            disabled={selected === -1}
          >
            {textButton}
          </Button>
        ) : (
          <V4hSpin fullScreen={false} size="40px" />
        )}
      </ContentSignDoc>
    </Container>
  );
};

export default SignDoc;
