import styled from 'styled-components';

export const DropContainer = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: height 0.2s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.313rem;
  object-fit: contain;

  gap: 10px;
  font-size: 15px;
  text-align: center;
  text-overflow: ellipsis;
  color: #b9b9b9;

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 16px;
  }
`;

export const DropInput = styled.div`
  p {
    color: #1756e8;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
`;

export const RemoveImage = styled.button`
  background-color: transparent;
  border: none;

  display: flex;
  flex-direction: row;
  margin-bottom: 260px;
  margin-left: 220px;
  position: absolute;
  z-index: 1;

  font-size: 24px;
  font-weight: bolder;
  color: red;
  cursor: pointer;
`;
