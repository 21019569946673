import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { iListRegisteredManager } from '../interface';
import { pageProps } from '~/presentation/roles/manager/pages/ListManager';
import { iStore, iUsers } from '~/domain/interfaces/models';
import { iOrganizer } from '~/domain/interfaces/models/Organizer';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectManager<P>(
  Component: ComponentType<P & pageProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const manager = useSelector((store: iStore) => store.organizer);
    const { user } = useSelector((store: iStore) => store.auth.info);

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      // Default descending order for date
      data: _.orderBy(
        mapManagerData(manager, user?.id),
        item => {
          const splitDate = item.date.split('/');
          const internationalDate =
            splitDate.length < 3
              ? item.date
              : `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;

          return new Date(internationalDate).getTime();
        },
        ['desc'],
      ),
      ...(rest as P),
    };

    return <Component {...(rest as P & pageProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param appointment data.
 */
const mapManagerData = (
  manager: iOrganizer,
  userId?: number,
): iListRegisteredManager[] => {
  const arr: iListRegisteredManager[] = [];
  if (!(manager.results.length > 0)) return arr;

  manager.results.forEach(item => {
    const date = item.organizer.created.split('T')[0].split('-');
    const dateFormated = `${date[2]}/${date[1]}/${date[0]}`;

    if (!userId || userId !== item.user.id)
      arr.push({
        avatar: item.user.avatar,
        cod: item.organizer.id.toString(),
        date: dateFormated,
        fullName: `${item.user.firstName} ${item.user.lastName}`,
        cpf: item.user.cpf,
        phone: item.user.phone,
        email: item.user.email,
        userId: item.user.id,
      });
  });

  return arr;
};
