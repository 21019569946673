import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { differenceInMinutes } from 'date-fns';
import DateAndTime, { typeArrayTime } from './DateAndTime';
import GeneralData from './GeneralData';
import Guest from './Guest';
import { iRegisterAppointment } from './interface';
import { StepMagic, Step } from '~/presentation/components/steps';
import { translator } from '~/presentation/components/i18n';
import { Container, Header, Body } from './styles/StyledRegiterAppointment';

import { CreateAppointment as ReduxCreateAppointment } from '~/domain/usecases/appointment/redux/CreateAppointment';
import { CreateAppointment as RemoteCreateAppointment } from '~/domain/usecases/appointment/remote/CreateAppointment';

import { ConnectComponent } from './mapper/MapperRegisterAppointment';

import { schema } from '~/validation/validators/appointment/CreateAppointmentValidator';

import { iStore } from '~/domain/interfaces/models';
import { RemoteGetHealthPlan } from '~/data/repository/healthPlan/RemoteGetHealthPlan';
import { GetHealthPlan } from '~/domain/usecases/healthPlan/Remote';
// import Professionals from '../interConsulta/Professionals';
import Professionals from './Professionals';
import { V4hSpin } from '../spin';

type HealthPlanProps = {
  id: number;
};

export interface ownProps {
  appointment: RemoteCreateAppointment | ReduxCreateAppointment;
  userId: number;
  getTimes: (e: string) => typeArrayTime[];
}

interface iParams {
  consultant: string;
  old?: string;
}

const NewSchedule: React.FC<ownProps> = props => {
  const auth = useSelector((store: iStore) => store.auth.info);
  const { role, orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const { loading } = useSelector((store: iStore) => store.appointment);
  const profession = useSelector(
    (states: iStore) => states.professions.filter?.id,
  );
  const oldAppointment = useLocation<iParams>().state;

  const professional = auth.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const [crement, setCrement] = useState<number>(1);
  const [submit, setSubmit] = useState<boolean>(false);
  const [appointment, setAppointment] = useState<iRegisterAppointment>({
    duration: 0,
    consultant:
      oldAppointment && Number(oldAppointment.old) === 1
        ? Number(oldAppointment.consultant)
        : -1,
    professional: role === 'PRO' ? professional?.id : -1,
    specialty:
      role === 'PRO' ? professional?.professions.specialties[0].id : -1,
    date: new Date().toISOString(),
    email: '',
    name: '',
    healthPlan: undefined,
    hourEnd: new Date().toISOString(),
    hourStart: new Date().toISOString(),
    type: -1,
    serviceArea: profession,
    orgUnitId: -1,
    title: '',
  });

  const FINAL_STEP = 4;

  useEffect(() => {
    if (submit) CreateAppointment();
  }, [submit]);

  const CreateAppointment = () => {
    const dataToSend: RemoteCreateAppointment.Params = {
      consultant: appointment.consultant!,
      professional: appointment.professional!,
      date: appointment.hourStart!,
      duration: differenceInMinutes(
        new Date(appointment.hourEnd!),
        new Date(appointment.hourStart!),
      ),
      org: orgId!,
      orgUnit:
        appointment?.orgUnitId !== -1
          ? appointment?.orgUnitId
          : orgUnitId || undefined,
      type: appointment.type!,
      specialty: appointment.specialty!,
      organizer: props.userId,
      healthPlan: appointment.healthPlan || undefined,
      // TODO: Remover mock data
      companions:
        appointment.email && appointment?.email !== ''
          ? [
              {
                email: appointment.email,
                role: 'OTHER',
                phone: '83986195322',
                name: appointment.name,
              },
            ]
          : undefined,
      title: appointment?.title || undefined,
    };

    try {
      const firstInternal = {
        professionalId: -1,
        specialtyId: -1,
      };

      const updatedProfessionals: any = appointment.professionals
        ?.map(item => {
          if (
            !item.phone &&
            !item.email &&
            firstInternal.professionalId === -1 &&
            firstInternal.specialtyId === -1
          ) {
            firstInternal.professionalId = item.id!;
            firstInternal.specialtyId = item.specialtyId!;

            return null;
          }

          return {
            id: item.id ?? undefined,
            name: item.name,
            specialty: item.specialty,
            email: item.email ?? '',
            phone: item.phone ?? '',
          };
        })
        .filter(Boolean);

      const updatedDataToSend: RemoteCreateAppointment.Params = {
        ...dataToSend,
        professional: firstInternal.professionalId,
        specialty: firstInternal.specialtyId,
        professionals: updatedProfessionals,
      };

      const validate = schema.parse(updatedDataToSend);
      console.log('validate back-end: ', validate);
      props.appointment.create(updatedDataToSend);
    } catch (e) {
      console.error(e);
    }
  };

  function handleBar(number: number) {
    if (crement + number > FINAL_STEP) {
      setSubmit(true);
    } else {
      setSubmit(false);
      setCrement(crement + number);
    }
  }

  function next(data: iRegisterAppointment) {
    setAppointment({ ...appointment, ...data });
    handleBar(1);
  }

  function back(data: iRegisterAppointment) {
    setAppointment({ ...appointment, ...data });
    handleBar(-1);
  }

  return (
    <Container>
      <Header>
        <StepMagic current={crement}>
          <Step title={translator('Dados Gerais')} />
          <Step title={translator('Profissionais')} />
          <Step title={translator('Data e Hora')} />
          <Step title={translator('Convidado')} />
        </StepMagic>
      </Header>

      <Body>
        {crement === 1 && <GeneralData state={appointment} next={next} />}
        {crement === 2 && (
          <Professionals state={appointment} next={next} back={back} />
        )}
        {crement === 3 && (
          <DateAndTime
            next={next}
            back={back}
            state={appointment}
            getTime={props.getTimes}
          />
        )}
        {crement === 4 && <Guest state={appointment} next={next} back={back} />}
        {loading && <V4hSpin fullScreen />}
      </Body>
    </Container>
  );
};

export default ConnectComponent(NewSchedule);
