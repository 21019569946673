import React from 'react';

import { Timeline, iTimelineData } from '~/presentation/components/timeline';
import { getLocale } from '~/utils/getLocale';
import { Container } from './styles/StyledTimeLine';

const TimeLine: React.FC = () => {
  const dateTest = new Date().toLocaleDateString(getLocale());
  const dataTest: iTimelineData[] = [
    {
      status: 'available',
      date: dateTest,
    },
    {
      status: 'available',
      date: dateTest,
    },
    {
      status: 'finished',
      date: dateTest,
    },
    {
      status: 'scheduled',
      date: dateTest,
    },
    {
      status: 'canceled',
      date: dateTest,
    },
    {
      status: 'scheduled',
      date: dateTest,
    },
    {
      status: 'scheduled',
      date: dateTest,
    },
    {
      status: 'canceled',
      date: dateTest,
    },
    {
      status: 'canceled',
      date: dateTest,
    },
    {
      status: 'scheduled',
      date: dateTest,
    },
    {
      status: 'canceled',
      date: dateTest,
    },
    {
      status: 'scheduled',
      date: dateTest,
    },
  ];

  return (
    <Container>
      <Timeline data={dataTest} maxPoints={7} />
    </Container>
  );
};

export default TimeLine;
