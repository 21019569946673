/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { isAfter, isBefore } from 'date-fns';

export default function orderData<T>(
  items: T[],
  direction: boolean,
  orderBy: keyof T,
) {
  console.log(items);
  if (orderBy !== 'date') {
    const newData = items.sort((a, b) => {
      if (a[orderBy]! > b[orderBy]!) {
        return direction ? 1 : -1;
      }
      if (a[orderBy]! < b[orderBy]!) {
        return direction ? -1 : 1;
      }
      return 0;
    });

    const dataCasting = (newData as unknown) as T[];
    return dataCasting;
  } else {
    const newData = items.reverse();
    const dataCasting = (newData as unknown) as T[];
    return dataCasting;
  }
}
