import { takeLatest } from 'redux-saga/effects';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { onDeleteRequest, onDeleteSuccess, onDeleteFailed } from './Delete';
import { onGetAll, onGetAllFailed, onGetAllSuccess } from './GetAll';
import { onUpdate, onUpdateFailed, onUpdateSuccess } from './Update';
import { onStopRequest, onStopSuccess, onStopFailed } from './Stop';
import {
  onConfirmRequest,
  onConfirmSuccess,
  onConfirmFailed,
} from './AppointmentConfirmation';
import {
  onCancelFailed,
  onCancelRequest,
  onCancelSuccess,
} from './CancelAppointment';
import { AppointmentTypes } from '~/domain/interfaces/redux/appointment/types';

const tasks = [
  takeLatest(AppointmentTypes.CREATE, onCreate),
  takeLatest(AppointmentTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(AppointmentTypes.CREATE_FAILED, onCreateFailed),

  takeLatest(AppointmentTypes.APPOINTMENTS_CONFIRMATION, onConfirmRequest),
  takeLatest(
    AppointmentTypes.APPOINTMENTS_CONFIRMATION_SUCCESS,
    onConfirmSuccess,
  ),
  takeLatest(
    AppointmentTypes.APPOINTMENTS_CONFIRMATION_FAILED,
    onConfirmFailed,
  ),

  takeLatest(AppointmentTypes.DELETE, onDeleteRequest),
  takeLatest(AppointmentTypes.DELETE_SUCCESS, onDeleteSuccess),
  takeLatest(AppointmentTypes.DELETE_FAILED, onDeleteFailed),

  takeLatest(AppointmentTypes.GETALL, onGetAll),
  takeLatest(AppointmentTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(AppointmentTypes.GETALL_FAILED, onGetAllFailed),

  takeLatest(AppointmentTypes.UPDATE, onUpdate),
  takeLatest(AppointmentTypes.UPDATE_SUCCESS, onUpdateSuccess),
  takeLatest(AppointmentTypes.UPDATE_FAILED, onUpdateFailed),

  takeLatest(AppointmentTypes.STOP, onStopRequest),
  takeLatest(AppointmentTypes.STOP_SUCCESS, onStopSuccess),
  takeLatest(AppointmentTypes.STOP_FAILED, onStopFailed),

  takeLatest(AppointmentTypes.CANCEL, onCancelRequest),
  takeLatest(AppointmentTypes.CANCEL_FAILED, onCancelFailed),
  takeLatest(AppointmentTypes.CANCEL_SUCCESS, onCancelSuccess),
];

export default tasks;
