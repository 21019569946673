// import { render } from 'fusioncharts';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import AcceptTerm from './AcceptTerm';
import Ready from './Ready';
import WaitingProfessional from './WaitingProfessional';

import {
  LeftWaitingRoom,
  JoinWaitingRoom,
} from '~/domain/usecases/waitingRoom/socket';

import { makeReduxUserQueueWaitingRoom } from '~/main/factories/usecases/waitingRoom/UserQueueWaitingRoomFactory';
import { makeSocketJoinWaitingRoom } from '~/main/factories/usecases/waitingRoom/JoinWaitingRoomFactory';
import { ConnectComponent } from './mapper/MapperWaitingRoom';
import { iStore } from '~/domain/interfaces/models';
import { History } from '~/main/routes';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface ownProps {
  ActionRoom: LeftWaitingRoom;
  notification: {
    status: string;
    userId: number;
    appointmentId?: number;
  };
  waitingRoom?: {
    room: {
      firstName: string;
      lastName: string;
      userId: number;
    }[];
  };
  userByLink?: {
    mainRole: string;
    id: number;
  };
  appointmentId: number;
}

interface iStateParams {
  appointmentId: string;
}

const WaitingRoom: React.FC<ownProps> = ({
  ActionRoom,
  notification,
  waitingRoom,
  userByLink,
  appointmentId,
}): JSX.Element => {
  const acceptedTerms = localStorage.getItem('acceptedTerms') || null; // TODO: Adicionar no Redux

  const [state, setState] = useState(acceptedTerms ? 1 : 0);
  const [isOnWaitingRoom, setIsOnWaitingRoom] = useState(false);

  const userAuth = useSelector((store: iStore) => store.auth.info.user);
  const userLogged = userByLink !== undefined ? userByLink : userAuth;

  const [waitingRoomData, setWaitingRoomData] =
    useState<JoinWaitingRoom.Model>();

  useEffect(() => {
    if (state !== 0 && !isOnWaitingRoom) {
      const room = makeSocketJoinWaitingRoom();

      room.join(
        {
          appointmentId,
          data: {
            role: userLogged!.mainRole!,
            user: userLogged!.id,
            queue: 1,
          },
        },
        (data: JoinWaitingRoom.Model) => {
          data.waitingRoom.users.forEach(el => {
            if (el.id !== userLogged?.id) {
              makeReduxUserQueueWaitingRoom().add({
                data: {
                  appointment: appointmentId,
                  status: 'JOINED',
                },
                from: {
                  userId: el.id,
                  firstName: el.name,
                  lastName: '',
                },
              });
            }
          });
          setWaitingRoomData(data);
        },
      );

      setIsOnWaitingRoom(true);
    }
  }, [isOnWaitingRoom, state]);

  useEffect(() => {
    window.onpopstate = () => {
      leaveWaitingRoom();
      window.onpopstate = null;
    };
  }, [waitingRoomData]);

  useEffect(() => {
    switch (notification?.status) {
      case 'JOINED':
        if (
          state !== 0 &&
          notification.userId !== userLogged?.id &&
          notification.appointmentId === Number(appointmentId)
        ) {
          // TODO: Criar Remote para requisição de buscar usuários na Waiting Room
          axios
            .get(
              `${window.config.connection.backendUrl}/api/appointments/${appointmentId}/waitingRooms`,
            )
            .then(res => {
              if (res.data.length) {
                const hasProfessional = Boolean(
                  res.data.find((item: any) => item.role === 'PRO'),
                );

                // eslint-disable-next-line no-unused-expressions
                hasProfessional ? setState(2) : setState(1);
              }
            })
            .catch(err => {
              AlertMessage({
                message: intl.formatMessage({
                  id: 'Não foi possível obter informações da sala de espera, tente novamente',
                }),
                type: 'danger',
              });
            });
        }
        break;
      case 'LEFT':
        setState(1);
        break;
      default:
        break;
    }
  }, [notification]);

  function accept() {
    if (
      state !== 0 &&
      waitingRoom &&
      waitingRoom?.room?.length > 1 &&
      waitingRoom?.room?.filter(item => {
        return item.userId !== userLogged?.id;
      })
    ) {
      // TODO: Criar Remote para requisição de buscar usuários na Waiting Room
      axios
        .get(
          `${window.config.connection.backendUrl}/api/appointments/${appointmentId}/waitingRooms`,
        )
        .then(res => {
          if (res.data.length) {
            const hasProfessional = Boolean(
              res.data.find((item: any) => item.role === 'PRO'),
            );

            // eslint-disable-next-line no-unused-expressions
            hasProfessional ? setState(2) : setState(1);
          }
        })
        .catch(err => {
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possível obter informações da sala de espera, tente novamente',
            }),
            type: 'danger',
          });
        });
    }
  }

  useEffect(() => {
    accept();
  }, [waitingRoom, waitingRoomData]);

  // const { appointmentId } = useLocation<iStateParams>().state;

  const left = () => {
    window.onpopstate = null;

    ActionRoom.left({
      appointmentId: Number(appointmentId),
      waitingRoomUserId: Number(waitingRoomData?.waitingRoomUserId),
    });
    History.push('/appointment');
  };

  const drawing = () => {
    switch (state) {
      case 0:
        return (
          <AcceptTerm
            updateState={(e: number) => {
              localStorage.setItem('acceptedTerms', 'accepted'); // TODO: Adicionar no Redux
              setState(e);
            }}
          />
        );
      case 1:
        accept();
        return <WaitingProfessional updateState={setState} left={left} />;
      case 2:
        return (
          <Ready
            appointmentId={Number(appointmentId)}
            waitingRoomUserId={Number(waitingRoomData?.waitingRoomUserId)}
            left={left}
          />
        );
      default:
        return <></>;
    }
  };

  const leaveWaitingRoom = useCallback(() => {
    if (waitingRoomData !== undefined) {
      ActionRoom.left({
        appointmentId: Number(appointmentId),
        waitingRoomUserId: Number(waitingRoomData?.waitingRoomUserId),
      });
    }
  }, [waitingRoomData]);

  return <>{drawing()}</>;
};

export default ConnectComponent(WaitingRoom);
