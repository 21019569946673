import styled, { css } from 'styled-components';
import { Rotate } from '~/presentation/base/animations';

interface iContainer {
  background?: string;
}

interface iImg {
  size?: string;
}

export const Container = styled.div<iContainer>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: ${props => props.background || 'rgba(0,0,0,0.3)'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spin = styled.img<iImg>`
  ${props =>
    props.size
      ? css`
          width: ${props.size};
        `
      : css`
          max-width: 60px;
        `}
  max-width: 60px;
  animation: ${Rotate} 2s linear infinite;
`;
