export default {
  Organizador: 'Organizador',
  Paciente: 'Paciente',
  Profissional: 'Profissional',
  Convidado: 'Convidado',
  Atendimentos: 'Atendimentos',
  Administrador: 'Administrador',
  'Documentos clínicos': 'Documentos clínicos',
  'Agenda de atendimentos': 'Agenda de atendimentos',
  'Perfil do usuário': 'Perfil do usuário',
  'Detalhes do atendimento': 'Detalhes do atendimento',
  'Documento clínico': 'Documento clínico',
  'Pacientes cadastrados': 'Pacientes cadastrados',
  'Profissionais cadastrados': 'Profissionais cadastrados',
  'Organizadores cadastrados': 'Organizadores cadastrados',
  'Agendar novo atendimento': 'Agendar novo atendimento',
  'Cadastrar novo documento clínico': 'Cadastrar novo documento clínico',
  'Cadastrar novo participante': 'Cadastrar novo participante',
  'Cadastrar novo profissional': 'Cadastrar novo profissional',
  'Cadastrar novo organizador': 'Cadastrar novo organizador',
  Cancelar: 'Cancelar',
  Voltar: 'Voltar',
  Próximo: 'Próximo',
  Enviar: 'Enviar',
  'Enviar e concluir': 'Enviar e concluir',
  'Sim, cancelar': 'Sim, cancelar',
  'Não cancelar': 'Não cancelar',
  'Excluir usuário': 'Excluir usuário',
  'Novo paciente': 'Novo paciente',
  'Novo profissional': 'Novo profissional',
  'Novo organizador': 'Novo organizador',
  'Novo documento': 'Novo documento',
  'Novo agendamento': 'Novo agendamento',
  Vencimento: 'Vencimento',
  Disponível: 'Disponível',
  Cancelado: 'Cancelado',
  Finalizado: 'Finalizado',
  Agendado: 'Agendado',
  'Em andamento': 'Em andamento',
  'Em aberto': 'Em aberto',
  Confirmado: 'Confirmado',
  'Documento não enviado': 'Documento não enviado',
  'Dados perfil': 'Dados perfil',
  'Mudar senha': 'Mudar senha',
  Permissão: 'Permissão',
  'Excluir conta': 'Excluir conta',
  Sair: 'Sair',
  'Aviso importante': 'Aviso importante',
  'De acordo com a legislação, a remoção da sua conta removerá todos os seus dados exceto aqueles relacionados aos atendimentos médicos.':
    'De acordo com a legislação, a remoção da sua conta removerá todos os seus dados exceto aqueles relacionados aos atendimentos médicos.',
  'Estou ciente que a exclusão dos dados é irreversível':
    'Estou ciente que a exclusão dos dados é irreversível',
  'Dados profissionais': 'Dados profissionais',
  Profissão: 'Profissão',
  Salvando: 'Salvando...',
  'Salvar alterações': 'Salvar alterações',
  'Nova senha': 'Nova senha',
  'Digite nova senha': 'Digite nova senha',
  'Senha atual': 'Senha atual',
  'Repita nova senha': 'Repita nova senha',
  'Digite novamente a nova senha': 'Digite novamente a nova senha',
  'Dados do perfil': 'Dados do perfil',
  'Dados gerais': 'Dados gerais',
  'Enviar documento por e-mail': 'Enviar documento por e-mail',
  'Dados Pessoais': 'Dados Pessoais',
  'Dados Profissionais': 'Dados Profissionais',
  'Selecione sua profissão': 'Selecione sua profissão',
  Médico: 'Médico',
  'Digite apenas letras maiusculas e minúsculas e espaço.':
    'Digite apenas letras maiusculas e minúsculas e espaço.',
  Dentista: 'Dentista',
  Contatos: 'Contatos',
  Status: 'Status',
  Ações: 'Ações',
  Período: 'Período',
  Tipo: 'Tipo',
  'Documentos de apoio': 'Documentos de apoio',
  Atendimento: 'Atendimento',
  Retorno: 'Retorno',
  Emergency: 'Emergency',
  Gravado: 'Gravado',
  'Cancelar atendimento?': 'Cancelar atendimento?',
  'Deseja realmente cancelar o atendimento: ':
    'Deseja realmente cancelar o atendimento: ',
  'Nome completo': 'Nome completo',
  'Seu primeiro nome': 'Seu primeiro nome',
  'Seu segundo nome': 'Seu segundo nome',
  'Digite seu nome': 'Digite seu nome',
  'Digite seu sobrenome': 'Digite seu sobrenome',
  'Digite o nome do convidado': 'Digite o nome do convidado',
  Gênero: 'Gênero',
  Masculino: 'Masculino',
  Feminino: 'Feminino',
  Cpf: 'Cpf',
  Rg: 'Rg',
  'Nome da mãe': 'Nome da mãe',
  Especialidade: 'Especialidade',
  'Digite sua especialidade': 'Digite sua especialidade',
  'Data do Atendimento': 'Data do Atendimento',
  'Selecione uma data disponível': 'Selecione uma data disponível',
  'Hora início': 'Hora início',
  'Hora fim': 'Hora fim',
  Selecione: 'Selecione',
  'Tipo de Atendimento': 'Tipo de Atendimento',
  Convidados: 'Convidados',
  'Digite o(s) email(s) para convidar': 'Digite o(s) email(s) para convidar',
  'Um Link estará disponível após a confirmação do agendamento.':
    'Um Link estará disponível após a confirmação do agendamento.',
  'Data de nascimento': 'Data de nascimento',
  Endereço: 'Endereço',
  Cep: 'Cep',
  Número: 'Número',
  Complemento: 'Complemento',
  Bairro: 'Bairro',
  UF: 'UF',
  State: 'State',
  Cidade: 'Cidade',
  Contato: 'Contato',
  'E-mail': 'E-mail',
  Senha: 'Senha',
  'Telefone celular': 'Telefone celular',
  'Telefone alternativo': 'Telefone alternativo',
  'Telefone celular 1': 'Telefone celular 1',
  'Telefone celular 2': 'Telefone celular 2',
  Salvar: 'Salvar',
  'Digite o seu email': 'Digite o seu email',
  'Digite sua Senha': 'Digite sua Senha',
  Código: 'Código',
  'Digite o código do atendimento': 'Digite o código do atendimento',
  'Sua relação familiar com o paciente': 'Sua relação familiar com o paciente',
  'Selecione uma opção': 'Selecione uma opção',
  Anterior: 'Anterior',
  'Termos de uso': 'Termos de uso',
  'Política de privacidade': 'Política de privacidade',
  Assinado: 'Assinado',
  Pendente: 'Pendente',
  'Lista de Documentos clínicos': 'Lista de Documentos clínicos',
  'Lista de Documentos de apoio': 'Lista de Documentos de apoio',
  Tamanho: 'Tamanho',
  Data: 'Data',
  Cod: 'Cod',
  Assinatura: 'Assinatura',
  Hora: 'Hora',
  Origem: 'Origem',
  CPF: 'CPF',
  'Profissional da saúde': 'Profissional da saúde',
  'Registro de conselho': 'Registro de conselho',
  'Data de inclusão': 'Data de inclusão',
  Idade: 'Idade',
  Início: 'Início',
  Fim: 'Fim',
  'Iniciar atendimento': 'Iniciar atendimento',
  Detalhes: 'Detalhes',
  'Acessas farmácia': 'Acessas farmácia',
  'Ver perfil': 'Ver perfil',
  'Editar setor': 'Editar setor',
  'Baixar documento': 'Baixar documento',
  'Ir para sala de espera': 'Ir para sala de espera',
  'Compartilhar por email': 'Compartilhar por email',
  'Novo documento clínico': 'Novo documento clínico',
  'Não foi possível obter informações do documento clínico. Tente novamente':
    'Não foi possível obter informações do documento clínico. Tente novamente',
  Observações: 'Observações',
  'Tempo de afastamento': 'Tempo de afastamento',
  'Motivo do afastamento': 'Motivo do afastamento',
  Painel: 'Painel',
  'Excluir usuario': 'Excluir usuario',
  'Cancelar atendimento': 'Cancelar atendimento',
  'Finalizar atendimento': 'Finalizar atendimento',
  'Editar especialidade': 'Editar especialidade',
  'Excluir especialidade': 'Excluir especialidade',
  'Copiar link de atendimento': 'Copiar link de atendimento',
  'Reenviar email': 'Reenviar email',
  'Enviar convite por Whatsapp': 'Enviar convite por Whatsapp',
  'Enviar convite': 'Enviar convite',
  'Convites Enviados': 'Convites Enviados',
  'Convidar por Link': 'Convidar por Link',
  'Excluir documento': 'Excluir documento',
  Avulso: 'Avulso',
  Simples: 'Simples',
  'Atestado Médico': 'Atestado Médico',
  'Requisição de exame': 'Requisição de exame',
  'Relatório médico': 'Relatório Médico',
  'Status do atendimento': 'Status do atendimento',
  'Participantes da Consulta': 'Participantes da Consulta',
  'Dados do paciente': 'Dados do paciente',
  Nome: 'Nome',
  anos: 'anos',
  'Pronto para participar?': 'Pronto para participar?',
  'Ações do atendimento': 'Ações do atendimento',
  'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.':
    'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.',
  'Atendimento cancelado': 'Atendimento cancelado',
  'Dados do profissional de saúde': 'Dados do profissional de saúde',
  'Registro no conselho profissional': 'Registro no conselho profissional',
  'Data e Hora': 'Data e Hora',
  Profissionais: 'Profissionais',
  Concluir: 'Concluir',
  'Paciente em questão': 'Paciente em questão',
  'Selecione um paciente': 'Selecione um paciente',
  'Convidar paciente para participar': 'Convidar paciente para participar',
  'Selecione uma especialidade': 'Selecione uma especialidade',
  'Data do atendimento': 'Data do atendimento',
  'Hora de início': 'Hora de início',
  'Hora do fim': 'Hora do fim',
  'Selecione um profissional': 'Selecione um profissional',
  'Convidar profissional externo': 'Convidar profissional externo',
  'Nome do Profissional': 'Nome do Profissional',
  'Digite o e-mail do profissional': 'Digite o e-mail do profissional',
  'E-mail do profissional': 'E-mail do profissional',
  Add: 'Add',
  'Lista de profissionais participantes':
    'Lista de profissionais participantes',
  'Digite seu CEP': 'Digite seu CEP',
  'Digite seu estado': 'Digite seu estado',
  Estado: 'Estado',
  'Digite sua cidade': 'Digite sua cidade',
  'Digite seu país': 'Digite seu país',
  País: 'País',
  'Digite seu bairro': 'Digite seu bairro',
  'Rua Av. Ladeira...': 'Rua Av. Ladeira...',
  'Número da residência': 'Número da residência',
  'Digite um complemento': 'Digite um complemento',
  'Seu nome completo': 'Seu nome completo',
  'Nome Completo': 'Nome Completo',
  'Cadastro de Pessoa Física': 'Cadastro de Pessoa Física',
  'Número da identidade': 'Número da identidade',
  'Selecione seu Gênero': 'Selecione seu Gênero',
  Outros: 'Outros',
  'DD/MM/AAAA': 'DD/MM/AAAA',
  'Data de Nascimento': 'Data de Nascimento',
  'Nome da sua mãe': 'Nome da sua mãe',
  'Digite uma senha pessoal': 'Digite uma senha pessoal',
  'Senha de Acesso': 'Senha de Acesso',
  'Confirme a senha': 'Confirme a senha',
  'Repita a senha de acesso': 'Repita a senha de acesso',
  'Insira o Código de atendimento que você recebeu por e-mail.':
    'Insira o Código de atendimento que você recebeu por e-mail.',
  'Pai/Mãe': 'Pai/Mãe',
  'Irmã(o)': 'Irmã(o)',
  'Filho(a)': 'Filho(a)',
  'Esposa(o)': 'Esposa(o)',
  'Avô/Avó': 'Avô/Avó',
  'Amigo(a)': 'Amigo(a)',
  'Outro parente': 'Outro parente',
  'Não listado': 'Não listado',
  Ativo: 'Ativo',
  Fraca: 'Fraca',
  Médio: 'Médio',
  Forte: 'Forte',
  'Senha de': 'Senha de',
  segurança: 'segurança',
  Avançar: 'Avançar',
  Cadastro: 'Cadastro',
  'Selecione um papel': 'Selecione um papel',
  'Permissões concedidas': 'Permissões concedidas',
  Descrição: 'Descrição',
  Franquia: 'Franquia',
  Papel: 'Papel',
  '+Adicionar': '+Adicionar',
  Adicionar: 'Adicionar',
  'Profissional de saúde': 'Profissional de saúde',
  'Última edição': 'Última edição',
  'Profissionais participantes': 'Profissionais participantes',
  'Não foi possível carregar o arquivo': 'Não foi possível carregar o arquivo',
  'Dados Gerais': 'Dados Gerais',
  'Acesso à Receita': 'Acesso à Receita',
  Acessar: 'Acessar',
  'Digite o código da receita': 'Digite o código da receita',
  'Digite a senha da receita': 'Digite a senha da receita',
  'Insira o CÓDIGO e SENHA disponíveis na receita do paciente':
    'Insira o CÓDIGO e SENHA disponíveis na receita do paciente',
  'para visualizar o arquivo assinado pelo médico':
    'para visualizar o arquivo assinado pelo médico',
  'Criado em': 'Criado em',
  'Acesso para farmácia': 'Acesso para farmácia',
  'Receita Especial': 'Receita Especial',
  'Validador ICP Brasil': 'Validador ICP Brasil',
  'Baixar PDF': 'Baixar PDF',
  'Um link estará disponível após confirmação do agendamento':
    'Um link estará disponível após confirmação do agendamento',
  'Digite o(s) e-mail(s) para convidar': 'Digite o(s) e-mail(s) para convidar',
  'Selecione um tipo': 'Selecione um tipo',
  'Tipo de serviço': 'Tipo de serviço',
  Mês: 'Mês',
  Hoje: 'Hoje',
  Domingo: 'Domingo',
  'Segunda-Feira': 'Segunda-Feira',
  'Terça-Feira': 'Terça-Feira',
  'Quarta-Feira': 'Quarta-Feira',
  'Quinta-Feira': 'Quinta-Feira',
  'Sexta-Feira': 'Sexta-Feira',
  Sábado: 'Sábado',
  ' de Janeiro': ' de Janeiro',
  ' de Fevereiro': ' de Fevereiro',
  ' de Março': ' de Março',
  ' de Maio': ' de Maio',
  ' de Abril': ' de Abril',
  ' de Junho': ' de Junho',
  ' de Julho': ' de Julho',
  ' de Agosto': ' de Agosto',
  ' de Setembro': ' de Setembro',
  ' de Outubro': ' de Outubro',
  ' de Novembro': ' de Novembro',
  ' de Dezembro': ' de Dezembro',
  'Receita simples': 'Receita simples',
  'Solicite a farmácia que acesse o endereço':
    'Solicite a farmácia que acesse o endereço',
  'para baixar sua receita digital.': 'para baixar sua receita digital.',
  'Utilize o código e senha para acessar':
    'Utilize o código e senha para acessar',
  'Código:': 'Código:',
  'Senha:': 'Senha:',
  'Ver QR Code': 'Ver QR Code',
  'Código e Senha': 'Código e Senha',
  'Acesse o documento': 'Acesse o documento',
  'via o QR code do documento': 'via o QR code do documento',
  Perfil: 'Perfil',
  Pacientes: 'Pacientes',
  'Profissionais de saúde': 'Profissionais de saúde',
  Organizações: 'Organizações',
  Painéis: 'Painéis',
  'Log de atividade': 'Log de atividade',
  'Serviços e faturamentos': 'Serviços e faturamentos',
  'Faturas e pagamentos': 'Faturas e pagamentos',
  'Itens da fatura': 'Itens da fatura',
  'Ocultar todos os detalhes': 'Ocultar todos os detalhes',
  'Status de pagamento': 'Status de pagamento',
  Setores: 'Setores',
  Agenda: 'Agenda',
  Usuários: 'Usuários',
  Administração: 'Administração',
  Serviços: 'Serviços',
  '(Pacote On Demand)': '(Pacote On Demand)',
  'Selecione um mês': 'Selecione um mês',
  'Limpar todas': 'Limpar todas',
  'Ocultar notificações': 'Ocultar notificações',
  'criou um atendimento': 'criou um atendimento',
  'cancelou um atendimento': 'cancelou um atendimento',
  'confirmou o atendimento': 'confirmou o atendimento',
  'atualizou um atendimento': 'atualizou um atendimento',
  'agendou o atendimento': 'agendou o atendimento',
  'deletou um atendimento': 'deletou um atendimento',
  'criou um atendimento com profissional acompanhante':
    'criou um atendimento com profissional acompanhante',
  'entrou na sala de espera': 'entrou na sala de espera',
  'saiu da sala de espera': 'saiu da sala de espera',
  'Nova mensagem': 'Nova mensagem',
  'Menu de Serviços': 'Menu de Serviços',
  'Mês de competência': 'Mês de competência',
  'Backup em nuvem': 'Backup em nuvem',
  'Reconhecimento facial': 'Reconhecimento facial',
  'Este recurso não está disponível no seu pacote de serviços atual.':
    'Este recurso não está disponível no seu pacote de serviços atual.',
  'desde o último mês': 'desde o último mês',
  'Atendimentos realizados': 'Atendimentos realizados',
  'Reconhecimentos realizados': 'Reconhecimentos realizados',
  'Prescrições geradas': 'Prescrições geradas',
  'Atendimentos preservados': 'Atendimentos preservados',
  'O valor total faturado será exibido depois que a fatura completa for fechada.':
    'O valor total faturado será exibido depois que a fatura completa for fechada.',
  'Total estimado': 'Total estimado',
  'Valor unitário': 'Valor unitário',
  'Valor total': 'Valor total',
  Pago: 'Pago',
  Utilizados: 'Utilizados',
  Unidades: 'Unidades',
  Proteção: 'Proteção',
  Prescrições: 'Prescrições',
  Vigência: 'Vigência',
  Notificações: 'Notificações',
  'Últimas conversas': 'Últimas conversas',
  'Selecione um tipo de documento clínico':
    'Selecione um tipo de documento clínico',
  'Selecione um certificado': 'Selecione um certificado',
  'Receita Simples': 'Receita Simples',
  'Receita Antimicrobiano': 'Receita Antimicrobiano',
  'Requisição de Exames': 'Requisição de Exames',
  'Requisição de exames': 'Requisição de Exames',
  Instituição: 'Instituição',
  'Assinar Documento': 'Assinar Documento',
  'Data de emissão': 'Data de emissão',
  Droga: 'Droga',
  'Drogas Inseridas': 'Drogas Inseridas',
  'Após preencher os campos, clique em “Adicionar” para incluir a medicação na prescrição da receita':
    'Após preencher os campos, clique em “Adicionar” para incluir a medicação na prescrição da receita.',
  Dosagem: 'Dosagem',
  'Adicionar >>': 'Adicionar >>',
  Posologia: 'Posologia',
  'Certificado Digital': 'Certificado Digital',
  'Excluir Receita': 'Excluir Receita',
  'Enviar ao Paciente': 'Enviar ao Paciente',
  'Tipo de documento': 'Tipo de documento',
  'Informações gerais': 'Informações gerais',
  Prescrição: 'Prescrição',
  'Assinatura do documento': 'Assinatura do documento',
  Pesquisar: 'Pesquisar',
  'Buscar por...': 'Buscar por...',
  'Pesquisar usuário': 'Pesquisar usuário',
  Redefinir: 'Redefinir',
  Qualquer: 'Qualquer',
  Gravados: 'Gravados',
  'Documentos Clínicos': 'Documentos Clínicos',
  'Digite o nome do administrador': 'Digite o nome do administrador',
  'Digite o CPF do administrador': 'Digite o CPF do administrador',
  'Digite o código do paciente': 'Digite o código do paciente',
  'Digite o nome do profissional': 'Digite o nome do profissional',
  'Digite o nome do paciente': 'Digite o nome do paciente',
  'Digite o nome de um organizador': 'Digite o nome de um organizador',
  'Digite o CPF do organizador': 'Digite o CPF do organizador',
  'Digite o CPF do paciente': 'Digite o CPF do paciente',
  'Selecione uma data': 'Selecione uma data',
  Ontem: 'Ontem',
  'Últimos 7 dias': 'Últimos 7 dias',
  'Últimos 30 dias': 'Últimos 30 dias',
  'Últimos 90 dias': 'Últimos 90 dias',
  Ínicio: 'Ínicio',
  'Em progresso': 'Em progresso',
  'Digite o nome de um médico': 'Digite o nome de um médico',
  'atendimentos neste dia': 'atendimentos neste dia',
  'O paciente foi informado que': 'O paciente foi informado que',
  'você está na sala de espera': 'você está na sala de espera',
  'Aguarde o início do atendimento': 'Aguarde o início do atendimento',
  'nesta sala de espera': 'nesta sala de espera',
  'Deixar sala': 'Deixar sala',
  'O paciente está pronto': 'O paciente está pronto',
  'Você já pode iniciar o atendimento': 'Você já pode iniciar o atendimento',
  'clicando em avançar': 'clicando em avançar',
  Cadastrados: 'Cadastrados',
  'Eventos do atendimento': 'Eventos do atendimento',
  Anotações: 'Anotações',
  'Documentos clínicos gerados no atendimento':
    'Documentos clínicos gerados no atendimento',
  'Gerar novo documento': 'Gerar novo documento',
  Gravação: 'Gravação',
  Chat: 'Chat',
  'Digite e confirme sua nova senha nos campos abaixo.':
    'Digite e confirme sua nova senha nos campos abaixo.',
  'Gravações do atendimento': 'Gravações do atendimento',
  'Novo documento de apoio': 'Novo documento de apoio',
  'Antes de entrar na sala de espera': 'Antes de entrar na sala de espera',
  'Você precisa concordar com os termos de uso da':
    'Você precisa concordar com os termos de uso da',
  'plataforma e também concordar que o atendimento poderá':
    'plataforma e também concordar que o atendimento poderá',
  'ser gravado mediante escolha do médico.':
    'ser gravado mediante escolha do médico.',
  'Aceito os': 'Aceito os',
  'termos de uso.': 'termos de uso.',
  'Aceito que o atendimento poderá ser gravado.':
    'Aceito que o atendimento poderá ser gravado.',
  'O médico foi informado que': 'O médico foi informado que',
  'Aguarde nesta tela.': 'Aguarde nesta tela.',
  'Você será avisado quando o': 'Você será avisado quando o',
  'médico estiver pronto': 'médico estiver pronto',
  'Entrar na consulta': 'Entrar na consulta',
  'Deixar a sala': 'Deixar a sala',
  'O médico está pronto': 'O médico está pronto',
  'O médico já está na sala de atendimento':
    'O médico já está na sala de atendimento',
  'Você já pode iniciar seu atendimento':
    'Você já pode iniciar seu atendimento',
  'Insira seu nome completo.': 'Insira seu nome completo.',
  'CPF inválido.': 'CPF inválido.',
  'RG inválido.': 'RG inválido.',
  'RG inválido, existe(m) digitos faltando':
    'RG inválido, existe(m) digitos faltando',
  'RG inválido, número de digitos ultrapassou o limite':
    'RG inválido, número de digitos ultrapassou o limite',
  'Email inválido.': 'Email inválido.',
  'Telefone inválido.': 'Telefone inválido.',
  'CEP inválido.': 'CEP inválido.',
  'CEP inválido, existe(m) digitos faltando':
    'CEP inválido, existe(m) digitos faltando',
  'CEP inválido, número de digitos ultrapassou o limite':
    'CEP inválido, número de digitos ultrapassou o limite',
  'Data de nascimento é obrigatório.': 'Data de nascimento é obrigatório.',
  'Data inválida.': 'Data inválida.',
  'Nome da mãe é obrigatório.': 'Nome da mãe é obrigatório.',
  'UF inválido.': 'UF inválido.',
  'UF inválido, existe(m) caracteres faltando':
    'UF inválido, existe(m) caracteres faltando',
  'UF inválido, número de caracteres ultrapassados':
    'UF inválido, número de caracteres ultrapassados',
  'Insira o nome da sua cidade.': 'Insira o nome da sua cidade.',
  'Insira o nome do seu bairro.': 'Insira o nome do seu bairro.',
  'Insira o seu endereço.': 'Insira o seu endereço.',
  'Insira o número de sua residência': 'Insira o número de sua residência',
  'Número inválido, ultrapassou o limite':
    'Número inválido, ultrapassou o limite',
  'Consultante inválido ou não encontrado.':
    'Consultante inválido ou não encontrado.',
  'Profissional inválido ou não encontrado.':
    'Profissional inválido ou não encontrado.',
  'Especialidade inválida ou não encontrada':
    'Especialidade inválida ou não encontrada',
  'Opção inválida': 'Opção inválida',
  'Data inválida': 'Data inválida',
  'Hora de ínicio incorreta': 'Hora de ínicio incorreta',
  'Hora final incorreta': 'Hora final incorreta',
  'Senha inválida': 'Senha inválida',
  'Insira o nome da droga': 'Insira o nome da droga',
  'Insira a dosagem': 'Insira a dosagem',
  'Insira a posologia da medicação': 'Insira a posologia da medicação',
  'Insira o tempo de afastamento': 'Insira o tempo de afastamento',
  'Insira uma indicação clínica.': 'Insira uma indicação clínica.',
  'Exames requisitados': 'Exames requisitados',
  'Requisito os seguintes exames': 'Requisito os seguintes exames',
  'Indicação Clínica': 'Indicacão Clínica',
  'Insira o número do seu documento profissional':
    'Insira o número do seu documento profissional',
  'Insira a UF do seu documento profissional':
    'Insira a UF do seu documento profissional',
  'Insira a data de emissão do seu documento profissional':
    'Insira a data de emissão do seu documento profissional',
  'Selecione a área de atendimento': 'Selecione a área de atendimento',
  'Falha no servidor, tente novamente mais tarde.':
    'Falha no servidor, tente novamente mais tarde.',
  'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.':
    'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.',
  'Assinar agora': 'Assinar agora',
  'Selecione seu certificado digital e finalize a criação do documento agora.':
    'Selecione seu certificado digital e finalize a criação do documento agora.',
  'Assinar depois': 'Assinar depois',
  'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.':
    'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.',
  'Você não tem nenhum documento associado ao atendimento.':
    'Você não tem nenhum documento associado ao atendimento.',
  'Você não tem nenhuma gravação.': 'Você não tem nenhuma gravação.',
  'Antes de iniciar': 'Antes de iniciar',
  'Não foi possível desativar a câmera.':
    'Não foi possível desativar a câmera.',
  'Recomendamos que entre com o microfone e câmera ligados.':
    'Recomendamos que entre com o microfone e câmera ligados.',
  'Aceite os termos de uso e de gravação para iniciar.':
    'Aceite os termos de uso e de gravação para iniciar.',
  DESATIVADA: 'DESATIVADA',
  'Entrar com:': 'Entrar com:',
  Ligado: 'Ligado',
  Desligado: 'Desligado',
  Ligada: 'Ligada',
  Desligada: 'Desligada',
  DESLIGADA: 'DESLIGADA',
  'Câmera desligada': 'Câmera desligada',
  'termos de uso': 'termos de uso',
  'Aceito que o atendimento poderá ser gravado':
    'Aceito que o atendimento poderá ser gravado',
  'Participar do atendimento': 'Participar do atendimento',
  'Sair do atendimento': 'Sair do atendimento',
  'Esqueceu sua senha?': 'Esqueceu sua senha?',
  Entrar: 'Entrar',
  'Bem-vindo, doutor(a)': 'Bem-vindo, doutor(a)',
  'Realize o login para iniciar o atendimento':
    'Realize o login para iniciar o atendimento',
  'do paciente': 'do paciente',
  'marcado para as': 'marcado para as',
  'Recuperar senha': 'Recuperar senha',
  'Insira seu e-mail no campo abaixo e lhe enviaremos um link para redefinir sua senha.':
    'Insira seu e-mail no campo abaixo e lhe enviaremos um link para redefinir sua senha.',
  'um link para alteração de senha': 'um link para alteração de senha',
  'Atualmente você está como': 'Atualmente você está como',
  'Acesso para convidado': 'Acesso para convidado',
  'Identifique-se': 'Identifique-se',
  'Insira seus dados para entrar no atendimento':
    'Insira seus dados para entrar no atendimento',
  em: 'em',
  'Para alterar sua função, selecione o papel em uma organização':
    'Para alterar sua função, selecione o papel em uma organização',
  'Defina seu papel na organização desejada.':
    'Defina seu papel na organização desejada.',
  Confirmar: 'Confirmar',
  Carregando: 'Carregando',
  'Seus documentos clínicos (receitas, atestados etc) aparecerão aqui. No momento você não tem nenhum':
    'Seus documentos clínicos (receitas, atestados etc) aparecerão aqui. No momento você não tem nenhum',
  'Primeiro atendimento': 'Primeiro atendimento',
  Nenhum: 'Nenhum',
  'Os pacientes cadastrados aparecerão aqui. No momento não há nenhum':
    'Os pacientes cadastrados aparecerão aqui. No momento não há nenhum',
  'Os profissionais cadastrados aparecerão aqui. No momento não há nenhum':
    'Os profissionais cadastrados aparecerão aqui. No momento não há nenhum',
  'Os organizadores cadastrados aparecerão aqui. No momento não há nenhum':
    'Os organizadores cadastrados aparecerão aqui. No momento não há nenhum',
  'Os administradores cadastrados aparecerão aqui. No momento não há nenhum':
    'Os administradores cadastrados aparecerão aqui. No momento não há nenhum',
  Convênio: 'Convênio',
  'Salvando...': 'Salvando...',
  'Registro (conselho regulador)': 'Registro (conselho regulador)',
  Especialidades: 'Especialidades',
  'Registro do conselho': 'Registro do conselho',
  Documento: 'Documento',
  'Cadastrar novo administrador': 'Cadastrar novo administrador',
  'Criar novo setor': 'Criar novo setor',
  Em: 'em',
  'Deseja agendar um novo atendimento?': 'Deseja agendar um novo atendimento?',
  'Novo atendimento': 'Novo atendimento',
  às: 'às',
  'anos e': 'anos e',
  meses: 'meses',
  'Editar consulta': 'Editar consulta',
  'Links e Convidados': 'Links e Convidados',
  'Área do atendimento': 'Área do atendimento',
  'Adicionar convidado': 'Adicionar convidado',
  'Digite o e-mail ou o número do celular para convidar':
    'Digite o e-mail ou o número do celular para convidar',
  interno: 'interno',
  externo: 'externo',
  Participante: 'Participante',
  'Data do envio': 'Data do envio',
  Ação: 'Ação',
  'Só você pode ver, editar e excluir a anotação do atendimento.':
    'Só você pode ver, editar e excluir a anotação do atendimento.',
  'Escreva aqui': 'Escreva aqui',
  'Administradores cadastrados': 'Administradores cadastrados',
  'Novo administrador': 'Novo administrador',
  Inativo: 'Inativo',
  Suspenso: 'Suspenso',
  Realizados: 'Realizados',
  'Não foi possível assinar o documento.':
    'Não foi possível assinar o documento.',
  'Enviado ao paciente:': 'Enviado ao paciente:',
  'Atesto que o(a) Sr(a).': 'Atesto que o(a) Sr(a).',
  'encontra-se sob meus cuidados profissionais necessitando de afastamento de suas atividades pelo período de':
    'encontra-se sob meus cuidados profissionais necessitando de afastamento de suas atividades pelo período de',
  'Observações:': 'Observações:',
  'Indicação clínica:': 'Indicação clínica:',
  'Solicito:': 'Solicito:',
  'Requisito o exame': 'Requisito o exame',
  'para o paciente pois o mesmo está com sintomas.':
    'para o paciente pois o mesmo está com sintomas.',
  'Selecione um arquivo': 'Selecione um arquivo',
  receituario: 'receituario',
  'Ocorreu um erro ao excluir o documento clínico':
    'Ocorreu um erro ao excluir o documento clínico',
  'Ocorreu um erro ao reenviar o e-mail, tente novamente mais tarde':
    'Ocorreu um erro ao reenviar o e-mail, tente novamente mais tarde',
  'Visualizar PDF': 'Visualizar PDF',
  Baixar: 'Baixar',
  'Assinar documento': 'Assinar documento',
  'Reenviar por e-mail': 'Reenviar por e-mail',
  Excluir: 'Excluir',
  'Adicionar profissional externo': 'Adicionar profissional externo',
  'profissional interno': 'Profissional Interno',
  'profissional externo': 'Profissional Externo',
  'Adicione uma ou mais especialidades para concluir o cadastro':
    'Adicione uma ou mais especialidades para concluir o cadastro',
  'Suas especialidades aparecerão aqui': 'Suas especialidades aparecerão aqui',
  Aguarde: 'Aguarde',
  'será atendido(a) em alguns instantes':
    'será atendido(a) em alguns instantes',
  'O atendimento será iniciado assim que o médico e paciente estiverem na sala.':
    'O atendimento será iniciado assim que o médico e paciente estiverem na sala.',
  'Cadastro realizado com sucesso!': 'Cadastro realizado com sucesso!',
  'Enviamos um email de confirmação para':
    'Enviamos um email de confirmação para',
  'Falta pouco: para habilitar sua conta, acesse a mensagem enviada e clique no link de confirmação.':
    'Falta pouco: para habilitar sua conta, acesse a mensagem enviada e clique no link de confirmação.',
  OK: 'OK',
  'Sair sem salvar': 'Sair sem salvar',
  'Se você sair agora, as alterações feitas':
    'Se você sair agora, as alterações feitas',
  'não serão salvas.': 'não serão salvas.',
  'Para salvá-las, clique no botão Salvar antes de sair.':
    'Para salvá-las, clique no botão Salvar antes de sair.',
  'Voltar para edição': 'Voltar para edição',
  'Alterações salvas com sucesso!': 'Alterações salvas com sucesso!',
  'Não excluir': 'Não excluir',
  'Sim, excluir': 'Sim, excluir',
  'Atendimento agendado com sucesso!': 'Atendimento agendado com sucesso!',
  'Link para o profissional': 'Link para o profissional',
  'Link para o paciente': 'Link para o paciente',
  'O atendimento foi cancelado. Consulte o médico ou paciente para mais informações.':
    'O atendimento foi cancelado. Consulte o médico ou paciente para mais informações.',
  'WhatsApp (opcional)': 'WhatsApp (opcional)',
  'Deseja finalizar o atendimento?': 'Deseja finalizar o atendimento?',
  'Caso opte por não finalizar o atendimento agora, você poderá finalizá-lo posteriormente.':
    'Caso opte por não finalizar o atendimento agora, você poderá finalizá-lo posteriormente.',
  'Não finalizar': 'Não finalizar',
  'Sim, finalizar agora': 'Sim, finalizar agora',
  'Pronto!': 'Pronto!',
  'Você já pode participar do atendimento':
    'Você já pode participar do atendimento',
  'Clique em entrar para iniciar': 'Clique em entrar para iniciar',
  Copiar: 'Copiar',
  'Copiado!': 'Copiado!',
  'Excluir setor?': 'Excluir setor?',
  'Você deseja excluir o documento?': 'Você deseja excluir o documento?',
  'Excluir documento pendente?': 'Excluir documento pendente?',
  'Deseja realmente excluir este documento?':
    'Deseja realmente excluir este documento?',
  'Atendimento cancelado com sucesso!': 'Atendimento cancelado com sucesso!',
  'Documento clínico criado com sucesso!':
    'Documento clínico criado com sucesso!',
  'Documento clínico enviado com sucesso!':
    'Documento clínico enviado com sucesso!',
  'Documento assinado com sucesso!': 'Documento assinado com sucesso!',
  'Usuario excluido com sucesso!': 'Usuario excluido com sucesso!',
  'Modelos de documentos clínicos': 'Modelos de documentos clínicos',
  'Documentos suportados para envio: jpeg, png, pdf e exe.':
    'Documentos suportados para envio: jpeg, png, pdf e exe.',
  'Lista de documentos de apoio do atendimento':
    'Lista de documentos de apoio do atendimento',
  'Novo setor': 'Novo setor',
  Setor: 'Setor',
  'Total de pacientes': 'Total de pacientes',
  'Total de profissionais': 'Total de profissionais',
  'Armazenamento este mês': '',
  'Setor excluido com sucesso!': 'Setor excluido com sucesso!',
  'Não foi possível excluir setor, tente novamente.':
    'Não foi possível excluir setor, tente novamente.',
  'Credenciais inválidas!': 'Credenciais inválidas!',
  'Falha ao realizar login!': 'Falha ao realizar login!',
  'Documento enviado com sucesso!': 'Documento enviado com sucesso!',
  'Falha ao registrar usuário.': 'Falha ao registrar usuário.',
  'E-mail ou CPF já utilizado.': 'E-mail ou CPF já utilizado.',
  'Criação de conta confirmada!': 'Criação de conta confirmada!',
  'Recuperação de senha bem-sucedida!': 'Recuperação de senha bem-sucedida!',
  'Email enviado com sucesso!': 'Email enviado com sucesso!',
  'O agendamento já foi confirmado.': 'O agendamento já foi confirmado.',
  'Agendamento confirmado com sucesso.': 'Agendamento confirmado com sucesso.',
  'Falha ao confirmar o atendimento.': 'Falha ao confirmar o atendimento.',
  'Falha ao cancelar o atendimento.': 'Falha ao cancelar o atendimento.',
  'Falha ao criar agendamento!': 'Falha ao criar agendamento!',
  'Falha ao remover agendamento.': 'Falha ao remover agendamento.',
  'Falha ao tentar atualizar agendamento.':
    'Falha ao tentar atualizar agendamento.',
  'Token expirado! Efetue o login novamente para trocar papéis.':
    'Token expirado! Efetue o login novamente para trocar papéis.',
  'Falha ao criar documento clínico.': 'Falha ao criar documento clínico.',
  'Falha ao remover documento clínico.': 'Falha ao remover documento clínico.',
  'Falha ao assinar documento clínico.': 'Falha ao assinar documento clínico.',
  'Falha ao fazer upload do arquivo.': 'Falha ao fazer upload do arquivo.',
  'Não foi possível atualizar o usuário. Você digitou os campos corretamente?':
    'Não foi possível atualizar o usuário. Você digitou os campos corretamente?',
  'Falha interna no servidor, tente novamente em minutos.':
    'Falha interna no servidor, tente novamente em minutos.',
  'entrou no atendimento de data': 'entrou no atendimento de data',
  'entrou no atendimento': 'entrou no atendimento',
  'Falha ao adicionar agendamento a lista de atendimentos!':
    'Falha ao adicionar agendamento a lista de atendimentos!',
  'Não foi possível carregar as conversas':
    'Não foi possível carregar as conversas',
  'Digite sua senha atual.': 'Digite sua senha atual.',
  'Confirmação de senha incorreta.': 'Confirmação de senha incorreta.',
  'Falha ao tentar atualizar. Verifique os dados e tente novamente':
    'Falha ao tentar atualizar. Verifique os dados e tente novamente',
  'Falha na busca dos estados': 'Falha na busca dos estados',
  'Falha na busca das cidades': 'Falha na busca das cidades',
  'Link copiado para área de transferência':
    'Link copiado para área de transferência',
  'Convite enviado com sucesso!': 'Convite enviado com sucesso!',
  'Falha ao enviar convite!': 'Falha ao enviar convite!',
  'Convite reenviado com sucesso!': 'Convite reenviado com sucesso!',
  'Falha ao reenviar convite!': 'Falha ao reenviar convite!',
  'Não é possível enviar o documento antes de assinado!':
    'Não é possível enviar o documento antes de assinado!',
  'Não foi possível atualizar especialidades, tente novamente':
    'Não foi possível atualizar especialidades, tente novamente',
  'Especialidades atualizadas com sucesso!':
    'Especialidades atualizadas com sucesso!',
  'Não foi possível atualizar especialidades, tente novamente.':
    'Não foi possível atualizar especialidades, tente novamente.',
  'É preciso adicionar pelo menos um profissional':
    'É preciso adicionar pelo menos um profissional',
  'Você precisa adicionar uma especialidade.':
    'Você precisa adicionar uma especialidade.',
  'Erro ao criar o profissional. Tente novamente.':
    'Erro ao criar o profissional. Tente novamente.',
  'Não foi possivel encontrar nenhum certificado':
    'Não foi possivel encontrar nenhum certificado',
  'Atualize os dados da sua prescrição.':
    'Atualize os dados da sua prescrição.',
  'Erro ao confirmar agendamento. Você realmente agendou um horário?':
    'Erro ao confirmar agendamento. Você realmente agendou um horário?',
  'Não foi possível entrar na conferência, tente novamente':
    'Não foi possível entrar na conferência, tente novamente',
  'Você realmente solicitou a criação de conta?':
    'Você realmente solicitou a criação de conta?',
  'Digite e confirme sua nova senha.': 'Digite e confirme sua nova senha.',
  'Você realmente é um novo usuário?': 'Você realmente é um novo usuário?',
  'Código não encontrado.': 'Código não encontrado.',
  'Não foi possível obter informações da sala de espera, tente novamente':
    'Não foi possível obter informações da sala de espera, tente novamente',
  'Preencha os campos corretamente.': 'Preencha os campos corretamente.',
  'Email inválido! Você já está cadastrado no sistema?':
    'Email inválido! Você já está cadastrado no sistema?',
  'Você realmente solicitou a alteração de senha?':
    'Você realmente solicitou a alteração de senha?',
  'Não foi possível atualizar imagem do setor.':
    'Não foi possível atualizar imagem do setor.',
  'Setor editado com sucesso!': 'Setor editado com sucesso!',
  'Setor criado com sucesso!': 'Setor criado com sucesso!',
  'Não foi possível criar setor.': 'Não foi possível criar setor.',
  'Voltar para a listagem de setores': 'Voltar para a listagem de setores',
  'Já existe um setor com esse nome.': 'Já existe um setor com esse nome.',
  'Não foi possível atualizar setor.': 'Não foi possível atualizar setor.',
  'Não foi possível associar imagem ao setor.':
    'Não foi possível associar imagem ao setor.',
  'Seu vídeo está sendo baixado!': 'Seu vídeo está sendo baixado!',
  'Erro ao fazer download!': 'Erro ao fazer download!',
  'Diminuir nota': 'Diminuir nota',
  'Expandir nota': 'Expandir nota',
  'Escreva aqui as anotações sobre o paciente.':
    'Escreva aqui as anotações sobre o paciente.',
  'Salvamento automático': 'Salvamento automático',
  'Preenchimento de dados': 'Preenchimento de dados',
  'Identificamos que o usuário já está cadastrado na plataforma. Deseja preencher automaticamente os campos com os seus dados?':
    'Identificamos que o usuário já está cadastrado na plataforma. Deseja preencher automaticamente os campos com os seus dados?',
  Atenção: 'Atenção',
  'O usuário já está cadastrado neste setor.':
    'O usuário já está cadastrado neste setor.',
  'O usuário já está cadastrado nesta organização.':
    'O usuário já está cadastrado nesta organização.',
  'É necessário que pelo menos um setor seja selecionado.':
    'É necessário que pelo menos um setor seja selecionado.',
  'Seu atendimento com qualidade e segurança.':
    'Seu atendimento com qualidade e segurança.',
  'Digite o email cadastrado': 'Digite o email cadastrado',
  'Digite sua senha': 'Digite sua senha',
  'Lembrar-me': 'Lembrar-me',
  ou: 'ou',
  'Criar conta gratuita': 'Criar conta gratuita',
  'Conheça mais sobre o serviço': 'Conheça mais sobre o serviço',
  'Campo obrigatório': 'Campo obrigatório',
  'Políticas de privacidade': 'Políticas de privacidade',
  e: 'e',
  'Título do atendimento': 'Título do atendimento',
};
