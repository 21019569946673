import styled from 'styled-components';

export const ContainerHelp = styled.div``;

export const HelpText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #004ba5;
  width: 282px;
  height: 44px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  border-radius: 5px 5px 0px 0px;
  #x {
    background-color: transparent;
    border: none;
    padding-top: 8px;
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const FunctionsHelp = styled.div`
  width: 282px;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 8px rgba(0, 0, 0, 0.1);
  padding-top: 15px;
`;

export const MicrofoneHelp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 250px;
  width: 250px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 6px;
  padding-left: 5px;
  margin-left: 10px;
  color: #063dbd;
  text-decoration: underline;
  margin-top: 10px;
  cursor: pointer;
  #seta {
    margin-right: 6px;
  }
`;

export const InputBusca = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    display: flex;
    justify-content: space-around;
    width: 266px;
    height: 40px;
    border: 1px solid #1756e8;
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 20px;
  }
  input {
    outline: 0;
    border: none;
  }
  button {
    outline: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;
