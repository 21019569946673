import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllNotificationUser as UseCaseRemoteGetAllNotificationUser } from '~/domain/usecases/Notifications/remote';
import { GetAllNotificationUser as UseCaseReduxGetAllNotificationUser } from '~/domain/usecases/Notifications/redux';

import { RemoteGetAllNotificationUser } from '~/data/repository/Notifications';
import { ReduxGetAllNotificationUser } from '~/data/store/reducer/Notifications/usecases/ReduxGetAllNotificationUser';

export const makeRemoteGetAllNotificationUser = (): UseCaseRemoteGetAllNotificationUser =>
  new RemoteGetAllNotificationUser(
    makeApiUrl('/notificationsUser/list'),
    makeHttpClient(),
  );

export const makeReduxGetAllNotificationUser = (): UseCaseReduxGetAllNotificationUser =>
  new ReduxGetAllNotificationUser();
