import React from 'react';
import { IconDelete } from '~/presentation/base/icons';
import { Container } from './styles/StyledPermision';
import { ListProps } from './interface';
import ListPermision from './ListPermision';
import AddPermision from './AddPermision';

const Permission: React.FC<ListProps> = () => {
  const showInformation: ListProps = {
    professionals: [
      {
        description: 'Criar um novo atendimento',
        role: 'Organizador',
        actions: IconDelete,
      },
      {
        description: 'Cadastrar um novo paciente',
        role: 'Organizador',
        actions: IconDelete,
      },
      {
        description: 'Gerar receita avulsa',
        role: 'Profissional de saúde',
        actions: IconDelete,
      },
    ],
  };

  return (
    <Container>
      <AddPermision />
      <ListPermision professionals={showInformation.professionals} />
    </Container>
  );
};

export default Permission;
