import React from 'react';
import { translator } from '../../i18n';

import {
  Escop,
  ConfirmCancel,
  Message,
  Title,
  WarningContainer,
} from './styles/StyledWarningMessage';

import { ownProps } from '../interface';

import { MessageOptions } from '~/domain/interfaces/redux/message';

const Warning: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.userWaitingRoomMessage;

  const { active, actionCancel, actionOk } = message;

  return (
    <>
      {msgName === active ? (
        <WarningContainer>
          <Escop>
            <Title>
              <span>{translator('Aviso importante')}</span>
            </Title>
            <Message>
              <p>{translator('Ao remover o usuário, algumas das informações associadas a ele (dados de consultas médicas, documentos clínicos e de suporte, conversas por chat) serão mantidas de acordo com as políticas de privacidade e termos de uso do serviço.')}</p>
            </Message>
            <ConfirmCancel onClick={actionOk}>
              {translator('Excluir usuário')}
            </ConfirmCancel>
          </Escop>
        </WarningContainer>
      ) : null}
    </>
  );
};

export default Warning;
