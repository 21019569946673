import React, { useState } from 'react';
import {
  Header,
  Container,
  Body,
  Footer,
  TitlePrescription,
} from './styles/StyledPrescription';

import {
  ChooseClinicalDocument,
  DocumentPreview,
  GeneralData,
} from '~/presentation/components/prescription';

import { Step, StepMagic } from '~/presentation/components/steps';
import ClinicalDocument from './ClinicalDocument';
import Signature from '../signature/Signature';

import { GetCertificates } from '~/domain/usecases/deamon/remote';

import { Health } from '~/presentation/base/icons';

import { Button } from '~/presentation/components/UI';
import { translator } from '../i18n';

const Prescription = () => {
  const [crement, setCrement] = useState<number>(1);
  const data = {
    isSigned: 'Assinado',
    name: 'Denilson',
  };

  function handleBar(number: number) {
    if (crement + number > 5 || crement + number < 1) {
      setCrement(crement);
    } else {
      setCrement(crement + number);
    }
  }

  const certificatedSelected = (datatest: GetCertificates.Certificate) => {
    console.log('selected: ', datatest);
  };

  return (
    <Container>
      {crement > 1 ? (
        <TitlePrescription>
          <Health />
          <h1>Receita Simples</h1>
        </TitlePrescription>
      ) : null}
      {crement !== 5 ? (
        <Header>
          <StepMagic current={crement}>
            <Step title="Tipo de documento" />
            <Step title="Informações gerais" />
            <Step title="Prescrição" />
            <Step title="Assinatura do documento" />
          </StepMagic>
        </Header>
      ) : null}
      <Body>
        {crement === 1 && <ClinicalDocument />}
        {crement === 2 && <GeneralData />}
        {crement === 3 && <ChooseClinicalDocument />}
        {crement === 4 && (
          <Signature
            changeSelected={(e: string) =>
              console.log('certificado escolhido: ', e)
            }
          />
        )}
        {crement === 5 && (
          <DocumentPreview isSigned={data.isSigned} name={data.name} />
        )}
      </Body>
      <Footer>
        <Button
          variant="secundary"
          size="small"
          rounded
          onClick={() => handleBar(-1)}
        >
          {translator('Voltar')}
        </Button>
        {crement > 1 ? (
          <Button rounded onClick={() => handleBar(1)} size="small">
            {translator('Avançar')}
          </Button>
        ) : null}
      </Footer>
    </Container>
  );
};

export default Prescription;
