import styled from 'styled-components';

interface successProps {
  content: boolean;
}

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
`;

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 27em;
  max-height: 15em;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Content = styled.div<successProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 120px;
  text-align: center;

  p {
    color: black;
    font-size: 18px;
    font-weight: bold;

    width: 230px;
    margin-left: 18px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;
