import React, { useEffect, useState } from 'react';
import { IconSort } from '~/presentation/base/icons';
import {
  iClinicalDocs,
  iSupportDocs,
} from '~/presentation/roles/professional/pages/appointment/interface';
import { translator } from '../i18n';
import DocumentsItem from './DocumentItem';

import { Container, Header } from './styles/StyledDocumentList';

import { GetAllSupportDocs } from '~/domain/usecases/supportDoc/remote';
import { getLocale } from '~/utils/getLocale';

interface DocumentsListProps {
  type?: string;
  docs?: iClinicalDocs[];
  docsSup?: GetAllSupportDocs.Model;
  openFunction?: Function;
}

const DocumentsList: React.FC<DocumentsListProps> = ({
  type = 'clinical-documents',
  docs,
  docsSup,
  openFunction,
}) => {
  const locale = getLocale();

  function renderSupportDocs() {
    return docsSup?.results?.map(item => (
      <DocumentsItem
        id={item.document.id ?? item.index}
        type="supporting-documents"
        title={item.document.filename || 'null in backend'}
        subTitle={item.type.name}
        date={new Date(item.document.sent).toLocaleDateString(locale)}
        documentSize={Number(item.document.size / 1e6).toPrecision(2)}
        isSigned
      />
    ));
  }
  const typeDoc = {
    BASIC: 'Receita simples',
    MEDICALCERTIFICATE: 'Atestado Médico',
    EXAMREQUEST: 'Requisição de exames',
  };

  function renderDocs() {
    return docs?.map(item => {
      let content = '';

      switch (item.type.name) {
        case 'BASIC':
          content = `${item.content.drugs?.map(drug => drug.name)}`;
          break;
        case 'MEDICALCERTIFICATE':
          content = `${new Date(item.document.sent).toLocaleDateString(
            locale,
          )} - ${item.content.periodo}`;
          break;
        case 'EXAMREQUEST':
          content = `${item.content.solicitacao?.map(
            requested => requested.exam,
          )}`;
          break;
        default:
          break;
      }

      return (
        <DocumentsItem
          open={openFunction}
          id={item.document.id ?? item.index}
          type="clinical-documents"
          title={translator(typeDoc[item.type.name])}
          subTitle={content}
          date={new Date(item.document.sent).toLocaleDateString(locale)}
          isSigned={item.signed}
        />
      );
    });
  }

  return (
    <Container>
      <Header>
        <div>
          {type === 'clinical-documents'
            ? translator('Lista de Documentos clínicos')
            : translator('Lista de Documentos de apoio')}
        </div>
        <span>
          {/* TODO translator */}
          <text>Mais recentes</text>
          <IconSort id="btn_sort" />
        </span>
      </Header>
      {type === 'clinical-documents' ? (
        <div id="clin_doc_list">{renderDocs()}</div>
      ) : (
        <div id="sup_doc_list">{renderSupportDocs()}</div>
      )}
    </Container>
  );
};
export default DocumentsList;
