import { UpdateAppointmentCompanion } from '~/domain/usecases/appointmentCompanion/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteUpdateAppointmentCompanion
  implements UpdateAppointmentCompanion {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateAppointmentCompanion.Model>;

  constructor(
    url: string,
    httClient: HttpClient<UpdateAppointmentCompanion.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: UpdateAppointmentCompanion.Params,
  ): Promise<UpdateAppointmentCompanion.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/guest/${params.companionCode}`,
      method: 'get',
    });
    console.log(httpResponse.body, httpResponse.statusCode);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
