/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ReduxCreateClinicalDocs } from '~/data/store/reducer/clinicalDocs/usecases';
import { iStore } from '~/domain/interfaces/models';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';
import { makeReduxCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { translator } from '~/presentation/components/i18n';
import { Signature } from '~/presentation/components/signature';
import {
  iPrescription,
  iValidation,
} from '~/presentation/components/simplePrescription/interface';
import { Button } from '~/presentation/components/UI';
import { schema } from '~/validation/validators/document/CreateClinicalDocValidator';
import GeneralData, { GeneralDataProps } from './GeneralData';
import { ConnectComponent } from './mapper/MapperSimplePrescription';
import Medication from './Medication';
import {
  ButtonStyle,
  Content,
  Header,
  Main,
} from './styles/StyledSimplePrescription';

export interface externalProps {
  basicPrescription?: RemoteCreateClinicalDocs | ReduxCreateClinicalDocs;
  goBackToListDocument: () => void;
}

export type ownProps = GeneralDataProps;

interface iStateParams {
  appointmentId: string;
  professional: string;
  consultant: string;
}

const SimplePrescription: React.FC<ownProps & externalProps> = props => {
  const [crement, setCrement] = useState<number>(1);
  const [drugs, setDrugs] = useState<iPrescription[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<number>(0);

  const stateParams = useLocation<iStateParams>().state;

  const { auth } = useSelector((store: iStore) => store);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const specialty =
    role === 'PRO'
      ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
          ?.professions.specialties[0].id
      : -1;

  const [basicPrescription, setBasicPrescription] = useState<iValidation>({
    professional:
      stateParams && stateParams.professional
        ? Number(stateParams.professional)
        : -1,
    patient:
      stateParams && stateParams?.consultant
        ? Number(stateParams?.consultant)
        : -1,
    drugs: [],
    orgUnitName,
  });

  const { goBackToListDocument } = props;

  function handleBar(number: number) {
    if (crement + number > 3 || crement + number < 1) {
      setCrement(crement);
      createBasicPrescription();
    } else {
      setCrement(crement + number);
    }
  }

  let dataToSend: RemoteCreateClinicalDocs.Params = {};

  const createBasicPrescription = () => {
    setDrugs(prevState =>
      prevState.map(item => {
        delete item.id;
        return item;
      }),
    );

    if (stateParams && stateParams.appointmentId !== undefined) {
      dataToSend = {
        appointment: Number(stateParams.appointmentId),
        type: 1,
        content: {
          drugs,
        },
        certificate: basicPrescription.certificate,
        certificateId: basicPrescription.certificateId,
        sidebar: true,
      };

      try {
        const validate = schema.parse(dataToSend);
        makeReduxCreateClinicalDocs().create({
          ...dataToSend,
          goBackToListDocument,
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      dataToSend = {
        org: orgId,
        orgUnit: orgUnitId,
        patient: basicPrescription.patient,
        professional: basicPrescription.professional,
        type: 1,
        content: {
          drugs,
        },
        certificate: basicPrescription.certificate,
        certificateId: basicPrescription.certificateId,
        specialty,
        sidebar: true,
      };

      try {
        const validate = schema.parse(dataToSend);
        makeReduxCreateClinicalDocs().create({
          ...dataToSend,
          goBackToListDocument,
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  function next(dados: iValidation) {
    setBasicPrescription({ ...basicPrescription, ...dados });
    handleBar(1);
  }

  function back(dados: iValidation) {
    setBasicPrescription({ ...basicPrescription, ...dados });
    handleBar(-1);
  }

  return (
    <>
      <Header>Receita Simples</Header>
      <Content>
        <Main>
          <p style={{ marginBottom: '20px' }}>
            Etapa {crement} de 3: Informações gerais
          </p>
          {crement === 1 && (
            <GeneralData
              professional={props?.professional}
              consultant={props?.consultant}
              next={next}
              state={basicPrescription}
            />
          )}
          {crement === 2 && (
            <Medication
              arrId={updateId}
              setArrId={setUpdateId}
              next={next}
              back={back}
              state={basicPrescription}
              items={drugs}
              setItems={setDrugs}
            />
          )}
          {crement === 3 && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              <Signature
                changeSelected={(e: {
                  certificateId: string;
                  certificate: string;
                }) =>
                  setBasicPrescription({
                    ...basicPrescription,
                    certificate: e.certificate,
                    certificateId: e.certificateId,
                  })
                }
                changeDisabled={(state: boolean) => setDisableButton(state)}
                onSignSubmit={createBasicPrescription}
              />
            </div>
          )}
        </Main>

        {crement === 3 ? (
          <ButtonStyle>
            <Button
              id="button_back"
              rounded
              variant="secundary"
              onClick={() => handleBar(-1)}
              size="small"
            >
              {`${translator('Voltar')}`}
            </Button>
            <Button
              id="button_conclude"
              rounded
              size="small"
              onClick={createBasicPrescription}
              disabled={loading || disableButton}
            >
              {`${translator('Concluir')}`}
            </Button>
          </ButtonStyle>
        ) : null}
      </Content>
    </>
  );
};

export default ConnectComponent(SimplePrescription);
