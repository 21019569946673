import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateClinicalDocs as UseCaseRemoteUpdateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { UpdateClinicalDocs as UsecaseReduxUpdateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteUpdateClinicalDocs } from '~/data/repository/clinicalDocs';
import { ReduxUpdateClinicalDocs } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteUpdateClinicalDocs = (): UseCaseRemoteUpdateClinicalDocs =>
  new RemoteUpdateClinicalDocs(makeApiUrl('/clinicalDocs'), makeHttpClient());

export const makeReduxUpdateClinicalDocs = (): UsecaseReduxUpdateClinicalDocs =>
  new ReduxUpdateClinicalDocs();
