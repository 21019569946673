import React, { useState } from 'react';

import { Button } from '~/presentation/components/UI';
import { IconWaitingRoom, IconV4Hatendimento } from '~/presentation/base/icons';
import { Icon } from '~/presentation/components/icon';

import { translator } from '~/presentation/components/i18n';

import { Container, Header, Image } from './styles/StyledWaitingProfessional';
import { V4hSpin } from '~/presentation/components/spin';

interface ownProps {
  updateState: Function;
  left: () => any;
}

export default function WaitingProfessional(props: ownProps): JSX.Element {
  const { updateState, left } = props;

  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  return (
    <Container>
      <Header>
        {logoUrl ? (
          <Image alt="Logo" src={logoUrl} width="200px" />
        ) : (
          <Icon src={IconV4Hatendimento} width="250px" height="80px" />
        )}
      </Header>
      <span className="title">
        {translator(
          'O médico foi informado que',
        )}
      </span>
      <span className="title">
        {translator(
          'você está na sala de espera',
        )}
      </span>
      <IconWaitingRoom />
      <V4hSpin fullScreen={false} background="none" />
      <span className="message">
        {translator(
          'Aguarde nesta tela.',
        )}
      </span>
      <span className="message">
        {translator(
          'Você será avisado quando o',
        )}
      </span>
      <span className="message">
        {translator(
          'médico estiver pronto',
        )}
      </span>

      <Button rounded disabled>
        {translator(
          'Entrar na consulta',
        )}
      </Button>
      <Button rounded variant="secundary" onClick={left}>
        {translator(
          'Deixar a sala',
        )}
      </Button>
    </Container>
  );
}
