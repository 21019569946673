import { OrgUnitList } from '~/domain/usecases/orgUnit/redux/OrgUnitList';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  OrgUnitTypes,
} from '~/domain/interfaces/redux/orgUnit/list';

export const listRequest = (
  payload: OrgUnitList.Params,
): iActionList => ({
  type: OrgUnitTypes.LIST,
  payload,
});

export const listSuccess = (
  params: OrgUnitList.Model,
): iActionListSuccess => ({
  type: OrgUnitTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: OrgUnitTypes.LIST_FAILED,
});
