import React from 'react';
import { Route } from 'react-router-dom';

import { AvatarImage } from '~/presentation/components/avatar-roles';
import ConfirmCancelSchedule from '~/presentation/components/messages/appointment/ConfirmCancellation';
import AppointmentCreatedSuccessfully from '~/presentation/components/messages/appointment/SuccessfullyCreated';
import Teste from './testOrganizer';
import TesteHelp from './testHelp';
import { PaginationByDate } from '~/presentation/components/paginationByDate';

import { HistoryChat } from '~/presentation/components/historyChat';
import { Specialty } from '~/presentation/components/specialty/';
import { UploadFiles } from '~/presentation/components/uploadFiles';
import { HeaderChat, FooterChat, Chat } from '~/presentation/components/chat';

import { NewConsult } from '~/presentation/components/newConsult';

import { Inputmask } from './inputmask';

import {
  FilterAppointment,
  FilterOrganizer,
  FilterPatient,
  FilterProfessional,
  FilterAdministrator,
  FilterClinicalDocs,
} from '~/presentation/components/filter';

import {
  DocumentPreview,
  GeneralData,
  ChooseClinicalDocument,
  ItemPrescription,
  Prescription,
} from '~/presentation/components/prescription';

import { Signature } from '~/presentation/components/signature';
import {
  Certificate,
  MedicalCertificateView,
} from '~/presentation/components/medicalCertificate';
import { ExamRequest } from '~/presentation/components/examRequest';
import { ClinicalDoc } from '~/presentation/components/clinicalDoc';

import {
  PreSettingsGuest,
  PreSettingsParticipant,
  PreSettingsProfessional,
} from '~/presentation/components/preConfSettings';

import {
  CodePassword,
  QrCode,
} from '~/presentation/components/pharmaceuticalAccess';

import { SimpleRecipe } from '~/presentation/components/simplePrescription';
import {
  ItemAntiMicroPrescription,
  AntiMicroPrescription,
  AntiMicrobial,
  AntiMicrobialView,
} from '~/presentation/components/antimicrobial';

import { MedicalReport } from '~/presentation/components/medicalReport';
import { SpecialPrescription } from '~/presentation/components/specialPrescription';

import TermsOfUse from '~/presentation/components/messages/appointment/TermsOfUse';
import { InvoiceAndPayments } from '~/presentation/components/invoiceAndPayments';
import Billing from '~/presentation/roles/adm/pages/billing';

import {
  InputsPage,
  SelectPage,
  TestComponent,
  BreadcrumbPage,
  IconPage,
  CalendarPage,
  ProgressBar,
  HeaderPage,
  Notes,
  CauroselParticipants,
  Editconsult,
  TestClinicalDocumentViewer,
  useTable,
  Timeline,
  Popover,
  TestMediaViewer,
  ClinicalDocuments,
  SupportingDocuments,
  InputDatePage,
  TestUserListManager,
  TestUserListProfessional,
  TestUserListParticipant,
  TestUserListAdm,
  Permission,
  TestNavigationBar,
  TestDropdown,
  Notification,
  TestHeader,
  TestMenu,
  TestNotification,
  TesteAvatarBadger,
  TestLink,
} from '.';
import { Services } from '~/presentation/components/menu';
import {
  ServicesCard,
  ServicesCardOnDemand,
} from '~/presentation/components/servicesCard';
import OrgUnitDetail from '~/presentation/components/OrgUnitDetail/OrgUnitDetail';
import { OrgUnitGrid } from '~/presentation/components/OrgUnitDetail';
import NewOrgUnit from '~/presentation/roles/adm/pages/newOrgUnit';
import InviteExternalProfessionals from '~/presentation/components/messages/appointment/InviteExternalProfessionals';
import MedicalCertificateSideBar from '~/presentation/components/sidebar/clinicalDocs/medicalCertificateSiderBar/MedicalCertificateSideBar';

const baseRoute = 'teste';

const RoutesTest = [
  <Route path={`/${baseRoute}`} exact component={TestComponent} />,
  <Route path={`/${baseRoute}/input`} component={InputsPage} />,
  <Route
    path={`/${baseRoute}/medicalview`}
    component={MedicalCertificateView}
  />,
  <Route path={`/${baseRoute}/select`} component={SelectPage} />,
  <Route path={`/${baseRoute}/microbial`} component={AntiMicroPrescription} />,
  <Route path={`/${baseRoute}/microbialview`} component={AntiMicrobialView} />,
  <Route path={`/${baseRoute}/microbialpage`} component={AntiMicrobial} />,
  <Route path={`/${baseRoute}/report`} component={MedicalReport} />,
  <Route
    path={`/${baseRoute}/specialprescription`}
    component={SpecialPrescription}
  />,
  <Route path={`/${baseRoute}/inputdate`} component={InputDatePage} />,
  <Route path={`/${baseRoute}/breadcrumb`} component={BreadcrumbPage} />,
  <Route path={`/${baseRoute}/icon`} component={IconPage} />,
  <Route path={`/${baseRoute}/calendar`} component={CalendarPage} />,
  <Route path={`/${baseRoute}/avatarimage`} component={AvatarImage} />,
  <Route path={`/${baseRoute}/avatar`} component={Teste} />,
  <Route path={`/${baseRoute}/progressbar`} component={ProgressBar} />,
  <Route path={`/${baseRoute}/header`} component={HeaderPage} />,
  <Route path={`/${baseRoute}/notes`} component={Notes} />,
  <Route path={`/${baseRoute}/permission`} component={Permission} />,
  <Route path={`/${baseRoute}/help`} component={TesteHelp} />,
  <Route path={`/${baseRoute}/editconsult`} component={Editconsult} />,
  <Route path={`/${baseRoute}/notification`} component={Notification} />,
  <Route path={`/${baseRoute}/popover`} component={Popover} />,
  <Route path={`/${baseRoute}/headerchat`} component={HeaderChat} />,
  <Route path={`/${baseRoute}/footerchat`} component={FooterChat} />,
  <Route path={`/${baseRoute}/chat`} component={Chat} />,
  <Route path={`/${baseRoute}/avatarbadger`} component={TesteAvatarBadger} />,
  <Route path={`/${baseRoute}/preview`} component={DocumentPreview} />,
  <Route path={`/${baseRoute}/signature`} component={Signature} />,
  <Route path={`/${baseRoute}/clinical`} component={ClinicalDoc} />,
  <Route path={`/${baseRoute}/general`} component={GeneralData} />,
  <Route path={`/${baseRoute}/choose`} component={ChooseClinicalDocument} />,
  <Route path={`/${baseRoute}/item`} component={ItemPrescription} />,
  <Route path={`/${baseRoute}/prescription`} component={Prescription} />,
  <Route path={`/${baseRoute}/certificate`} component={Certificate} />,
  <Route path={`/${baseRoute}/simplerecipe`} component={SimpleRecipe} />,
  <Route path={`/${baseRoute}/examrequest`} component={ExamRequest} />,
  <Route path={`/${baseRoute}/newconsult`} component={NewConsult} />,
  <Route path={`/${baseRoute}/inputmask`} component={Inputmask} />,
  <Route path={`/${baseRoute}/filterpatient`} component={FilterPatient} />,
  <Route path={`/${baseRoute}/filterorganizer`} component={FilterOrganizer} />,
  <Route path={`/${baseRoute}/terms`} component={TermsOfUse} />,
  <Route path={`/${baseRoute}/payment`} component={InvoiceAndPayments} />,
  <Route
    path={`/${baseRoute}/genside`}
    component={MedicalCertificateSideBar}
  />,
  <Route
    path={`/${baseRoute}/external`}
    component={InviteExternalProfessionals}
  />,

  <Route
    path={`/${baseRoute}/filterclinicaldocs`}
    component={FilterClinicalDocs}
  />,
  <Route
    path={`/${baseRoute}/filteradministrator`}
    component={FilterAdministrator}
  />,
  <Route
    path={`/${baseRoute}/filterProfessional`}
    component={FilterProfessional}
  />,
  <Route
    path={`/${baseRoute}/filterAppointment`}
    component={FilterAppointment}
  />,
  <Route
    path={`/${baseRoute}/clinicalDocuments`}
    component={ClinicalDocuments}
  />,
  <Route
    path={`/${baseRoute}/supportingDocuments`}
    component={SupportingDocuments}
  />,
  <Route path={`/${baseRoute}/userList`} component={TestUserListManager} />,
  <Route
    path={`/${baseRoute}/userListProfessional`}
    component={TestUserListProfessional}
  />,
  <Route
    path={`/${baseRoute}/userListParticipant`}
    component={TestUserListParticipant}
  />,
  <Route path={`/${baseRoute}/userListAdm`} component={TestUserListAdm} />,
  <Route path={`/${baseRoute}/carousel`} component={CauroselParticipants} />,
  <Route
    path={`/${baseRoute}/testClinicalDocumentViewer`}
    component={TestClinicalDocumentViewer}
  />,
  <Route path={`/${baseRoute}/usetable`} component={useTable} />,
  <Route path={`/${baseRoute}/timeline`} component={Timeline} />,
  <Route path={`/${baseRoute}/mediaviewer`} component={TestMediaViewer} />,
  <Route
    path={`/${baseRoute}/paginationByDate`}
    component={PaginationByDate}
  />,
  <Route path={`/${baseRoute}/navigationbar`} component={TestNavigationBar} />,
  <Route path={`/${baseRoute}/dropdown`} component={TestDropdown} />,
  <Route path={`/${baseRoute}/headerSimples`} component={TestHeader} />,
  <Route path={`/${baseRoute}/menu`} component={TestMenu} />,
  <Route path={`/${baseRoute}/historychat`} component={HistoryChat} />,
  <Route path={`/${baseRoute}/notification`} component={TestNotification} />,
  <Route path={`/${baseRoute}/codepassword`} component={CodePassword} />,
  <Route path={`/${baseRoute}/qrcode`} component={QrCode} />,
  <Route path={`/${baseRoute}/uploadfiles`} component={UploadFiles} />,
  <Route path={`/${baseRoute}/speciality`} component={Specialty} />,
  <Route
    path={`/${baseRoute}/cancelconfirm`}
    component={ConfirmCancelSchedule}
  />,
  <Route
    path={`/${baseRoute}/presettingsguest`}
    component={PreSettingsGuest}
  />,
  <Route
    path={`/${baseRoute}/presettingsparticipant`}
    component={PreSettingsParticipant}
  />,
  <Route
    path={`/${baseRoute}/presettingsprofessional`}
    component={PreSettingsProfessional}
  />,
  <Route path={`/${baseRoute}/link`} component={TestLink} />,
  <Route
    path={`/${baseRoute}/successfullycreated`}
    component={AppointmentCreatedSuccessfully}
  />,
  <Route path={`/${baseRoute}/servicesmenu`} component={Services} />,
  <Route path={`/${baseRoute}/billing`} component={Billing} />,
  <Route path={`/${baseRoute}/testingcard`} component={ServicesCard} />,
  <Route path={`/${baseRoute}/newsector`} component={NewOrgUnit} />,
  <Route
    path="/teste/cardondemand"
    component={() => (
      <OrgUnitGrid>
        <OrgUnitDetail
          percentValue={2.15}
          title="Atendimentos Realizados"
          value={256}
        />
        <OrgUnitDetail
          percentValue={2.15}
          title="Atendimentos Realizados"
          value={256}
        />
        <OrgUnitDetail
          percentValue={2.15}
          title="Atendimentos Realizados"
          value={256}
        />
        <OrgUnitDetail
          percentValue={2.15}
          title="Atendimentos Realizados"
          value={256}
        />
      </OrgUnitGrid>
    )}
  />,
];

export default RoutesTest;
