import React from 'react';

// import { Container } from './styles';
import { UserListAdm } from '~/presentation/roles/adm/components/userListAdm';

const TestUserListAdm: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <div>
        <UserListAdm
          list="list-1"
          participant
          professional
          timeStart
          timeEnd
          typeIs
          specialty
          status2
          actions={{ cancel: true, person: true }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <UserListAdm
          list="list-2"
          cod
          date
          professional
          specialty
          phone
          email
          actions={{ cancel: true, person: true }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <UserListAdm
          list="list-3"
          date
          participant
          professional
          specialty
          register
          origin
          clinicalDocument
          actions={{ cancel: true, person: true }}
        />
      </div>
    </div>
  );
};

export default TestUserListAdm;
