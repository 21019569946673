import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  background: #fbfbfb;
  margin-bottom: 8px;
  .bolinha {
    position: relative;
    right: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #ec5c52;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;

export const Role = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  height: 14px;
  color: #7a7a7a;
  display: flex;
`;
