import React from 'react';
import { PrivateRoute } from '~/main/routes/PrivateRoute';

import {
  ClinicalDocsFactory,
  MakeCreateSimplePrescriptionPage,
} from '~/main/factories/pages/clinicalDocs';
import {
  DocumentDetailPage,
  DocumentViewPage,
} from '~/presentation/pages/documentDetail';
import { SupportDocsFactory } from '../factories/pages/suportDoc';

const RoutesClinicalDocs = [
  <PrivateRoute exact path="/clinicaldocs" component={ClinicalDocsFactory} />,
  <PrivateRoute
    exact
    path="/clinicaldoc/detail"
    component={DocumentDetailPage}
  />,
  <PrivateRoute
    exact
    path="/clinicaldoc/display"
    component={DocumentViewPage}
  />,
  <PrivateRoute
    exact
    path="/supportdoc/detail"
    component={SupportDocsFactory}
  />,

  <PrivateRoute
    exact
    path="/clinicaldoc/new"
    component={MakeCreateSimplePrescriptionPage}
  />,
  <PrivateRoute
    exact
    path="/clinicaldocs/:language"
    component={ClinicalDocsFactory}
  />,
];

export default RoutesClinicalDocs;
