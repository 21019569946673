import React from 'react';

import { ClinicalDocumentViewer } from '~/presentation/components/clinicalDocumentViewer';

interface Object {
  drug: string;
  posology: string;
  dosage: string;
}

const TestClinicalDocumentViewer: React.FC = () => {
  const data: Object[] = [
    {
      drug: 'Rivotril',
      posology: '1x ao dia antes de dormir',
      dosage: '0,25mg',
    },
    {
      drug: 'Propanol',
      posology: '1x ao dia durante a manhã',
      dosage: '40mg',
    },
    {
      drug: 'Rivotril',
      posology: '2x ao dia antes de dormir',
      dosage: '0,25mg',
    },
    {
      drug: 'Rivotril',
      posology: '1x ao dia durante a manhã',
      dosage: '40mg',
    },
  ];
  return <ClinicalDocumentViewer />;
};

export default TestClinicalDocumentViewer;
