import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAppointmentById as UsecaseRemoteGetAppointmentById } from '~/domain/usecases/appointment/remote';

import { RemoteGetAppointmentById } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteGetAppointmentById = (): UsecaseRemoteGetAppointmentById =>
  new RemoteGetAppointmentById(makeApiUrl('/appointments'), makeHttpClient());
