import { iPermissions } from '~/domain/interfaces/models';
import { iPermissionsActions } from './actions';
import { PermissionTypes } from '~/domain/interfaces/redux/permissions/types';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const InitialState: iPermissions = {
  loading: true,
  acl: {
    totalItems: 0,
    resources: [],
  },
};

const reducer = (
  state = InitialState,
  action: iPermissionsActions,
): iPermissions => {
  switch (action.type) {
    case PermissionTypes.GET_ACL:
      return { ...state, loading: true };
    case PermissionTypes.GET_ACL_SUCCESS:
      return { ...state, loading: false, acl: { ...action.payload } };
    case PermissionTypes.GET_ACL_FAILED:
      return { ...state, loading: false };
    case AuthTypes.LOGOUT:
      return InitialState;
    default:
      return state;
  }
};

export default reducer;
