import React from 'react';

import { Icon } from '~/presentation/components/icon';
import { HeaderSimples, PageHeader } from '~/presentation/components/header';
import { IconCancelOutline } from '~/presentation/base/icons';

import { Container } from './styles/StyledHeader';

const Header = () => {
  return (
    <Container>
      <PageHeader backDescription="Contatos" title="Profile" />
    </Container>
  );
};

export default Header;
