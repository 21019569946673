import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Interconsult, NewAppointment } from './styles/StyledBody';
import { IconInterConsult, IconCalendar } from '~/presentation/base/icons';
import { translator } from '../i18n';

const Body = () => {
  return (
    <Container>
      <NewAppointment>
        <Link
          to={{
            pathname: '/appointment/new',
            state: {
              from: window.location.pathname.replaceAll('/', ''),
            },
          }}
          className="link"
        >
          <IconCalendar width="25px" className="icon" />
          {translator('Novo agendamento')}
        </Link>
      </NewAppointment>
      <Interconsult>
        <Link to="/appointment/funciona">
          <IconInterConsult width="25px" />
          Interconsulta
        </Link>
      </Interconsult>
    </Container>
  );
};

export default Body;
