import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Health } from '~/presentation/base/icons';
import { StepMagic, Step } from '~/presentation/components/steps';
import { Button } from '~/presentation/components/UI/buttons';
import Prescription, { ownProps as PrescriptionProps } from './Prescription';
import { Signature } from '~/presentation/components/signature';
import {
  Body,
  Container,
  Content,
  Footer,
  Header,
  ContainerButton,
} from './styles/StyledSimplePrescription';
import SimplePrescriptionView from './SimplePrescriptionView';

import GeneralData, { ownProps as GeneralDataProps } from './GeneralData';

import { iClinicalDocs } from '~/domain/interfaces/models/ClinicalDocs';

import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';

import { GetCertificates } from '~/domain/usecases/deamon/remote';

import { ConnectComponent } from './mapper/MapperSimplePrescription';
import { schema } from '~/validation/validators/document/CreateClinicalDocValidator';
import { iValidation, iPrescription } from './interface';
import {
  makeRemoteCreateClinicalDocs,
  makeReduxCreateClinicalDocs,
} from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';

export interface Props {
  basicPrescription: RemoteCreateClinicalDocs | ReduxCreateClinicalDocs;
}

export type ownProps = Props & GeneralDataProps;

interface iStateParams {
  appointmentId: string;
  professional: string;
  consultant: string;
}

const SimplePrescription: React.FC<ownProps> = (props): JSX.Element => {
  const [crement, setCrement] = useState<number>(1);
  const [drugs, setDrugs] = useState<iPrescription[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<number>(0);

  const stateParams = useLocation<iStateParams>().state;

  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { auth } = useSelector((store: iStore) => store);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const specialty =
    role === 'PRO'
      ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
          ?.professions.specialties[0].id
      : -1;

  const [basicPrescription, setBasicPrescription] = useState<iValidation>({
    professional:
      stateParams && stateParams.professional
        ? Number(stateParams.professional)
        : -1,
    patient:
      stateParams && stateParams?.consultant
        ? Number(stateParams?.consultant)
        : -1,
    drugs: [],
    orgUnitName,
  });

  function handleBar(number: number) {
    if (crement + number > 3 || crement + number < 1) {
      setCrement(crement);
      createBasicPrescription();
    } else {
      setCrement(crement + number);
    }
  }
  const data = {
    isSigned: 'Assinado',
    name: 'Danilo',
  };

  function showSomething() {
    console.log('mostrando alguma coisa: ', drugs);
  }

  let dataToSend: RemoteCreateClinicalDocs.Params = {};

  const createBasicPrescription = () => {
    setDrugs(prevState =>
      prevState.map(item => {
        delete item.id;
        return item;
      }),
    );

    if (stateParams && stateParams.appointmentId !== undefined) {
      dataToSend = {
        appointment: Number(stateParams.appointmentId),
        type: 1,
        content: {
          drugs,
        },
        certificate: basicPrescription.certificate,
        certificateId: basicPrescription.certificateId,
      };

      try {
        const validate = schema.parse(dataToSend);
        makeReduxCreateClinicalDocs().create(dataToSend);
      } catch (e) {
        console.error(e);
      }
    } else {
      dataToSend = {
        org: orgId,
        orgUnit: orgUnitId,
        patient: basicPrescription.patient,
        professional: basicPrescription.professional,
        type: 1,
        content: {
          drugs,
        },
        certificate: basicPrescription.certificate,
        certificateId: basicPrescription.certificateId,
        specialty,
      };

      try {
        const validate = schema.parse(dataToSend);
        makeReduxCreateClinicalDocs().create(dataToSend);
      } catch (e) {
        console.error(e);
      }
    }
  };

  function next(dados: iValidation) {
    setBasicPrescription({ ...basicPrescription, ...dados });
    handleBar(1);
  }

  function back(dados: iValidation) {
    setBasicPrescription({ ...basicPrescription, ...dados });
    handleBar(-1);
  }

  return (
    <Container>
      <Content>
        <Health />
        <h1>{`${translator('Receita simples')}`}</h1>
      </Content>
      {crement !== 4 ? (
        <Header>
          <StepMagic current={crement}>
            <Step title={`${translator('Informações gerais')}`} />
            <Step title={`${translator('Prescrição')}`} />
            <Step title={`${translator('Assinatura do documento')}`} />
          </StepMagic>
        </Header>
      ) : null}
      <Body>
        {crement === 1 && (
          <GeneralData
            professional={props.professional}
            consultant={props.consultant}
            next={next}
            state={basicPrescription}
          />
        )}
        {crement === 2 && (
          <Prescription
            arrId={updateId}
            setArrId={setUpdateId}
            next={next}
            back={back}
            state={basicPrescription}
            items={drugs}
            setItems={setDrugs}
          />
        )}
        {crement === 3 && (
          <Signature
            changeSelected={(e: {
              certificateId: string;
              certificate: string;
            }) =>
              setBasicPrescription({
                ...basicPrescription,
                certificate: e.certificate,
                certificateId: e.certificateId,
              })
            }
            changeDisabled={(state: boolean) => setDisableButton(state)}
            onSignSubmit={createBasicPrescription}
          />
        )}
        {crement === 4 && (
          <SimplePrescriptionView isSigned={data.isSigned} name={data.name} />
        )}
      </Body>
      {crement === 3 ? (
        <ContainerButton>
          <Button
            id="button_back"
            rounded
            variant="secundary"
            onClick={() => handleBar(-1)}
            size="small"
          >
            {`${translator('Voltar')}`}
          </Button>
          <Button
            id="button_conclude"
            rounded
            size="small"
            onClick={createBasicPrescription}
            disabled={loading || disableButton}
          >
            {`${translator('Concluir')}`}
          </Button>
        </ContainerButton>
      ) : null}
    </Container>
  );
};

export default ConnectComponent(SimplePrescription);
