import React from 'react';
import {
  Container,
  Table,
  Content,
  Description,
  Role,
  Actions,
  ButtonStyle,
} from './styles/StyledListPermision';
import { Button } from '../UI';
import { ListProps } from './interface';
import ItemListPermision from './ItemListPermision';
import { translator } from '../i18n';

const ListPermision: React.FC<ListProps> = ({ professionals }) => {
  return (
    <Container>
      <Content>
        {translator( 'Permissões concedidas')}
      </Content>
      <Table>
        <Description>
          {translator('Descrição')}
        </Description>
        <Role>{translator('Papel')}</Role>
        <Actions>{translator('Ações')}</Actions>
      </Table>
      {professionals.map(item => (
        <ItemListPermision professional={item} />
      ))}
      <ButtonStyle>
        <Button size="medium" className="btnAction" rounded>
          {translator('Salvar alterações')}
        </Button>
      </ButtonStyle>
    </Container>
  );
};

export default ListPermision;
