import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

interface StyledProps {
  list?: string;
}

export const Container = styled.div`
  display: grid;
  flex: 1;
  flex-direction: column;
  div {
    text-align: start;
  }
  button {
    border: 0px;
    width: 15px;
    background-color: ${defaultTheme.background};
  }
`;

export const Wrapper = styled.div`
  text-align: start;
`;

export const CardContainer = styled.div`
  padding: 5px;
`;
export const StatusBar = styled.div<StyledProps>`
  display: grid;
  justify-items: start;
  grid-gap: 10px;
  grid-template-columns: ${({ list }) => {
    switch (list) {
      case 'list-1':
        return '2fr 2fr 1fr 1fr 1fr 1fr 1fr 2fr';
      case 'list-2':
        return '60px 1fr 2fr 1fr 1fr 2fr 2fr';
      case 'list-3':
        return '1fr 2fr 2fr 1fr 2fr 2fr 2fr 1fr';
      default:
        return 'auto';
    }
  }};
  /* grid-template-columns: repeat(auto, 1fr); */
  /* grid-template-rows: auto; */
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  width: 100%;
`;

export const Actions = styled.div``;
export const Cancel = styled.div`
  background: url('~/presentation/base/icons/logo2.png') center center no-repeat;
`;
