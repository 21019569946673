import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iStore, iOrgUnit } from '~/domain/interfaces/models';
import { editOrgUnitProps } from '../index';

export interface editOrgUn {
  id: number;
  shortname?: string;
}

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & editOrgUnitProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { results } = useSelector((store: iStore) => store.orgUnit);
    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      data: MapEditOrgUnitData(results),
      ...(rest as P),
    };
    return <Component {...(rest as P & editOrgUnitProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param appointment data.
 */
export const MapEditOrgUnitData = (
  orgUnit: iOrgUnit['results'],
): editOrgUn[] => {
  if (!orgUnit.length) return [];
  const data: editOrgUn[] = orgUnit.map(item => ({
    id: item.org.id,
    shortname: item.org.shortname,
  }));
  console.log('infos: ', data);

  return data;
};
