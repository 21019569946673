import React from 'react';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IconSuccessIcon } from '~/presentation/base/icons';
import { Button } from '../../UI';
import { ownProps } from '../interface';
import { Body, Container, Content } from './styles/StyledSuccessMessage';

const SuccessMessage: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.success;

  const { active, actionOk, content } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Content content={Boolean(content)}>
              <IconSuccessIcon width="60px" height="60px" />
              {content && <p>{content}</p>}
            </Content>
            <Button
              id="button_confirm"
              rounded
              variant="primary"
              size="large"
              autoFocus
              onClick={actionOk}
            >
              OK
            </Button>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default SuccessMessage;
