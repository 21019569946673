import React from 'react';
import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  ContainerHeader,
  ContentLeft,
  OrgLogo,
  InformationOrg,
  ContentRight,
  ContentsOrgUnit,
  Border,
  LogoImage,
} from './styles/styledHeaderOrgUnit';

import { Button } from '~/presentation/components/UI';
import { History } from '~/main/routes';
import { makeReduxSetupDashboard } from '~/main/factories/usecases/dashboard/SetupDashboardFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { AlertMessage } from '../messages/AlertMessage';
import { makeRemoteDeleteOrgUnit } from '~/main/factories/usecases/orgUnit/DeleteOrgUnitFactory';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';
import { closeModal } from '~/utils/closeModal';

interface headerProps {
  sector?: string;
  org?: string;
  orgUnitId?: number;
  orgUnitName?: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
}

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const HeaderOrgUnit: React.FC<headerProps> = ({
  Icon,
  org,
  sector,
  orgUnitId,
  orgUnitName,
}) => {
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const removeSector = translator('Excluir setor?');

  const deleteOrgUnit = (ouId: number) => {
    return makeReduxActiveMessage().active({
      active: 'delete',
      title: removeSector,
      actionOk: () => {
        makeRemoteDeleteOrgUnit()
          .delete({ ouId })
          .then(() => {
            makeReduxGetAllOrgUnitList().list({
              filter: { org: orgId, enabled: true },
            });

            History.getHistory().push('/administrator/sectors');

            AlertMessage({
              type: 'success',
              message: intl.formatMessage({
                id: 'Setor excluido com sucesso!',
              }),
            });
          })
          .catch(() => {
            AlertMessage({
              type: 'danger',
              message: intl.formatMessage({
                id: 'Não foi possível excluir setor, tente novamente.',
              }),
            });
          });
        closeModal();
      },
      actionCancel: closeModal,
    });
  };

  return (
    <ContainerHeader>
      <ContentsOrgUnit>
        <ContentLeft>
          <OrgLogo>
            {typeof Icon === 'string' ? (
              <LogoImage src={Icon} alt="orgUnitLogo" />
            ) : (
              <Icon />
            )}
          </OrgLogo>
          <InformationOrg>
            <h3>{sector}</h3>
            <text>{org}</text>
          </InformationOrg>
        </ContentLeft>
        <ContentRight>
          <Button
            size="medium"
            variant="primary"
            rounded
            onClick={() => {
              History.getHistory().push('/administrator/editsector', {
                orgUnitId,
                orgUnitName,
                orgName: org,
              });
              makeReduxSetupDashboard().setup({
                selectedUnit: orgUnitId,
              });
            }}
          >
            Editar
          </Button>
          <Button
            id="button-secundary"
            size="medium"
            variant="secundary"
            rounded
            onClick={() => {
              if (orgUnitId) deleteOrgUnit(orgUnitId);
            }}
          >
            Excluir
          </Button>
        </ContentRight>
      </ContentsOrgUnit>
      <Border />
    </ContainerHeader>
  );
};

export default HeaderOrgUnit;
