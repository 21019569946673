import React from 'react';
import CarouselParticipants from '~/presentation/components/carouselParticipant/carousel';

type User = {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
  avatar: string;
};

const TestCarousel: React.FC = () => {
  const users: User[] = [];
  return (
    <div style={{ width: '100%' }}>
      <CarouselParticipants users={users} />
    </div>
  );
};

export default TestCarousel;
