import React from 'react';
import { useLocation } from 'react-router';
import { iGuestData } from '~/domain/interfaces/models/GuestData';
import {
  Content,
  Title,
  InformationGuest,
  MessageGuest,
  GetOut,
  Container,
} from './styles/StyledWaitingGuest';

import { History } from '~/main/routes';
import { Button } from '~/presentation/components/UI';

interface locationParams {
  isMicOn: boolean;
  isCamOn: boolean;
  guestData: iGuestData;
}

const WaitingGuest: React.FC = () => {
  const { guestData } = useLocation<locationParams>().state;

  function pushTo(path: string, state?: any) {
    return state
      ? History.getHistory().push(path, { guestData: state })
      : History.getHistory().push(path);
  }

  return (
    <Container>
      <Content>
        <Title>
          <h1>Aguarde</h1>
        </Title>
        <InformationGuest>
          <h3>{guestData.guest?.identification?.fullName || 'Convidado(a)'}</h3>
          <text>será atendido(a) em alguns instantes</text>
        </InformationGuest>
        <MessageGuest>
          <text>
            O atendimento será iniciado assim que médico e paciente estiverem na
            sala.
          </text>
        </MessageGuest>
        <GetOut>
          <Button
            variant="secundary"
            size="large"
            rounded
            onClick={() => pushTo('/guest')}
          >
            Sair
          </Button>
        </GetOut>
      </Content>
    </Container>
  );
};

export default WaitingGuest;
