/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useRef, useState } from 'react';
import Dropzone, { IInputProps, IDropzoneProps } from 'react-dropzone-uploader';

import { UploadImageSector } from '~/presentation/base/icons';

import { DropContainer, DropInput, RemoveImage } from './styled/StyledDropzone';

export interface fileProps {
  handleFile?: (file: any) => void;
}

const LogoDropzone: React.FC<fileProps> = ({ handleFile }) => {
  const [hasFile, setHasFile] = useState(false);
  const hiddenInputFile = useRef<HTMLInputElement>(null);
  const hiddenRemoveFile = useRef<HTMLInputElement>(null);

  const handleClick = (e: any) => {
    if (hiddenInputFile.current) hiddenInputFile.current.click();
  };

  const Input = ({ onFiles, getFilesFromEvent }: IInputProps) => {
    const text = 'Arraste a imagem ou';

    return (
      <DropContainer>
        {!hasFile && (
          <>
            <UploadImageSector
              width="70"
              height="70"
              style={{ alignContent: 'center', marginTop: '10px' }}
              onClick={handleClick}
            />
            <label
              style={{
                backgroundColor: 'transparent',
                cursor: 'pointer',
                padding: 15,
                borderRadius: 3,
              }}
            >
              {text}
              <DropInput>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/*"
                  onChange={async e => {
                    const chosenFiles = await getFilesFromEvent(e);
                    onFiles(chosenFiles as File[]);
                  }}
                  ref={hiddenInputFile}
                />
                <p>clique aqui para fazer upload.</p>
              </DropInput>
            </label>
          </>
        )}

        <input
          type="button"
          ref={hiddenRemoveFile}
          onClick={() => {
            onFiles([]);
            setHasFile(false);
          }}
          style={{ display: 'none' }}
        />
      </DropContainer>
    );
  };

  const handleSubmit: IDropzoneProps['onSubmit'] = (fils, allFiles) => {
    allFiles.forEach(e => e.remove());
  };

  return (
    <>
      {hasFile && (
        <RemoveImage
          type="button"
          onClick={() => {
            const buttonSubmit: any =
              document.querySelector('.dzu-submitButton');
            if (buttonSubmit?.click) buttonSubmit.click();
            if (hiddenRemoveFile.current) hiddenRemoveFile.current.click();
          }}
        >
          X
        </RemoveImage>
      )}
      <Dropzone
        getUploadParams={({ file }) => {
          console.log('arquivo enviado: ', file);
          if (handleFile) handleFile(file);
          if (file) setHasFile(true);
          return { url: 'https://httpbin.org/post' };
        }}
        onSubmit={handleSubmit}
        InputComponent={Input}
        multiple
        maxFiles={2}
        styles={{
          dropzone: {
            width: '100%',
            maxWidth: '300px',
            height: '100%',
            objectFit: 'cover',
          },
          preview: { width: '100%', height: '100%', objectFit: 'contain' },
          previewImage: { width: '100%', height: '100%', objectFit: 'contain' },
          submitButton: { display: 'none' },
        }}
      />
    </>
  );
};

export default LogoDropzone;
