import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Guest, ParticipantPages } from '~/presentation/pages';
import { PreSettingsGuest } from '~/presentation/components/preConfSettings';
import { PageNotFound } from '~/presentation/pages/errors/index';
import InviteProfessional from '~/presentation/pages/inviteProfessional';
import { makeRemoteUpdateAppointmentCompanion } from '~/main/factories/usecases/appointmentCompanion/UpdateAppointmentCompanionFactory';
import { makeRemoteGuestAppointment } from '~/main/factories/usecases/appointment/GuestAppointmentFactory';
import { GuestAppointment } from '~/domain/usecases/appointment/remote/GuestAppointment';

export const MakeAccessCodeFactory: React.FC = () => {
  const info = useLocation().pathname.split('/');
  const role = info[2];
  const code = info[info.length - 1];
  const [data, setData] = useState<GuestAppointment.Model>(
    {} as GuestAppointment.Model,
  );

  useEffect(() => {
    const getInfoAppointment = async () => {
      const response = await makeRemoteGuestAppointment().guest({
        code,
      });

      setData(response);
    };

    getInfoAppointment();
  }, []);

  switch (role) {
    case 'con':
      if (Object.keys(data).length) {
        return (
          <PreSettingsGuest
            inviteData={{
              ...data,
              guest: {
                identification: {
                  fullName: data.consultant.firstName,
                  role: 'CON',
                },
              },
            }}
          />
        );
      }

      return <></>;

    case 'pro': {
      return <InviteProfessional data={data} />;
    }

    default:
      if (info.includes('invite')) {
        return (
          <Guest
            authenticationCode={makeRemoteUpdateAppointmentCompanion()}
            code={code}
          />
        );
      }

      return <PageNotFound />;
  }
};
