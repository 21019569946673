/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputMask from 'react-input-mask';

import { Select } from '~/presentation/components/UI';
import Input from '../UI/input';

import { Container, Form } from './style/StyledAddress';

import { translator } from '../i18n';
import { Navigator } from './Navigator';

import { iRegisterParticipant } from './interface';

import { schemaAddress } from '~/validation/validators/user/CreateUserValidator';
import {
  GetAddressData as RemoteGetAddressData,
  GetUfs as RemoteGetUfs,
  GetCitiesByUf as RemoteGetCitiesByUf,
} from '~/domain/usecases/externalServices/remote';

import { makeRemoteGetAddressData } from '~/main/factories/usecases/externalServices/GetAddressData';
import { makeRemoteGetUfs } from '~/main/factories/usecases/externalServices/GetUfs';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';

interface ownProps {
  next: (data: iRegisterParticipant) => any;
  back: (data: iRegisterParticipant) => any;
  state?: iRegisterParticipant;
  cancel?: string;
  isFinish?: boolean;
  isFound?: boolean;
}

const Address: React.FC<ownProps> = ({
  back,
  next,
  state,
  cancel,
  isFinish = false,
  isFound = false,
}): JSX.Element => {
  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaAddress),
    defaultValues: {
      ...state?.address,
    },
  });

  const [ufs, setUfs] = useState<RemoteGetUfs.Model>([]);
  const [cities, setCities] = useState<RemoteGetCitiesByUf.Model>([]);
  const [selectedUf, setSelectedUf] = useState('');
  const [form, setForm] = useState<iRegisterParticipant['address']>({
    ...getValues(),
  });

  useEffect(() => {
    makeRemoteGetUfs()
      .getUfs({})
      .then(res => {
        setUfs(res);
        if (state?.address?.uf) {
          setSelectedUf(state?.address?.uf);
          setForm({ ...form, uf: state?.address?.uf });
        }
      })
      .catch(err => {
        console.log('Erro ao buscar UFs: ', err);
      });
  }, []);

  useEffect(() => {
    if (selectedUf) {
      makeRemoteGetCitiesByUf()
        .getCitiesByUf({ uf: selectedUf })
        .then(res => {
          setCities(res);

          if (state?.address?.city)
            setForm({ ...form, city: state?.address?.city });
        })
        .catch(err => {
          console.log('Erro ao buscar cidades: ', err);
        });
    }
  }, [selectedUf]);

  const onSubmit = handleSubmit(data => {
    next({ address: { ...data, country: 'BRA' } });
  });

  const onBack = () => back({ address: { ...getValues() } });

  const onChangeZipcode = (value: string) => {
    setForm({ ...form, zipcode: value });
    setValue('zipcode', value);

    if (value.length >= 8 && value !== state?.address?.zipcode) {
      const dataToSend: RemoteGetAddressData.Params = {
        zipcode: value,
      };

      makeRemoteGetAddressData()
        .getAddressData(dataToSend)
        .then(response => {
          console.log('Informações do CEP: ', response);

          const updatedForm = {
            uf: response.uf,
            city: response.localidade,
            neighborhood: response.bairro,
            street: response.logradouro,
            zipcode: value,
          };

          setSelectedUf(response.uf);
          setValue('uf', response.uf);
          setValue('city', response.localidade);
          setValue('neighborhood', response.bairro);
          setValue('street', response.logradouro);
          setForm({ ...form, ...updatedForm });
        })
        .catch(error => {
          console.log('Erro: ', error);
        });
    }
  };

  const onChangeState = (value: string) => {
    setValue('uf', value);
    setForm({ ...form, uf: value });
    setSelectedUf(value);
  };

  const onChangeComplement = (value: string) => {
    setValue('complement', value);
    setForm({ ...form, complement: value });
  };

  const onChangeCity = (value: string) => {
    setValue('city', value);
    setForm({ ...form, city: value });
  };

  const onChangeNeighborhood = (value: string) => {
    setValue('neighborhood', value);
    setForm({ ...form, neighborhood: value });
  };

  const onChangeStreet = (value: string) => {
    setValue('street', value);
    setForm({ ...form, street: value });
  };

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <InputMask
          mask="99999-999"
          name="zipcode"
          defaultValue={state?.address?.zipcode}
          value={form?.zipcode}
          ref={() => register('zipcode')}
          disabled={isFound}
          onChange={e => {
            onChangeZipcode(e.target.value.replace(/[^\d]+/g, '').trim());
          }}
        >
          <Input
            id="registerZipCode"
            name="zipcode"
            placeholder={translator('Digite seu CEP')}
            label="CEP"
            error={Boolean(errors.zipcode)}
            message={
              errors?.zipcode?.message
                ? translator(errors?.zipcode?.message)
                : ''
            }
            disabled={isFound}
            required
            autoFocus
          />
        </InputMask>
        <Select
          id="registerUf"
          name="uf"
          placeholder={translator('Digite seu estado')}
          label={translator('Estado')}
          defaultValue={state?.address?.uf}
          value={form?.uf}
          onChange={e => onChangeState(e.target.value.trim())}
          register={() => register('uf')}
          error={Boolean(errors.uf)}
          message={errors?.uf?.message ? translator(errors?.uf?.message) : ''}
          disabled={isFound}
          required
        >
          <option key={-1} value="">
            {translator('Digite seu estado')}
          </option>
          {ufs &&
            ufs.length > 0 &&
            ufs.map(uf => (
              <option key={uf.id} value={uf.sigla}>
                {uf.sigla}
              </option>
            ))}
        </Select>
        <Select
          id="registerCity"
          name="city"
          placeholder={translator('Digite sua cidade')}
          label={translator('Cidade')}
          defaultValue={state?.address?.city}
          value={form?.city}
          onChange={e => onChangeCity(e.target.value.trim())}
          register={() => register('city')}
          error={Boolean(errors.city)}
          message={
            errors?.city?.message ? translator(errors?.city?.message) : ''
          }
          disabled={isFound}
          required
        >
          <option key={-1} value="">
            {translator('Digite sua cidade')}
          </option>
          {cities &&
            cities.length > 0 &&
            cities.map(city => (
              <option key={city.id} value={city.nome}>
                {city.nome}
              </option>
            ))}
        </Select>
        <Input
          id="registerNeighborhood"
          name="neighborhood"
          placeholder={translator('Digite seu bairro')}
          label={translator('Bairro')}
          value={form?.neighborhood}
          defaultValue={state?.address?.neighborhood}
          onChange={e => onChangeNeighborhood(e.target.value)}
          register={() => register('neighborhood')}
          error={Boolean(errors.neighborhood)}
          message={
            errors?.neighborhood?.message
              ? translator(errors?.neighborhood?.message)
              : ''
          }
          disabled={isFound}
          required
        />
        <Input
          id="registerStreet"
          name="street"
          placeholder={translator('Rua Av. Ladeira...')}
          label={translator('Endereço')}
          value={form?.street}
          defaultValue={state?.address?.street}
          onChange={e => onChangeStreet(e.target.value)}
          register={() => register('street')}
          error={Boolean(errors.street)}
          message={
            errors?.street?.message ? translator(errors?.street?.message) : ''
          }
          disabled={isFound}
          required
        />
        <Input
          id="registerNumber"
          name="number"
          pattern="[0-9]+$"
          placeholder={translator('Número da residência')}
          label={translator('Número')}
          value={form?.number}
          defaultValue={state?.address?.number}
          onChange={e => setValue('number', e.target.value.trim())}
          register={() => register('number')}
          error={Boolean(errors.number)}
          message={
            errors?.number?.message ? translator(errors?.number?.message) : ''
          }
          disabled={isFound}
          required
        />
        <Input
          id="registerComplement"
          name="complement"
          placeholder={translator('Digite um complemento')}
          label={translator('Complemento')}
          value={form?.complement}
          defaultValue={state?.address?.complement}
          onChange={e => onChangeComplement(e.target.value)}
          register={() => register('complement')}
          error={Boolean(errors.complement)}
          message={errors?.complement?.message}
          disabled={isFound}
        />
        <Navigator back={onBack} cancel={cancel} isFinish={isFinish} />
      </Form>
    </Container>
  );
};

export default Address;
