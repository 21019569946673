import React from 'react';

import { Popover, More } from '~/presentation/components/UI/popover';

import { Button } from '~/presentation/components/UI';
import { Container } from './styles/StyledPopover';

const PagePopover: React.FC = () => {
  const content = (
    <div>
      <div>teste 01</div>
      <div>teste contetnt 02</div>
      <div>teste 03</div>
      <div>teste 04</div>
    </div>
  );

  return (
    <Container>
      <Popover content={content}>
        <Button type="button" rounded>
          Popover
        </Button>
      </Popover>
      <More content={content} />
    </Container>
  );
};

export default PagePopover;
