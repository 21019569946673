import React, { useState } from 'react';
import { Button, ItemCard } from '~/presentation/components/UI';

import { Progress, Content } from './styles/progressbarstyled';

interface Props {
  step?: number;
}

const ProgressBar: React.FC<Props> = ({ step }) => {
  const [passo, setPasso] = useState(1);

  function nextStep(iter: number) {
    if (passo + iter > 4) {
      setPasso(4);
    } else if (passo + iter <= 0) {
      setPasso(1);
    } else {
      setPasso(passo + iter);
    }
  }
  return (
    <Content>
      <Progress id="progressbar">
        <li className={passo >= 1 ? 'active' : 'blank'}>Dados pessoais</li>
        <li className={passo >= 2 ? 'active' : 'blank'}>Contato</li>
        <li className={passo >= 3 ? 'active' : 'blank'}>Endereço</li>
        <li className={passo >= 4 ? 'active' : 'blank'}>Senha</li>
      </Progress>

      <Button onClick={() => nextStep(1)}>Próximo</Button>
      <Button onClick={() => nextStep(-1)}>Anterior</Button>
    </Content>
  );
};

export default ProgressBar;
