import React, { useState, useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { History } from '~/main/routes';
import { AvatarImage } from '~/presentation/components/avatar-roles';
import { translator } from '~/presentation/components/i18n';
import { iParticipants } from './interface';
import {
  Address,
  Age,
  Contact,
  Content,
  Email,
  Header,
  Infos,
  LinkProfile,
  Name,
  PhotoView,
  UserInfo,
} from './styles/StyledUserInformation';

interface PropsInfo {
  name?: string;
  age: string;
  src?: string;
  contact?: string;
  address?: string;
  email?: string;
}

interface User {
  user?: iParticipants;
}

const InfoUser: React.FC<User> = ({ user }) => {
  return (
    <Content>
      <Header>{translator('Participantes da Consulta')}</Header>
      <Infos>
        <PhotoView>
          <AvatarImage
            size="large"
            name={user?.consultant.name}
            src={user?.consultant.src}
          />
          <LinkProfile
            id="btn_consultant_profile"
            onClick={() => {
              const url = window.location.pathname;
              History.getHistory().push(`/profile/${user?.consultant.user}`, {
                from: url.replaceAll('/', ''),
              });
            }}
          >
            {translator('Ver perfil')}
          </LinkProfile>
        </PhotoView>
        <UserInfo>
          <Name>
            {`${translator('Nome')}:`}
            {!user?.consultant.name ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.consultant.name}</div>
            )}
          </Name>
          <Age>
            {`${translator('Idade')}:`}
            {!user?.consultant.age ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.consultant.age}</div>
            )}
          </Age>
          <Contact>
            {`${translator('Contato')}:`}
            {!user?.consultant.contact ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.consultant.contact}</div>
            )}
          </Contact>
          <Address>
            {`${translator('Endereço')}:`}
            {!user?.consultant.address ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.consultant.address}</div>
            )}
          </Address>
          <Email>
            {`${translator('E-mail')}:`}
            {!user?.consultant.email ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.consultant.email}</div>
            )}
          </Email>
        </UserInfo>
      </Infos>
    </Content>
  );
};

export default InfoUser;
