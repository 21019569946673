import React, { useState, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { IconSearch } from '~/presentation/base/icons';
import { Container } from './styles/StyledSearchList';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { translator } from '../i18n';
import { iStore } from '~/domain/interfaces/models';

interface SearchProps {
  name?: string;
  children?: JSX.Element[] | JSX.Element;
  icon?: JSX.Element;
}

const SearchListSectors: React.FC<SearchProps> = ({ children }) => {
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const [sectorName, setSectorName] = useState('');

  const handleSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      makeReduxGetAllOrgUnitList().list({
        filter: { name: sectorName, org: orgId },
      });
    }
  };

  return (
    <Container onSubmit={() => handleSubmit}>
      <IconSearch id="btn_searchFilter" />
      <input
        id="input_search"
        placeholder={`${translator('Buscar por...')}`}
        value={sectorName}
        onChange={e => setSectorName(e.target.value)}
        onKeyPressCapture={handleSubmit}
      />
    </Container>
  );
};
export default SearchListSectors;
