import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';

import {
  Body,
  ButtonsDiv,
  Container,
  Content,
  ConfirmCancel,
  Header,
  TimeBox,
} from './styles/StyledConfirmCancellation';

import { translator } from '../../i18n';
import { Button } from '../../UI';
import { CarouselParticipants } from '../../carouselParticipant';
import { User } from '../../carouselParticipant/interface';

import { MessageOptions } from '~/domain/interfaces/redux/message';

import { ownProps } from '../interface';
import { MapAppointmentDetail } from '~/presentation/roles/professional/pages/appointment/mapper/map';
import { V4hSpin } from '../../spin';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory ';
import { AlertMessage } from '../AlertMessage';
import { makeTime, getDay } from './functions/request';
import { MapUsers } from './mapper/map';

interface iStateParams {
  appointmentId: string;
}

const ConfirmCancellation: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.appointmentConfirmCancel;

  const { active, actionCancel, actionOk } = message;
  const [map, setMap] = useState<any>({
    appointment: {
      scheduled: '',
    },
    specialty: {
      name: '',
    },
  });
  const [load, setLoad] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);

  const select = useSelector((store: iStore) => store.appointment.selected);

  useEffect(() => {
    setLoad(true);
    if (select !== 0)
      makeRemoteGetAppointmentById()
        .getAppointmentById({
          id: select,
        })
        .then(response => {
          setMap(response);
          setUsers(MapUsers(response));
          setLoad(false);
        });
  }, [select]);

  // const users: User[] = [
  //   {
  //     name: 'Vinicius dos Santos',
  //     role: 'Paciente',
  //     src:
  //       'https://avatars1.githubusercontent.com/u/41171735?s=460&u=e21a8c9def8c4fb54d0d59e65b8c44bd19fee32f&v=4',
  //   },
  //   {
  //     name: 'Matheus Oliveira',
  //     role: 'Profissional de saúde',
  //     src:
  //       'https://avatars2.githubusercontent.com/u/58794027?s=400&u=5faf30736b619fd1b1e59a46a0d578846ead7aa2&v=4',
  //   },
  // ];

  const text1 = translator('Cancelar atendimento?');
  const text2 = translator('Deseja realmente cancelar o atendimento: ');
  const buttonCancel = translator('Não cancelar');
  const buttonOk = translator('Sim, cancelar');

  document.addEventListener('keydown', function (e) {
    if (e.key === 'Enter') {
      document.getElementById('button_not_cancel')?.click();
    }
  });

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            {load ? (
              <V4hSpin fullScreen background="white" />
            ) : (
              <>
                <Header>
                  <span>{text1}</span>
                </Header>
                <Content>
                  <p>{text2}</p>
                  <h3>{map && getDay(map)}</h3>
                  <TimeBox>
                    <span>{`${map && map.specialty.name}`}</span>
                    <span>{map && makeTime(map)}</span>
                  </TimeBox>
                </Content>
                <CarouselParticipants users={users} />
                <ButtonsDiv>
                  <Button
                    id="button_not_cancel"
                    autoFocus
                    rounded
                    variant="primary"
                    size="large"
                    onClick={actionCancel}
                  >
                    {buttonCancel}
                  </Button>
                  <ConfirmCancel onClick={actionOk}>{buttonOk}</ConfirmCancel>
                </ButtonsDiv>
              </>
            )}
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default ConfirmCancellation;
