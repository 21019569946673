import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router';
import { translator } from '~/presentation/components/i18n';

import { ContentComponents, Nav, NavContent } from './styles/StyledService';

import { NavigationBar } from '~/presentation/components/navigationBar';
import ClinicalDocuments from './ClinicalDocuments';
import SupportingDocuments from './SupportingDocuments';
import Media from './MediaView';
import { Notes } from '~/presentation/components/notes';
import { iClinicalDocs, iSupportDocs } from '../../pages/appointment/interface';
import { makeReduxUpdateAppointment } from '~/main/factories/usecases/appointment/UpdateAppointmentFactory';

interface ownProps {
  clinicalDocs: iClinicalDocs[];
  supportingDocs: iSupportDocs[];
  openFunction: Function;
}

const AppointementDocs: React.FC<ownProps> = ({
  clinicalDocs,
  supportingDocs,
  openFunction,
}) => {
  const labels: string[] = [
    translator('Documentos clínicos'),
    translator('Documentos de apoio'),
    translator('Anotações'),
    translator('Gravação'),
    // translator('Chat'),
  ];

  const [select, setSelect] = useState<string>(labels[0]);

  function handleSelect(key: string) {
    setSelect(key);
  }

  return (
    <NavContent>
      <Nav>
        <NavigationBar
          active={select}
          labels={labels}
          clickLabel={handleSelect}
        />
      </Nav>
      <ContentComponents>
        {labels.indexOf(select) === 0 && (
          <ClinicalDocuments docs={clinicalDocs} openFunction={openFunction} />
        )}
        {labels.indexOf(select) === 1 && (
          <SupportingDocuments supportingDocs={supportingDocs} />
        )}
        {labels.indexOf(select) === 2 && <Notes />}
        {labels.indexOf(select) === 3 && (
          <Media /> /* link="https://www.ufjf.br/revistaveredas/files/2009/11/ARTIGO-Maira-Avelar-e-Janaina-Rabelo.pdf" */
        )}
      </ContentComponents>
    </NavContent>
  );
};

export default AppointementDocs;
