import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Content = styled.div`
  display: flex;
  border: 1px solid ${defaultTheme.statusNone};
  border-radius: 5px;
  /* grid-template-columns: 1fr 3fr 2fr; */
  padding: 5px;
`;
export const Prefix = styled.div`
  display: flex;
  min-width: 80px;
  border-right: 1px solid ${defaultTheme.statusNone};
`;
export const Body = styled.div`
  display: flex;
  padding-left: 5px;
  min-width: 320px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid ${defaultTheme.statusNone};
`;

export const Download = styled.button`
  width: 118px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${defaultTheme.primaryDark};
  border: 1px solid ${defaultTheme.primaryDark};
  box-sizing: border-box;
  border-radius: 15px;
  color: white;
`;

export const Suffix = styled.div`
  display: flex;
  padding-left: 5px;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
`;

export const Title = styled.text`
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  color: ${defaultTheme.primaryDark};
`;

export const SubTitle = styled.text`
  font-size: 12px;
  color: ${defaultTheme.typographyDarkGray};
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  color: ${defaultTheme.primaryDark};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  text {
    padding: 3px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text {
      padding-left: 5px;
    }
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text {
      color: ${defaultTheme.typographyDarkGray};
    }
  }
`;
