import React from 'react';
import { PageHeader, HeaderSimples } from '~/presentation/components/header';

import { IconBack, IconCalendar } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';

const TestHeader: React.FC = () => {
  return (
    <div style={{ width: '100%' }}>
      <PageHeader
        icon={IconCalendar}
        title={translator('Agenda de atendimentos')}
        backDescription="appointment"
      />
      <HeaderSimples
        icon={IconCalendar}
        title={translator('Agenda de atendimentos')}
        value={55}
        valueDescription="atendimentos hoje"
      />
    </div>
  );
};

export default TestHeader;
