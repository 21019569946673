import React, { useState } from 'react';

import { Button } from '~/presentation/components/UI';
import { IconWaitingRoom, IconV4Hatendimento } from '~/presentation/base/icons';
import { Icon } from '~/presentation/components/icon';
import { translator } from '~/presentation/components/i18n';

import { Container, Header, Image } from './styles/StyledWaitingParticipant';
import { V4hSpin } from '~/presentation/components/spin';

interface ownProps {
  left: () => any;
}

export default function WaitingParticipant(props: ownProps): JSX.Element {
  const { left } = props;

  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  return (
    <Container>
      <Header>
        {logoUrl ? (
          <Image alt="Logo" src={logoUrl} width="200px" />
        ) : (
          <Icon src={IconV4Hatendimento} width="250px" height="80px" />
        )}
      </Header>
      <span className="title">
        {translator('O paciente foi informado que')}
      </span>
      <span className="title">
        {translator('você está na sala de espera')}
      </span>
      <IconWaitingRoom />
      <V4hSpin fullScreen={false} background="none" />
      <span className="message">
        {translator('Aguarde o início do atendimento')}
      </span>
      <span className="message">
        {translator('nesta sala de espera')}
      </span>
      <Button variant="secundary" onClick={left} rounded>
        {translator('Deixar sala')}
      </Button>
    </Container>
  );
}
