import React, { useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';

import { TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import ListSkeleton from '~/presentation/components/skeleton/ListSkeleton';
import ActionsList from '~/presentation/components/actionsList/ActionsList';
import { History } from '~/main/routes';
import { iListSectors } from './interface';
import { TextSector } from './styles/StyledListSectors';
import { ConnectComponent } from './mapper/MapperListSector';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeRemoteDeleteOrgUnit } from '~/main/factories/usecases/orgUnit/DeleteOrgUnitFactory';
import { iStore } from '~/domain/interfaces/models';
import { AlertMessage } from '../messages/AlertMessage';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { translator } from '../i18n';
import { closeModal } from '~/utils/closeModal';
import { makeReduxGetDashboardUnits } from '~/main/factories/usecases/dashboard/GetDashboardUnitsFactory';

export interface ownProps {
  data: iListSectors[];
  selectUnit: Function;
}

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    minHeight: 520,
    maxHeight: '100vh',
    width: '100%',
  },
});

const ListSectors: React.FC<ownProps> = ({ data, selectUnit }) => {
  const arrToCompare = ['Instituição'];
  const classes = useStyles();
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const removeSector = translator('Excluir setor?');

  const columns = [
    {
      id: 'institution',
      label: 'Instituição',
      minWidth: 290,
      maxWidth: 400,
      align: 'left',
    },
    {
      id: 'sector',
      label: 'Setor',
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'totalPatient',
      label: 'Total de pacientes',
      minWidth: 20,
      align: 'right',
    },
    {
      id: 'totalProfessional',
      label: 'Total de profissionais',
      minWidth: 20,
      align: 'right',
    },
    {
      id: 'monthStorage',
      label: 'Armazenamento este mês',
      minWidth: 20,
      align: 'right',
    },
    {
      id: 'actions',
      label: 'Ações',
      minWidth: 20,
      align: 'left',
      paddingLeft: 100,
    },
  ];

  const deleteOrgUnit = (ouId: number) => {
    return makeReduxActiveMessage().active({
      active: 'delete',
      title: removeSector,
      actionOk: () => {
        makeRemoteDeleteOrgUnit()
          .delete({ ouId })
          .then(() => {
            makeReduxGetAllOrgUnitList().list({
              filter: { org: orgId, enabled: true },
            });

            makeReduxGetDashboardUnits().get({
              filter: {
                org: orgId,
              },
            });

            AlertMessage({
              type: 'success',
              message: intl.formatMessage({
                id: 'Setor excluido com sucesso!',
              }),
            });
          })
          .catch(() => {
            AlertMessage({
              type: 'danger',
              message: intl.formatMessage({
                id: 'Não foi possível excluir setor, tente novamente.',
              }),
            });
          });
        closeModal();
      },
      actionCancel: closeModal,
    });
  };

  const RenderComponents: React.FC<{ id: any; item: any; idx: number }> = ({
    id,
    item,
    idx,
  }) => {
    switch (id) {
      case 'actions':
        return (
          <ActionsList
            actions={{
              dashboard: () => {
                History.getHistory().push('/administrator/orgdetail', {
                  orgUnitId: item.id,
                  orgUnitName: item.sector,
                  orgName: item.institution,
                });
                selectUnit(item.id);
              },
              editSector: () => {
                History.getHistory().push('/administrator/editsector', {
                  orgUnitId: item.id,
                  orgUnitName: item.sector,
                  orgName: item.institution,
                });
                selectUnit(item.id);
              },
              deleteSector: () => {
                deleteOrgUnit(item.id);
              },
            }}
          />
        );

      case 'sector':
        return <TextSector>{item[`${id}`]}</TextSector>;

      default:
        return <div>{item[`${id}`]}</div>;
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.container}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {columns.map(item => {
                return (
                  <TableCell
                    align={
                      item.id === 'totalPatient' ||
                      item.id === 'totalProfessional' ||
                      item.id === 'monthStorage'
                        ? 'right'
                        : 'left'
                    }
                    style={{
                      paddingLeft: item.id === 'actions' ? '100px' : '',
                    }}
                  >
                    {arrToCompare.includes(item.id) ? (
                      <TableSortLabel
                        active
                        style={{ color: '#8A8A8A', fontWeight: 'normal' }}
                      >
                        {item.label}
                      </TableSortLabel>
                    ) : (
                      <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                        {item.label}
                      </div>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {false ? (
              <>
                {columns.map(item => {
                  return (
                    <TableRow>
                      <ListSkeleton columns={columns} />
                    </TableRow>
                  );
                })}
              </>
            ) : (
              data.map((item: iListSectors, index) => (
                <TableRow key="key">
                  {columns.map((columnProps: any) => {
                    return (
                      <TableCell
                        align={columnProps.align}
                        style={{
                          maxWidth: columnProps.maxWidth,
                          minWidth: columnProps.minWidth,
                          paddingLeft: columnProps.paddingLeft,
                        }}
                      >
                        <RenderComponents
                          id={columnProps.id}
                          item={item}
                          idx={index}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ListSectors;
