import { GetAppointmentRecordingDownload } from '~/domain/usecases/appointment/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadRequestError,
  InvalidCredentialsError,
  Forbidden,
  NotFound,
} from '~/domain/errors';

export class RemoteGetAppointmentRecordingDownload
  implements GetAppointmentRecordingDownload {
  private readonly url: string;

  private readonly httpCliente: HttpClient<GetAppointmentRecordingDownload.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<GetAppointmentRecordingDownload.Model>,
  ) {
    this.url = url;
    this.httpCliente = httpClient;
  }

  getRecordingsDownload = async (
    params: GetAppointmentRecordingDownload.Params,
  ): Promise<GetAppointmentRecordingDownload.Model> => {
    const httpResponse = await this.httpCliente.request({
      url: `${this.url}/${params.appointmentId}/recordings/${params.recordingId}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new NotFound();
    }
  };
}
