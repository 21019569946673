import React from 'react';
import { Menu } from '~/presentation/components/menu';

const TestMenu: React.FC = () => {
  // const MapMenuActions: iMenuActions = {
  //   options: {
  //     schedule: () => '/appointment',
  //     patient: () => '/appointment',
  //     clinicalDocuments: () => '/appointment',
  //     user: {
  //       manager: () => '/appointment',
  //       patient: () => '/appointment',
  //       professional: () => '/appointment',
  //     },
  //     adm: {
  //       activityLog: () => '/appointment',
  //       panels: () => '/appointment',
  //     },
  //   },
  //   user: {
  //     logout: () => '/appointment',
  //     profile: () => '/appointment',
  //   },
  // };

  // const menu: iMenu = {
  //   options: {
  //     schedule: true,
  //     patient: false,
  //     clinicalDocuments: true,
  //     user: {
  //       active: false,
  //       options: {
  //         manager: true,
  //         patient: true,
  //         professional: true,
  //       },
  //     },
  //     adm: {
  //       active: false,
  //       options: {
  //         activityLog: true,
  //         panels: true,
  //       },
  //     },
  //   },
  //   user: {
  //     name: 'Vinicius',
  //     role: 'Organizer',
  //   },
  //   actions: MapMenuActions,
  //   active: 'manager',
  // };

  return <Menu />;
};

export default TestMenu;
