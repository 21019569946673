import React, { useState } from 'react';

import {
  Body,
  ButtonsDiv,
  TextConditions,
  BlackBox,
  Circle,
  Container,
  Content,
  ConfirmCancel,
  Header,
  OptionsContainer,
  CheckboxContainer,
} from './styles/StyledPreSettingsProfessional';
import { History } from '~/main/routes';
import { Button, Check } from '~/presentation/components/UI';
import { MicrophoneOn, CameraOn } from '~/presentation/base/icons/index';

const PreSettingsProfessional: React.FC = (): JSX.Element => {
  const [ruleone, setRuleOne] = useState<boolean>(false);
  const [ruletwo, setRuleTwo] = useState<boolean>(false);

  function pushTo(path: string) {
    History.push(path);
  }

  return (
    <Container>
      <Body>
        <Header>
          <span>Antes de iniciar</span>
        </Header>
        <Content>
          <p>Recomendamos que entre com microfone e câmera ligados.</p>
          <TextConditions>
            Aceite os termos de uso e de gravação para iniciar.
          </TextConditions>
          <BlackBox />
          <h3>Entrar com:</h3>
          <OptionsContainer>
            <Circle>
              <MicrophoneOn width="26px" height="26px" />
              <p>Ligado</p>
            </Circle>
            <Circle>
              <CameraOn width="26px" height="26px" />
              <p>Ligada</p>
            </Circle>
          </OptionsContainer>
          <CheckboxContainer>
            <Check
              name="rule"
              checked={ruleone}
              onClick={() => setRuleOne(!ruleone)}
              label="Aceito os termos de uso"
            />
            <Check
              name="secondRule"
              checked={ruletwo}
              onClick={() => setRuleTwo(!ruletwo)}
              label="Aceito que o atendimento poderá ser gravado"
            />
          </CheckboxContainer>
          <ButtonsDiv>
            {ruleone && ruletwo ? (
              <Button
                resourcers={['VIDEOCONFERENCE']}
                actions={['ACCESS']}
                variant="primary"
                size="large"
              >
                Iniciar atendimento
              </Button>
            ) : (
              <Button variant="primary" size="large" disabled>
                Iniciar atendimento
              </Button>
            )}
            <ConfirmCancel onClick={() => pushTo('/guest')}>
              Sair do atendimento
            </ConfirmCancel>
          </ButtonsDiv>
        </Content>
      </Body>
    </Container>
  );
};

export default PreSettingsProfessional;
