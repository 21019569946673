import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
   */
`;

export const Header = styled.div`
  width: 50%;
  margin-top: 50px;
`;

export const Body = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
`;

export const Footer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
  .btnAction {
    width: 150px;
  }
`;

export const Separate = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
`;

export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;