import React, { useEffect, useState, useRef } from 'react';
import WiseAPI from 'wise-api';
import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import { useLocation } from 'react-router-dom';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { makeRemoteStartAppointment } from '~/main/factories/usecases/appointment/StartAppointment';
import { makeReduxLeftWaitingRoom } from '~/main/factories/usecases/waitingRoom/LeftWaitingRoomFactory';

import { Sidebar } from '../../components/sidebar';
import { History } from '~/main/routes';
import { iStore } from '~/domain/interfaces/models';
import { iGuestData } from '~/domain/interfaces/models/GuestData';
import { Container, MeetDiv } from './styledConf';
import storeDev from '~/data/store';
import { AppointmentTypes } from '~/domain/interfaces/redux/appointment/types';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { V4hSpin } from '~/presentation/components/spin';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface ParamsState {
  isMicOn: boolean;
  isCamOn: boolean;
  appointmentId: string;
  waitingRoomUserId: string;
  guestData?: iGuestData;
}

export const Conf: React.FC = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const {
    isMicOn,
    isCamOn,
    appointmentId,
    waitingRoomUserId,
    guestData = null,
  } = useLocation<ParamsState>().state;
  const auth = useSelector((store: iStore) => store.auth.info);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const timeoutMeet: { current: NodeJS.Timeout | null } = useRef(null);

  useEffect(() => {
    if (guestData) {
      const start = async (): Promise<void> => {
        try {
          const { roomName, sessionName, sessiontoken } =
            await makeRemoteStartAppointment().start({
              appointmentId: Number(guestData?.appointment?.id),
            });

          const v4h = new WiseAPI();

          await v4h.setup({
            baseUrl: `${window.config.connection.sessionManagerUrl}/api`,
            domain: `${window.config.connection.confUrl}`,
          });

          await v4h.session.startConference(sessionName, {
            parentNode: document.getElementById('meet'),
            userInfo: {
              displayName:
                guestData?.guest?.identification?.fullName || 'Convidado',
            },
            startWithAudioMuted: !isMicOn,
            startWithVideoMuted: !isCamOn,
            buttons: [
              'microphone',
              'camera',
              'closedcaptions',
              'desktop',
              'fullscreen',
              'fodeviceselection',
              'hangup',
              'profile',
              'info',
              'chat',
              'livestreaming',
              'sharedvideo',
              'settings',
              'raisehand',
              'videoquality',
              'filmstrip',
              'invite',
              'feedback',
              'stats',
              'shortcuts',
              'tileview',
              'videobackgroundblur',
              'download',
              'help',
            ],
            onClose: () => History.push('/guest'),
          });

          setLoading(false);
        } catch (error) {
          setLoading(false);
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possível entrar na conferência, tente novamente',
            }),
            type: 'danger',
          });
          History.getHistory().push('/guest/preconference', {
            conference: guestData,
          });
        }
      };

      start();

      // timeoutMeet.current = setTimeout(() => {
      //   const meetChildNodesCount =
      //     document.getElementById('meet')?.childNodes.length;

      //   if (!meetChildNodesCount) start();
      // }, 2000);
    } else if (role === 'CON') {
      const start = async (): Promise<void> => {
        try {
          const { roomName, sessionName, sessiontoken } =
            await makeRemoteStartAppointment().start({
              appointmentId: Number(appointmentId),
            });

          const v4h = new WiseAPI();

          await v4h.setup({
            baseUrl: `${window.config.connection.sessionManagerUrl}/api`,
            domain: `${window.config.connection.confUrl}`,
          });

          await v4h.session.startConference(sessionName, {
            parentNode: document.getElementById('meet'),
            userInfo: {
              displayName: `${auth.user?.firstName} ${auth.user?.lastName}`,
            },
            startWithAudioMuted: !isMicOn,
            startWithVideoMuted: !isCamOn,
            buttons: [
              'microphone',
              'camera',
              'closedcaptions',
              'desktop',
              'fullscreen',
              'fodeviceselection',
              'hangup',
              'profile',
              'info',
              'chat',
              'livestreaming',
              'sharedvideo',
              'settings',
              'raisehand',
              'videoquality',
              'filmstrip',
              'invite',
              'feedback',
              'stats',
              'shortcuts',
              'tileview',
              'videobackgroundblur',
              'download',
              'help',
            ],
            onClose: () => History.push('/appointment'),
          });

          setLoading(false);
        } catch (error) {
          setLoading(false);
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possível entrar na conferência, tente novamente',
            }),
            type: 'danger',
          });
          History.getHistory().push('/appointment');
        }
      };

      start();

      // timeoutMeet.current = setTimeout(() => {
      //   const meetChildNodesCount =
      //     document.getElementById('meet')?.childNodes.length;

      //   if (!meetChildNodesCount) start();
      // }, 2000);
    } else {
      const start = async (): Promise<void> => {
        try {
          const { roomName, sessionName, sessiontoken } =
            await makeRemoteStartAppointment().start({
              appointmentId: Number(appointmentId),
            });

          const v4h = new WiseAPI();

          await v4h.setup({
            baseUrl: `${window.config.connection.sessionManagerUrl}/api`,
            domain: `${window.config.connection.confUrl}`,
          });

          await v4h.session.startConference(sessionName, {
            parentNode: document.getElementById('meet'),
            userInfo: {
              displayName: `${auth.user?.firstName} ${auth.user?.lastName}`,
            },
            startWithAudioMuted: !isMicOn,
            startWithVideoMuted: !isCamOn,
            buttons: [
              'microphone',
              'camera',
              'closedcaptions',
              'desktop',
              'fullscreen',
              'fodeviceselection',
              'hangup',
              'profile',
              'info',
              'chat',
              'recording',
              'livestreaming',
              'sharedvideo',
              'settings',
              'raisehand',
              'videoquality',
              'filmstrip',
              'invite',
              'feedback',
              'stats',
              'shortcuts',
              'tileview',
              'videobackgroundblur',
              'download',
              'help',
              'mute-everyone',
            ],

            onClose: () => {
              History.getHistory().push('/appointment', {
                appointmentId,
              });
              storeDev.dispatch({
                type: AppointmentTypes.STOP,
                payload: {
                  id: Number(appointmentId),
                },
              });
            },
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
          AlertMessage({
            message: intl.formatMessage({
              id: 'Não foi possível entrar na conferência, tente novamente',
            }),
            type: 'danger',
          });
          History.getHistory().push('/appointment');
        }
      };

      start();

      // timeoutMeet.current = setTimeout(() => {
      //   const meetChildNodesCount =
      //     document.getElementById('meet')?.childNodes.length;

      //   if (!meetChildNodesCount) start();
      // }, 2000);
    }

    return () => {
      if (timeoutMeet?.current) clearTimeout(timeoutMeet.current);
      if (appointmentId && waitingRoomUserId)
        makeReduxLeftWaitingRoom().left({
          appointmentId: Number(appointmentId),
          waitingRoomUserId: Number(waitingRoomUserId),
        });
    };
  }, [
    appointmentId,
    auth.user?.firstName,
    auth.user?.lastName,
    auth.user?.mainRole,
    role,
    guestData,
    timeoutMeet,
    waitingRoomUserId,
  ]);

  return (
    <Container>
      {!loading && <Sidebar />}
      <MeetDiv id="meet">{loading && <V4hSpin fullScreen />}</MeetDiv>
    </Container>
  );
};
