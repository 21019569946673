import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { GetUfs as RemoteGetUfs } from '~/domain/usecases/externalServices/remote';
import { makeRemoteGetUfs } from '~/main/factories/usecases/externalServices/GetUfs';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import {
  makeReduxGetAllSpecialty,
  makeRemoteGetAllSpecialty,
} from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import { AddFiles, IconPlus } from '~/presentation/base/icons';
import { Button, Input, Select } from '~/presentation/components/UI';
import { schemaSpecialtyCreate } from '~/validation/validators/specialty/CreateSpecialtyValidator';
import { translator } from '../i18n';
import { iSpecialty } from '../professionalData/interface';
import { StickyHeadTableSpecialty } from '../Table';
import { Specialty } from './interface';
import { Message } from './style/StyledProfessionalData';
import {
  Card,
  Container,
  EmptySpecialty,
  NoEmptySpecialty,
} from './style/StyledSpecialty';

export interface Cards {
  index?: {
    page?: number;
    index?: number;
  };
  specialty?: {
    id?: number;
    name?: string;
    profession?: number;
    description?: string;
    enabled?: boolean;
    created?: string;
  };
  profession?: {
    id?: number;
    name?: string;
    docProfName?: string;
  };
  id?: number;
  cardId?: number;
  docProf: string;
  docProfUF: string;
  docProfIssued: string;
}
export interface iArrayCards {
  specialty: Cards[];
}

export interface MySpecialty {
  id?: number;
  docProf?: string;
  docProfUF?: string;
  docProfIssued?: string;
}

interface ownProps {
  addSpecialty: Function;
  specialty: Specialty[];
  state: iArrayCards;
  professionID?: number;
  id: number;
  setId: Function;
  setError?: boolean;
  setLength?: Function;
  setDisable: Function;
  handlePreviousData?: (value: Array<number>) => void;
  handleDeleteData?: (value: Array<number>) => void;
  handleSave?: (value: iSpecialty[]) => void;
  save?: boolean;
}

const SpecialtyCard: React.FC<ownProps> = ({
  specialty,
  state,
  addSpecialty,
  id,
  setId,
  professionID,
  setError,
  setLength,
  setDisable,
  handlePreviousData,
  handleDeleteData,
  handleSave,
  save,
}) => {
  const { specialty: specialtyRedux } = useSelector(
    (store: iStore) => store.showModal,
  );
  const [ufs, setUfs] = useState<RemoteGetUfs.Model>([]);
  const [listSpecialty, setListSpecialty] = useState(
    specialty.filter(item => item?.profession?.id === professionID),
  );
  const [specialtyProps, setSpecialtyProps] = useState(state);
  const [previousIds, setPreviousIds] = useState<Array<number>>([]);
  const [deleteIds, setDeleteIds] = useState<Array<number>>([]);
  const [newSpe, setNewSpe] = useState({
    id: -1,
    docProf: '',
    docProfUF: 'AC',
    docProfIssued: '',
  });

  const [oldID, setOldID] = useState<number | undefined>(professionID);
  const [edit, setEdit] = useState<boolean>(false);
  const [IndexeditItem, setEditItem] = useState<number>();

  const { errors, handleSubmit, setValue, getValues, register, unregister } =
    useForm({
      mode: 'onChange',
      shouldFocusError: true,
      resolver: zodResolver(schemaSpecialtyCreate),
      defaultValues: {
        ...newSpe,
      },
    });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { value, name } = e.target;

    if (name === 'id') {
      if (
        previousIds.findIndex(itemId => itemId === Number(value)) !== -1 &&
        deleteIds.findIndex(itemId => itemId === Number(value)) === -1
      ) {
        if (handleDeleteData) handleDeleteData([...deleteIds, Number(value)]);
        setDeleteIds([...deleteIds, Number(value)]);
      }
    }

    if (name === 'id') setNewSpe({ ...newSpe, [name]: Number(value) });
    else setNewSpe({ ...newSpe, [name]: value });

    if (!specialtyRedux) makeReduxSetShowModal().set({ specialty: true });
  };

  const handleDelete = (value: number) => {
    if (state.specialty.length - 1 === 0) {
      setDisable(true);
    }

    if (
      deleteIds.findIndex(
        deleteId => deleteId === state.specialty[value].id,
      ) === -1
    ) {
      if (handleDeleteData)
        handleDeleteData([...deleteIds, Number(state.specialty[value].id)]);
      setDeleteIds([...deleteIds, Number(state.specialty[value].id)]);
    }

    addSpecialty(
      state.specialty.filter(item => state.specialty.indexOf(item) !== value),
    );

    if (!specialtyRedux) makeReduxSetShowModal().set({ specialty: true });
  };

  const handleEdit = (value: number) => {
    setEdit(true);
    setEditItem(value);
    setDisable(true);
    // handleDelete(value);
    setNewSpe({
      id: state.specialty[value].id!,
      docProf: state.specialty[value].docProf,
      docProfUF: state.specialty[value].docProfUF,
      docProfIssued: state.specialty[value].docProfIssued,
    });
    setValue('id', state.specialty[value].id!);
    setValue('docProf', state.specialty[value].docProf!);
    setValue('docProfUF', state.specialty[value].docProfUF!);
    setValue('docProfIssued', state.specialty[value].docProfIssued!);
  };

  const cancelEdit = () => {
    setEdit(false);
    setDisable(false);
    setNewSpe({
      id: -1,
      docProf: '',
      docProfUF: 'AC',
      docProfIssued: '',
    });
    setValue('id', -1);
    setValue('docProf', '');
    setValue('docProfUF', 'AC');
    setValue('docProfIssued', '');
  };

  const handleAdd = handleSubmit(data => {
    // state.specialty.push(newSpe);
    // addSpecialty([...state.specialty, newSpe]);
    if (!specialtyRedux) makeReduxSetShowModal().set({ specialty: true });

    setDisable(false);
    if (edit) {
      const upToDate = state.specialty.map((item, index) => {
        console.log('### if:', { index, IndexeditItem });
        if (index === Number(IndexeditItem)) {
          return {
            ...item,
            id: newSpe.id,
            docProf: newSpe.docProf,
            docProfUF: newSpe.docProfUF,
            docProfIssued: newSpe.docProfIssued,
          };
        }
        return item;
      });
      addSpecialty(upToDate);
    } else {
      state.specialty.push(newSpe);
    }
    setNewSpe({
      id: -1,
      docProf: '',
      docProfUF: 'AC',
      docProfIssued: '',
    });

    unregister('id');
    unregister('docProf');
    unregister('docProfUF');
    unregister('docProfIssued');
    setEdit(false);
  });

  useEffect(() => {
    makeRemoteGetUfs()
      .getUfs({})
      .then(setUfs)
      .catch(err => {
        console.log('Erro ao buscar UFs: ', err);
      });
  }, []);

  useEffect(() => {
    if (handleSave) handleSave(state.specialty);
  }, [save]);

  useEffect(() => {
    makeReduxGetAllSpecialty().getAll({
      pageSize: 9999,
      filter: {
        enabled: true,
        profession: professionID,
      },
    });
    makeRemoteGetAllSpecialty()
      .getAll({
        pageSize: 9999,
        filter: {
          enabled: true,
          profession: professionID,
        },
      })
      .then(value => {
        setListSpecialty(value.results);
      })
      .catch(err => {
        console.log('Erro ao buscar Specialties: ', err);
      });
    if (Number.isNaN(professionID!) || professionID === undefined) {
      setListSpecialty([]);
    }
  }, [professionID]);

  useEffect(() => {
    // If adicionado para não atualizar após primeira inserção de IDs anteriores
    if (!previousIds.length) {
      const previousIdsArray: Array<number> = state.specialty.map(item =>
        Number(item.id),
      );

      if (handlePreviousData) handlePreviousData(previousIdsArray);
      setPreviousIds(previousIdsArray);
    }

    if (state.specialty.length) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [state.specialty]);

  return (
    <Container>
      <Card>
        <div id="item1">
          {listSpecialty !== undefined && (
            <Select
              name="id"
              label={translator('Especialidade')}
              placeholder="Placeholder"
              value={Number.isNaN(professionID!) ? -1 : newSpe.id}
              onChange={e => {
                setValue('id', Number(e.target.value));
                handleChange(e);
              }}
              register={() => register('id')}
              error={Boolean(errors.id)}
              message={errors.id?.message}
              autoFocus
              width="300px"
              required
              disabled={
                Number.isNaN(professionID) || professionID === undefined
              }
            >
              <option value={-1}>
                {translator('Selecione uma especialidade')}
              </option>
              {listSpecialty !== undefined &&
                state.specialty &&
                listSpecialty.map(speci => {
                  const alreadyUsed = Boolean(
                    state.specialty.find(
                      item => item.id === speci.specialty?.id,
                    ),
                  );
                  const isEditMode = Boolean(
                    edit && state.specialty[IndexeditItem ?? -1]?.id === speci.specialty?.id 
                  );
                  // Se opção já estiver sendo utilizada, não renderizar
                  if (alreadyUsed && !isEditMode) return null;

                  return (
                    <option value={speci.specialty?.id}>
                      {speci.specialty?.name}
                    </option>
                  );
                })}
            </Select>
          )}
        </div>
        <div id="item2">
          <Input
            name="docProf"
            label={translator('Registro (conselho regulador)')}
            value={newSpe.docProf}
            onChange={e => {
              setValue('docProf', e.target.value);
              handleChange(e);
            }}
            register={() => register('docProf')}
            error={Boolean(errors.docProf)}
            message={errors.docProf?.message || ''}
            disabled={Number.isNaN(professionID) || professionID === undefined}
          />
        </div>
        <div id="item3">
          <Select
            name="docProfUF"
            value={newSpe.docProfUF}
            label="UF"
            onChange={e => {
              setValue('docProfUF', e.target.value);
              handleChange(e);
            }}
            register={() => register('docProfUF')}
            error={Boolean(errors.docProfUF)}
            message={errors.docProfUF?.message || ''}
            width="100px"
            disabled={Number.isNaN(professionID) || professionID === undefined}
          >
            {ufs?.length > 0 &&
              ufs.map(uf => (
                <option key={uf.id} value={uf.sigla}>
                  {uf.sigla}
                </option>
              ))}
          </Select>
        </div>
        <div id="item4">
          <InputMask
            mask="99/99/9999"
            name="docProfIssued"
            value={newSpe.docProfIssued}
            disabled={Number.isNaN(professionID) || professionID === undefined}
            onChange={e => {
              setValue('docProfIssued', e.target.value);
              handleChange(e);
            }}
          >
            <Input
              register={() => register('docProfIssued')}
              error={Boolean(errors.docProfIssued)}
              message={errors.docProfIssued?.message}
              name="docProfIssued"
              label={translator('Data de emissão')}
              required
            />
          </InputMask>
        </div>
        <div className="action" style={{ marginTop: '25px', gap: '24px' }}>
          {edit && (
            <Button
              id="btnActionCancel"
              variant="secundary"
              rounded
              onClick={cancelEdit}
              height="48px"
            >
              Cancelar
            </Button>
          )}
          <Button
            id="btnAction"
            type="submit"
            variant="primary"
            rounded
            onClick={handleAdd}
            size={edit ? undefined : 'mini'}
            height="48px"
            disabled={Number.isNaN(professionID) || professionID === undefined}
          >
            {edit ? 'Salvar' : <IconPlus color="#0000ff" />}
          </Button>
        </div>
      </Card>
      {state.specialty.length !== 0 || edit ? (
        <NoEmptySpecialty>
          <StickyHeadTableSpecialty
            key={1}
            disabled={edit}
            columns={[
              translator('Especialidades'),
              translator('Registro do conselho'),
              translator('UF'),
              translator('Data de emissão'),
              translator('Ações'),
            ]}
            rows={state.specialty}
            deleteFunction={handleDelete}
            editFunticion={handleEdit}
            specialty={listSpecialty}
          >
            {/* {specialtyCreate.specialty.map((item, index) => {})} */}
          </StickyHeadTableSpecialty>
        </NoEmptySpecialty>
      ) : (
        <EmptySpecialty>
          <AddFiles />
          <Message>
            {translator(
              window.location.pathname.includes('/profile')
                ? 'Suas especialidades aparecerão aqui'
                : 'Adicione uma ou mais especialidades para concluir o cadastro',
            )}
          </Message>
        </EmptySpecialty>
      )}
    </Container>
  );
};

export default SpecialtyCard;
