/* eslint-disable */
import {
  createStyles,
  Divider,
  List,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { defaultTheme } from '~/presentation/base/themes';
import { sortByParameter2, iParameter2, iData2 } from '~/main/utils/dataSort';
import { IconSort } from '~/presentation/base/icons';
import {
  Container,
  StatusBar,
  Cancel,
  Wrapper,
  CardContainer,
} from './styles/StyledUserList';
import { translator } from '~/presentation/components/i18n';
import UserItemAdm from './UserItemAdm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      overflow: 'auto',
      margin: 0,
      padding: 0,
      maxHeight: 300,
      backgroundColor: defaultTheme.background,
    },
    dividerColor: {
      backgroundColor: defaultTheme.typographyLightGray,
    },
  }),
);

const dataTest: iData2[] = [
  {
    cod: '0109',
    cpf: '104.400.134-88',
    date: '02/03/23',
    email: 'paulo@gmail.com',
    name: 'Paulo',
    phone: '83 999997979',
    status: 'scheduled',
    surname: 'Carvalho',
    professional: 'Carlão da cruz',
    specialty: 'Cardiologia',
    participant: 'Lucrecia medeiros',
    status2: 'Agendado',
    timeStart: '10:00',
    timeEnd: '10:30',
    typeIs: 'Retorno',
    age: '45',
    statusSignature: 'SIGNED',
    time: '10:30',
    origin: 'Prescrição avulsa',
    clinicalDocument: 'Requisição de exame',
    register: 'Cardiologista',
  },
  {
    cod: '0209',
    cpf: '104.400.134-88',
    date: '01/02/23',
    email: 'lucas@gmail.com',
    name: 'Lucas',
    phone: '83 999997979',
    status: 'finished',
    surname: 'Carvalho',
    professional: 'Carlão da cruz',
    specialty: 'Cardiologia',
    participant: 'Lucrecia medeiros da silva',
    status2: 'Agendado',
    timeStart: '10:00',
    timeEnd: '10:30',
    typeIs: 'Retorno',
    age: '45',
    statusSignature: 'SIGNED',
    time: '10:30',
    origin: 'Prescrição avulsa',
    clinicalDocument: 'Requisição de exame(s)',
    register: 'Cardiologista',
  },
  {
    cod: '0309',
    cpf: '104.400.134-88',
    date: '10/04/23',
    email: 'joao@gmail.com',
    name: 'João',
    phone: '83 999997979',
    status: 'available',
    surname: 'Carvalho',
    professional: 'Carlão da cruz',
    specialty: 'Cardiologia',
    participant: 'Lucrecia medeiros',
    status2: 'Agendado',
    timeStart: '10:00',
    timeEnd: '10:30',
    typeIs: 'Retorno',
    age: '45',
    statusSignature: 'NOTREQUESTED',
    time: '10:30',
    origin: 'Prescrição avulsa',
    clinicalDocument: 'Requisição de exame(s)',
    register: 'Cardiologista',
  },
  {
    cod: '0409',
    cpf: '104.400.134-88',
    date: '02/02/21',
    email: 'carlos@gmail.com',
    name: 'Carlos',
    phone: '83 999997979',
    status: 'finished',
    surname: 'Carvalho',
    professional: 'Carlão da cruz',
    specialty: 'Cardiologia',
    participant: 'Lucrecia medeiros da silva',
    status2: 'Agendado',
    timeStart: '10:00',
    timeEnd: '10:30',
    typeIs: 'Retorno',
    age: '45',
    statusSignature: 'NOTREQUESTED',
    time: '10:30',
    origin: 'Prescrição avulsa',
    clinicalDocument: 'Requisição de exame(s)',
    register: 'Cardiologista',
  },
  {
    cod: '0509',
    cpf: '104.400.134-88',
    date: '02/03/23',
    email: 'marcelo@gmail.com',
    name: 'Marcelo',
    phone: '83 999997979',
    status: 'finished',
    surname: 'Carvalho',
    professional: 'Carlão da cruz',
    specialty: 'Cardiologia',
    participant: 'Lucrecia medeiros',
    status2: 'Agendado',
    timeStart: '10:00',
    timeEnd: '10:30',
    typeIs: 'Retorno',
    age: '45',
    statusSignature: 'NOTREQUESTED',
    time: '10:30',
    origin: 'Prescrição avulsa',
    clinicalDocument: 'Requisição de exame(s)',
    register: 'Cardiologista',
  },
];

interface UserListProps {
  list?: string;
  cod?: boolean;
  age?: boolean;
  time?: boolean;
  specialty?: boolean;
  participant?: boolean;
  avatar?: boolean;
  date?: boolean;
  email?: boolean;
  phone?: boolean;
  status2?: boolean;
  professional?: boolean;
  cpf?: boolean;
  name?: boolean;
  timeStart?: boolean;
  timeEnd?: boolean;
  typeIs?: boolean;
  register?: boolean;
  origin?: boolean;
  statusSignature?: 'SIGNED' | 'SUBMITTED' | 'NOTREQUESTED';
  clinicalDocument?: boolean;
  actions?: {
    details?: boolean;
    cancel?: boolean;
    person?: boolean;
    delete?: Boolean;
    shareEmail?: Boolean;
  };
}

const UserListAdm: React.FC<UserListProps> = ({
  cod,
  date,
  email,
  phone,
  age,
  time,
  status2,
  timeStart,
  timeEnd,
  typeIs,
  name,
  register,
  participant,
  cpf,
  specialty,
  professional,
  origin,
  clinicalDocument,
  statusSignature,
  actions,
  list = 'list-1',
}) => {
  const [data, setData] = useState(dataTest);
  const sortData = (parameter: iParameter2) => {
    const newData = sortByParameter2(data, parameter);
    setData([...newData]);
  };

  function renderList() {
    return data.map((item, index) => (
      <>
        <CardContainer>
          <UserItemAdm
            key={index}
            list={list}
            cod={cod ? item.cod : undefined}
            age={age ? item.age : undefined}
            date={date ? item.date : undefined}
            timeStart={timeStart ? item.timeStart : undefined}
            timeEnd={timeEnd ? item.timeEnd : undefined}
            name={name ? item.name : undefined}
            participant={participant ? item.participant : undefined}
            status2={status2 ? item.status2 : undefined}
            email={email ? item.email : undefined}
            typeIs={typeIs ? item.typeIs : undefined}
            specialty={specialty ? item.specialty : undefined}
            cpf={cpf ? item.cpf : undefined}
            register={register ? item.register : undefined}
            phone={phone ? item.phone : undefined}
            professional={professional ? item.professional : undefined}
            origin={origin ? item.origin : undefined}
            statusSignature={statusSignature ? item.statusSignature : undefined}
            clinicalDocument={
              clinicalDocument ? item.clinicalDocument : undefined
            }
            time={time ? item.time : undefined}
            actions={actions}
          />
        </CardContainer>
        <Divider />
      </>
    ));
  }
  const classes = useStyles();
  return (
    <Container>
      <StatusBar list={list}>
        {cod && (
          <Wrapper>
            <text>cod</text>
          </Wrapper>
        )}

        {date && (
          <Wrapper>
            <text>{translator('Data')}</text>
            <button onClick={() => sortData('date')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {participant && (
          <Wrapper>
            <text>{translator('Paciente')}</text>
            <button onClick={() => sortData('name')}>
              <IconSort />
            </button>
          </Wrapper>
        )}
        {name && (
          <Wrapper>
            <text>{translator('Nome completo')}</text>
            <button onClick={() => sortData('name')}>
              <IconSort />
            </button>
          </Wrapper>
        )}
        {cpf && (
          <Wrapper>
            <text>{translator('CPF')}</text>
          </Wrapper>
        )}

        {professional && (
          <Wrapper>
            <text>{translator('Profissional da saúde')}</text>
            <button onClick={() => sortData('name')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {age && (
          <Wrapper>
            <text>{translator('Idade')}</text>
            <button onClick={() => sortData('age')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {timeStart && (
          <Wrapper>
            <text>{translator('Início')}</text>
            <button onClick={() => sortData('date')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {timeEnd && (
          <Wrapper>
            <text>{translator('Fim')}</text>
            <button onClick={() => sortData('date')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {statusSignature && (
          <Wrapper>
            <text>{translator('Assinatura')}</text>
            <button onClick={() => sortData('status')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {specialty && (
          <Wrapper>
            <text>{translator('Especialidade')}</text>
          </Wrapper>
        )}

        {time && (
          <Wrapper>
            <text>{translator('Hora')}</text>
            <button onClick={() => sortData('status')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {origin && (
          <Wrapper>
            <text>{translator('Origem')}</text>
            <button onClick={() => sortData('status')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {clinicalDocument && (
          <Wrapper>
            <text>{translator('Documento clínico')}</text>
            <button onClick={() => sortData('status')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {typeIs && (
          <Wrapper>
            <text>{translator('Tipo')}</text>
            <button onClick={() => sortData('status')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        {register && (
          <Wrapper>
            <text>{translator('Registro de conselho')}</text>
          </Wrapper>
        )}

        {phone && (
          <Wrapper>
            <text>{translator('Contato')}</text>
          </Wrapper>
        )}

        {email && (
          <Wrapper>
            <text>{translator('E-mail')}</text>
          </Wrapper>
        )}

        {status2 && (
          <Wrapper>
            <text>{translator('Status')}</text>
            <button onClick={() => sortData('status')}>
              <IconSort />
            </button>
          </Wrapper>
        )}

        <Wrapper>{translator('Ações')}</Wrapper>
      </StatusBar>
      <Divider />
      <List className={classes.root}>{renderList()}</List>
    </Container>
  );
};

export default UserListAdm;
