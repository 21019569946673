import { iOrganizer } from '~/domain/interfaces/models/Organizer';
import { OrganizerTypes } from '~/domain/interfaces/redux/Organizer/types';
import { OrganizerActions } from './actions';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const initialState: iOrganizer = {
  loading: false,
  pageSize: 0,
  totalItems: 0,
  results: [],
};

const reducer = (
  state = initialState,
  action: OrganizerActions,
): iOrganizer => {
  switch (action.type) {
    case OrganizerTypes.GETALL:
      return { ...state, loading: true };
    case OrganizerTypes.GETALL_SUCCESS:
      return {
        ...state,
        pageSize: action.payload.pageSize,
        totalItems: action.payload.totalItems,
        results: action.payload.results,
        loading: false,
      };
    case OrganizerTypes.GETALL_FAILED:
      return { ...state, loading: false };
    case OrganizerTypes.CREATE:
      return { ...state, loading: true };
    case OrganizerTypes.CREATE_SUCCESS:
      return { ...state, loading: false };
    case OrganizerTypes.CREATE_FAILED:
      return { ...state, loading: false };
    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
