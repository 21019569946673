import React from 'react';

import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { Container } from './styles';

const InputDatePage: React.FC = () => {
  return (
    <Container>
      <InputKeyboardDate
        label="Selecione uma data"
        state={new Date()}
        setState={() => 'string'}
      />
    </Container>
  );
};

export default InputDatePage;
