import { takeLatest } from 'redux-saga/effects';
import { onGetAll, onGetAllSuccess, onGetAllFailed } from './GetAll';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { OrganizerTypes } from '~/domain/interfaces/redux/Organizer/types';

const tasks = [
  takeLatest(OrganizerTypes.GETALL, onGetAll),
  takeLatest(OrganizerTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(OrganizerTypes.GETALL_FAILED, onGetAllFailed),
  takeLatest(OrganizerTypes.CREATE, onCreate),
  takeLatest(OrganizerTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(OrganizerTypes.CREATE_FAILED, onCreateFailed),
];

export default tasks;
