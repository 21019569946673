import React, { useState } from 'react';

import {
  Body,
  Container,
  Content,
  ConfirmCancel,
  CloseButton,
  Header,
  Text,
} from './styles';

import { Button } from '../../UI';

import { ownProps } from '../interface';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import { translator } from '../../i18n';

const AppointmentStop: React.FC<ownProps> = ({ message }) => {
  const [ExcludeVisible, setExcludeVisible] = useState<boolean>(false);

  const msgName = MessageOptions.appointmentStop;

  const { active, actionCancel, actionOk } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span>{translator('Deseja finalizar o atendimento?')}</span>
              <CloseButton onClick={actionCancel}>X</CloseButton>
            </Header>

            <Content>
              {translator(
                'Caso opte por não finalizar o atendimento agora, você poderá finalizá-lo posteriormente.',
              )}
            </Content>
            {/* <Text>Deseja realmente excluir este usuário?</Text> */}
            <Button
              rounded
              variant="primary"
              size="large"
              onClick={actionCancel}
              autoFocus
            >
              {translator('Não finalizar')}
            </Button>
            <ConfirmCancel onClick={actionOk}>
              {translator('Sim, finalizar agora')}
            </ConfirmCancel>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default AppointmentStop;
