import React from 'react';

// import { Container } from './styles';

const TestUserListProfessional: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <div>
        {/* <UserListProfessional
          list="list-1"
          participant
          timeStart
          timeEnd
          typeIs
          specialty
          status
          actions={{ cancel: true, person: true, delete: true, details: true }}
        /> */}
      </div>
      <div style={{ marginTop: 20 }}>
        {/* <UserListProfessional
          list="list-2"
          cod
          date
          participant
          age
          phone
          email
          actions={{ cancel: true, person: true, delete: true }}
        /> */}
      </div>
      <div style={{ marginTop: 20 }}>
        {/* <UserListProfessional
          list="list-3"
          date
          participant
          statusSignature
          specialty
          time
          origin
          clinicalDocument
          actions={{ cancel: true, person: true }}
        /> */}
      </div>
    </div>
  );
};

export default TestUserListProfessional;
