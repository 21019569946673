import React from 'react';
import { useSelector } from 'react-redux';

import { iStore } from '~/domain/interfaces/models';
import { BodyChat, HeaderChat, FooterChat } from '.';
import { ContainerChat } from './styles/StyledChat';
import { ConnectComponent } from './mapper/MapperChat';
import { iUser, iMessages, iChat } from './interface';
import { chatSetup } from '~/data/store/reducer/chat/actions/setup';

export interface ownProps {
  actionClose: Function;
  sendMessage: Function;
  activeChat: iChat;
  userRoot: iUser;
  userLogged: number;
}

const Chat = (props: ownProps) => {
  const { chat } = useSelector((store: iStore) => store);
  const { userRoot, activeChat, sendMessage, userLogged } = props;

  /* const content = [
    {
      user: {
        id: 0,
        firstName: 'Denilson',
        lastName: 'Pedro',
      },
      message: {
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        date: '14:12',
      },
    },
    {
      user: {
        id: 1,
        firstName: 'Clistenes',
        lastName: 'Oanassis',
      },
      message: {
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Libero id faucibus nisl tincidunt eget nullam non nisi est. Nulla aliquet porttitor lacus luctus accumsan tortor. Orci dapibus ultrices in iaculis nunc sed augue. Diam vel quam elementum pulvinar etiam. Lectus arcu bibendum at varius vel. Morbi tristique senectus et netus et. Sit amet nisl purus in mollis nunc. Sed ullamcorper morbi tincidunt ornare massa. Accumsan tortor posuere ac ut consequat semper viverra. Ornare massa eget egestas purus viverra accumsan. Pretium fusce id velit ut. Sed egestas egestas fringilla phasellus faucibus. Et tortor consequat id porta nibh venenatis. Neque egestas congue quisque egestas diam.',
        date: '14:50',
      },
    },
  ];

  const userRoot = {
    id: 1,
    firstName: 'Clistenes',
    lastName: 'Oanassis',
  }; */

  return (
    <ContainerChat style={{ display: chat.visible ? 'block' : 'none' }}>
      <HeaderChat user={userRoot} close={() => props.actionClose()} />
      {userRoot !== undefined && chat.visible && (
        <>
          <BodyChat
            content={activeChat.messages}
            user={userRoot}
            userLogged={userLogged}
          />
          <FooterChat
            emiter={userRoot.id}
            chatId={activeChat.id}
            sendMessage={sendMessage}
          />
        </>
      )}
    </ContainerChat>
  );
};

export default ConnectComponent(Chat);
