import React from 'react';

import { Select } from '~/presentation/components/UI';
import { Container } from './styles';

const SelectPage: React.FC = () => {
  return (
    <Container>
      <Select
        label="Input label"
        message="Helper text"
        placeholder="Placeholder"
        name="default"
      >
        <option value="volvo">Volvo</option>
        <option value="saab">Saab</option>
        <option value="mercedes">Mercedes</option>
        <option value="audi">Audi</option>
      </Select>
      <br />
      <Select
        label="Input label"
        message="Helper text"
        placeholder="Placeholder"
        name="default"
        disabled
      >
        <option value="volvo">Volvo</option>
        <option value="saab">Saab</option>
        <option value="mercedes">Mercedes</option>
        <option value="audi">Audi</option>
      </Select>
      <br />
      <Select
        label="Input label"
        message="Error message"
        placeholder="Placeholder"
        name="default"
        error
      >
        <option value="volvo">Volvo</option>
        <option value="saab">Saab</option>
        <option value="mercedes">Mercedes</option>
        <option value="audi">Audi</option>
      </Select>
    </Container>
  );
};

export default SelectPage;
